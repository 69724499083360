@import url(https://rsms.me/inter/inter.css);
body{
  -webkit-font-smoothing:antialiased;
  -moz-osx-font-smoothing:grayscale
}

._ods_sr_only_ih07p_6{
  position:absolute;
  width:1px;
  height:1px;
  padding:0;
  margin:-1px;
  overflow:hidden;
  clip:rect(0,0,0,0);
  white-space:nowrap;
  border-width:0
}

*,:before,:after{
  box-sizing:border-box;
  border-width:0;
  border-style:solid;
  border-color:#e5e7eb
}

:before,:after{
  --tw-content: ""
}

html{
  line-height:1.5;
  -webkit-text-size-adjust:100%;
  tab-size:4;
  font-family:"Inter var",ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-family:var(--font-family),ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"
}

body{
  margin:0;
  line-height:inherit
}

hr{
  height:0;
  color:inherit;
  border-top-width:1px
}

abbr:where([title]){
  -webkit-text-decoration:underline dotted;
  text-decoration:underline dotted
}

h1,h2,h3,h4,h5,h6{
  font-size:inherit;
  font-weight:inherit
}

a{
  color:inherit;
  text-decoration:inherit
}

b,strong{
  font-weight:bolder
}

code,kbd,samp,pre{
  font-family:ui-monospace,SFMono-Regular,Menlo,Monaco,Consolas,Liberation Mono,Courier New,monospace;
  font-size:1em
}

small{
  font-size:80%
}

sub,sup{
  font-size:75%;
  line-height:0;
  position:relative;
  vertical-align:baseline
}

sub{
  bottom:-.25em
}

sup{
  top:-.5em
}

table{
  text-indent:0;
  border-color:inherit;
  border-collapse:collapse
}

button,input,optgroup,select,textarea{
  font-family:inherit;
  font-size:100%;
  line-height:inherit;
  color:inherit;
  margin:0;
  padding:0
}

button,select{
  text-transform:none
}

button,[type=button],[type=reset],[type=submit]{
  -webkit-appearance:button;
  background-color:transparent;
  background-image:none
}

:-moz-focusring{
  outline:auto
}

:-moz-ui-invalid{
  box-shadow:none
}

progress{
  vertical-align:baseline
}

::-webkit-inner-spin-button,::-webkit-outer-spin-button{
  height:auto
}

[type=search]{
  -webkit-appearance:textfield;
  outline-offset:-2px
}

::-webkit-search-decoration{
  -webkit-appearance:none
}

::-webkit-file-upload-button{
  -webkit-appearance:button;
  font:inherit
}

summary{
  display:list-item
}

blockquote,dl,dd,h1,h2,h3,h4,h5,h6,hr,figure,p,pre{
  margin:0
}

fieldset{
  margin:0;
  padding:0
}

legend{
  padding:0
}

ol,ul,menu{
  list-style:none;
  margin:0;
  padding:0
}

textarea{
  resize:vertical
}

input:-ms-input-placeholder,textarea:-ms-input-placeholder{
  opacity:1;
  color:#9ca3af
}

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder{
  opacity:1;
  color:#9ca3af
}

input:-ms-input-placeholder, textarea:-ms-input-placeholder{
  opacity:1;
  color:#9ca3af
}

input::placeholder,textarea::placeholder{
  opacity:1;
  color:#9ca3af
}

button,[role=button]{
  cursor:pointer
}

:disabled{
  cursor:default
}

img,svg,video,canvas,audio,iframe,embed,object{
  display:block;
  vertical-align:middle
}

img,video{
  max-width:100%;
  height:auto
}

[hidden]{
  display:none
}

:root{
  --font-family: "Inter var";
  --color-white: 255, 255, 255;
  --color-black: 0, 0, 0;
  --color-primary-50: 238, 242, 255;
  --color-primary-100: 224, 231, 255;
  --color-primary-200: 199, 210, 254;
  --color-primary-300: 165, 180, 252;
  --color-primary-400: 129, 140, 248;
  --color-primary-500: 99, 102, 241;
  --color-primary-600: 79, 70, 229;
  --color-primary-700: 67, 56, 202;
  --color-primary-800: 55, 48, 163;
  --color-primary-900: 49, 46, 129;
  --color-light-50: 249, 250, 251;
  --color-light-100: 243, 244, 246;
  --color-light-200: 229, 231, 235;
  --color-light-300: 209, 213, 219;
  --color-light-400: 156, 163, 175;
  --color-dark-500: 107, 114, 128;
  --color-dark-600: 75, 85, 99;
  --color-dark-700: 55, 65, 81;
  --color-dark-800: 31, 41, 55;
  --color-dark-900: 17, 24, 39;
  --color-info-50: 239, 246, 255;
  --color-info-100: 219, 234, 254;
  --color-info-200: 191, 219, 254;
  --color-info-300: 147, 197, 253;
  --color-info-400: 96, 165, 250;
  --color-info-500: 59, 130, 246;
  --color-info-600: 37, 99, 235;
  --color-info-700: 29, 78, 216;
  --color-info-800: 30, 64, 175;
  --color-info-900: 30, 58, 138;
  --color-danger-50: 254, 242, 242;
  --color-danger-100: 254, 226, 226;
  --color-danger-200: 254, 202, 202;
  --color-danger-300: 252, 165, 165;
  --color-danger-400: 248, 113, 113;
  --color-danger-500: 239, 68, 68;
  --color-danger-600: 220, 38, 38;
  --color-danger-700: 185, 28, 28;
  --color-danger-800: 153, 27, 27;
  --color-danger-900: 127, 29, 29;
  --color-success-50: 236, 253, 245;
  --color-success-100: 209, 250, 229;
  --color-success-200: 167, 243, 208;
  --color-success-300: 110, 231, 183;
  --color-success-400: 52, 211, 153;
  --color-success-500: 16, 185, 129;
  --color-success-600: 5, 150, 105;
  --color-success-700: 4, 120, 87;
  --color-success-800: 6, 95, 70;
  --color-success-900: 6, 78, 59;
  --color-warning-50: 255, 251, 235;
  --color-warning-100: 254, 243, 199;
  --color-warning-200: 253, 230, 138;
  --color-warning-300: 252, 211, 77;
  --color-warning-400: 251, 191, 36;
  --color-warning-500: 245, 158, 11;
  --color-warning-600: 217, 119, 6;
  --color-warning-700: 180, 83, 9;
  --color-warning-800: 146, 64, 14;
  --color-warning-900: 120, 53, 15
}

*,:before,:after{
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / .5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia:
}

._container_ih07p_1{
  width:100%
}

@media (min-width: 640px){
  ._container_ih07p_1{
    max-width:640px
  }
}

@media (min-width: 768px){
  ._container_ih07p_1{
    max-width:768px
  }
}

@media (min-width: 1024px){
  ._container_ih07p_1{
    max-width:1024px
  }
}

@media (min-width: 1280px){
  ._container_ih07p_1{
    max-width:1280px
  }
}

@media (min-width: 1536px){
  ._container_ih07p_1{
    max-width:1536px
  }
}

._sr-only_ih07p_1{
  position:absolute;
  width:1px;
  height:1px;
  padding:0;
  margin:-1px;
  overflow:hidden;
  clip:rect(0,0,0,0);
  white-space:nowrap;
  border-width:0
}

._pointer-events-none_ih07p_1{
  pointer-events:none
}

._absolute_ih07p_1{
  position:absolute
}

._relative_ih07p_1{
  position:relative
}

._sticky_ih07p_1{
  position:-webkit-sticky;
  position:sticky
}

._inset-0_ih07p_1{
  inset:0
}

._inset-y-0_ih07p_1{
  top:0;
  bottom:0
}

._right-0_ih07p_1{
  right:0
}

._top-0_ih07p_1{
  top:0
}

._left-0_ih07p_1{
  left:0
}

._z-10_ih07p_1{
  z-index:10
}

._order-2_ih07p_1{
  order:2
}

._col-span-2_ih07p_1{
  grid-column:span 2 / span 2
}

._-m-2_ih07p_1{
  margin:-.5rem
}

._m-2_ih07p_1{
  margin:.5rem
}

._mx-auto_ih07p_1{
  margin-left:auto;
  margin-right:auto
}

._my-4_ih07p_1{
  margin-top:1rem;
  margin-bottom:1rem
}

._mx-8_ih07p_1{
  margin-left:2rem;
  margin-right:2rem
}

._mx-1_ih07p_1{
  margin-left:.25rem;
  margin-right:.25rem
}

._mt-6_ih07p_1{
  margin-top:1.5rem
}

._mt-2_ih07p_1{
  margin-top:.5rem
}

._ml-1_ih07p_1{
  margin-left:.25rem
}

._mt-8_ih07p_1{
  margin-top:2rem
}

._ml-2_ih07p_1{
  margin-left:.5rem
}

._-ml-2_ih07p_1{
  margin-left:-.5rem
}

._mt-1_ih07p_1{
  margin-top:.25rem
}

._ml-3_ih07p_1{
  margin-left:.75rem
}

._mt-3_ih07p_1{
  margin-top:.75rem
}

._mt-10_ih07p_1{
  margin-top:2.5rem
}

._mt-4_ih07p_1{
  margin-top:1rem
}

._ml-4_ih07p_1{
  margin-left:1rem
}

._mb-2\.5_ih07p_1{
  margin-bottom:.625rem
}

._mb-2_ih07p_1{
  margin-bottom:.5rem
}

._mr-1\.5_ih07p_1{
  margin-right:.375rem
}

._mr-1_ih07p_1{
  margin-right:.25rem
}

._mr-5_ih07p_1{
  margin-right:1.25rem
}

._mt-1\.5_ih07p_1{
  margin-top:.375rem
}

._-mt-20_ih07p_1{
  margin-top:-5rem
}

._-mt-2_ih07p_1{
  margin-top:-.5rem
}

._ml-5_ih07p_1{
  margin-left:1.25rem
}

._ml-0_ih07p_1{
  margin-left:0
}

._mb-4_ih07p_1{
  margin-bottom:1rem
}

._mb-1\.5_ih07p_1{
  margin-bottom:.375rem
}

._mb-1_ih07p_1{
  margin-bottom:.25rem
}

._ml-6_ih07p_1{
  margin-left:1.5rem
}

._mb-px_ih07p_1{
  margin-bottom:1px
}

._mr-3_ih07p_1{
  margin-right:.75rem
}

._ml-7_ih07p_1{
  margin-left:1.75rem
}

._mt-2\.5_ih07p_1{
  margin-top:.625rem
}

._mb-5_ih07p_1{
  margin-bottom:1.25rem
}

._mt-0_ih07p_1{
  margin-top:0
}

._mb-8_ih07p_1{
  margin-bottom:2rem
}

._ml-0\.5_ih07p_1{
  margin-left:.125rem
}

._ml-auto_ih07p_1{
  margin-left:auto
}

._mt-px_ih07p_1{
  margin-top:1px
}

._block_ih07p_1{
  display:block
}

._inline-block_ih07p_1{
  display:inline-block
}

._inline_ih07p_1{
  display:inline
}

._\!inline_ih07p_1{
  display:inline!important
}

._flex_ih07p_1{
  display:flex
}

._inline-flex_ih07p_1{
  display:inline-flex
}

._table_ih07p_1{
  display:table
}

._\!table_ih07p_1{
  display:table!important
}

._grid_ih07p_1{
  display:grid
}

._contents_ih07p_1{
  display:contents
}

._hidden_ih07p_1{
  display:none
}

._h-12_ih07p_1{
  height:3rem
}

._h-5_ih07p_1{
  height:1.25rem
}

._h-full_ih07p_1{
  height:100%
}

._h-16_ih07p_1{
  height:4rem
}

._h-6_ih07p_1{
  height:1.5rem
}

._h-10_ih07p_1{
  height:2.5rem
}

._h-32_ih07p_1{
  height:8rem
}

._h-24_ih07p_1{
  height:6rem
}

._h-screen_ih07p_1{
  height:100vh
}

._h-20_ih07p_1{
  height:5rem
}

._h-2_ih07p_1{
  height:.5rem
}

._h-8_ih07p_1{
  height:2rem
}

._h-48_ih07p_1{
  height:12rem
}

._h-64_ih07p_1{
  height:16rem
}

._h-96_ih07p_1{
  height:24rem
}

._min-h-\[640px\]_ih07p_1{
  min-height:640px
}

._w-auto_ih07p_1{
  width:auto
}

._w-5_ih07p_1{
  width:1.25rem
}

._w-full_ih07p_1{
  width:100%
}

._w-0_ih07p_1{
  width:0px
}

._w-16_ih07p_1{
  width:4rem
}

._w-6_ih07p_1{
  width:1.5rem
}

._w-10_ih07p_1{
  width:2.5rem
}

._w-24_ih07p_1{
  width:6rem
}

._w-20_ih07p_1{
  width:5rem
}

._w-2_ih07p_1{
  width:.5rem
}

._w-12_ih07p_1{
  width:3rem
}

._w-32_ih07p_1{
  width:8rem
}

._w-64_ih07p_1{
  width:16rem
}

._min-w-0_ih07p_1{
  min-width:0px
}

._max-w-sm_ih07p_1{
  max-width:24rem
}

._max-w-3xl_ih07p_1{
  max-width:48rem
}

._max-w-2xl_ih07p_1{
  max-width:42rem
}

._max-w-lg_ih07p_1{
  max-width:32rem
}

._max-w-xs_ih07p_1{
  max-width:20rem
}

._flex-1_ih07p_1{
  flex:1 1
}

._flex-shrink-0_ih07p_1{
  flex-shrink:0
}

._flex-grow_ih07p_1{
  flex-grow:1
}

._rotate-\[-120deg\]_ih07p_1{
  --tw-rotate: -120deg;
  -webkit-transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

._rotate-0_ih07p_1{
  --tw-rotate: 0deg;
  -webkit-transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

._scale-50_ih07p_1{
  --tw-scale-x: .5;
  --tw-scale-y: .5;
  -webkit-transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

._scale-100_ih07p_1{
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  -webkit-transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

._scale-95_ih07p_1{
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  -webkit-transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

._transform_ih07p_1{
  -webkit-transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

._cursor-pointer_ih07p_1{
  cursor:pointer
}

._select-all_ih07p_1{
  -webkit-user-select:all;
  -ms-user-select:all;
  user-select:all
}

._list-disc_ih07p_1{
  list-style-type:disc
}

._grid-cols-1_ih07p_1{
  grid-template-columns:repeat(1,minmax(0,1fr))
}

._grid-cols-3_ih07p_1{
  grid-template-columns:repeat(3,minmax(0,1fr))
}

._flex-row_ih07p_1{
  flex-direction:row
}

._flex-col_ih07p_1{
  flex-direction:column
}

._flex-col-reverse_ih07p_1{
  flex-direction:column-reverse
}

._flex-wrap_ih07p_1{
  flex-wrap:wrap
}

._items-start_ih07p_1{
  align-items:flex-start
}

._items-end_ih07p_1{
  align-items:flex-end
}

._items-center_ih07p_1{
  align-items:center
}

._items-stretch_ih07p_1{
  align-items:stretch
}

._justify-start_ih07p_1{
  justify-content:flex-start
}

._justify-end_ih07p_1{
  justify-content:flex-end
}

._justify-center_ih07p_1{
  justify-content:center
}

._justify-between_ih07p_1{
  justify-content:space-between
}

._gap-3_ih07p_1{
  grid-gap:.75rem;
  gap:.75rem
}

._gap-6_ih07p_1{
  grid-gap:1.5rem;
  gap:1.5rem
}

._gap-4_ih07p_1{
  grid-gap:1rem;
  gap:1rem
}

._gap-x-4_ih07p_1{
  -moz-column-gap:1rem;
  grid-column-gap:1rem;
  -webkit-column-gap:1rem;
  column-gap:1rem
}

._space-y-6_ih07p_1>:not([hidden])~:not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top:calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom:calc(1.5rem * var(--tw-space-y-reverse))
}

._space-y-1_ih07p_1>:not([hidden])~:not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top:calc(.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom:calc(.25rem * var(--tw-space-y-reverse))
}

._space-y-3_ih07p_1>:not([hidden])~:not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top:calc(.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom:calc(.75rem * var(--tw-space-y-reverse))
}

._space-x-4_ih07p_1>:not([hidden])~:not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right:calc(1rem * var(--tw-space-x-reverse));
  margin-left:calc(1rem * calc(1 - var(--tw-space-x-reverse)))
}

._space-x-3_ih07p_1>:not([hidden])~:not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right:calc(.75rem * var(--tw-space-x-reverse));
  margin-left:calc(.75rem * calc(1 - var(--tw-space-x-reverse)))
}

._space-y-4_ih07p_1>:not([hidden])~:not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top:calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom:calc(1rem * var(--tw-space-y-reverse))
}

._space-y-2_ih07p_1>:not([hidden])~:not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top:calc(.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom:calc(.5rem * var(--tw-space-y-reverse))
}

._-space-x-1_ih07p_1>:not([hidden])~:not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right:calc(-.25rem * var(--tw-space-x-reverse));
  margin-left:calc(-.25rem * calc(1 - var(--tw-space-x-reverse)))
}

._divide-y_ih07p_1>:not([hidden])~:not([hidden]){
  --tw-divide-y-reverse: 0;
  border-top-width:calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width:calc(1px * var(--tw-divide-y-reverse))
}

._divide-none_ih07p_1>:not([hidden])~:not([hidden]){
  border-style:none
}

._divide-light-200_ih07p_1>:not([hidden])~:not([hidden]){
  --tw-divide-opacity: 1;
  border-color:rgba(229, 231, 235,var(--tw-divide-opacity));
  border-color:rgba(var(--color-light-200),var(--tw-divide-opacity))
}

._self-center_ih07p_1{
  align-self:center
}

._overflow-hidden_ih07p_1{
  overflow:hidden
}

._truncate_ih07p_1{
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap
}

._whitespace-nowrap_ih07p_1{
  white-space:nowrap
}

._rounded_ih07p_1{
  border-radius:.25rem
}

._rounded-xl_ih07p_1{
  border-radius:.75rem
}

._rounded-lg_ih07p_1{
  border-radius:.5rem
}

._rounded-full_ih07p_1{
  border-radius:9999px
}

._rounded-md_ih07p_1{
  border-radius:.375rem
}

._rounded-none_ih07p_1{
  border-radius:0
}

._rounded-t-md_ih07p_1{
  border-top-left-radius:.375rem;
  border-top-right-radius:.375rem
}

._rounded-b-md_ih07p_1{
  border-bottom-right-radius:.375rem;
  border-bottom-left-radius:.375rem
}

._border_ih07p_1{
  border-width:1px
}

._border-2_ih07p_1{
  border-width:2px
}

._border-4_ih07p_1{
  border-width:4px
}

._border-y-0_ih07p_1{
  border-top-width:0px;
  border-bottom-width:0px
}

._border-t_ih07p_1{
  border-top-width:1px
}

._border-b_ih07p_1{
  border-bottom-width:1px
}

._border-t-0_ih07p_1{
  border-top-width:0px
}

._border-l_ih07p_1{
  border-left-width:1px
}

._border-r-0_ih07p_1{
  border-right-width:0px
}

._border-dashed_ih07p_1{
  border-style:dashed
}

._border-none_ih07p_1{
  border-style:none
}

._border-light-200_ih07p_1{
  --tw-border-opacity: 1;
  border-color:rgba(229, 231, 235,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-200),var(--tw-border-opacity))
}

._border-transparent_ih07p_1{
  border-color:transparent
}

._border-light-300_ih07p_1{
  --tw-border-opacity: 1;
  border-color:rgba(209, 213, 219,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-300),var(--tw-border-opacity))
}

._border-white_ih07p_1{
  --tw-border-opacity: 1;
  border-color:rgba(255, 255, 255,var(--tw-border-opacity));
  border-color:rgba(var(--color-white),var(--tw-border-opacity))
}

._border-light-100_ih07p_1{
  --tw-border-opacity: 1;
  border-color:rgba(243, 244, 246,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-100),var(--tw-border-opacity))
}

._border-light-400_ih07p_1{
  --tw-border-opacity: 1;
  border-color:rgba(156, 163, 175,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-400),var(--tw-border-opacity))
}

._bg-light-50_ih07p_1{
  --tw-bg-opacity: 1;
  background-color:rgba(249, 250, 251,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-50),var(--tw-bg-opacity))
}

._bg-pink-500_ih07p_1{
  --tw-bg-opacity: 1;
  background-color:rgb(236 72 153 / var(--tw-bg-opacity))
}

._bg-warning-500_ih07p_1{
  --tw-bg-opacity: 1;
  background-color:rgba(245, 158, 11,var(--tw-bg-opacity));
  background-color:rgba(var(--color-warning-500),var(--tw-bg-opacity))
}

._bg-success-500_ih07p_1{
  --tw-bg-opacity: 1;
  background-color:rgba(16, 185, 129,var(--tw-bg-opacity));
  background-color:rgba(var(--color-success-500),var(--tw-bg-opacity))
}

._bg-info-500_ih07p_1{
  --tw-bg-opacity: 1;
  background-color:rgba(59, 130, 246,var(--tw-bg-opacity));
  background-color:rgba(var(--color-info-500),var(--tw-bg-opacity))
}

._bg-primary-500_ih07p_1{
  --tw-bg-opacity: 1;
  background-color:rgba(99, 102, 241,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-500),var(--tw-bg-opacity))
}

._bg-purple-500_ih07p_1{
  --tw-bg-opacity: 1;
  background-color:rgb(168 85 247 / var(--tw-bg-opacity))
}

._bg-light-100_ih07p_1{
  --tw-bg-opacity: 1;
  background-color:rgba(243, 244, 246,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-100),var(--tw-bg-opacity))
}

._bg-primary-600_ih07p_1{
  --tw-bg-opacity: 1;
  background-color:rgba(79, 70, 229,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-600),var(--tw-bg-opacity))
}

._bg-light-400_ih07p_1{
  --tw-bg-opacity: 1;
  background-color:rgba(156, 163, 175,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-400),var(--tw-bg-opacity))
}

._bg-dark-600_ih07p_1{
  --tw-bg-opacity: 1;
  background-color:rgba(75, 85, 99,var(--tw-bg-opacity));
  background-color:rgba(var(--color-dark-600),var(--tw-bg-opacity))
}

._bg-white_ih07p_1{
  --tw-bg-opacity: 1;
  background-color:rgba(255, 255, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-white),var(--tw-bg-opacity))
}

._bg-light-200_ih07p_1{
  --tw-bg-opacity: 1;
  background-color:rgba(229, 231, 235,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-200),var(--tw-bg-opacity))
}

._bg-success-400_ih07p_1{
  --tw-bg-opacity: 1;
  background-color:rgba(52, 211, 153,var(--tw-bg-opacity));
  background-color:rgba(var(--color-success-400),var(--tw-bg-opacity))
}

._bg-transparent_ih07p_1{
  background-color:transparent
}

._bg-red-800_ih07p_1{
  --tw-bg-opacity: 1;
  background-color:rgb(153 27 27 / var(--tw-bg-opacity))
}

._bg-black_ih07p_1{
  --tw-bg-opacity: 1;
  background-color:rgba(0, 0, 0,var(--tw-bg-opacity));
  background-color:rgba(var(--color-black),var(--tw-bg-opacity))
}

._bg-gray-50_ih07p_1{
  --tw-bg-opacity: 1;
  background-color:rgb(249 250 251 / var(--tw-bg-opacity))
}

._bg-danger-200_ih07p_1{
  --tw-bg-opacity: 1;
  background-color:rgba(254, 202, 202,var(--tw-bg-opacity));
  background-color:rgba(var(--color-danger-200),var(--tw-bg-opacity))
}

._bg-info-400_ih07p_1{
  --tw-bg-opacity: 1;
  background-color:rgba(96, 165, 250,var(--tw-bg-opacity));
  background-color:rgba(var(--color-info-400),var(--tw-bg-opacity))
}

._bg-danger-600_ih07p_1{
  --tw-bg-opacity: 1;
  background-color:rgba(220, 38, 38,var(--tw-bg-opacity));
  background-color:rgba(var(--color-danger-600),var(--tw-bg-opacity))
}

._bg-gray-100_ih07p_1{
  --tw-bg-opacity: 1;
  background-color:rgb(243 244 246 / var(--tw-bg-opacity))
}

._bg-success-50_ih07p_1{
  --tw-bg-opacity: 1;
  background-color:rgba(236, 253, 245,var(--tw-bg-opacity));
  background-color:rgba(var(--color-success-50),var(--tw-bg-opacity))
}

._bg-opacity-20_ih07p_1{
  --tw-bg-opacity: .2
}

._fill-white_ih07p_1{
  fill:rgb(255, 255, 255);
  fill:rgb(var(--color-white))
}

._object-cover_ih07p_1{
  object-fit:cover
}

._p-0_ih07p_1{
  padding:0
}

._p-2_ih07p_1{
  padding:.5rem
}

._p-8_ih07p_1{
  padding:2rem
}

._py-12_ih07p_1{
  padding-top:3rem;
  padding-bottom:3rem
}

._px-4_ih07p_1{
  padding-left:1rem;
  padding-right:1rem
}

._py-8_ih07p_1{
  padding-top:2rem;
  padding-bottom:2rem
}

._px-2_ih07p_1{
  padding-left:.5rem;
  padding-right:.5rem
}

._py-6_ih07p_1{
  padding-top:1.5rem;
  padding-bottom:1.5rem
}

._py-4_ih07p_1{
  padding-top:1rem;
  padding-bottom:1rem
}

._px-3_ih07p_1{
  padding-left:.75rem;
  padding-right:.75rem
}

._py-2_ih07p_1{
  padding-top:.5rem;
  padding-bottom:.5rem
}

._px-6_ih07p_1{
  padding-left:1.5rem;
  padding-right:1.5rem
}

._py-5_ih07p_1{
  padding-top:1.25rem;
  padding-bottom:1.25rem
}

._px-0_ih07p_1{
  padding-left:0;
  padding-right:0
}

._py-0_ih07p_1{
  padding-top:0;
  padding-bottom:0
}

._px-2\.5_ih07p_1{
  padding-left:.625rem;
  padding-right:.625rem
}

._py-0\.5_ih07p_1{
  padding-top:.125rem;
  padding-bottom:.125rem
}

._py-16_ih07p_1{
  padding-top:4rem;
  padding-bottom:4rem
}

._py-3_ih07p_1{
  padding-top:.75rem;
  padding-bottom:.75rem
}

._px-12_ih07p_1{
  padding-left:3rem;
  padding-right:3rem
}

._py-1_ih07p_1{
  padding-top:.25rem;
  padding-bottom:.25rem
}

._pb-2_ih07p_1{
  padding-bottom:.5rem
}

._pl-5_ih07p_1{
  padding-left:1.25rem
}

._pl-4_ih07p_1{
  padding-left:1rem
}

._pb-1\.5_ih07p_1{
  padding-bottom:.375rem
}

._pb-1_ih07p_1{
  padding-bottom:.25rem
}

._pl-1_ih07p_1{
  padding-left:.25rem
}

._pt-2_ih07p_1{
  padding-top:.5rem
}

._pl-3_ih07p_1{
  padding-left:.75rem
}

._pr-4_ih07p_1{
  padding-right:1rem
}

._pt-8_ih07p_1{
  padding-top:2rem
}

._pt-4_ih07p_1{
  padding-top:1rem
}

._pl-8_ih07p_1{
  padding-left:2rem
}

._pr-3_ih07p_1{
  padding-right:.75rem
}

._text-left_ih07p_1{
  text-align:left
}

._text-center_ih07p_1{
  text-align:center
}

._text-right_ih07p_1{
  text-align:right
}

._text-sm_ih07p_1{
  font-size:.875rem;
  line-height:1.25rem
}

._text-lg_ih07p_1{
  font-size:1.125rem;
  line-height:1.75rem
}

._text-xs_ih07p_1{
  font-size:.75rem;
  line-height:1rem
}

._text-2xl_ih07p_1{
  font-size:1.5rem;
  line-height:2rem
}

._text-xl_ih07p_1{
  font-size:1.25rem;
  line-height:1.75rem
}

._text-3xl_ih07p_1{
  font-size:1.875rem;
  line-height:2.25rem
}

._font-bold_ih07p_1{
  font-weight:700
}

._font-extrabold_ih07p_1{
  font-weight:800
}

._font-medium_ih07p_1{
  font-weight:500
}

._font-semibold_ih07p_1{
  font-weight:600
}

._font-light_ih07p_1{
  font-weight:300
}

._font-normal_ih07p_1{
  font-weight:400
}

._uppercase_ih07p_1{
  text-transform:uppercase
}

._leading-7_ih07p_1{
  line-height:1.75rem
}

._leading-5_ih07p_1{
  line-height:1.25rem
}

._leading-6_ih07p_1{
  line-height:1.5rem
}

._tracking-wide_ih07p_1{
  letter-spacing:.025em
}

._text-dark-500_ih07p_1{
  --tw-text-opacity: 1;
  color:rgba(107, 114, 128,var(--tw-text-opacity));
  color:rgba(var(--color-dark-500),var(--tw-text-opacity))
}

._text-primary-500_ih07p_1{
  --tw-text-opacity: 1;
  color:rgba(99, 102, 241,var(--tw-text-opacity));
  color:rgba(var(--color-primary-500),var(--tw-text-opacity))
}

._text-dark-700_ih07p_1{
  --tw-text-opacity: 1;
  color:rgba(55, 65, 81,var(--tw-text-opacity));
  color:rgba(var(--color-dark-700),var(--tw-text-opacity))
}

._text-light-400_ih07p_1{
  --tw-text-opacity: 1;
  color:rgba(156, 163, 175,var(--tw-text-opacity));
  color:rgba(var(--color-light-400),var(--tw-text-opacity))
}

._text-dark-900_ih07p_1{
  --tw-text-opacity: 1;
  color:rgba(17, 24, 39,var(--tw-text-opacity));
  color:rgba(var(--color-dark-900),var(--tw-text-opacity))
}

._text-white_ih07p_1{
  --tw-text-opacity: 1;
  color:rgba(255, 255, 255,var(--tw-text-opacity));
  color:rgba(var(--color-white),var(--tw-text-opacity))
}

._text-dark-600_ih07p_1{
  --tw-text-opacity: 1;
  color:rgba(75, 85, 99,var(--tw-text-opacity));
  color:rgba(var(--color-dark-600),var(--tw-text-opacity))
}

._text-light-200_ih07p_1{
  --tw-text-opacity: 1;
  color:rgba(229, 231, 235,var(--tw-text-opacity));
  color:rgba(var(--color-light-200),var(--tw-text-opacity))
}

._text-light-300_ih07p_1{
  --tw-text-opacity: 1;
  color:rgba(209, 213, 219,var(--tw-text-opacity));
  color:rgba(var(--color-light-300),var(--tw-text-opacity))
}

._text-primary-100_ih07p_1{
  --tw-text-opacity: 1;
  color:rgba(224, 231, 255,var(--tw-text-opacity));
  color:rgba(var(--color-primary-100),var(--tw-text-opacity))
}

._text-success-400_ih07p_1{
  --tw-text-opacity: 1;
  color:rgba(52, 211, 153,var(--tw-text-opacity));
  color:rgba(var(--color-success-400),var(--tw-text-opacity))
}

._text-dark-800_ih07p_1{
  --tw-text-opacity: 1;
  color:rgba(31, 41, 55,var(--tw-text-opacity));
  color:rgba(var(--color-dark-800),var(--tw-text-opacity))
}

._text-primary-600_ih07p_1{
  --tw-text-opacity: 1;
  color:rgba(79, 70, 229,var(--tw-text-opacity));
  color:rgba(var(--color-primary-600),var(--tw-text-opacity))
}

._text-primary-300_ih07p_1{
  --tw-text-opacity: 1;
  color:rgba(165, 180, 252,var(--tw-text-opacity));
  color:rgba(var(--color-primary-300),var(--tw-text-opacity))
}

._text-primary-200_ih07p_1{
  --tw-text-opacity: 1;
  color:rgba(199, 210, 254,var(--tw-text-opacity));
  color:rgba(var(--color-primary-200),var(--tw-text-opacity))
}

._text-gray-900_ih07p_1{
  --tw-text-opacity: 1;
  color:rgb(17 24 39 / var(--tw-text-opacity))
}

._text-gray-500_ih07p_1{
  --tw-text-opacity: 1;
  color:rgb(107 114 128 / var(--tw-text-opacity))
}

._text-success-500_ih07p_1{
  --tw-text-opacity: 1;
  color:rgba(16, 185, 129,var(--tw-text-opacity));
  color:rgba(var(--color-success-500),var(--tw-text-opacity))
}

._text-danger-500_ih07p_1{
  --tw-text-opacity: 1;
  color:rgba(239, 68, 68,var(--tw-text-opacity));
  color:rgba(var(--color-danger-500),var(--tw-text-opacity))
}

._text-success-800_ih07p_1{
  --tw-text-opacity: 1;
  color:rgba(6, 95, 70,var(--tw-text-opacity));
  color:rgba(var(--color-success-800),var(--tw-text-opacity))
}

._text-success-600_ih07p_1{
  --tw-text-opacity: 1;
  color:rgba(5, 150, 105,var(--tw-text-opacity));
  color:rgba(var(--color-success-600),var(--tw-text-opacity))
}

._text-warning-700_ih07p_1{
  --tw-text-opacity: 1;
  color:rgba(180, 83, 9,var(--tw-text-opacity));
  color:rgba(var(--color-warning-700),var(--tw-text-opacity))
}

._text-info-700_ih07p_1{
  --tw-text-opacity: 1;
  color:rgba(29, 78, 216,var(--tw-text-opacity));
  color:rgba(var(--color-info-700),var(--tw-text-opacity))
}

._text-warning-800_ih07p_1{
  --tw-text-opacity: 1;
  color:rgba(146, 64, 14,var(--tw-text-opacity));
  color:rgba(var(--color-warning-800),var(--tw-text-opacity))
}

._text-info-800_ih07p_1{
  --tw-text-opacity: 1;
  color:rgba(30, 64, 175,var(--tw-text-opacity));
  color:rgba(var(--color-info-800),var(--tw-text-opacity))
}

._text-danger-800_ih07p_1{
  --tw-text-opacity: 1;
  color:rgba(153, 27, 27,var(--tw-text-opacity));
  color:rgba(var(--color-danger-800),var(--tw-text-opacity))
}

._underline_ih07p_1{
  -webkit-text-decoration-line:underline;
  text-decoration-line:underline
}

._placeholder-dark-500_ih07p_1:-ms-input-placeholder{
  --tw-placeholder-opacity: 1;
  color:rgba(107, 114, 128,var(--tw-placeholder-opacity));
  color:rgba(var(--color-dark-500),var(--tw-placeholder-opacity))
}

._placeholder-dark-500_ih07p_1::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1;
  color:rgba(107, 114, 128,var(--tw-placeholder-opacity));
  color:rgba(var(--color-dark-500),var(--tw-placeholder-opacity))
}

._placeholder-dark-500_ih07p_1::placeholder{
  --tw-placeholder-opacity: 1;
  color:rgba(107, 114, 128,var(--tw-placeholder-opacity));
  color:rgba(var(--color-dark-500),var(--tw-placeholder-opacity))
}

._opacity-25_ih07p_1{
  opacity:.25
}

._opacity-75_ih07p_1{
  opacity:.75
}

._opacity-0_ih07p_1{
  opacity:0
}

._opacity-100_ih07p_1{
  opacity:1
}

._opacity-70_ih07p_1{
  opacity:.7
}

._shadow-sm_ih07p_1{
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / .05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow:0 0 #0000,0 0 #0000,var(--tw-shadow);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow)
}

._shadow_ih07p_1{
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / .1), 0 1px 2px -1px rgb(0 0 0 / .1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow:0 0 #0000,0 0 #0000,var(--tw-shadow);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow)
}

._shadow-lg_ih07p_1{
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / .1), 0 4px 6px -4px rgb(0 0 0 / .1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow:0 0 #0000,0 0 #0000,var(--tw-shadow);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow)
}

._shadow-none_ih07p_1{
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow:0 0 #0000,0 0 #0000,var(--tw-shadow);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow)
}

._filter_ih07p_1{
  -webkit-filter:var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  filter:var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

._transition_ih07p_1{
  transition-property:color,background-color,border-color,fill,stroke,opacity,box-shadow,transform,filter,-webkit-text-decoration-color,-webkit-backdrop-filter;
  transition-property:color,background-color,border-color,fill,stroke,opacity,box-shadow,-webkit-text-decoration-color,-webkit-transform,-webkit-filter,-webkit-backdrop-filter;
  transition-property:color,background-color,border-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter;
  transition-property:color,background-color,border-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter,-webkit-text-decoration-color,-webkit-transform,-webkit-filter,-webkit-backdrop-filter;
  transition-property:color,background-color,border-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter,-webkit-text-decoration-color,-webkit-backdrop-filter;
  transition-timing-function:cubic-bezier(.4,0,.2,1);
  transition-duration:.15s
}

._duration-\[400ms\]_ih07p_1{
  transition-duration:.4s
}

._duration-200_ih07p_1{
  transition-duration:.2s
}

._ease-in-out_ih07p_1{
  transition-timing-function:cubic-bezier(.4,0,.2,1)
}

._line-clamp-2_ih07p_1{
  overflow:hidden;
  display:-webkit-box;
  -webkit-box-orient:vertical;
  -webkit-line-clamp:2
}

._focus-within\:text-dark-600_ih07p_1:focus-within{
  --tw-text-opacity: 1;
  color:rgba(75, 85, 99,var(--tw-text-opacity));
  color:rgba(var(--color-dark-600),var(--tw-text-opacity))
}

._focus-within\:outline-none_ih07p_1:focus-within{
  outline:2px solid transparent;
  outline-offset:2px
}

._focus-within\:ring-2_ih07p_1:focus-within{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),0 0 #0000;
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),var(--tw-shadow, 0 0 #0000)
}

._focus-within\:ring-inset_ih07p_1:focus-within{
  --tw-ring-inset: inset
}

._focus-within\:ring-primary-500_ih07p_1:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(var(--color-primary-500), var(--tw-ring-opacity))
}

._focus-within\:ring-primary-600_ih07p_1:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(var(--color-primary-600), var(--tw-ring-opacity))
}

._hover\:scale-105_ih07p_1:hover{
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
  -webkit-transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

._hover\:cursor-pointer_ih07p_1:hover{
  cursor:pointer
}

._hover\:rounded-t-md_ih07p_1:hover{
  border-top-left-radius:.375rem;
  border-top-right-radius:.375rem
}

._hover\:bg-light-50_ih07p_1:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(249, 250, 251,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-50),var(--tw-bg-opacity))
}

._hover\:bg-light-200_ih07p_1:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(229, 231, 235,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-200),var(--tw-bg-opacity))
}

._hover\:bg-primary-700_ih07p_1:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(67, 56, 202,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-700),var(--tw-bg-opacity))
}

._hover\:bg-dark-700_ih07p_1:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(55, 65, 81,var(--tw-bg-opacity));
  background-color:rgba(var(--color-dark-700),var(--tw-bg-opacity))
}

._hover\:bg-danger-100_ih07p_1:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(254, 226, 226,var(--tw-bg-opacity));
  background-color:rgba(var(--color-danger-100),var(--tw-bg-opacity))
}

._hover\:bg-danger-700_ih07p_1:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(185, 28, 28,var(--tw-bg-opacity));
  background-color:rgba(var(--color-danger-700),var(--tw-bg-opacity))
}

._hover\:bg-success-100_ih07p_1:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(209, 250, 229,var(--tw-bg-opacity));
  background-color:rgba(var(--color-success-100),var(--tw-bg-opacity))
}

._hover\:bg-opacity-30_ih07p_1:hover{
  --tw-bg-opacity: .3
}

._hover\:text-primary-500_ih07p_1:hover{
  --tw-text-opacity: 1;
  color:rgba(99, 102, 241,var(--tw-text-opacity));
  color:rgba(var(--color-primary-500),var(--tw-text-opacity))
}

._hover\:text-light-200_ih07p_1:hover{
  --tw-text-opacity: 1;
  color:rgba(229, 231, 235,var(--tw-text-opacity));
  color:rgba(var(--color-light-200),var(--tw-text-opacity))
}

._hover\:text-dark-500_ih07p_1:hover{
  --tw-text-opacity: 1;
  color:rgba(107, 114, 128,var(--tw-text-opacity));
  color:rgba(var(--color-dark-500),var(--tw-text-opacity))
}

._hover\:text-warning-600_ih07p_1:hover{
  --tw-text-opacity: 1;
  color:rgba(217, 119, 6,var(--tw-text-opacity));
  color:rgba(var(--color-warning-600),var(--tw-text-opacity))
}

._hover\:text-info-600_ih07p_1:hover{
  --tw-text-opacity: 1;
  color:rgba(37, 99, 235,var(--tw-text-opacity));
  color:rgba(var(--color-info-600),var(--tw-text-opacity))
}

._hover\:text-white_ih07p_1:hover{
  --tw-text-opacity: 1;
  color:rgba(255, 255, 255,var(--tw-text-opacity));
  color:rgba(var(--color-white),var(--tw-text-opacity))
}

._hover\:underline_ih07p_1:hover{
  -webkit-text-decoration-line:underline;
  text-decoration-line:underline
}

._hover\:no-underline_ih07p_1:hover{
  -webkit-text-decoration-line:none;
  text-decoration-line:none
}

._focus\:border-transparent_ih07p_1:focus{
  border-color:transparent
}

._focus\:placeholder-light-400_ih07p_1:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1;
  color:rgba(156, 163, 175,var(--tw-placeholder-opacity));
  color:rgba(var(--color-light-400),var(--tw-placeholder-opacity))
}

._focus\:placeholder-light-400_ih07p_1:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1;
  color:rgba(156, 163, 175,var(--tw-placeholder-opacity));
  color:rgba(var(--color-light-400),var(--tw-placeholder-opacity))
}

._focus\:placeholder-light-400_ih07p_1:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color:rgba(156, 163, 175,var(--tw-placeholder-opacity));
  color:rgba(var(--color-light-400),var(--tw-placeholder-opacity))
}

._focus\:outline-none_ih07p_1:focus{
  outline:2px solid transparent;
  outline-offset:2px
}

._focus\:outline_ih07p_1:focus{
  outline-style:solid
}

._focus\:outline-2_ih07p_1:focus{
  outline-width:2px
}

._focus\:outline-primary-500_ih07p_1:focus{
  outline-color:rgb(99, 102, 241);
  outline-color:rgb(var(--color-primary-500))
}

._focus\:ring-2_ih07p_1:focus{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),0 0 #0000;
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),var(--tw-shadow, 0 0 #0000)
}

._focus\:ring-0_ih07p_1:focus{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),0 0 #0000;
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),var(--tw-shadow, 0 0 #0000)
}

._focus\:ring-primary-500_ih07p_1:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(var(--color-primary-500), var(--tw-ring-opacity))
}

._focus\:ring-pink-500_ih07p_1:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(236 72 153 / var(--tw-ring-opacity))
}

._focus\:ring-danger-500_ih07p_1:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(var(--color-danger-500), var(--tw-ring-opacity))
}

._focus\:ring-success-600_ih07p_1:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(var(--color-success-600), var(--tw-ring-opacity))
}

._focus\:ring-offset-2_ih07p_1:focus{
  --tw-ring-offset-width: 2px
}

._focus\:ring-offset-dark-800_ih07p_1:focus{
  --tw-ring-offset-color: rgb(var(--color-dark-800))
}

._focus\:ring-offset-success-50_ih07p_1:focus{
  --tw-ring-offset-color: rgb(var(--color-success-50))
}

._active\:bg-opacity-40_ih07p_1:active{
  --tw-bg-opacity: .4
}

._group_ih07p_1:hover ._group-hover\:text-primary-400_ih07p_1{
  --tw-text-opacity: 1;
  color:rgba(129, 140, 248,var(--tw-text-opacity));
  color:rgba(var(--color-primary-400),var(--tw-text-opacity))
}

._group_ih07p_1:hover ._group-hover\:text-dark-500_ih07p_1{
  --tw-text-opacity: 1;
  color:rgba(107, 114, 128,var(--tw-text-opacity));
  color:rgba(var(--color-dark-500),var(--tw-text-opacity))
}

@media (min-width: 640px){
  ._sm\:col-span-2_ih07p_1{
    grid-column:span 2 / span 2
  }

  ._sm\:col-start-2_ih07p_1{
    grid-column-start:2
  }

  ._sm\:mx-auto_ih07p_1{
    margin-left:auto;
    margin-right:auto
  }

  ._sm\:mx-0_ih07p_1{
    margin-left:0;
    margin-right:0
  }

  ._sm\:mx-64_ih07p_1{
    margin-left:16rem;
    margin-right:16rem
  }

  ._sm\:mx-1_ih07p_1{
    margin-left:.25rem;
    margin-right:.25rem
  }

  ._sm\:mt-0_ih07p_1{
    margin-top:0
  }

  ._sm\:ml-4_ih07p_1{
    margin-left:1rem
  }

  ._sm\:-mt-26_ih07p_1{
    margin-top:-104px
  }

  ._sm\:ml-5_ih07p_1{
    margin-left:1.25rem
  }

  ._sm\:ml-0_ih07p_1{
    margin-left:0
  }

  ._sm\:ml-6_ih07p_1{
    margin-left:1.5rem
  }

  ._sm\:mb-0_ih07p_1{
    margin-bottom:0
  }

  ._sm\:mt-px_ih07p_1{
    margin-top:1px
  }

  ._sm\:ml-3_ih07p_1{
    margin-left:.75rem
  }

  ._sm\:mr-3_ih07p_1{
    margin-right:.75rem
  }

  ._sm\:block_ih07p_1{
    display:block
  }

  ._sm\:inline_ih07p_1{
    display:inline
  }

  ._sm\:flex_ih07p_1{
    display:flex
  }

  ._sm\:grid_ih07p_1{
    display:grid
  }

  ._sm\:hidden_ih07p_1{
    display:none
  }

  ._sm\:h-32_ih07p_1{
    height:8rem
  }

  ._sm\:h-80_ih07p_1{
    height:20rem
  }

  ._sm\:h-6_ih07p_1{
    height:1.5rem
  }

  ._sm\:w-full_ih07p_1{
    width:100%
  }

  ._sm\:w-32_ih07p_1{
    width:8rem
  }

  ._sm\:min-w-0_ih07p_1{
    min-width:0px
  }

  ._sm\:max-w-md_ih07p_1{
    max-width:28rem
  }

  ._sm\:max-w-xs_ih07p_1{
    max-width:20rem
  }

  ._sm\:flex-1_ih07p_1{
    flex:1 1
  }

  ._sm\:flex-shrink-0_ih07p_1{
    flex-shrink:0
  }

  ._sm\:grid-cols-3_ih07p_1{
    grid-template-columns:repeat(3,minmax(0,1fr))
  }

  ._sm\:flex-row_ih07p_1{
    flex-direction:row
  }

  ._sm\:flex-wrap_ih07p_1{
    flex-wrap:wrap
  }

  ._sm\:items-end_ih07p_1{
    align-items:flex-end
  }

  ._sm\:items-center_ih07p_1{
    align-items:center
  }

  ._sm\:items-baseline_ih07p_1{
    align-items:baseline
  }

  ._sm\:justify-end_ih07p_1{
    justify-content:flex-end
  }

  ._sm\:space-y-0_ih07p_1>:not([hidden])~:not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top:calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(0px * var(--tw-space-y-reverse))
  }

  ._sm\:space-y-5_ih07p_1>:not([hidden])~:not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top:calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(1.25rem * var(--tw-space-y-reverse))
  }

  ._sm\:divide-y-0_ih07p_1>:not([hidden])~:not([hidden]){
    --tw-divide-y-reverse: 0;
    border-top-width:calc(0px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width:calc(0px * var(--tw-divide-y-reverse))
  }

  ._sm\:divide-x_ih07p_1>:not([hidden])~:not([hidden]){
    --tw-divide-x-reverse: 0;
    border-right-width:calc(1px * var(--tw-divide-x-reverse));
    border-left-width:calc(1px * calc(1 - var(--tw-divide-x-reverse)))
  }

  ._sm\:truncate_ih07p_1{
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap
  }

  ._sm\:px-10_ih07p_1{
    padding-left:2.5rem;
    padding-right:2.5rem
  }

  ._sm\:px-6_ih07p_1{
    padding-left:1.5rem;
    padding-right:1.5rem
  }

  ._sm\:px-48_ih07p_1{
    padding-left:12rem;
    padding-right:12rem
  }

  ._sm\:px-72_ih07p_1{
    padding-left:18rem;
    padding-right:18rem
  }

  ._sm\:px-40_ih07p_1{
    padding-left:10rem;
    padding-right:10rem
  }

  ._sm\:px-0_ih07p_1{
    padding-left:0;
    padding-right:0
  }

  ._sm\:py-5_ih07p_1{
    padding-top:1.25rem;
    padding-bottom:1.25rem
  }

  ._sm\:py-0_ih07p_1{
    padding-top:0;
    padding-bottom:0
  }

  ._sm\:px-52_ih07p_1{
    padding-left:13rem;
    padding-right:13rem
  }

  ._sm\:pt-2_ih07p_1{
    padding-top:.5rem
  }

  ._sm\:pt-5_ih07p_1{
    padding-top:1.25rem
  }

  ._sm\:pt-10_ih07p_1{
    padding-top:2.5rem
  }

  ._sm\:text-left_ih07p_1{
    text-align:left
  }

  ._sm\:text-right_ih07p_1{
    text-align:right
  }

  ._sm\:text-sm_ih07p_1{
    font-size:.875rem;
    line-height:1.25rem
  }

  ._sm\:text-3xl_ih07p_1{
    font-size:1.875rem;
    line-height:2.25rem
  }

  ._sm\:text-2xl_ih07p_1{
    font-size:1.5rem;
    line-height:2rem
  }
}

@media (min-width: 768px){
  ._md\:mt-0_ih07p_1{
    margin-top:0
  }

  ._md\:ml-6_ih07p_1{
    margin-left:1.5rem
  }

  ._md\:ml-0_ih07p_1{
    margin-left:0
  }

  ._md\:block_ih07p_1{
    display:block
  }

  ._md\:inline_ih07p_1{
    display:inline
  }

  ._md\:hidden_ih07p_1{
    display:none
  }

  ._md\:space-y-0_ih07p_1>:not([hidden])~:not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top:calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(0px * var(--tw-space-y-reverse))
  }

  ._md\:pl-0_ih07p_1{
    padding-left:0
  }

  ._md\:text-center_ih07p_1{
    text-align:center
  }

  ._md\:text-right_ih07p_1{
    text-align:right
  }
}

@media (min-width: 1024px){
  ._lg\:mt-4_ih07p_1{
    margin-top:1rem
  }

  ._lg\:mt-0_ih07p_1{
    margin-top:0
  }

  ._lg\:block_ih07p_1{
    display:block
  }

  ._lg\:flex_ih07p_1{
    display:flex
  }

  ._lg\:hidden_ih07p_1{
    display:none
  }

  ._lg\:h-0_ih07p_1{
    height:0px
  }

  ._lg\:min-h-\[768px\]_ih07p_1{
    min-height:768px
  }

  ._lg\:flex-none_ih07p_1{
    flex:none
  }

  ._lg\:items-center_ih07p_1{
    align-items:center
  }

  ._lg\:px-20_ih07p_1{
    padding-left:5rem;
    padding-right:5rem
  }
}

@media (min-width: 1280px){
  ._xl\:px-24_ih07p_1{
    padding-left:6rem;
    padding-right:6rem
  }
}

._ods_alert_action_link_ih07p_81{
  font-weight:500;
  outline:2px solid transparent;
  outline-offset:2px
}

._ods_alert_action_link_ih07p_81:active{
  outline:2px solid transparent;
  outline-offset:2px
}

._ods_alert_action_link_withIcon_ih07p_84{
  white-space:nowrap
}

._ods_alert_action_button_ih07p_87{
  border-radius:.375rem;
  padding:.375rem .5rem;
  font-size:.875rem;
  line-height:1.25rem;
  font-weight:500
}

._ods_alert_action_button_ih07p_87:focus{
  outline:2px solid transparent;
  outline-offset:2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),0 0 #0000;
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),var(--tw-shadow, 0 0 #0000);
  --tw-ring-offset-width: 2px
}

._ods_alert_actions_ih07p_91{
  margin:-.375rem -.5rem;
  display:flex
}

._ods_alert_actions_ih07p_91 :nth-child(even){
  margin-left:.75rem;
  margin-right:.75rem
}

._ods_alert_body_ih07p_98{
  font-size:.875rem;
  line-height:1.25rem
}

._ods_alert_body_ih07p_98+div{
  margin-top:.625rem
}

._ods_alert_heading_ih07p_105{
  font-size:.875rem;
  line-height:1.25rem;
  font-weight:500
}

._ods_alert_heading_ih07p_105+div{
  margin-top:.5rem
}

._ods_avatar_ih07p_112{
  display:inline-block
}

._ods_avatar_wrapper_ih07p_115{
  position:relative;
  display:inline-block
}

._ods_avatar_circular_ih07p_118{
  border-radius:9999px
}

._ods_avatar_placeholder_ih07p_121{
  display:inline-block;
  overflow:hidden;
  --tw-bg-opacity: 1;
  background-color:rgba(243, 244, 246,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-100),var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:rgba(209, 213, 219,var(--tw-text-opacity));
  color:rgba(var(--color-light-300),var(--tw-text-opacity))
}

._ods_avatar_initial_ih07p_124{
  display:inline-flex;
  align-items:center;
  justify-content:center;
  --tw-bg-opacity: 1;
  background-color:rgba(107, 114, 128,var(--tw-bg-opacity));
  background-color:rgba(var(--color-dark-500),var(--tw-bg-opacity))
}

._ods_avatar_initial_icon_ih07p_127{
  font-size:.875rem;
  line-height:1.25rem;
  font-weight:500;
  line-height:1;
  --tw-text-opacity: 1;
  color:rgba(255, 255, 255,var(--tw-text-opacity));
  color:rgba(var(--color-white),var(--tw-text-opacity))
}

._ods_avatar_rounded_ih07p_130{
  border-radius:.375rem
}

._ods_avatar_xs_ih07p_133{
  height:1.5rem;
  width:1.5rem
}

._ods_avatar_sm_ih07p_136{
  height:2rem;
  width:2rem
}

._ods_avatar_base_ih07p_139{
  height:2.5rem;
  width:2.5rem
}

._ods_avatar_lg_ih07p_142{
  height:3rem;
  width:3rem
}

._ods_avatar_xl_ih07p_145{
  height:3.5rem;
  width:3.5rem
}

._ods_avatar_xxl_ih07p_148{
  height:4rem;
  width:4rem
}

._ods_avatar_notification_ih07p_151{
  position:absolute;
  right:0;
  display:block;
  height:.625rem;
  width:.625rem;
  border-radius:9999px;
  --tw-bg-opacity: 1;
  background-color:rgba(209, 213, 219,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-300),var(--tw-bg-opacity));
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),0 0 #0000;
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(var(--color-white), var(--tw-ring-opacity))
}

._ods_avatar_notification_rounded_top_ih07p_154{
  --tw-translate-x: 50%;
  --tw-translate-y: -50%;
  -webkit-transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

._ods_avatar_notification_rounded_bottom_ih07p_157{
  --tw-translate-x: 50%;
  --tw-translate-y: 50%;
  -webkit-transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

._ods_avatar_notification_xs_ih07p_160{
  height:.375rem;
  width:.375rem
}

._ods_avatar_notification_sm_ih07p_163{
  height:.5rem;
  width:.5rem
}

._ods_avatar_notification_base_ih07p_166{
  height:.625rem;
  width:.625rem
}

._ods_avatar_notification_lg_ih07p_169{
  height:.75rem;
  width:.75rem
}

._ods_avatar_notification_xl_ih07p_172{
  height:.875rem;
  width:.875rem
}

._ods_avatar_notification_xxl_ih07p_175{
  height:1rem;
  width:1rem
}

._ods_avatar_notification_away_ih07p_178{
  --tw-bg-opacity: 1;
  background-color:rgba(209, 213, 219,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-300),var(--tw-bg-opacity))
}

._ods_avatar_notification_busy_ih07p_181{
  --tw-bg-opacity: 1;
  background-color:rgba(248, 113, 113,var(--tw-bg-opacity));
  background-color:rgba(var(--color-danger-400),var(--tw-bg-opacity))
}

._ods_avatar_notification_available_ih07p_184{
  --tw-bg-opacity: 1;
  background-color:rgba(52, 211, 153,var(--tw-bg-opacity));
  background-color:rgba(var(--color-success-400),var(--tw-bg-opacity))
}

._ods_avatar_notification_vertical_top_ih07p_187{
  top:0
}

._ods_avatar_notification_vertical_bottom_ih07p_190{
  bottom:0
}

._ods_avatar_profile_ih07p_194{
  display:inline-block;
  height:6rem;
  width:6rem
}

@media (min-width: 640px){
  ._ods_avatar_profile_ih07p_194{
    height:12rem;
    width:12rem
  }
}

._ods_avatar_profile_wrapper_ih07p_197{
  position:relative;
  display:inline-block
}

._ods_avatar_profile_circular_ih07p_200{
  border-radius:9999px
}

._ods_avatar_profile_rounded_ih07p_203{
  border-radius:.375rem
}

._ods_avatar_profile_placeholder_ih07p_206{
  overflow:hidden;
  --tw-bg-opacity: 1;
  background-color:rgba(243, 244, 246,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-100),var(--tw-bg-opacity))
}

._ods_avatar_upload_ih07p_210{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),0 0 #0000;
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(var(--color-white), var(--tw-ring-opacity))
}

._ods_avatar_upload_wrapper_ih07p_213{
  position:relative;
  margin-left:auto;
  margin-right:auto;
  display:flex;
  max-width:-webkit-max-content;
  max-width:max-content;
  flex-direction:column;
  align-items:center;
  justify-content:center;
  border-radius:9999px;
  padding-bottom:.75rem
}

._ods_avatar_upload_wrapper_ih07p_213>img{
  --tw-bg-opacity: 1;
  background-color:rgba(243, 244, 246,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-100),var(--tw-bg-opacity))
}

._ods_avatar_upload_wrapper_ih07p_213+p{
  text-align:center
}

._ods_avatar_upload_actions_wrapper_ih07p_222{
  position:absolute;
  top:0;
  display:flex;
  height:100%;
  width:100%;
  align-items:center;
  justify-content:center;
  border-radius:9999px
}

._ods_avatar_upload_actions_wrapper_ih07p_222:hover div{
  visibility:visible
}

._ods_avatar_upload_actions_wrapper_ih07p_222 div{
  visibility:hidden;
  display:flex;
  flex-direction:column;
  align-items:center;
  grid-gap:.5rem;
  gap:.5rem
}

._ods_avatar_upload_actions_wrapper_ih07p_222 div label{
  display:inline-flex;
  cursor:pointer;
  align-items:center;
  border-radius:9999px;
  --tw-bg-opacity: 1;
  background-color:rgba(243, 244, 246,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-100),var(--tw-bg-opacity));
  padding:.125rem .625rem;
  font-size:.75rem;
  line-height:1rem;
  font-weight:500;
  --tw-text-opacity: 1;
  color:rgba(31, 41, 55,var(--tw-text-opacity));
  color:rgba(var(--color-dark-800),var(--tw-text-opacity))
}

._ods_avatar_upload_actions_wrapper_ih07p_222 div input{
  display:none
}

._ods_avatar_upload_actions_wrapper_ih07p_222 div button{
  display:inline-flex;
  align-items:center;
  border-radius:9999px;
  --tw-bg-opacity: 1;
  background-color:rgba(254, 226, 226,var(--tw-bg-opacity));
  background-color:rgba(var(--color-danger-100),var(--tw-bg-opacity));
  padding:.125rem .625rem;
  font-size:.75rem;
  line-height:1rem;
  font-weight:500;
  --tw-text-opacity: 1;
  color:rgba(153, 27, 27,var(--tw-text-opacity));
  color:rgba(var(--color-danger-800),var(--tw-text-opacity))
}

._ods_avatar_upload_sm_ih07p_240{
  height:5rem;
  width:5rem
}

._ods_avatar_upload_sm_ih07p_240>span{
  height:5rem;
  width:5rem
}

._ods_avatar_upload_base_ih07p_246{
  height:6rem;
  width:6rem
}

._ods_avatar_upload_base_ih07p_246>span{
  height:6rem;
  width:6rem
}

._ods_avatar_upload_lg_ih07p_252{
  height:8rem;
  width:8rem
}

._ods_avatar_upload_lg_ih07p_252>span{
  height:8rem;
  width:8rem
}

._ods_badge_ih07p_259{
  display:inline-flex;
  align-items:center;
  font-weight:500
}

._ods_badge_ih07p_259 button{
  height:1rem;
  width:1rem
}

._ods_badge_sm_ih07p_265{
  padding:.125rem .625rem;
  font-size:.75rem;
  line-height:1rem
}

._ods_badge_l_ih07p_268{
  padding:.125rem .75rem;
  font-size:.875rem;
  line-height:1.25rem
}

._ods_badge_dot_only_ih07p_271,._ods_badge_dot_only_ih07p_271 button{
  --tw-text-opacity: 1;
  color:rgba(31, 41, 55,var(--tw-text-opacity));
  color:rgba(var(--color-dark-800),var(--tw-text-opacity))
}

._ods_badge_primary_ih07p_277{
  --tw-bg-opacity: 1;
  background-color:rgba(224, 231, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-100),var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:rgba(55, 48, 163,var(--tw-text-opacity));
  color:rgba(var(--color-primary-800),var(--tw-text-opacity))
}

._ods_badge_primary_ih07p_277 button,._ods_badge_primary_ih07p_277 div{
  --tw-text-opacity: 1;
  color:rgba(129, 140, 248,var(--tw-text-opacity));
  color:rgba(var(--color-primary-400),var(--tw-text-opacity))
}

._ods_badge_primary_ih07p_277 button:hover,._ods_badge_primary_ih07p_277 div:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(165, 180, 252,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-300),var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:rgba(99, 102, 241,var(--tw-text-opacity));
  color:rgba(var(--color-primary-500),var(--tw-text-opacity))
}

._ods_badge_primary_ih07p_277 button:focus,._ods_badge_primary_ih07p_277 div:focus{
  --tw-bg-opacity: 1;
  background-color:rgba(99, 102, 241,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-500),var(--tw-bg-opacity))
}

._ods_badge_primary_dark_ih07p_284{
  --tw-bg-opacity: 1;
  background-color:rgba(55, 48, 163,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-800),var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:rgba(224, 231, 255,var(--tw-text-opacity));
  color:rgba(var(--color-primary-100),var(--tw-text-opacity))
}

._ods_badge_primary_dark_ih07p_284 button,._ods_badge_primary_dark_ih07p_284 div{
  --tw-text-opacity: 1;
  color:rgba(224, 231, 255,var(--tw-text-opacity));
  color:rgba(var(--color-primary-100),var(--tw-text-opacity))
}

._ods_badge_primary_dark_ih07p_284 button:hover,._ods_badge_primary_dark_ih07p_284 div:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(49, 46, 129,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-900),var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:rgba(199, 210, 254,var(--tw-text-opacity));
  color:rgba(var(--color-primary-200),var(--tw-text-opacity))
}

._ods_badge_primary_dark_ih07p_284 button:focus,._ods_badge_primary_dark_ih07p_284 div:focus{
  --tw-bg-opacity: 1;
  background-color:rgba(49, 46, 129,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-900),var(--tw-bg-opacity))
}

._ods_badge_secondary_ih07p_291{
  --tw-bg-opacity: 1;
  background-color:rgba(224, 231, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-100),var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:rgba(55, 48, 163,var(--tw-text-opacity));
  color:rgba(var(--color-primary-800),var(--tw-text-opacity))
}

._ods_badge_secondary_ih07p_291 button,._ods_badge_secondary_ih07p_291 div{
  --tw-text-opacity: 1;
  color:rgba(129, 140, 248,var(--tw-text-opacity));
  color:rgba(var(--color-primary-400),var(--tw-text-opacity))
}

._ods_badge_secondary_ih07p_291 button:hover,._ods_badge_secondary_ih07p_291 div:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(199, 210, 254,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-200),var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:rgba(99, 102, 241,var(--tw-text-opacity));
  color:rgba(var(--color-primary-500),var(--tw-text-opacity))
}

._ods_badge_secondary_ih07p_291 button:focus,._ods_badge_secondary_ih07p_291 div:focus{
  --tw-bg-opacity: 1;
  background-color:rgba(99, 102, 241,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-500),var(--tw-bg-opacity))
}

._ods_badge_light_ih07p_298{
  --tw-bg-opacity: 1;
  background-color:rgba(243, 244, 246,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-100),var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:rgba(31, 41, 55,var(--tw-text-opacity));
  color:rgba(var(--color-dark-800),var(--tw-text-opacity))
}

._ods_badge_light_ih07p_298 button,._ods_badge_light_ih07p_298 div{
  --tw-text-opacity: 1;
  color:rgba(156, 163, 175,var(--tw-text-opacity));
  color:rgba(var(--color-light-400),var(--tw-text-opacity))
}

._ods_badge_light_ih07p_298 button:hover,._ods_badge_light_ih07p_298 div:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(229, 231, 235,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-200),var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:rgba(107, 114, 128,var(--tw-text-opacity));
  color:rgba(var(--color-dark-500),var(--tw-text-opacity))
}

._ods_badge_light_ih07p_298 button:focus,._ods_badge_light_ih07p_298 div:focus{
  --tw-bg-opacity: 1;
  background-color:rgba(107, 114, 128,var(--tw-bg-opacity));
  background-color:rgba(var(--color-dark-500),var(--tw-bg-opacity))
}

._ods_badge_dark_ih07p_305{
  --tw-bg-opacity: 1;
  background-color:rgb(17 24 39 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:rgba(209, 213, 219,var(--tw-text-opacity));
  color:rgba(var(--color-light-300),var(--tw-text-opacity))
}

._ods_badge_dark_ih07p_305 button,._ods_badge_dark_ih07p_305 div{
  --tw-text-opacity: 1;
  color:rgba(209, 213, 219,var(--tw-text-opacity));
  color:rgba(var(--color-light-300),var(--tw-text-opacity))
}

._ods_badge_dark_ih07p_305 button:hover,._ods_badge_dark_ih07p_305 div:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(0, 0, 0,var(--tw-bg-opacity));
  background-color:rgba(var(--color-black),var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:rgba(156, 163, 175,var(--tw-text-opacity));
  color:rgba(var(--color-light-400),var(--tw-text-opacity))
}

._ods_badge_dark_ih07p_305 button:focus,._ods_badge_dark_ih07p_305 div:focus{
  --tw-bg-opacity: 1;
  background-color:rgba(0, 0, 0,var(--tw-bg-opacity));
  background-color:rgba(var(--color-black),var(--tw-bg-opacity))
}

._ods_badge_danger_ih07p_312{
  --tw-bg-opacity: 1;
  background-color:rgba(254, 226, 226,var(--tw-bg-opacity));
  background-color:rgba(var(--color-danger-100),var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:rgba(153, 27, 27,var(--tw-text-opacity));
  color:rgba(var(--color-danger-800),var(--tw-text-opacity))
}

._ods_badge_danger_ih07p_312 button{
  --tw-text-opacity: 1;
  color:rgba(248, 113, 113,var(--tw-text-opacity));
  color:rgba(var(--color-danger-400),var(--tw-text-opacity))
}

._ods_badge_danger_ih07p_312 button:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(254, 202, 202,var(--tw-bg-opacity));
  background-color:rgba(var(--color-danger-200),var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:rgba(239, 68, 68,var(--tw-text-opacity));
  color:rgba(var(--color-danger-500),var(--tw-text-opacity))
}

._ods_badge_danger_ih07p_312 button:focus{
  --tw-bg-opacity: 1;
  background-color:rgba(239, 68, 68,var(--tw-bg-opacity));
  background-color:rgba(var(--color-danger-500),var(--tw-bg-opacity))
}

._ods_badge_warning_ih07p_318{
  --tw-bg-opacity: 1;
  background-color:rgba(254, 243, 199,var(--tw-bg-opacity));
  background-color:rgba(var(--color-warning-100),var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:rgba(146, 64, 14,var(--tw-text-opacity));
  color:rgba(var(--color-warning-800),var(--tw-text-opacity))
}

._ods_badge_warning_ih07p_318 button,._ods_badge_warning_ih07p_318 div{
  --tw-text-opacity: 1;
  color:rgba(251, 191, 36,var(--tw-text-opacity));
  color:rgba(var(--color-warning-400),var(--tw-text-opacity))
}

._ods_badge_warning_ih07p_318 button:hover,._ods_badge_warning_ih07p_318 div:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(253, 230, 138,var(--tw-bg-opacity));
  background-color:rgba(var(--color-warning-200),var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:rgba(245, 158, 11,var(--tw-text-opacity));
  color:rgba(var(--color-warning-500),var(--tw-text-opacity))
}

._ods_badge_warning_ih07p_318 button:focus,._ods_badge_warning_ih07p_318 div:focus{
  --tw-bg-opacity: 1;
  background-color:rgba(245, 158, 11,var(--tw-bg-opacity));
  background-color:rgba(var(--color-warning-500),var(--tw-bg-opacity))
}

._ods_badge_success_ih07p_325{
  --tw-bg-opacity: 1;
  background-color:rgba(209, 250, 229,var(--tw-bg-opacity));
  background-color:rgba(var(--color-success-100),var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:rgba(6, 95, 70,var(--tw-text-opacity));
  color:rgba(var(--color-success-800),var(--tw-text-opacity))
}

._ods_badge_success_ih07p_325 button,._ods_badge_success_ih07p_325 div{
  --tw-text-opacity: 1;
  color:rgba(52, 211, 153,var(--tw-text-opacity));
  color:rgba(var(--color-success-400),var(--tw-text-opacity))
}

._ods_badge_success_ih07p_325 button:hover,._ods_badge_success_ih07p_325 div:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(167, 243, 208,var(--tw-bg-opacity));
  background-color:rgba(var(--color-success-200),var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:rgba(16, 185, 129,var(--tw-text-opacity));
  color:rgba(var(--color-success-500),var(--tw-text-opacity))
}

._ods_badge_success_ih07p_325 button:focus,._ods_badge_success_ih07p_325 div:focus{
  --tw-bg-opacity: 1;
  background-color:rgba(16, 185, 129,var(--tw-bg-opacity));
  background-color:rgba(var(--color-success-500),var(--tw-bg-opacity))
}

._ods_badge_info_ih07p_332{
  --tw-bg-opacity: 1;
  background-color:rgb(224 242 254 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:rgb(7 89 133 / var(--tw-text-opacity))
}

._ods_badge_info_ih07p_332 button,._ods_badge_info_ih07p_332 div{
  --tw-text-opacity: 1;
  color:rgb(56 189 248 / var(--tw-text-opacity))
}

._ods_badge_info_ih07p_332 button:hover,._ods_badge_info_ih07p_332 div:hover{
  --tw-bg-opacity: 1;
  background-color:rgb(186 230 253 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:rgb(14 165 233 / var(--tw-text-opacity))
}

._ods_badge_info_ih07p_332 button:focus,._ods_badge_info_ih07p_332 div:focus{
  --tw-bg-opacity: 1;
  background-color:rgb(14 165 233 / var(--tw-bg-opacity))
}

._ods_badge_white_ih07p_339{
  --tw-bg-opacity: 1;
  background-color:rgba(255, 255, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-white),var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:rgba(17, 24, 39,var(--tw-text-opacity));
  color:rgba(var(--color-dark-900),var(--tw-text-opacity))
}

._ods_badge_white_ih07p_339 button,._ods_badge_white_ih07p_339 div{
  --tw-text-opacity: 1;
  color:rgba(17, 24, 39,var(--tw-text-opacity));
  color:rgba(var(--color-dark-900),var(--tw-text-opacity))
}

._ods_badge_white_ih07p_339 button:hover,._ods_badge_white_ih07p_339 div:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(249, 250, 251,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-50),var(--tw-bg-opacity))
}

._ods_badge_white_ih07p_339 button:focus,._ods_badge_white_ih07p_339 div:focus{
  --tw-bg-opacity: 1;
  background-color:rgba(243, 244, 246,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-100),var(--tw-bg-opacity))
}

._ods_badge_pink_ih07p_346{
  --tw-bg-opacity: 1;
  background-color:rgb(252 231 243 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:rgb(157 23 77 / var(--tw-text-opacity))
}

._ods_badge_pink_ih07p_346 button,._ods_badge_pink_ih07p_346 div{
  --tw-text-opacity: 1;
  color:rgb(244 114 182 / var(--tw-text-opacity))
}

._ods_badge_pink_ih07p_346 button:hover,._ods_badge_pink_ih07p_346 div:hover{
  --tw-bg-opacity: 1;
  background-color:rgb(251 207 232 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:rgb(236 72 153 / var(--tw-text-opacity))
}

._ods_badge_pink_ih07p_346 button:focus,._ods_badge_pink_ih07p_346 div:focus{
  --tw-bg-opacity: 1;
  background-color:rgb(236 72 153 / var(--tw-bg-opacity))
}

._ods_badge_icon_ih07p_353{
  height:1rem;
  width:1rem
}

._ods_badge_icon_div_ih07p_356{
  display:flex;
  cursor:pointer;
  align-items:center
}

._ods_badge_icon_div_ih07p_356>span{
  height:auto
}

._ods_badge_icon_dot_ih07p_362{
  margin-right:.375rem;
  margin-left:-.25rem
}

._ods_badge_icon_x_ih07p_365:hover{
  border-radius:9999px
}

._ods_badge_icon_x_ih07p_365:focus{
  border-radius:9999px
}

._ods_badge_icon_x_ih07p_365:focus svg{
  --tw-text-opacity: 1;
  color:rgba(255, 255, 255,var(--tw-text-opacity));
  color:rgba(var(--color-white),var(--tw-text-opacity))
}

._ods_badge_icon_x_left_ih07p_374{
  margin-right:.25rem;
  margin-left:-.375rem
}

._ods_badge_icon_x_right_ih07p_377{
  margin-left:.25rem;
  margin-right:-.375rem
}

._ods_badge_icon_primary_dot_ih07p_380{
  --tw-text-opacity: 1;
  color:rgba(129, 140, 248,var(--tw-text-opacity));
  color:rgba(var(--color-primary-400),var(--tw-text-opacity))
}

._ods_badge_icon_primary_dark_dot_ih07p_383{
  --tw-text-opacity: 1;
  color:rgba(55, 48, 163,var(--tw-text-opacity));
  color:rgba(var(--color-primary-800),var(--tw-text-opacity))
}

._ods_badge_icon_secondary_dot_ih07p_386{
  --tw-text-opacity: 1;
  color:rgba(129, 140, 248,var(--tw-text-opacity));
  color:rgba(var(--color-primary-400),var(--tw-text-opacity))
}

._ods_badge_icon_light_dot_ih07p_389{
  --tw-text-opacity: 1;
  color:rgba(156, 163, 175,var(--tw-text-opacity));
  color:rgba(var(--color-light-400),var(--tw-text-opacity))
}

._ods_badge_icon_dark_dot_ih07p_392{
  --tw-text-opacity: 1;
  color:rgba(17, 24, 39,var(--tw-text-opacity));
  color:rgba(var(--color-dark-900),var(--tw-text-opacity))
}

._ods_badge_icon_danger_dot_ih07p_395{
  --tw-text-opacity: 1;
  color:rgba(248, 113, 113,var(--tw-text-opacity));
  color:rgba(var(--color-danger-400),var(--tw-text-opacity))
}

._ods_badge_icon_warning_dot_ih07p_398{
  --tw-text-opacity: 1;
  color:rgba(251, 191, 36,var(--tw-text-opacity));
  color:rgba(var(--color-warning-400),var(--tw-text-opacity))
}

._ods_badge_icon_white_dot_ih07p_401{
  --tw-text-opacity: 1;
  color:rgba(75, 85, 99,var(--tw-text-opacity));
  color:rgba(var(--color-dark-600),var(--tw-text-opacity))
}

._ods_badge_icon_success_dot_ih07p_404{
  --tw-text-opacity: 1;
  color:rgba(52, 211, 153,var(--tw-text-opacity));
  color:rgba(var(--color-success-400),var(--tw-text-opacity))
}

._ods_badge_icon_info_dot_ih07p_407{
  --tw-text-opacity: 1;
  color:rgb(56 189 248 / var(--tw-text-opacity))
}

._ods_badge_icon_pink_dot_ih07p_410{
  --tw-text-opacity: 1;
  color:rgb(244 114 182 / var(--tw-text-opacity))
}

._ods_badge_pill_ih07p_413{
  border-radius:9999px
}

._ods_badge_rounded_ih07p_416{
  border-radius:4px
}

._ods_button_ih07p_420{
  display:inline-flex;
  align-items:center;
  justify-content:center;
  border-width:1px;
  font-weight:500
}

._ods_button_ih07p_420:focus{
  outline:2px solid transparent;
  outline-offset:2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),0 0 #0000;
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(var(--color-primary-200), var(--tw-ring-opacity));
  --tw-ring-offset-width: 2px
}

._ods_button_full_width_ih07p_423{
  width:100%
}

._ods_button_xs_ih07p_426{
  border-radius:.25rem;
  padding:.375rem .625rem;
  font-size:.75rem;
  line-height:1rem
}

._ods_button_sm_ih07p_429{
  border-radius:.375rem;
  padding:.5rem .75rem;
  font-size:.875rem;
  line-height:1.25rem
}

._ods_button_base_ih07p_432{
  border-radius:.375rem;
  padding:.5rem 1rem;
  font-size:.875rem;
  line-height:1.25rem
}

._ods_button_l_ih07p_435{
  border-radius:.375rem;
  padding:.5rem 1rem;
  font-size:1rem;
  line-height:1.5rem
}

._ods_button_xl_ih07p_438{
  border-radius:.375rem;
  padding:.75rem 1.5rem;
  font-size:1rem;
  line-height:1.5rem
}

._ods_button_circular_xs_ih07p_441{
  border-radius:9999px;
  padding:.25rem;
  font-size:.75rem;
  line-height:1rem
}

._ods_button_circular_sm_ih07p_444{
  border-radius:9999px;
  padding:.375rem;
  font-size:.875rem;
  line-height:1.25rem
}

._ods_button_circular_base_ih07p_447{
  border-radius:9999px;
  padding:.5rem;
  font-size:.875rem;
  line-height:1.25rem
}

._ods_button_circular_l_ih07p_450{
  border-radius:9999px;
  padding:.5rem;
  font-size:1rem;
  line-height:1.5rem
}

._ods_button_circular_xl_ih07p_453{
  border-radius:9999px;
  padding:.75rem;
  font-size:1rem;
  line-height:1.5rem
}

._ods_button_primary_ih07p_456{
  border-color:transparent;
  --tw-bg-opacity: 1;
  background-color:rgba(79, 70, 229,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-600),var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:rgba(255, 255, 255,var(--tw-text-opacity));
  color:rgba(var(--color-white),var(--tw-text-opacity));
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / .05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow:0 0 #0000,0 0 #0000,var(--tw-shadow);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow);
  --tw-ring-offset-color: rgb(var(--color-white))
}

._ods_button_primary_ih07p_456:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(67, 56, 202,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-700),var(--tw-bg-opacity))
}

._ods_button_primary_ih07p_456:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(var(--color-primary-500), var(--tw-ring-opacity))
}

._ods_button_secondary_ih07p_459{
  border-color:transparent;
  --tw-bg-opacity: 1;
  background-color:rgba(224, 231, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-100),var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:rgba(67, 56, 202,var(--tw-text-opacity));
  color:rgba(var(--color-primary-700),var(--tw-text-opacity))
}

._ods_button_secondary_ih07p_459:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(165, 180, 252,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-300),var(--tw-bg-opacity))
}

._ods_button_secondary_ih07p_459:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(var(--color-primary-500), var(--tw-ring-opacity))
}

._ods_button_danger_ih07p_462{
  border-color:transparent;
  --tw-bg-opacity: 1;
  background-color:rgba(220, 38, 38,var(--tw-bg-opacity));
  background-color:rgba(var(--color-danger-600),var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:rgba(255, 255, 255,var(--tw-text-opacity));
  color:rgba(var(--color-white),var(--tw-text-opacity))
}

._ods_button_danger_ih07p_462:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(185, 28, 28,var(--tw-bg-opacity));
  background-color:rgba(var(--color-danger-700),var(--tw-bg-opacity))
}

._ods_button_danger_ih07p_462:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(var(--color-danger-500), var(--tw-ring-opacity))
}

._ods_button_subtle_danger_ih07p_465{
  border-color:transparent;
  --tw-bg-opacity: 1;
  background-color:rgba(254, 226, 226,var(--tw-bg-opacity));
  background-color:rgba(var(--color-danger-100),var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:rgba(185, 28, 28,var(--tw-text-opacity));
  color:rgba(var(--color-danger-700),var(--tw-text-opacity))
}

._ods_button_subtle_danger_ih07p_465:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(254, 202, 202,var(--tw-bg-opacity));
  background-color:rgba(var(--color-danger-200),var(--tw-bg-opacity))
}

._ods_button_subtle_danger_ih07p_465:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(var(--color-danger-500), var(--tw-ring-opacity))
}

._ods_button_success_ih07p_468{
  border-color:transparent;
  --tw-bg-opacity: 1;
  background-color:rgba(5, 150, 105,var(--tw-bg-opacity));
  background-color:rgba(var(--color-success-600),var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:rgba(255, 255, 255,var(--tw-text-opacity));
  color:rgba(var(--color-white),var(--tw-text-opacity))
}

._ods_button_success_ih07p_468:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(4, 120, 87,var(--tw-bg-opacity));
  background-color:rgba(var(--color-success-700),var(--tw-bg-opacity))
}

._ods_button_success_ih07p_468:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(var(--color-success-500), var(--tw-ring-opacity))
}

._ods_button_subtle_success_ih07p_471{
  border-color:transparent;
  --tw-bg-opacity: 1;
  background-color:rgba(209, 250, 229,var(--tw-bg-opacity));
  background-color:rgba(var(--color-success-100),var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:rgba(4, 120, 87,var(--tw-text-opacity));
  color:rgba(var(--color-success-700),var(--tw-text-opacity))
}

._ods_button_subtle_success_ih07p_471:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(167, 243, 208,var(--tw-bg-opacity));
  background-color:rgba(var(--color-success-200),var(--tw-bg-opacity))
}

._ods_button_subtle_success_ih07p_471:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(var(--color-success-500), var(--tw-ring-opacity))
}

._ods_button_warning_ih07p_474{
  border-color:transparent;
  --tw-bg-opacity: 1;
  background-color:rgba(217, 119, 6,var(--tw-bg-opacity));
  background-color:rgba(var(--color-warning-600),var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:rgba(255, 255, 255,var(--tw-text-opacity));
  color:rgba(var(--color-white),var(--tw-text-opacity))
}

._ods_button_warning_ih07p_474:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(180, 83, 9,var(--tw-bg-opacity));
  background-color:rgba(var(--color-warning-700),var(--tw-bg-opacity))
}

._ods_button_warning_ih07p_474:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(var(--color-warning-500), var(--tw-ring-opacity))
}

._ods_button_subtle_warning_ih07p_477{
  border-color:transparent;
  --tw-bg-opacity: 1;
  background-color:rgba(254, 243, 199,var(--tw-bg-opacity));
  background-color:rgba(var(--color-warning-100),var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:rgba(180, 83, 9,var(--tw-text-opacity));
  color:rgba(var(--color-warning-700),var(--tw-text-opacity))
}

._ods_button_subtle_warning_ih07p_477:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(253, 230, 138,var(--tw-bg-opacity));
  background-color:rgba(var(--color-warning-200),var(--tw-bg-opacity))
}

._ods_button_subtle_warning_ih07p_477:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(var(--color-warning-500), var(--tw-ring-opacity))
}

._ods_button_white_ih07p_480{
  --tw-border-opacity: 1;
  border-color:rgba(209, 213, 219,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-300),var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color:rgba(255, 255, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-white),var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:rgba(55, 65, 81,var(--tw-text-opacity));
  color:rgba(var(--color-dark-700),var(--tw-text-opacity));
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / .05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow:0 0 #0000,0 0 #0000,var(--tw-shadow);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow)
}

._ods_button_white_ih07p_480:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(249, 250, 251,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-50),var(--tw-bg-opacity))
}

._ods_button_white_ih07p_480:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(var(--color-primary-500), var(--tw-ring-opacity))
}

._ods_button_disabled_ih07p_483{
  cursor:not-allowed;
  opacity:.5
}

._ods_button_rounded_ih07p_486{
  border-radius:9999px
}

._ods_button_loader_message_ih07p_489{
  pointer-events:none
}

._ods_breadcrumb_item_ih07p_493{
  display:flex
}

._ods_breadcrumb_item_ih07p_493 div{
  display:flex;
  align-items:center
}

._ods_breadcrumb_item_icon_ih07p_499{
  height:100%;
  width:1.5rem;
  flex-shrink:0;
  --tw-text-opacity: 1;
  color:rgba(156, 163, 175,var(--tw-text-opacity));
  color:rgba(var(--color-light-400),var(--tw-text-opacity))
}

._ods_breadcrumb_item_icon_ih07p_499 span{
  margin:0
}

._ods_breadcrumb_item_icon_only_ih07p_505{
  --tw-text-opacity: 1;
  color:rgba(156, 163, 175,var(--tw-text-opacity));
  color:rgba(var(--color-light-400),var(--tw-text-opacity))
}

._ods_breadcrumb_item_icon_only_ih07p_505:hover{
  --tw-text-opacity: 1;
  color:rgba(107, 114, 128,var(--tw-text-opacity));
  color:rgba(var(--color-dark-500),var(--tw-text-opacity))
}

._ods_breadcrumb_item_link_ih07p_508{
  margin-left:.5rem;
  -moz-column-gap:.5rem;
  grid-column-gap:.5rem;
  -webkit-column-gap:.5rem;
  column-gap:.5rem;
  font-size:.875rem;
  line-height:1.25rem;
  font-weight:500;
  --tw-text-opacity: 1;
  color:rgba(107, 114, 128,var(--tw-text-opacity));
  color:rgba(var(--color-dark-500),var(--tw-text-opacity))
}

._ods_breadcrumb_item_link_ih07p_508:visited{
  color:rgba(107, 114, 128,var(--tw-text-opacity));
  color:rgba(var(--color-dark-500),var(--tw-text-opacity))
}

._ods_breadcrumb_item_link_ih07p_508:hover{
  --tw-text-opacity: 1;
  color:rgba(55, 65, 81,var(--tw-text-opacity));
  color:rgba(var(--color-dark-700),var(--tw-text-opacity));
  -webkit-text-decoration-line:none;
  text-decoration-line:none
}

._ods_breadcrumb_item_link_ih07p_508:focus{
  outline:2px solid transparent;
  outline-offset:2px
}

._ods_breadcrumb_item_link_ih07p_508:disabled{
  --tw-text-opacity: 1;
  color:rgba(107, 114, 128,var(--tw-text-opacity));
  color:rgba(var(--color-dark-500),var(--tw-text-opacity))
}

@media (min-width: 640px){
  ._ods_breadcrumb_item_link_ih07p_508{
    margin-left:1rem
  }
}

._ods_breadcrumb_item_icon_wrapper_ih07p_511:focus{
  outline:2px solid transparent;
  outline-offset:2px
}

._ods_dropdown_header_ih07p_515{
  padding:.75rem 1rem
}

._ods_dropdown_item_ih07p_519{
  display:flex;
  width:100%;
  align-items:center;
  overflow-wrap:break-word;
  padding:.5rem 1rem;
  font-size:.875rem;
  line-height:1.25rem;
  --tw-text-opacity: 1;
  color:rgba(55, 65, 81,var(--tw-text-opacity));
  color:rgba(var(--color-dark-700),var(--tw-text-opacity))
}

._ods_dropdown_item_ih07p_519:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(243, 244, 246,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-100),var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:rgba(17, 24, 39,var(--tw-text-opacity));
  color:rgba(var(--color-dark-900),var(--tw-text-opacity))
}

._ods_dropdown_item_ih07p_519:focus{
  outline:2px solid transparent;
  outline-offset:2px
}

._ods_dropdown_trigger_ih07p_523{
  display:inline-flex;
  justify-content:center;
  border-radius:.375rem;
  border-width:1px;
  --tw-border-opacity: 1;
  border-color:rgba(209, 213, 219,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-300),var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color:rgba(255, 255, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-white),var(--tw-bg-opacity));
  padding:.5rem 1rem;
  font-size:.875rem;
  line-height:1.25rem;
  font-weight:500;
  --tw-text-opacity: 1;
  color:rgba(55, 65, 81,var(--tw-text-opacity));
  color:rgba(var(--color-dark-700),var(--tw-text-opacity));
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / .05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow:0 0 #0000,0 0 #0000,var(--tw-shadow);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow)
}

._ods_dropdown_trigger_ih07p_523:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(249, 250, 251,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-50),var(--tw-bg-opacity))
}

._ods_dropdown_trigger_ih07p_523:focus{
  outline:2px solid transparent;
  outline-offset:2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),0 0 #0000;
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(var(--color-primary-500), var(--tw-ring-opacity));
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: rgb(var(--color-light-100))
}

._ods_dropdown_trigger_borderless_ih07p_527{
  border-style:none;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow:0 0 #0000,0 0 #0000,var(--tw-shadow);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow)
}

._ods_dropdown_trigger_borderless_ih07p_527:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(255, 255, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-white),var(--tw-bg-opacity))
}

._ods_dropdown_trigger_borderless_ih07p_527:focus{
  --tw-border-opacity: 1;
  border-color:rgba(99, 102, 241,var(--tw-border-opacity));
  border-color:rgba(var(--color-primary-500),var(--tw-border-opacity))
}

._ods_dropdown_trigger_borderless_ih07p_527:hover svg,._ods_dropdown_trigger_borderless_ih07p_527:focus svg{
  --tw-text-opacity: 1;
  color:rgba(99, 102, 241,var(--tw-text-opacity));
  color:rgba(var(--color-primary-500),var(--tw-text-opacity))
}

._ods_dropdown_trigger_iconOnly_ih07p_533{
  display:flex;
  align-items:center;
  border-radius:9999px;
  --tw-bg-opacity: 1;
  background-color:rgba(243, 244, 246,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-100),var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:rgba(156, 163, 175,var(--tw-text-opacity));
  color:rgba(var(--color-light-400),var(--tw-text-opacity))
}

._ods_dropdown_trigger_iconOnly_ih07p_533:hover{
  --tw-text-opacity: 1;
  color:rgba(75, 85, 99,var(--tw-text-opacity));
  color:rgba(var(--color-dark-600),var(--tw-text-opacity))
}

._ods_dropdown_trigger_iconOnly_ih07p_533:focus{
  outline:2px solid transparent;
  outline-offset:2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),0 0 #0000;
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(var(--color-primary-500), var(--tw-ring-opacity));
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: rgb(var(--color-light-100))
}

._ods_dropdown_trigger_avatar_ih07p_536{
  display:flex;
  border-radius:9999px;
  --tw-bg-opacity: 1;
  background-color:rgba(31, 41, 55,var(--tw-bg-opacity));
  background-color:rgba(var(--color-dark-800),var(--tw-bg-opacity));
  font-size:.875rem;
  line-height:1.25rem
}

._ods_dropdown_trigger_avatar_ih07p_536:focus{
  outline:2px solid transparent;
  outline-offset:2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),0 0 #0000;
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(var(--color-white), var(--tw-ring-opacity));
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: rgb(var(--color-dark-800))
}

._ods_icon_sm_ih07p_540{
  height:1rem;
  width:1rem
}

._ods_icon_base_ih07p_543{
  height:1.25rem;
  width:1.25rem
}

._ods_icon_l_ih07p_546{
  height:1.5rem;
  width:1.5rem
}

._ods_icon_left_spacing_sm_ih07p_549{
  margin-left:-.125rem;
  margin-right:.5rem
}

._ods_icon_left_spacing_base_ih07p_552{
  margin-left:-.25rem;
  margin-right:.5rem
}

._ods_icon_left_spacing_l_ih07p_555{
  margin-left:-.25rem;
  margin-right:.75rem
}

._ods_icon_right_spacing_sm_ih07p_558{
  margin-left:.5rem;
  margin-right:-.125rem
}

._ods_icon_right_spacing_base_ih07p_561{
  margin-left:.5rem;
  margin-right:-.25rem
}

._ods_icon_right_spacing_l_ih07p_564{
  margin-left:.75rem;
  margin-right:-.25rem
}

._ods_icon_primary_ih07p_567{
  --tw-text-opacity: 1;
  color:rgba(37, 99, 235,var(--tw-text-opacity));
  color:rgba(var(--color-info-600),var(--tw-text-opacity))
}

._ods_icon_white_ih07p_570{
  --tw-text-opacity: 1;
  color:rgba(255, 255, 255,var(--tw-text-opacity));
  color:rgba(var(--color-white),var(--tw-text-opacity))
}

@-webkit-keyframes _spin_ih07p_1{
  to{
    -webkit-transform:rotate(360deg);
    transform:rotate(360deg)
  }
}

@keyframes _spin_ih07p_1{
  to{
    -webkit-transform:rotate(360deg);
    transform:rotate(360deg)
  }
}

._ods_icon_spin_ih07p_573{
  -webkit-animation:_spin_ih07p_1 1s linear infinite;
  animation:_spin_ih07p_1 1s linear infinite
}

._ods_icon_avatar_ih07p_576{
  height:100%;
  width:100%;
  --tw-text-opacity: 1;
  color:rgba(209, 213, 219,var(--tw-text-opacity));
  color:rgba(var(--color-light-300),var(--tw-text-opacity))
}

._ods_icon_toggle_icons_enabled_ih07p_579{
  height:.75rem;
  width:.75rem;
  --tw-text-opacity: 1;
  color:rgba(79, 70, 229,var(--tw-text-opacity));
  color:rgba(var(--color-primary-600),var(--tw-text-opacity))
}

._ods_icon_toggle_icons_disabled_ih07p_582{
  height:.75rem;
  width:.75rem;
  --tw-text-opacity: 1;
  color:rgba(156, 163, 175,var(--tw-text-opacity));
  color:rgba(var(--color-light-400),var(--tw-text-opacity))
}

._ods_icon_drag_upload_ih07p_585{
  margin-left:auto;
  margin-right:auto;
  height:2.5rem;
  width:2.5rem;
  --tw-text-opacity: 1;
  color:rgba(156, 163, 175,var(--tw-text-opacity));
  color:rgba(var(--color-light-400),var(--tw-text-opacity))
}

@media (min-width: 640px){
  ._ods_icon_drag_upload_ih07p_585{
    height:3rem;
    width:3rem
  }
}

._ods_icon_custom_ih07p_588{
  margin-left:auto;
  margin-right:auto;
  height:3rem;
  width:3rem;
  --tw-text-opacity: 1;
  color:rgba(156, 163, 175,var(--tw-text-opacity));
  color:rgba(var(--color-light-400),var(--tw-text-opacity))
}

._ods_icon_sort_ih07p_591{
  margin-left:.25rem
}

._ods_icon_sort_normal_ih07p_594{
  fill:rgb(156, 163, 175);
  fill:rgb(var(--color-light-400))
}

._ods_icon_sort_asc_or_desc_ih07p_597{
  fill:rgb(79, 70, 229);
  fill:rgb(var(--color-primary-600))
}

._ods_help_text_ih07p_601{
  font-size:.875rem;
  line-height:1.25rem;
  font-weight:400;
  --tw-text-opacity: 1;
  color:rgba(107, 114, 128,var(--tw-text-opacity));
  color:rgba(var(--color-dark-500),var(--tw-text-opacity))
}

._ods_help_text_ih07p_601 svg{
  margin-right:.375rem;
  height:1.25rem;
  width:1.25rem;
  flex-shrink:0
}

._ods_link_ih07p_608{
  display:inline-flex;
  cursor:pointer;
  align-items:center;
  font-weight:500
}

._ods_link_ih07p_608:focus{
  outline-style:dotted;
  outline-width:1px
}

._ods_link_sm_ih07p_611{
  font-size:.75rem;
  line-height:1rem
}

._ods_link_base_ih07p_614{
  font-size:.875rem;
  line-height:1.25rem
}

._ods_link_l_ih07p_617{
  font-size:1rem;
  line-height:1.5rem
}

._ods_link_underline_ih07p_620:hover,._ods_link_inline_ih07p_623{
  -webkit-text-decoration-line:underline;
  text-decoration-line:underline
}

._ods_link_primary_ih07p_626{
  --tw-text-opacity: 1;
  color:rgba(79, 70, 229,var(--tw-text-opacity));
  color:rgba(var(--color-primary-600),var(--tw-text-opacity))
}

._ods_link_primary_ih07p_626:visited{
  color:rgba(79, 70, 229,var(--tw-text-opacity));
  color:rgba(var(--color-primary-600),var(--tw-text-opacity))
}

._ods_link_primary_ih07p_626:focus{
  outline-color:rgb(165, 180, 252);
  outline-color:rgb(var(--color-primary-300))
}

._ods_link_primary_ih07p_626:active{
  --tw-text-opacity: 1;
  color:rgba(55, 48, 163,var(--tw-text-opacity));
  color:rgba(var(--color-primary-800),var(--tw-text-opacity));
  outline-color:rgb(79, 70, 229);
  outline-color:rgb(var(--color-primary-600))
}

._ods_link_primary_button_ih07p_629{
  --tw-text-opacity: 1;
  color:rgba(79, 70, 229,var(--tw-text-opacity));
  color:rgba(var(--color-primary-600),var(--tw-text-opacity))
}

._ods_link_primary_button_ih07p_629:hover{
  --tw-text-opacity: 1;
  color:rgba(67, 56, 202,var(--tw-text-opacity));
  color:rgba(var(--color-primary-700),var(--tw-text-opacity))
}

._ods_link_danger_ih07p_632{
  --tw-text-opacity: 1;
  color:rgba(220, 38, 38,var(--tw-text-opacity));
  color:rgba(var(--color-danger-600),var(--tw-text-opacity))
}

._ods_link_danger_ih07p_632:visited{
  color:rgba(79, 70, 229,var(--tw-text-opacity));
  color:rgba(var(--color-primary-600),var(--tw-text-opacity))
}

._ods_link_danger_ih07p_632:focus{
  outline-color:rgb(252, 165, 165);
  outline-color:rgb(var(--color-danger-300))
}

._ods_link_danger_ih07p_632:active{
  --tw-text-opacity: 1;
  color:rgba(153, 27, 27,var(--tw-text-opacity));
  color:rgba(var(--color-danger-800),var(--tw-text-opacity));
  outline-color:rgb(252, 165, 165);
  outline-color:rgb(var(--color-danger-300))
}

._ods_link_danger_button_ih07p_635{
  --tw-text-opacity: 1;
  color:rgba(220, 38, 38,var(--tw-text-opacity));
  color:rgba(var(--color-danger-600),var(--tw-text-opacity))
}

._ods_link_danger_button_ih07p_635:hover{
  --tw-text-opacity: 1;
  color:rgba(185, 28, 28,var(--tw-text-opacity));
  color:rgba(var(--color-danger-700),var(--tw-text-opacity))
}

._ods_link_success_button_ih07p_638{
  --tw-text-opacity: 1;
  color:rgba(5, 150, 105,var(--tw-text-opacity));
  color:rgba(var(--color-success-600),var(--tw-text-opacity))
}

._ods_link_success_button_ih07p_638:hover{
  --tw-text-opacity: 1;
  color:rgba(4, 120, 87,var(--tw-text-opacity));
  color:rgba(var(--color-success-700),var(--tw-text-opacity))
}

._ods_link_warning_button_ih07p_641{
  --tw-text-opacity: 1;
  color:rgba(217, 119, 6,var(--tw-text-opacity));
  color:rgba(var(--color-warning-600),var(--tw-text-opacity))
}

._ods_link_warning_button_ih07p_641:hover{
  --tw-text-opacity: 1;
  color:rgba(180, 83, 9,var(--tw-text-opacity));
  color:rgba(var(--color-warning-700),var(--tw-text-opacity))
}

._ods_link_white_ih07p_644{
  --tw-text-opacity: 1;
  color:rgba(255, 255, 255,var(--tw-text-opacity));
  color:rgba(var(--color-white),var(--tw-text-opacity))
}

._ods_link_white_ih07p_644:visited{
  color:rgba(209, 213, 219,var(--tw-text-opacity));
  color:rgba(var(--color-light-300),var(--tw-text-opacity))
}

._ods_link_white_ih07p_644:focus{
  outline-color:rgb(255, 255, 255);
  outline-color:rgb(var(--color-white))
}

._ods_link_white_ih07p_644:active{
  outline-color:rgb(255, 255, 255);
  outline-color:rgb(var(--color-white))
}

._ods_link_white_button_ih07p_647{
  --tw-text-opacity: 1;
  color:rgba(255, 255, 255,var(--tw-text-opacity));
  color:rgba(var(--color-white),var(--tw-text-opacity))
}

._ods_link_secondary_button_ih07p_650{
  --tw-text-opacity: 1;
  color:rgba(224, 231, 255,var(--tw-text-opacity));
  color:rgba(var(--color-primary-100),var(--tw-text-opacity))
}

._ods_link_secondary_button_ih07p_650:hover{
  --tw-text-opacity: 1;
  color:rgba(199, 210, 254,var(--tw-text-opacity));
  color:rgba(var(--color-primary-200),var(--tw-text-opacity))
}

._ods_link_disabled_ih07p_653{
  pointer-events:none;
  cursor:not-allowed;
  opacity:.5
}

._ods_link_disabled_ih07p_653:focus{
  outline:2px solid transparent;
  outline-offset:2px
}

._ods_input_choice_ih07p_657{
  position:relative;
  display:flex;
  align-items:flex-start
}

._ods_input_choice_input_ih07p_660{
  height:1rem;
  width:1rem;
  cursor:pointer;
  -webkit-appearance:none;
  appearance:none;
  border-width:1px;
  --tw-border-opacity: 1;
  border-color:rgba(209, 213, 219,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-300),var(--tw-border-opacity))
}

._ods_input_choice_input_ih07p_660:checked{
  --tw-border-opacity: 1;
  border-color:rgba(79, 70, 229,var(--tw-border-opacity));
  border-color:rgba(var(--color-primary-600),var(--tw-border-opacity))
}

._ods_input_choice_input_ih07p_660:hover{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),0 0 #0000;
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(var(--color-primary-100), var(--tw-ring-opacity))
}

._ods_input_choice_input_ih07p_660:focus{
  outline-offset:4px;
  outline-color:rgb(79, 70, 229);
  outline-color:rgb(var(--color-primary-600))
}

._ods_input_choice_input_checkbox_ih07p_663{
  border-radius:.25rem;
  background-position:center;
  background-repeat:no-repeat
}

._ods_input_choice_input_checkbox_ih07p_663:checked{
  --tw-bg-opacity: 1;
  background-color:rgba(79, 70, 229,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-600),var(--tw-bg-opacity));
  background-image:url(/static/media/Checkmark.023bfd64.svg)
}

._ods_input_choice_input_checkbox_intermediate_ih07p_666{
  --tw-bg-opacity: 1;
  background-color:rgba(79, 70, 229,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-600),var(--tw-bg-opacity));
  background-image:url(/static/media/Intermediate.8403765e.svg)
}

._ods_input_choice_input_radio_ih07p_669{
  border-radius:9999px
}

._ods_input_choice_input_radio_ih07p_669:checked{
  --tw-shadow: inset 0 0 0 4px rgb(var(--color-primary-600));
  --tw-shadow-colored: inset 0 0 0 4px var(--tw-shadow-color);
  box-shadow:0 0 #0000,0 0 #0000,var(--tw-shadow);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow)
}

._ods_input_choice_input_container_ih07p_672{
  display:flex;
  height:1.25rem;
  align-items:center
}

._ods_input_choice_input_container_left_ih07p_675{
  order:1
}

._ods_input_choice_input_container_right_ih07p_678{
  order:2;
  margin-left:.75rem
}

._ods_input_choice_label_col_ih07p_681{
  display:flex;
  flex-direction:column
}

._ods_input_choice_label_left_ih07p_684{
  order:2;
  margin-left:.75rem;
  display:flex
}

._ods_input_choice_label_right_ih07p_687{
  order:1;
  display:flex
}

._ods_input_choice_description_ih07p_690{
  --tw-text-opacity: 1;
  color:rgba(107, 114, 128,var(--tw-text-opacity));
  color:rgba(var(--color-dark-500),var(--tw-text-opacity))
}

._ods_input_choice_description_inline_ih07p_693{
  display:inline-table;
  text-indent:.25rem
}

._ods_input_choice_disabled_ih07p_696{
  cursor:not-allowed;
  opacity:.5
}

._ods_input_choice_disabled_ih07p_696:hover{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),0 0 #0000;
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),var(--tw-shadow, 0 0 #0000)
}

._ods_tab_item_ih07p_700{
  display:none;
  white-space:nowrap;
  font-size:.875rem;
  line-height:1.25rem;
  font-weight:500
}

@media (min-width: 640px){
  ._ods_tab_item_ih07p_700{
    display:block
  }
}

._ods_tab_item_container_ih07p_703{
  border-bottom-width:2px;
  padding:1rem .25rem
}

._ods_tab_item_container_default_ih07p_706{
  border-color:transparent;
  --tw-text-opacity: 1;
  color:rgba(107, 114, 128,var(--tw-text-opacity));
  color:rgba(var(--color-dark-500),var(--tw-text-opacity))
}

._ods_tab_item_container_default_ih07p_706:hover{
  --tw-border-opacity: 1;
  border-color:rgba(209, 213, 219,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-300),var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color:rgba(55, 65, 81,var(--tw-text-opacity));
  color:rgba(var(--color-dark-700),var(--tw-text-opacity))
}

._ods_tab_item_container_default_selected_ih07p_709{
  --tw-border-opacity: 1;
  border-color:rgba(99, 102, 241,var(--tw-border-opacity));
  border-color:rgba(var(--color-primary-500),var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color:rgba(79, 70, 229,var(--tw-text-opacity));
  color:rgba(var(--color-primary-600),var(--tw-text-opacity))
}

._ods_tab_item_container_w_full_ih07p_712{
  width:25%;
  text-align:center;
  font-size:.875rem;
  line-height:1.25rem;
  font-weight:500
}

@media (min-width: 640px){
  ._ods_tab_item_mobile_ih07p_715{
    display:none
  }
}

._ods_tab_list_ih07p_719{
  display:flex;
  border-bottom-width:1px;
  --tw-border-opacity: 1;
  border-color:rgba(229, 231, 235,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-200),var(--tw-border-opacity))
}

._ods_tab_list_desktop_ih07p_722{
  display:none
}

@media (min-width: 640px){
  ._ods_tab_list_desktop_ih07p_722{
    display:block
  }
}

._ods_tab_list_spacing_ih07p_725>:not([hidden])~:not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right:calc(2rem * var(--tw-space-x-reverse));
  margin-left:calc(2rem * calc(1 - var(--tw-space-x-reverse)))
}

._ods_tab_list_w_full_ih07p_728>button{
  width:100%
}

._ods_tab_list_bar_ih07p_731{
  position:relative;
  z-index:0;
  display:flex
}

._ods_tab_list_bar_ih07p_731>:not([hidden])~:not([hidden]){
  --tw-divide-x-reverse: 0;
  border-right-width:calc(1px * var(--tw-divide-x-reverse));
  border-left-width:calc(1px * calc(1 - var(--tw-divide-x-reverse)));
  --tw-divide-opacity: 1;
  border-color:rgba(229, 231, 235,var(--tw-divide-opacity));
  border-color:rgba(var(--color-light-200),var(--tw-divide-opacity))
}

._ods_tab_list_bar_ih07p_731{
  border-radius:.5rem;
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / .1), 0 1px 2px -1px rgb(0 0 0 / .1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow:0 0 #0000,0 0 #0000,var(--tw-shadow);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow)
}

._ods_tab_list_bar_ih07p_731>button{
  width:100%;
  --tw-bg-opacity: 1;
  background-color:rgba(255, 255, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-white),var(--tw-bg-opacity))
}

._ods_tab_list_bar_ih07p_731>button:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(249, 250, 251,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-50),var(--tw-bg-opacity))
}

._ods_tab_list_bar_ih07p_731>button:first-child{
  overflow:hidden;
  border-top-left-radius:.5rem;
  border-bottom-left-radius:.5rem
}

._ods_tab_list_bar_ih07p_731>button:last-child{
  overflow:hidden;
  border-top-right-radius:.5rem;
  border-bottom-right-radius:.5rem
}

._ods_tab_list_bar_ih07p_731>button[aria-selected=true]>div{
  border-bottom-width:2px
}

._ods_tab_list_bar_ih07p_731>button[aria-selected=false]>div:hover{
  border-color:transparent
}

._ods_tab_list_pill_ih07p_749{
  display:flex
}

._ods_tab_list_pill_ih07p_749>:not([hidden])~:not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right:calc(1rem * var(--tw-space-x-reverse));
  margin-left:calc(1rem * calc(1 - var(--tw-space-x-reverse)))
}

._ods_tab_list_pill_ih07p_749>button>div{
  border-radius:.375rem;
  border-bottom-width:0px;
  padding:.5rem .75rem;
  font-size:.875rem;
  line-height:1.25rem;
  font-weight:500
}

._ods_tab_list_pill_primary_ih07p_755>button[aria-selected=true]>div{
  --tw-bg-opacity: 1;
  background-color:rgba(224, 231, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-100),var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:rgba(67, 56, 202,var(--tw-text-opacity));
  color:rgba(var(--color-primary-700),var(--tw-text-opacity))
}

._ods_tab_list_pill_primary_ih07p_755>button[aria-selected=false]>div{
  --tw-text-opacity: 1;
  color:rgba(107, 114, 128,var(--tw-text-opacity));
  color:rgba(var(--color-dark-500),var(--tw-text-opacity))
}

._ods_tab_list_pill_primary_ih07p_755>button[aria-selected=false]>div:hover{
  --tw-text-opacity: 1;
  color:rgba(55, 65, 81,var(--tw-text-opacity));
  color:rgba(var(--color-dark-700),var(--tw-text-opacity))
}

._ods_tab_list_pill_secondary_ih07p_761>button[aria-selected=true]>div{
  --tw-bg-opacity: 1;
  background-color:rgba(243, 244, 246,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-100),var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:rgba(55, 65, 81,var(--tw-text-opacity));
  color:rgba(var(--color-dark-700),var(--tw-text-opacity))
}

._ods_tab_list_pill_secondary_ih07p_761>button[aria-selected=false]>div{
  --tw-text-opacity: 1;
  color:rgba(107, 114, 128,var(--tw-text-opacity));
  color:rgba(var(--color-dark-500),var(--tw-text-opacity))
}

._ods_tab_list_pill_secondary_ih07p_761>button[aria-selected=false]>div:hover{
  --tw-text-opacity: 1;
  color:rgba(55, 65, 81,var(--tw-text-opacity));
  color:rgba(var(--color-dark-700),var(--tw-text-opacity))
}

._ods_tab_list_pill_gray_ih07p_767>button[aria-selected=true]>div{
  --tw-bg-opacity: 1;
  background-color:rgba(229, 231, 235,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-200),var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:rgba(31, 41, 55,var(--tw-text-opacity));
  color:rgba(var(--color-dark-800),var(--tw-text-opacity))
}

._ods_tab_list_pill_gray_ih07p_767>button[aria-selected=false]>div{
  --tw-text-opacity: 1;
  color:rgba(75, 85, 99,var(--tw-text-opacity));
  color:rgba(var(--color-dark-600),var(--tw-text-opacity))
}

._ods_tab_list_pill_gray_ih07p_767>button[aria-selected=false]>div:hover{
  --tw-text-opacity: 1;
  color:rgba(31, 41, 55,var(--tw-text-opacity));
  color:rgba(var(--color-dark-800),var(--tw-text-opacity))
}

._ods_tab_panels_ih07p_774{
  margin-top:.5rem
}

._ods_table_heading_ih07p_782{
  padding:.75rem 1.5rem;
  text-align:left;
  font-size:.75rem;
  line-height:1rem;
  font-weight:500;
  text-transform:uppercase;
  letter-spacing:.05em;
  --tw-text-opacity: 1;
  color:rgba(107, 114, 128,var(--tw-text-opacity));
  color:rgba(var(--color-dark-500),var(--tw-text-opacity))
}

._ods_table_data_ih07p_786{
  white-space:nowrap;
  padding:1rem 1.5rem;
  font-size:.875rem;
  line-height:1.25rem;
  --tw-text-opacity: 1;
  color:rgba(17, 24, 39,var(--tw-text-opacity));
  color:rgba(var(--color-dark-900),var(--tw-text-opacity))
}

._ods_table_data_ih07p_786 a{
  --tw-text-opacity: 1;
  color:rgba(79, 70, 229,var(--tw-text-opacity));
  color:rgba(var(--color-primary-600),var(--tw-text-opacity))
}

._ods_table_data_ih07p_786 a:hover{
  --tw-text-opacity: 1;
  color:rgba(49, 46, 129,var(--tw-text-opacity));
  color:rgba(var(--color-primary-900),var(--tw-text-opacity));
  -webkit-text-decoration-line:none;
  text-decoration-line:none
}

._ods_table_data_ih07p_786 a:focus{
  outline:2px solid transparent;
  outline-offset:2px
}

._ods_table_data_leading_ih07p_792{
  justify-content:flex-start;
  text-align:left
}

._ods_table_data_leading_ih07p_792>*{
  justify-content:flex-start
}

._ods_table_data_trailing_ih07p_798{
  justify-content:flex-end;
  text-align:right
}

._ods_table_data_trailing_ih07p_798>*{
  justify-content:flex-end
}

._ods_table_data_center_ih07p_804{
  justify-content:center;
  text-align:center
}

._ods_table_data_center_ih07p_804>*{
  justify-content:center
}

._ods_text_input_ih07p_811{
  display:block;
  width:100%;
  border-radius:.375rem;
  font-size:1rem;
  line-height:1.5rem;
  outline:2px solid transparent;
  outline-offset:2px
}

._ods_text_input_ih07p_811:focus{
  z-index:3
}

@media (min-width: 640px){
  ._ods_text_input_ih07p_811{
    font-size:.875rem;
    line-height:1.25rem
  }
}

._ods_text_input_border_ih07p_814{
  border-width:1px;
  --tw-border-opacity: 1;
  border-color:rgba(209, 213, 219,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-300),var(--tw-border-opacity))
}

._ods_text_input_border_ih07p_814:hover{
  --tw-border-opacity: 1;
  border-color:rgba(156, 163, 175,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-400),var(--tw-border-opacity))
}

._ods_text_input_borderless_ih07p_817{
  border-width:1px;
  border-color:transparent;
  padding:.5rem;
  transition-property:all;
  transition-timing-function:cubic-bezier(.4,0,.2,1);
  transition-duration:.3s
}

._ods_text_input_borderless_ih07p_817:hover{
  --tw-border-opacity: 1;
  border-color:rgba(156, 163, 175,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-400),var(--tw-border-opacity))
}

._ods_text_input_borderless_ih07p_817:focus{
  --tw-border-opacity: 1;
  border-color:rgba(156, 163, 175,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-400),var(--tw-border-opacity))
}

._ods_text_input_borderless_inline_addon_ih07p_820{
  border-width:1px;
  border-style:none
}

._ods_text_input_borderless_inline_addon_ih07p_820:hover{
  border-style:none
}

._ods_text_input_borderless_inline_addon_ih07p_820:focus{
  border-style:none
}

._ods_text_input_shadow_ih07p_823{
  padding:.5rem;
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / .05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow:0 0 #0000,0 0 #0000,var(--tw-shadow);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow)
}

._ods_text_input_overlapping_ih07p_826{
  padding-left:.25rem;
  padding-right:.25rem
}

._ods_text_input_pill_ih07p_829{
  border-radius:9999px;
  padding-left:1rem;
  padding-right:1rem
}

._ods_text_input_underline_ih07p_832{
  border-radius:0;
  border-width:0px;
  border-bottom-width:1px;
  border-color:transparent;
  --tw-bg-opacity: 1;
  background-color:rgba(249, 250, 251,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-50),var(--tw-bg-opacity))
}

._ods_text_input_underline_container_ih07p_835{
  border-bottom-width:1px;
  --tw-border-opacity: 1;
  border-color:rgba(209, 213, 219,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-300),var(--tw-border-opacity))
}

._ods_text_input_underline_container_ih07p_835:focus-within{
  --tw-border-opacity: 1;
  border-color:rgba(79, 70, 229,var(--tw-border-opacity));
  border-color:rgba(var(--color-primary-600),var(--tw-border-opacity))
}

._ods_text_input_focus_ih07p_838:focus{
  --tw-border-opacity: 1;
  border-color:rgba(99, 102, 241,var(--tw-border-opacity));
  border-color:rgba(var(--color-primary-500),var(--tw-border-opacity));
  --tw-shadow: 0px 0px 0px 1px rgb(var(--color-primary-500));
  --tw-shadow-colored: 0px 0px 0px 1px var(--tw-shadow-color);
  box-shadow:0 0 #0000,0 0 #0000,var(--tw-shadow);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow)
}

._ods_text_input_no_focus_ih07p_841{
  border-style:none
}

._ods_text_input_no_focus_ih07p_841:hover{
  --tw-border-opacity: 1;
  border-color:rgba(209, 213, 219,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-300),var(--tw-border-opacity))
}

._ods_text_input_no_focus_ih07p_841:focus{
  border-style:none;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow:0 0 #0000,0 0 #0000,var(--tw-shadow);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow);
  outline:2px solid transparent;
  outline-offset:2px
}

._ods_text_input_no_hover_ih07p_844:hover{
  --tw-border-opacity: 1;
  border-color:rgba(209, 213, 219,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-300),var(--tw-border-opacity))
}

._ods_text_input_container_ih07p_847{
  position:relative;
  width:100%
}

._ods_text_input_container_ih07p_847:focus-within{
  z-index:3
}

._ods_text_input_parent_focus_ih07p_850{
  border-radius:.375rem;
  border-width:1px;
  --tw-border-opacity: 1;
  border-color:rgba(209, 213, 219,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-300),var(--tw-border-opacity))
}

._ods_text_input_parent_focus_ih07p_850:focus-within{
  --tw-border-opacity: 1;
  border-color:rgba(99, 102, 241,var(--tw-border-opacity));
  border-color:rgba(var(--color-primary-500),var(--tw-border-opacity));
  --tw-shadow: 0px 0px 0px 1px rgb(var(--color-primary-500));
  --tw-shadow-colored: 0px 0px 0px 1px var(--tw-shadow-color);
  box-shadow:0 0 #0000,0 0 #0000,var(--tw-shadow);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow);
  outline:2px solid transparent;
  outline-offset:2px
}

._ods_text_input_parent_focus_ih07p_850:hover{
  --tw-border-opacity: 1;
  border-color:rgba(156, 163, 175,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-400),var(--tw-border-opacity))
}

._ods_text_input_parent_focus_error_ih07p_853{
  --tw-border-opacity: 1;
  border-color:rgba(252, 165, 165,var(--tw-border-opacity));
  border-color:rgba(var(--color-danger-300),var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color:rgba(127, 29, 29,var(--tw-text-opacity));
  color:rgba(var(--color-danger-900),var(--tw-text-opacity))
}

._ods_text_input_parent_focus_error_ih07p_853:focus-within{
  --tw-border-opacity: 1;
  border-color:rgba(239, 68, 68,var(--tw-border-opacity));
  border-color:rgba(var(--color-danger-500),var(--tw-border-opacity));
  --tw-shadow: 0px 0px 0px 1px rgb(var(--color-danger-500));
  --tw-shadow-colored: 0px 0px 0px 1px var(--tw-shadow-color);
  box-shadow:0 0 #0000,0 0 #0000,var(--tw-shadow);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow);
  outline:2px solid transparent;
  outline-offset:2px
}

._ods_text_input_parent_focus_error_ih07p_853:hover{
  --tw-border-opacity: 1;
  border-color:rgba(248, 113, 113,var(--tw-border-opacity));
  border-color:rgba(var(--color-danger-400),var(--tw-border-opacity))
}

._ods_text_input_parent_focus_disabled_ih07p_856:hover{
  --tw-border-opacity: 1;
  border-color:rgba(209, 213, 219,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-300),var(--tw-border-opacity))
}

._ods_text_input_icon_ih07p_859{
  position:absolute;
  top:0;
  bottom:0;
  display:flex;
  align-items:center
}

._ods_text_input_icon_ih07p_859:hover{
  cursor:pointer
}

._ods_text_input_icon_left_ih07p_862{
  left:0;
  z-index:5;
  padding-left:.75rem
}

._ods_text_input_icon_right_ih07p_865{
  right:0;
  z-index:5;
  padding-right:.75rem
}

._ods_text_input_icon_left_inline_ih07p_868{
  padding-left:4rem
}

@media (min-width: 640px){
  ._ods_text_input_icon_left_inline_ih07p_868{
    padding-left:3.5rem
  }
}

._ods_text_input_icon_error_ih07p_871{
  height:1.25rem;
  width:1.25rem;
  --tw-text-opacity: 1;
  color:rgba(239, 68, 68,var(--tw-text-opacity));
  color:rgba(var(--color-danger-500),var(--tw-text-opacity))
}

._ods_text_input_icon_success_ih07p_874{
  height:1.25rem;
  width:1.25rem;
  --tw-text-opacity: 1;
  color:rgba(16, 185, 129,var(--tw-text-opacity));
  color:rgba(var(--color-success-500),var(--tw-text-opacity))
}

._ods_text_input_icon_padding_left_ih07p_877{
  padding-left:2.5rem
}

._ods_text_input_icon_padding_right_ih07p_880{
  padding-right:2.5rem
}

._ods_text_input_icon_color_ih07p_883{
  --tw-text-opacity: 1;
  color:rgba(156, 163, 175,var(--tw-text-opacity));
  color:rgba(var(--color-light-400),var(--tw-text-opacity))
}

._ods_text_input_addon_ih07p_886{
  display:inline-flex;
  align-items:center;
  border-top-left-radius:.375rem;
  border-bottom-left-radius:.375rem;
  --tw-bg-opacity: 1;
  background-color:rgba(255, 255, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-white),var(--tw-bg-opacity));
  padding-left:.75rem;
  --tw-text-opacity: 1;
  color:rgba(107, 114, 128,var(--tw-text-opacity));
  color:rgba(var(--color-dark-500),var(--tw-text-opacity))
}

@media (min-width: 640px){
  ._ods_text_input_addon_ih07p_886{
    font-size:.875rem;
    line-height:1.25rem
  }
}

._ods_text_input_addon_border_ih07p_889{
  border-width:1px;
  border-right-width:0px;
  --tw-border-opacity: 1;
  border-color:rgba(209, 213, 219,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-300),var(--tw-border-opacity));
  padding-right:.75rem
}

._ods_text_input_addon_select_ih07p_892{
  border-radius:.375rem;
  border-width:1px;
  --tw-border-opacity: 1;
  border-color:rgba(209, 213, 219,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-300),var(--tw-border-opacity))
}

._ods_text_input_addon_select_ih07p_892:focus{
  --tw-border-opacity: 1;
  border-color:rgba(99, 102, 241,var(--tw-border-opacity));
  border-color:rgba(var(--color-primary-500),var(--tw-border-opacity));
  --tw-shadow: 0px 0px 0px 1px rgb(var(--color-primary-500));
  --tw-shadow-colored: 0px 0px 0px 1px var(--tw-shadow-color);
  box-shadow:0 0 #0000,0 0 #0000,var(--tw-shadow);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow);
  outline:2px solid transparent;
  outline-offset:2px
}

._ods_text_input_addon_select_container_ih07p_895{
  display:flex;
  align-items:center;
  padding-right:.75rem
}

._ods_text_input_addon_select_left_ih07p_898{
  border-top-right-radius:0;
  border-bottom-right-radius:0;
  border-right-width:0px;
  padding-right:.25rem
}

._ods_text_input_addon_select_right_ih07p_901{
  border-top-left-radius:0;
  border-bottom-left-radius:0;
  border-left-width:0px;
  padding-left:.25rem
}

._ods_text_input_addon_select_right_container_ih07p_904{
  padding-right:.75rem
}

._ods_text_input_addon_select_right_focus_ih07p_907:focus-within{
  --tw-shadow: 0px 0px 0px 1px rgb(var(--color-primary-500));
  --tw-shadow-colored: 0px 0px 0px 1px var(--tw-shadow-color);
  box-shadow:0 0 #0000,0 0 #0000,var(--tw-shadow);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow);
  outline:2px solid transparent;
  outline-offset:2px
}

._ods_text_input_addon_select_right_no_focus_ih07p_910:focus{
  border-style:none;
  outline:2px solid transparent;
  outline-offset:2px
}

._ods_text_input_addon_select_no_border_ih07p_913{
  border-width:0px
}

._ods_text_input_addon_select_no_border_ih07p_913:focus{
  border-style:none;
  outline:2px solid transparent;
  outline-offset:2px
}

._ods_text_input_addon_trailing_ih07p_916{
  border-top-left-radius:0;
  border-bottom-left-radius:0;
  border-top-right-radius:.375rem;
  border-bottom-right-radius:.375rem;
  padding-right:.75rem;
  padding-left:0
}

._ods_text_input_addon_container_ih07p_919{
  display:flex;
  width:100%;
  border-width:1px
}

._ods_text_input_addon_container_ih07p_919:focus{
  z-index:3
}

._ods_text_input_addon_container_borderless_ih07p_922{
  border-radius:.375rem;
  border-width:1px;
  border-color:transparent;
  transition-property:all;
  transition-timing-function:cubic-bezier(.4,0,.2,1);
  transition-duration:.3s
}

._ods_text_input_addon_container_borderless_ih07p_922:focus-within{
  --tw-border-opacity: 1;
  border-color:rgba(156, 163, 175,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-400),var(--tw-border-opacity))
}

._ods_text_input_addon_container_borderless_ih07p_922:hover{
  --tw-border-opacity: 1;
  border-color:rgba(156, 163, 175,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-400),var(--tw-border-opacity))
}

._ods_text_input_addon_input_ih07p_925{
  border-top-left-radius:0;
  border-bottom-left-radius:0;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow:0 0 #0000,0 0 #0000,var(--tw-shadow);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow);
  outline:2px solid transparent;
  outline-offset:2px
}

._ods_text_input_addon_input_ih07p_925:focus{
  --tw-border-opacity: 1;
  border-color:rgba(99, 102, 241,var(--tw-border-opacity));
  border-color:rgba(var(--color-primary-500),var(--tw-border-opacity));
  --tw-shadow: 0px 0px 0px 1px rgb(var(--color-primary-500));
  --tw-shadow-colored: 0px 0px 0px 1px var(--tw-shadow-color);
  box-shadow:0 0 #0000,0 0 #0000,var(--tw-shadow);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow)
}

._ods_text_input_addon_input_inline_ih07p_928{
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow:0 0 #0000,0 0 #0000,var(--tw-shadow);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow)
}

._ods_text_input_addon_input_no_border_right_ih07p_931{
  border-top-right-radius:0;
  border-bottom-right-radius:0
}

._ods_text_input_addon_input_no_border_right_ih07p_931:focus{
  border-right-width:0px
}

._ods_text_input_addon_input_no_border_left_ih07p_934{
  border-top-left-radius:0;
  border-bottom-left-radius:0
}

._ods_text_input_addon_input_success_ih07p_937{
  --tw-border-opacity: 1;
  border-color:rgba(110, 231, 183,var(--tw-border-opacity));
  border-color:rgba(var(--color-success-300),var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color:rgba(6, 78, 59,var(--tw-text-opacity));
  color:rgba(var(--color-success-900),var(--tw-text-opacity))
}

._ods_text_input_addon_input_success_ih07p_937:hover{
  --tw-border-opacity: 1;
  border-color:rgba(52, 211, 153,var(--tw-border-opacity));
  border-color:rgba(var(--color-success-400),var(--tw-border-opacity))
}

._ods_text_input_addon_input_success_ih07p_937:focus{
  --tw-border-opacity: 1;
  border-color:rgba(16, 185, 129,var(--tw-border-opacity));
  border-color:rgba(var(--color-success-500),var(--tw-border-opacity));
  --tw-shadow: 0px 0px 0px 1px rgb(var(--color-success-500));
  --tw-shadow-colored: 0px 0px 0px 1px var(--tw-shadow-color);
  box-shadow:0 0 #0000,0 0 #0000,var(--tw-shadow);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow)
}

._ods_text_input_addon_input_error_ih07p_940{
  --tw-border-opacity: 1;
  border-color:rgba(252, 165, 165,var(--tw-border-opacity));
  border-color:rgba(var(--color-danger-300),var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color:rgba(127, 29, 29,var(--tw-text-opacity));
  color:rgba(var(--color-danger-900),var(--tw-text-opacity))
}

._ods_text_input_addon_input_error_ih07p_940:hover{
  --tw-border-opacity: 1;
  border-color:rgba(248, 113, 113,var(--tw-border-opacity));
  border-color:rgba(var(--color-danger-400),var(--tw-border-opacity))
}

._ods_text_input_addon_input_error_ih07p_940:focus{
  --tw-border-opacity: 1;
  border-color:rgba(239, 68, 68,var(--tw-border-opacity));
  border-color:rgba(var(--color-danger-500),var(--tw-border-opacity));
  --tw-shadow: 0px 0px 0px 1px rgb(var(--color-danger-500));
  --tw-shadow-colored: 0px 0px 0px 1px var(--tw-shadow-color);
  box-shadow:0 0 #0000,0 0 #0000,var(--tw-shadow);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow)
}

._ods_text_input_addon_inline_ih07p_943{
  pointer-events:none;
  position:absolute;
  top:0;
  bottom:0;
  left:0;
  display:flex;
  align-items:center;
  padding-left:.75rem;
  --tw-text-opacity: 1;
  color:rgba(107, 114, 128,var(--tw-text-opacity));
  color:rgba(var(--color-dark-500),var(--tw-text-opacity))
}

@media (min-width: 640px){
  ._ods_text_input_addon_inline_ih07p_943{
    font-size:.875rem;
    line-height:1.25rem
  }
}

._ods_text_input_addon_with_bg_ih07p_946{
  --tw-bg-opacity: 1;
  background-color:rgba(249, 250, 251,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-50),var(--tw-bg-opacity))
}

._ods_text_input_btn_ih07p_949{
  margin-left:-1px;
  display:inline-flex;
  align-items:center;
  border-top-right-radius:.375rem;
  border-bottom-right-radius:.375rem;
  border-width:1px;
  --tw-border-opacity: 1;
  border-color:rgba(209, 213, 219,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-300),var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color:rgba(249, 250, 251,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-50),var(--tw-bg-opacity));
  padding:.5rem 1rem;
  font-size:.875rem;
  line-height:1.25rem;
  font-weight:500;
  --tw-text-opacity: 1;
  color:rgba(55, 65, 81,var(--tw-text-opacity));
  color:rgba(var(--color-dark-700),var(--tw-text-opacity))
}

._ods_text_input_btn_ih07p_949:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(243, 244, 246,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-100),var(--tw-bg-opacity))
}

._ods_text_input_btn_ih07p_949:focus{
  z-index:5;
  --tw-border-opacity: 1;
  border-color:rgba(99, 102, 241,var(--tw-border-opacity));
  border-color:rgba(var(--color-primary-500),var(--tw-border-opacity));
  outline:2px solid transparent;
  outline-offset:2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),0 0 #0000;
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(var(--color-primary-500), var(--tw-ring-opacity))
}

._ods_text_input_btn_text_ih07p_952{
  padding-left:.5rem;
  padding-right:.5rem
}

._ods_text_input_btn_container_ih07p_955{
  position:relative;
  display:flex
}

._ods_text_input_btn_no_border_right_ih07p_958{
  border-top-right-radius:0;
  border-bottom-right-radius:0
}

._ods_text_input_kbd_ih07p_961{
  border-radius:.25rem;
  border-width:1px;
  --tw-border-opacity: 1;
  border-color:rgba(229, 231, 235,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-200),var(--tw-border-opacity));
  padding-left:.5rem;
  padding-right:.5rem;
  font-family:"Inter var",ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-family:var(--font-family),ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-size:.875rem;
  line-height:1.25rem;
  --tw-text-opacity: 1;
  color:rgba(156, 163, 175,var(--tw-text-opacity));
  color:rgba(var(--color-light-400),var(--tw-text-opacity))
}

._ods_text_input_kbd_container_ih07p_964{
  display:inline-flex;
  align-items:center;
  border-top-right-radius:.375rem;
  border-bottom-right-radius:.375rem;
  --tw-bg-opacity: 1;
  background-color:rgba(255, 255, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-white),var(--tw-bg-opacity));
  padding-right:.375rem
}

._ods_text_input_disabled_ih07p_967{
  cursor:not-allowed;
  opacity:.5
}

._ods_text_input_readonly_ih07p_970{
  cursor:not-allowed
}

._ods_text_input_error_ih07p_973{
  z-index:3;
  --tw-border-opacity: 1;
  border-color:rgba(252, 165, 165,var(--tw-border-opacity));
  border-color:rgba(var(--color-danger-300),var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color:rgba(127, 29, 29,var(--tw-text-opacity));
  color:rgba(var(--color-danger-900),var(--tw-text-opacity))
}

._ods_text_input_error_ih07p_973:hover{
  --tw-border-opacity: 1;
  border-color:rgba(248, 113, 113,var(--tw-border-opacity));
  border-color:rgba(var(--color-danger-400),var(--tw-border-opacity))
}

._ods_text_input_error_ih07p_973:focus{
  --tw-border-opacity: 1;
  border-color:rgba(239, 68, 68,var(--tw-border-opacity));
  border-color:rgba(var(--color-danger-500),var(--tw-border-opacity));
  --tw-shadow: 0px 0px 0px 1px rgb(var(--color-danger-500));
  --tw-shadow-colored: 0px 0px 0px 1px var(--tw-shadow-color);
  box-shadow:0 0 #0000,0 0 #0000,var(--tw-shadow);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow)
}

._ods_text_input_success_ih07p_976{
  z-index:3;
  --tw-border-opacity: 1;
  border-color:rgba(110, 231, 183,var(--tw-border-opacity));
  border-color:rgba(var(--color-success-300),var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color:rgba(6, 78, 59,var(--tw-text-opacity));
  color:rgba(var(--color-success-900),var(--tw-text-opacity))
}

._ods_text_input_success_ih07p_976:hover{
  --tw-border-opacity: 1;
  border-color:rgba(52, 211, 153,var(--tw-border-opacity));
  border-color:rgba(var(--color-success-400),var(--tw-border-opacity))
}

._ods_text_input_success_ih07p_976:focus{
  --tw-border-opacity: 1;
  border-color:rgba(16, 185, 129,var(--tw-border-opacity));
  border-color:rgba(var(--color-success-500),var(--tw-border-opacity));
  --tw-shadow: 0px 0px 0px 1px rgb(var(--color-success-500));
  --tw-shadow-colored: 0px 0px 0px 1px var(--tw-shadow-color);
  box-shadow:0 0 #0000,0 0 #0000,var(--tw-shadow);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow)
}

._ods_text_input_ih07p_811::-webkit-search-cancel-button{
  height:1rem;
  width:1rem;
  -webkit-appearance:none;
  appearance:none;
  background-image:url(/static/media/XIcon.d1277669.svg);
  background-size:1rem 1rem;
  background-repeat:no-repeat
}

._ods_text_input_ih07p_811::-webkit-search-cancel-button:hover{
  cursor:pointer
}

._ods_text_input_borderless_ih07p_817::-webkit-autofill,._ods_text_input_borderless_ih07p_817::-webkit-autofill:hover,._ods_text_input_borderless_ih07p_817::-webkit-autofill:focus{
  background-color:transparent
}

._ods_label_ih07p_990{
  font-weight:500;
  --tw-text-opacity: 1;
  color:rgba(55, 65, 81,var(--tw-text-opacity));
  color:rgba(var(--color-dark-700),var(--tw-text-opacity))
}

._ods_label_xs_ih07p_993{
  font-size:.75rem;
  line-height:1rem
}

._ods_label_sm_ih07p_996{
  font-size:.875rem;
  line-height:1.25rem
}

._ods_label_base_ih07p_999{
  font-size:1rem;
  line-height:1.5rem
}

._ods_label_helptext_ih07p_1002{
  --tw-text-opacity: 1;
  color:rgba(107, 114, 128,var(--tw-text-opacity));
  color:rgba(var(--color-dark-500),var(--tw-text-opacity))
}

._ods_label_helptext_error_ih07p_1005{
  --tw-text-opacity: 1;
  color:rgba(239, 68, 68,var(--tw-text-opacity));
  color:rgba(var(--color-danger-500),var(--tw-text-opacity))
}

._ods_pagination_item_ih07p_1009{
  position:relative;
  display:inline-flex;
  align-items:center;
  border-radius:0;
  padding-left:1rem;
  padding-right:1rem;
  font-size:.875rem;
  line-height:1.25rem;
  font-weight:500
}

._ods_pagination_item_ih07p_1009:focus{
  --tw-ring-opacity: 0;
  --tw-ring-offset-width: 0px
}

._ods_pagination_item_link_ih07p_1012{
  border-radius:0;
  border-width:0px;
  border-top-width:2px;
  border-color:transparent;
  background-color:transparent;
  padding-top:1rem;
  --tw-text-opacity: 1;
  color:rgba(107, 114, 128,var(--tw-text-opacity));
  color:rgba(var(--color-dark-500),var(--tw-text-opacity));
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow:0 0 #0000,0 0 #0000,var(--tw-shadow);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow)
}

._ods_pagination_item_link_ih07p_1012:hover{
  --tw-border-opacity: 1;
  border-color:rgba(209, 213, 219,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-300),var(--tw-border-opacity));
  background-color:transparent;
  --tw-text-opacity: 1;
  color:rgba(55, 65, 81,var(--tw-text-opacity));
  color:rgba(var(--color-dark-700),var(--tw-text-opacity))
}

._ods_pagination_item_link_active_ih07p_1015{
  --tw-border-opacity: 1;
  border-color:rgba(99, 102, 241,var(--tw-border-opacity));
  border-color:rgba(var(--color-primary-500),var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color:rgba(79, 70, 229,var(--tw-text-opacity));
  color:rgba(var(--color-primary-600),var(--tw-text-opacity))
}

._ods_pagination_item_link_active_ih07p_1015:hover{
  --tw-border-opacity: 1;
  border-color:rgba(99, 102, 241,var(--tw-border-opacity));
  border-color:rgba(var(--color-primary-500),var(--tw-border-opacity))
}

._ods_pagination_item_link_hidden_ih07p_1018{
  display:inline-flex;
  align-items:center;
  border-top-width:2px;
  border-color:transparent;
  padding-left:1rem;
  padding-right:1rem;
  padding-top:.5rem;
  font-size:.875rem;
  line-height:1.25rem;
  font-weight:500;
  --tw-text-opacity: 1;
  color:rgba(107, 114, 128,var(--tw-text-opacity));
  color:rgba(var(--color-dark-500),var(--tw-text-opacity))
}

._ods_pagination_item_button_ih07p_1021{
  --tw-border-opacity: 1;
  border-color:rgba(209, 213, 219,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-300),var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color:rgba(255, 255, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-white),var(--tw-bg-opacity));
  padding-top:.5rem;
  padding-bottom:.5rem;
  --tw-text-opacity: 1;
  color:rgba(107, 114, 128,var(--tw-text-opacity));
  color:rgba(var(--color-dark-500),var(--tw-text-opacity))
}

._ods_pagination_item_button_ih07p_1021:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(249, 250, 251,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-50),var(--tw-bg-opacity))
}

._ods_pagination_item_button_active_ih07p_1024{
  z-index:10;
  --tw-border-opacity: 1;
  border-color:rgba(99, 102, 241,var(--tw-border-opacity));
  border-color:rgba(var(--color-primary-500),var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color:rgba(238, 242, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-50),var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:rgba(79, 70, 229,var(--tw-text-opacity));
  color:rgba(var(--color-primary-600),var(--tw-text-opacity))
}

._ods_pagination_item_button_active_ih07p_1024:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(238, 242, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-50),var(--tw-bg-opacity))
}

._ods_pagination_item_button_hidden_ih07p_1027{
  position:relative;
  display:inline-flex;
  align-items:center;
  border-width:1px;
  --tw-border-opacity: 1;
  border-color:rgba(209, 213, 219,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-300),var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color:rgba(255, 255, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-white),var(--tw-bg-opacity));
  padding:.5rem 1rem;
  font-size:.875rem;
  line-height:1.25rem;
  font-weight:500;
  --tw-text-opacity: 1;
  color:rgba(55, 65, 81,var(--tw-text-opacity));
  color:rgba(var(--color-dark-700),var(--tw-text-opacity))
}

._ods_pagination_step_ih07p_1031{
  display:inline-flex;
  align-items:center;
  border-width:0px;
  font-size:.875rem;
  line-height:1.25rem;
  font-weight:500
}

._ods_pagination_step_ih07p_1031:focus{
  --tw-ring-opacity: 0;
  --tw-ring-offset-width: 0px
}

._ods_pagination_step_link_ih07p_1034{
  border-radius:0;
  border-top-width:2px;
  border-color:transparent;
  background-color:transparent;
  padding-left:.25rem;
  padding-right:.25rem;
  padding-top:1rem;
  --tw-text-opacity: 1;
  color:rgba(107, 114, 128,var(--tw-text-opacity));
  color:rgba(var(--color-dark-500),var(--tw-text-opacity));
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow:0 0 #0000,0 0 #0000,var(--tw-shadow);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow)
}

._ods_pagination_step_link_ih07p_1034:hover{
  --tw-border-opacity: 1;
  border-color:rgba(209, 213, 219,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-300),var(--tw-border-opacity));
  background-color:transparent;
  --tw-text-opacity: 1;
  color:rgba(55, 65, 81,var(--tw-text-opacity));
  color:rgba(var(--color-dark-700),var(--tw-text-opacity))
}

._ods_pagination_step_link_ih07p_1034:focus{
  border-top-width:2px
}

._ods_pagination_step_link_ih07p_1034:active{
  border-top-width:2px
}

._ods_pagination_step_button_ih07p_1037{
  position:relative;
  border-radius:.375rem;
  border-width:1px;
  --tw-border-opacity: 1;
  border-color:rgba(209, 213, 219,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-300),var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color:rgba(255, 255, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-white),var(--tw-bg-opacity));
  padding:.5rem 1rem;
  --tw-text-opacity: 1;
  color:rgba(107, 114, 128,var(--tw-text-opacity));
  color:rgba(var(--color-dark-500),var(--tw-text-opacity))
}

._ods_pagination_step_button_ih07p_1037:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(249, 250, 251,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-50),var(--tw-bg-opacity))
}

._ods_pagination_step_button_iconOnly_leading_ih07p_1040,._ods_pagination_step_button_iconOnly_trailing_ih07p_1040{
  border-radius:0;
  padding-left:.5rem;
  padding-right:.5rem
}

._ods_pagination_step_button_iconOnly_leading_ih07p_1040{
  border-top-left-radius:.375rem;
  border-bottom-left-radius:.375rem
}

._ods_pagination_step_button_iconOnly_trailing_ih07p_1040{
  border-top-right-radius:.375rem;
  border-bottom-right-radius:.375rem
}

._ods_select_trigger_ih07p_1050{
  position:relative;
  display:flex;
  cursor:default;
  outline:2px solid transparent;
  outline-offset:2px
}

@media (min-width: 640px){
  ._ods_select_trigger_ih07p_1050{
    font-size:.875rem;
    line-height:1.25rem
  }
}

._ods_select_trigger_default_ih07p_1053{
  border-radius:.375rem;
  --tw-bg-opacity: 1;
  background-color:rgba(255, 255, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-white),var(--tw-bg-opacity));
  padding:.5rem 2.5rem .5rem .75rem;
  text-align:left
}

._ods_select_trigger_default_ih07p_1053:focus{
  --tw-shadow: 0px 0px 0px 1px rgb(var(--color-primary-500));
  --tw-shadow-colored: 0px 0px 0px 1px var(--tw-shadow-color);
  box-shadow:0 0 #0000,0 0 #0000,var(--tw-shadow);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow)
}

._ods_select_trigger_w_full_ih07p_1056{
  width:100%
}

._ods_select_trigger_primary_ih07p_1059{
  display:inline-flex;
  align-items:center;
  border-top-left-radius:0;
  border-bottom-left-radius:0;
  border-top-right-radius:.375rem;
  border-bottom-right-radius:.375rem;
  --tw-bg-opacity: 1;
  background-color:rgba(99, 102, 241,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-500),var(--tw-bg-opacity));
  padding:.5rem;
  font-weight:500;
  --tw-text-opacity: 1;
  color:rgba(255, 255, 255,var(--tw-text-opacity));
  color:rgba(var(--color-white),var(--tw-text-opacity))
}

._ods_select_trigger_primary_ih07p_1059:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(79, 70, 229,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-600),var(--tw-bg-opacity))
}

._ods_select_trigger_primary_ih07p_1059:focus{
  z-index:10;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),0 0 #0000;
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(var(--color-primary-500), var(--tw-ring-opacity));
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: rgb(var(--color-light-50))
}

._ods_select_trigger_primary_container_ih07p_1062{
  display:inline-flex
}

._ods_select_trigger_primary_container_ih07p_1062>:not([hidden])~:not([hidden]){
  --tw-divide-x-reverse: 0;
  border-right-width:calc(1px * var(--tw-divide-x-reverse));
  border-left-width:calc(1px * calc(1 - var(--tw-divide-x-reverse)));
  --tw-divide-opacity: 1;
  border-color:rgba(79, 70, 229,var(--tw-divide-opacity));
  border-color:rgba(var(--color-primary-600),var(--tw-divide-opacity))
}

._ods_select_trigger_primary_children_ih07p_1065{
  margin-left:.625rem;
  border-width:1px;
  border-color:transparent;
  font-size:.875rem;
  line-height:1.25rem
}

._ods_select_trigger_primary_children_container_ih07p_1068{
  display:inline-flex;
  align-items:center;
  border-top-left-radius:.375rem;
  border-bottom-left-radius:.375rem;
  --tw-bg-opacity: 1;
  background-color:rgba(99, 102, 241,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-500),var(--tw-bg-opacity));
  padding:.5rem 1rem .5rem .75rem;
  --tw-text-opacity: 1;
  color:rgba(255, 255, 255,var(--tw-text-opacity));
  color:rgba(var(--color-white),var(--tw-text-opacity));
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / .05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow:0 0 #0000,0 0 #0000,var(--tw-shadow);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow)
}

._ods_select_trigger_border_ih07p_1071{
  border-width:1px;
  --tw-border-opacity: 1;
  border-color:rgba(209, 213, 219,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-300),var(--tw-border-opacity));
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / .05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow:0 0 #0000,0 0 #0000,var(--tw-shadow);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow)
}

._ods_select_trigger_border_ih07p_1071:hover{
  --tw-border-opacity: 1;
  border-color:rgba(156, 163, 175,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-400),var(--tw-border-opacity))
}

._ods_select_trigger_border_ih07p_1071:focus{
  --tw-border-opacity: 1;
  border-color:rgba(99, 102, 241,var(--tw-border-opacity));
  border-color:rgba(var(--color-primary-500),var(--tw-border-opacity))
}

._ods_select_trigger_borderless_ih07p_1074{
  padding-right:.75rem
}

._ods_select_trigger_borderless_ih07p_1074:focus{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),0 0 #0000;
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(var(--color-primary-500), var(--tw-ring-opacity))
}

._ods_select_trigger_children_ih07p_1077{
  display:block;
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap
}

._ods_select_trigger_children_container_ih07p_1080{
  display:flex;
  align-items:center
}

._ods_select_trigger_icon_ih07p_1083{
  height:1.25rem;
  width:1.25rem
}

._ods_select_trigger_icon_default_ih07p_1086{
  --tw-text-opacity: 1;
  color:rgba(156, 163, 175,var(--tw-text-opacity));
  color:rgba(var(--color-light-400),var(--tw-text-opacity))
}

._ods_select_trigger_icon_selected_ih07p_1089{
  --tw-text-opacity: 1;
  color:rgba(79, 70, 229,var(--tw-text-opacity));
  color:rgba(var(--color-primary-600),var(--tw-text-opacity))
}

._ods_select_trigger_icon_primary_ih07p_1092{
  --tw-text-opacity: 1;
  color:rgba(255, 255, 255,var(--tw-text-opacity));
  color:rgba(var(--color-white),var(--tw-text-opacity))
}

._ods_select_trigger_icon_container_ih07p_1095{
  pointer-events:none;
  display:flex;
  align-items:center
}

._ods_select_trigger_icon_container_borderless_ih07p_1098{
  padding-left:.25rem
}

._ods_select_trigger_icon_container_default_ih07p_1101{
  position:absolute;
  top:0;
  bottom:0;
  right:0;
  padding-right:.5rem
}

._ods_select_menu_ih07p_1105{
  position:absolute;
  z-index:30;
  max-height:15rem;
  overflow:auto;
  border-radius:.375rem;
  --tw-bg-opacity: 1;
  background-color:rgba(255, 255, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-white),var(--tw-bg-opacity));
  font-size:1rem;
  line-height:1.5rem;
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / .1), 0 4px 6px -4px rgb(0 0 0 / .1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow:0 0 #0000,0 0 #0000,var(--tw-shadow);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow);
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),0 0 #0000;
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),var(--tw-shadow, 0 0 #0000);
  --tw-ring-color: rgba(var(--color-black), var(--tw-ring-opacity));
  --tw-ring-opacity: .05
}

._ods_select_menu_ih07p_1105:focus{
  outline:2px solid transparent;
  outline-offset:2px
}

@media (min-width: 640px){
  ._ods_select_menu_ih07p_1105{
    font-size:.875rem;
    line-height:1.25rem
  }
}

._ods_select_menu_leading_icon_ih07p_1108 span._ods_select_option_icon_ih07p_1108{
  left:0;
  padding-left:.375rem
}

._ods_select_menu_leading_icon_ih07p_1108 span._ods_select_option_children_ih07p_1111{
  padding-left:1.25rem
}

._ods_select_menu_transition_leave_ih07p_1114{
  transition-property:color,background-color,border-color,fill,stroke,opacity,box-shadow,transform,filter,-webkit-text-decoration-color,-webkit-backdrop-filter;
  transition-property:color,background-color,border-color,fill,stroke,opacity,box-shadow,-webkit-text-decoration-color,-webkit-transform,-webkit-filter,-webkit-backdrop-filter;
  transition-property:color,background-color,border-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter;
  transition-property:color,background-color,border-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter,-webkit-text-decoration-color,-webkit-transform,-webkit-filter,-webkit-backdrop-filter;
  transition-property:color,background-color,border-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter,-webkit-text-decoration-color,-webkit-backdrop-filter;
  transition-timing-function:cubic-bezier(.4,0,.2,1);
  transition-duration:.1s;
  transition-timing-function:cubic-bezier(.4,0,1,1)
}

._ods_select_menu_transition_leave_from_ih07p_1117{
  opacity:1
}

._ods_select_menu_transition_leave_to_ih07p_1120{
  opacity:0
}

._ods_select_menu_divider_ih07p_1123>:not([hidden])~:not([hidden]){
  --tw-divide-y-reverse: 0;
  border-top-width:calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width:calc(1px * var(--tw-divide-y-reverse));
  --tw-divide-opacity: 1;
  border-color:rgba(229, 231, 235,var(--tw-divide-opacity));
  border-color:rgba(var(--color-light-200),var(--tw-divide-opacity))
}

._ods_select_menu_default_ih07p_1126{
  margin-top:.25rem;
  width:100%;
  padding-top:.25rem;
  padding-bottom:.25rem
}

._ods_select_menu_primary_ih07p_1129{
  margin-top:.5rem;
  width:18rem;
  overflow:hidden
}

._ods_select_menu_primary_leading_ih07p_1132{
  left:0;
  -webkit-transform-origin:top left;
  transform-origin:top left
}

._ods_select_menu_primary_trailing_ih07p_1135{
  right:0;
  -webkit-transform-origin:top right;
  transform-origin:top right
}

._ods_select_option_ih07p_1108{
  position:relative;
  z-index:10;
  cursor:default;
  -webkit-user-select:none;
  -ms-user-select:none;
  user-select:none
}

._ods_select_option_container_primary_ih07p_1142{
  padding:1rem
}

._ods_select_option_container_default_ih07p_1145{
  padding:.5rem 2.25rem .5rem .75rem
}

._ods_select_option_primary_ih07p_1148{
  --tw-bg-opacity: 1;
  background-color:rgba(79, 70, 229,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-600),var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:rgba(255, 255, 255,var(--tw-text-opacity));
  color:rgba(var(--color-white),var(--tw-text-opacity))
}

._ods_select_option_gray_ih07p_1151{
  --tw-text-opacity: 1;
  color:rgba(17, 24, 39,var(--tw-text-opacity));
  color:rgba(var(--color-dark-900),var(--tw-text-opacity))
}

._ods_select_option_children_ih07p_1111{
  display:block;
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap
}

._ods_select_option_children_container_ih07p_1157{
  display:flex;
  align-items:center
}

._ods_select_option_children_semibold_ih07p_1160{
  font-weight:600
}

._ods_select_option_children_normal_ih07p_1163{
  font-weight:400
}

._ods_select_option_icon_ih07p_1108{
  position:absolute;
  top:0;
  bottom:0;
  right:0;
  display:flex;
  padding-right:1rem
}

._ods_select_option_icon_white_ih07p_1169{
  --tw-text-opacity: 1;
  color:rgba(255, 255, 255,var(--tw-text-opacity));
  color:rgba(var(--color-white),var(--tw-text-opacity))
}

._ods_select_option_icon_primary_ih07p_1172{
  --tw-text-opacity: 1;
  color:rgba(79, 70, 229,var(--tw-text-opacity));
  color:rgba(var(--color-primary-600),var(--tw-text-opacity))
}

._ods_select_option_icon_regular_ih07p_1175{
  align-items:center
}

._ods_select_option_icon_helptext_ih07p_1178{
  margin-top:.75rem;
  align-items:flex-start
}

._ods_select_option_helptext_ih07p_1181{
  margin-top:.5rem
}

._ods_select_option_helptext_primary_ih07p_1184{
  --tw-text-opacity: 1;
  color:rgba(165, 180, 252,var(--tw-text-opacity));
  color:rgba(var(--color-primary-300),var(--tw-text-opacity))
}

._ods_select_option_helptext_gray_ih07p_1187{
  --tw-text-opacity: 1;
  color:rgba(107, 114, 128,var(--tw-text-opacity));
  color:rgba(var(--color-dark-500),var(--tw-text-opacity))
}

._ods_select_option_disabled_ih07p_1190{
  cursor:not-allowed;
  opacity:.6
}

._ods_select_option_disabled_ih07p_1190:hover{
  --tw-text-opacity: 1;
  color:rgba(0, 0, 0,var(--tw-text-opacity));
  color:rgba(var(--color-black),var(--tw-text-opacity))
}

._ods_select_option_flex_ih07p_1193{
  display:flex
}

._ods_select_option_checkbox_ih07p_1196{
  margin-right:.75rem
}

._ods_switch_default_ih07p_1200{
  position:relative;
  display:inline-flex;
  height:1.5rem;
  width:2.75rem;
  flex-shrink:0;
  cursor:pointer;
  border-radius:9999px;
  border-width:2px;
  border-color:transparent;
  --tw-bg-opacity: 1;
  background-color:rgba(229, 231, 235,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-200),var(--tw-bg-opacity));
  transition-property:color,background-color,border-color,fill,stroke,-webkit-text-decoration-color;
  transition-property:color,background-color,border-color,text-decoration-color,fill,stroke;
  transition-property:color,background-color,border-color,text-decoration-color,fill,stroke,-webkit-text-decoration-color;
  transition-duration:.2s;
  transition-timing-function:cubic-bezier(.4,0,.2,1)
}

._ods_switch_default_ih07p_1200:focus{
  outline:2px solid transparent;
  outline-offset:2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),0 0 #0000;
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(var(--color-primary-500), var(--tw-ring-opacity));
  --tw-ring-offset-width: 2px
}

._ods_switch_short_ih07p_1203{
  position:relative;
  display:inline-flex;
  height:1.25rem;
  width:2.5rem;
  flex-shrink:0;
  cursor:pointer;
  align-items:center;
  justify-content:center;
  border-radius:9999px
}

._ods_switch_short_ih07p_1203:focus{
  outline:2px solid transparent;
  outline-offset:2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),0 0 #0000;
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(var(--color-primary-500), var(--tw-ring-opacity));
  --tw-ring-offset-width: 2px
}

._ods_switch_short_wrapper_ih07p_1206{
  pointer-events:none;
  position:absolute;
  height:100%;
  width:100%;
  border-radius:.375rem;
  --tw-bg-opacity: 1;
  background-color:rgba(255, 255, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-white),var(--tw-bg-opacity))
}

._ods_switch_icon_ih07p_1209{
  position:absolute;
  inset:0;
  display:flex;
  height:100%;
  width:100%;
  align-items:center;
  justify-content:center;
  transition-property:opacity;
  transition-timing-function:cubic-bezier(.4,0,.2,1);
  transition-duration:.15s
}

._ods_switch_icon_enabled_ih07p_1212{
  opacity:0;
  transition-duration:.1s;
  transition-timing-function:cubic-bezier(0,0,.2,1)
}

._ods_switch_icon_disabled_ih07p_1215{
  opacity:1;
  transition-duration:.2s;
  transition-timing-function:cubic-bezier(.4,0,1,1)
}

._ods_switch_toggler_ih07p_1218{
  pointer-events:none;
  display:inline-block;
  height:1.25rem;
  width:1.25rem;
  -webkit-transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  border-radius:9999px;
  --tw-bg-opacity: 1;
  background-color:rgba(255, 255, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-white),var(--tw-bg-opacity));
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / .1), 0 1px 2px -1px rgb(0 0 0 / .1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow:0 0 #0000,0 0 #0000,var(--tw-shadow);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow);
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),0 0 #0000;
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),var(--tw-shadow, 0 0 #0000);
  transition-property:color,background-color,border-color,fill,stroke,opacity,box-shadow,transform,filter,-webkit-text-decoration-color,-webkit-backdrop-filter;
  transition-property:color,background-color,border-color,fill,stroke,opacity,box-shadow,-webkit-text-decoration-color,-webkit-transform,-webkit-filter,-webkit-backdrop-filter;
  transition-property:color,background-color,border-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter;
  transition-property:color,background-color,border-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter,-webkit-text-decoration-color,-webkit-transform,-webkit-filter,-webkit-backdrop-filter;
  transition-property:color,background-color,border-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter,-webkit-text-decoration-color,-webkit-backdrop-filter;
  transition-duration:.2s;
  transition-timing-function:cubic-bezier(.4,0,.2,1)
}

._ods_switch_toggler_enabled_ih07p_1221{
  --tw-translate-x: 1.25rem;
  -webkit-transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

._ods_switch_toggler_disabled_ih07p_1224{
  --tw-translate-x: 0px;
  -webkit-transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

._ods_switch_toggler_short_ih07p_1227{
  pointer-events:none;
  position:absolute;
  margin-left:auto;
  margin-right:auto;
  height:1rem;
  width:2.25rem;
  border-radius:9999px;
  transition-property:color,background-color,border-color,fill,stroke,-webkit-text-decoration-color;
  transition-property:color,background-color,border-color,text-decoration-color,fill,stroke;
  transition-property:color,background-color,border-color,text-decoration-color,fill,stroke,-webkit-text-decoration-color;
  transition-duration:.2s;
  transition-timing-function:cubic-bezier(.4,0,.2,1)
}

._ods_switch_toggler_short_circle_ih07p_1230{
  pointer-events:none;
  position:absolute;
  left:0;
  display:inline-block;
  height:1.25rem;
  width:1.25rem;
  -webkit-transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  border-radius:9999px;
  border-width:1px;
  --tw-border-opacity: 1;
  border-color:rgba(229, 231, 235,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-200),var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color:rgba(255, 255, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-white),var(--tw-bg-opacity));
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / .1), 0 1px 2px -1px rgb(0 0 0 / .1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow:0 0 #0000,0 0 #0000,var(--tw-shadow);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow);
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),0 0 #0000;
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),var(--tw-shadow, 0 0 #0000);
  transition-property:-webkit-transform;
  transition-property:transform;
  transition-property:transform, -webkit-transform;
  transition-duration:.2s;
  transition-timing-function:cubic-bezier(.4,0,.2,1)
}

._ods_switch_enabled_ih07p_1233{
  --tw-bg-opacity: 1;
  background-color:rgba(79, 70, 229,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-600),var(--tw-bg-opacity))
}

._ods_switch_disabled_ih07p_1236{
  --tw-bg-opacity: 1;
  background-color:rgba(229, 231, 235,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-200),var(--tw-bg-opacity))
}

._ods_switch_group_ih07p_1239{
  display:flex;
  align-items:center;
  justify-content:space-between
}

._ods_switch_label_ih07p_1242{
  font-size:.875rem;
  line-height:1.25rem;
  font-weight:500;
  --tw-text-opacity: 1;
  color:rgba(17, 24, 39,var(--tw-text-opacity));
  color:rgba(var(--color-dark-900),var(--tw-text-opacity))
}

._ods_switch_description_ih07p_1245{
  font-size:.875rem;
  line-height:1.25rem;
  --tw-text-opacity: 1;
  color:rgba(107, 114, 128,var(--tw-text-opacity));
  color:rgba(var(--color-dark-500),var(--tw-text-opacity))
}

._ods_page_heading_actions_ih07p_1249{
  margin-top:1rem;
  display:flex;
  align-items:flex-end;
  grid-gap:.75rem;
  gap:.75rem
}

@media (min-width: 768px){
  ._ods_page_heading_actions_ih07p_1249{
    margin-top:0;
    margin-left:1rem
  }
}

._ods_page_heading_content_ih07p_1253{
  min-width:0px;
  flex:1 1;
  align-items:center
}

._ods_stacked_list_item_ih07p_1257{
  display:flex;
  align-items:center
}

._ods_stacked_list_item_ih07p_1257>div>p:first-child{
  font-size:.875rem;
  line-height:1.25rem;
  font-weight:500
}

._ods_stacked_list_item_gap_ih07p_1263>:not([hidden])~:not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top:calc(.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom:calc(.25rem * var(--tw-space-y-reverse))
}

._ods_stacked_list_item_justified_ih07p_1266{
  justify-content:space-between
}

._ods_stacked_list_item_leading_ih07p_1269{
  align-items:flex-start
}

._ods_stacked_list_item_center_ih07p_1272{
  align-items:center
}

._ods_stacked_list_item_trailing_ih07p_1275{
  align-items:flex-end
}

._ods_stacked_list_row_ih07p_1279{
  display:flex;
  align-items:center;
  padding-left:0;
  padding-right:0;
  padding-top:1rem;
  padding-bottom:1rem
}

@media (min-width: 640px){
  ._ods_stacked_list_row_ih07p_1279{
    padding-left:.5rem;
    padding-right:.5rem
  }
}

._ods_stacked_list_row_link_ih07p_1282{
  display:flex;
  flex-direction:column;
  align-items:flex-start
}

._ods_stacked_list_row_link_ih07p_1282:hover{
  cursor:pointer;
  --tw-bg-opacity: 1;
  background-color:rgba(249, 250, 251,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-50),var(--tw-bg-opacity));
  -webkit-text-decoration-line:none;
  text-decoration-line:none
}

._ods_stacked_list_row_link_ih07p_1282:focus{
  outline:2px solid transparent;
  outline-offset:2px
}

._ods_stacked_list_row_wrapper_ih07p_1285{
  display:flex;
  width:100%;
  flex:1 1;
  align-items:center
}

@media (min-width: 640px){
  ._ods_stacked_list_row_wrapper_ih07p_1285{
    min-width:0px
  }
}

._ods_stacked_list_row_grid_ih07p_1288{
  display:flex;
  min-width:0px;
  flex:1 1;
  place-content:space-between;
  padding-left:1rem;
  padding-right:1rem
}

@media (min-width: 640px){
  ._ods_stacked_list_row_grid_ih07p_1288{
    padding-left:1rem;
    padding-right:1rem
  }
}

@media (min-width: 768px){
  ._ods_stacked_list_row_grid_ih07p_1288{
    display:grid;
    grid-auto-flow:column;
    grid-gap:1rem;
    gap:1rem
  }
}

._ods_stacked_list_row_item_ih07p_1291{
  width:100%
}

@media (min-width: 768px){
  ._ods_stacked_list_row_columns_one_ih07p_1294{
    grid-template-columns:repeat(1,minmax(0,1fr))
  }

  ._ods_stacked_list_row_columns_two_ih07p_1297{
    grid-template-columns:repeat(2,minmax(0,1fr))
  }

  ._ods_stacked_list_row_columns_three_ih07p_1300{
    grid-template-columns:repeat(3,minmax(0,1fr))
  }

  ._ods_stacked_list_row_columns_four_ih07p_1303{
    grid-template-columns:repeat(4,minmax(0,1fr))
  }

  ._ods_stacked_list_row_columns_five_ih07p_1306{
    grid-template-columns:repeat(5,minmax(0,1fr))
  }

  ._ods_stacked_list_row_columns_six_ih07p_1309{
    grid-template-columns:repeat(6,minmax(0,1fr))
  }
}

._ods_stacked_list_header_ih07p_1313{
  position:-webkit-sticky;
  position:sticky;
  top:0;
  z-index:10;
  border-top-width:1px;
  border-bottom-width:1px;
  --tw-border-opacity: 1;
  border-color:rgba(229, 231, 235,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-200),var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color:rgba(249, 250, 251,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-50),var(--tw-bg-opacity));
  padding:.25rem 1.5rem;
  font-size:.875rem;
  line-height:1.25rem;
  font-weight:400;
  --tw-text-opacity: 1;
  color:rgba(107, 114, 128,var(--tw-text-opacity));
  color:rgba(var(--color-dark-500),var(--tw-text-opacity))
}

._ods_stat_body_ih07p_1317{
  display:flex;
  align-items:center
}

._ods_stat_footer_ih07p_1321{
  position:absolute;
  left:0;
  right:0;
  bottom:0;
  --tw-bg-opacity: 1;
  background-color:rgba(249, 250, 251,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-50),var(--tw-bg-opacity));
  padding:1rem
}

@media (min-width: 640px){
  ._ods_stat_footer_ih07p_1321{
    padding-left:1.5rem;
    padding-right:1.5rem
  }
}

._ods_stat_footer_ih07p_1321 a{
  font-weight:500;
  --tw-text-opacity: 1;
  color:rgba(79, 70, 229,var(--tw-text-opacity));
  color:rgba(var(--color-primary-600),var(--tw-text-opacity));
  outline:2px solid transparent;
  outline-offset:2px
}

._ods_stat_footer_ih07p_1321 a:hover{
  --tw-text-opacity: 1;
  color:rgba(99, 102, 241,var(--tw-text-opacity));
  color:rgba(var(--color-primary-500),var(--tw-text-opacity))
}

._ods_stat_body_helptext_ih07p_1328{
  font-size:1.5rem;
  line-height:2rem;
  font-weight:600;
  --tw-text-opacity: 1;
  color:rgba(17, 24, 39,var(--tw-text-opacity));
  color:rgba(var(--color-dark-900),var(--tw-text-opacity))
}

._ods_stat_body_helptext_ih07p_1328 span{
  font-size:.875rem;
  line-height:1.25rem;
  font-weight:500;
  --tw-text-opacity: 1;
  color:rgba(107, 114, 128,var(--tw-text-opacity));
  color:rgba(var(--color-dark-500),var(--tw-text-opacity))
}

._ods_stat_indicator_ih07p_1335{
  font-size:.875rem;
  line-height:1.25rem
}

._ods_stat_indicator_ih07p_1335 svg{
  height:1.25rem;
  width:1.25rem;
  flex-shrink:0;
  align-self:center
}

._ods_stepBullet_bulletOnly_ih07p_1342{
  position:absolute;
  display:flex;
  height:1.25rem;
  width:1.25rem;
  padding:1px
}

._ods_stepBullet_bulletOnly_ih07p_1342>span{
  height:100%;
  width:100%;
  border-radius:9999px;
  --tw-bg-opacity: 1;
  background-color:rgba(165, 180, 252,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-300),var(--tw-bg-opacity))
}

._ods_stepBullet_bulletOnly_ih07p_1342+span{
  position:relative;
  display:block;
  height:.625rem;
  width:.625rem;
  border-radius:9999px;
  --tw-bg-opacity: 1;
  background-color:rgba(79, 70, 229,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-600),var(--tw-bg-opacity))
}

._ods_stepBullet_bulletWithText_ih07p_1352{
  position:relative;
  display:flex;
  height:1.25rem;
  width:1.25rem;
  flex-shrink:0;
  align-items:center;
  justify-content:center
}

._ods_stepBullet_bulletWithText_ih07p_1352>svg{
  height:100%;
  width:100%;
  --tw-text-opacity: 1;
  color:rgba(79, 70, 229,var(--tw-text-opacity));
  color:rgba(var(--color-primary-600),var(--tw-text-opacity))
}

._ods_stepBullet_bulletWithText_ih07p_1352 span:first-child{
  position:absolute;
  height:1rem;
  width:1rem;
  border-radius:9999px;
  --tw-bg-opacity: 1;
  background-color:rgba(165, 180, 252,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-300),var(--tw-bg-opacity))
}

._ods_stepBullet_bulletWithText_ih07p_1352 span:last-child{
  position:relative;
  display:block;
  height:.5rem;
  width:.5rem;
  border-radius:9999px;
  --tw-bg-opacity: 1;
  background-color:rgba(79, 70, 229,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-600),var(--tw-bg-opacity))
}

._ods_stepBullet_bulletWithText_ih07p_1352>div{
  height:.5rem;
  width:.5rem;
  border-radius:9999px;
  --tw-bg-opacity: 1;
  background-color:rgba(209, 213, 219,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-300),var(--tw-bg-opacity))
}

._ods_stepCircle_circle_ih07p_1368{
  height:.625rem;
  width:.625rem;
  border-radius:9999px
}

._ods_stepCircle_circle_wrapper_ih07p_1371{
  display:flex;
  height:2.25rem;
  align-items:center
}

._ods_stepCircle_circle_innerWrapper_ih07p_1374{
  position:relative;
  z-index:10;
  display:flex;
  height:2rem;
  width:2rem;
  align-items:center;
  justify-content:center;
  border-radius:9999px
}

._ods_stepCircle_icon_ih07p_1378{
  height:1.25rem;
  width:1.25rem;
  --tw-text-opacity: 1;
  color:rgba(255, 255, 255,var(--tw-text-opacity));
  color:rgba(var(--color-white),var(--tw-text-opacity))
}

._ods_stepPanel_outlinedCircle_wrapper_ih07p_1382{
  flex-shrink:0
}

._ods_stepPanel_outlinedCircle_ih07p_1382{
  display:flex;
  height:2.5rem;
  width:2.5rem;
  flex-shrink:0;
  align-items:center;
  justify-content:center;
  border-radius:9999px
}

._ods_stepPanel_outlinedCircleIcon_ih07p_1390{
  height:1.5rem;
  width:1.5rem;
  --tw-text-opacity: 1;
  color:rgba(255, 255, 255,var(--tw-text-opacity));
  color:rgba(var(--color-white),var(--tw-text-opacity))
}

._ods_panel_body_ih07p_1394{
  padding:1.25rem 1rem
}

@media (min-width: 640px){
  ._ods_panel_body_ih07p_1394{
    padding:1.5rem
  }
}

._ods_panel_footer_ih07p_1398{
  border-top-width:1px;
  --tw-border-opacity: 1;
  border-color:rgba(229, 231, 235,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-200),var(--tw-border-opacity));
  padding:1rem
}

@media (min-width: 640px){
  ._ods_panel_footer_ih07p_1398{
    padding-left:1.5rem;
    padding-right:1.5rem
  }
}

._ods_panel_header_ih07p_1402{
  padding:1.25rem 1rem
}

@media (min-width: 640px){
  ._ods_panel_header_ih07p_1402{
    padding-left:1.5rem;
    padding-right:1.5rem
  }
}

._ods_panel_header_divided_ih07p_1405{
  border-bottom-width:1px;
  --tw-border-opacity: 1;
  border-color:rgba(229, 231, 235,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-200),var(--tw-border-opacity))
}

._ods_modal_action_fullWidth_ih07p_1409{
  margin-top:1.25rem
}

@media (min-width: 640px){
  ._ods_modal_action_fullWidth_ih07p_1409{
    margin-top:1.5rem
  }
}

._ods_modal_action_fullWidth_ih07p_1409 button{
  width:100%
}

._ods_modal_action_leading_ih07p_1415{
  margin-top:1.25rem
}

@media (min-width: 640px){
  ._ods_modal_action_leading_ih07p_1415{
    margin-top:1rem;
    margin-left:2.5rem;
    display:flex;
    padding-left:1rem
  }
}

._ods_modal_action_centered_ih07p_1418{
  margin-top:1.25rem
}

@media (min-width: 640px){
  ._ods_modal_action_centered_ih07p_1418{
    margin-top:1.5rem;
    display:flex;
    flex-direction:row-reverse;
    grid-gap:.75rem;
    gap:.75rem
  }
}

._ods_modal_action_centered_ih07p_1418 button{
  width:100%
}

._ods_modal_action_trailing_ih07p_1424{
  margin-top:1.25rem
}

@media (min-width: 640px){
  ._ods_modal_action_trailing_ih07p_1424{
    margin-top:1rem;
    display:flex;
    flex-direction:row-reverse
  }
}

._ods_modal_action_leading_ih07p_1415 button,._ods_modal_action_trailing_ih07p_1424 button,._ods_modal_action_gray_ih07p_1427 button{
  width:100%
}

@media (min-width: 640px){
  ._ods_modal_action_leading_ih07p_1415 button,._ods_modal_action_trailing_ih07p_1424 button,._ods_modal_action_gray_ih07p_1427 button{
    width:auto
  }
}

._ods_modal_action_ih07p_1409 button{
  font-size:1rem;
  line-height:1.5rem
}

@media (min-width: 640px){
  ._ods_modal_action_ih07p_1409 button{
    font-size:.875rem;
    line-height:1.25rem
  }
}

._ods_modal_title_ih07p_1434{
  font-size:1.125rem;
  font-weight:500;
  line-height:1.5rem;
  --tw-text-opacity: 1;
  color:rgba(17, 24, 39,var(--tw-text-opacity));
  color:rgba(var(--color-dark-900),var(--tw-text-opacity))
}

._ods_navbar_link_ih07p_1438{
  border-radius:.375rem;
  padding:.5rem .75rem;
  font-size:.875rem;
  line-height:1.25rem;
  font-weight:500
}

._ods_navbar_link_ih07p_1438:hover{
  -webkit-text-decoration-line:none;
  text-decoration-line:none
}

._ods_navbar_link_ih07p_1438:focus{
  outline:2px solid transparent;
  outline-offset:2px
}

._ods_navbar_link_ih07p_1438 svg{
  height:1.5rem;
  width:1.5rem;
  flex-shrink:0
}

._ods_notification_actions_split_ih07p_1445{
  display:flex
}

._ods_notification_actions_split_ih07p_1445 ._ods_notification_actions_block_ih07p_1448{
  border-left-width:0px
}

._ods_notification_actions_split_ih07p_1445 ._ods_notification_actions_ih07p_1445{
  display:flex;
  flex-direction:column
}

._ods_notification_actions_split_ih07p_1445 ._ods_notification_actions_ih07p_1445>:not([hidden])~:not([hidden]){
  --tw-divide-y-reverse: 0;
  border-top-width:calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width:calc(1px * var(--tw-divide-y-reverse));
  --tw-divide-opacity: 1;
  border-color:rgba(229, 231, 235,var(--tw-divide-opacity));
  border-color:rgba(var(--color-light-200),var(--tw-divide-opacity))
}

._ods_notification_actions_split_ih07p_1445 ._ods_notification_actions_ih07p_1445 button{
  flex:1 1
}

._ods_notification_actions_split_ih07p_1445 ._ods_notification_actions_ih07p_1445 button:focus{
  z-index:10
}

._ods_notification_actions_normal_ih07p_1457{
  margin-top:.75rem
}

._ods_notification_actions_inline_ih07p_1460{
  margin-top:.75rem;
  display:flex
}

._ods_notification_actions_inline_ih07p_1460>:not([hidden])~:not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right:calc(1.75rem * var(--tw-space-x-reverse));
  margin-left:calc(1.75rem * calc(1 - var(--tw-space-x-reverse)))
}

._ods_notification_actions_block_ih07p_1448{
  display:flex;
  border-left-width:1px;
  --tw-border-opacity: 1;
  border-color:rgb(229 231 235 / var(--tw-border-opacity))
}

._ods_notification_button_ih07p_1467{
  border-radius:0
}

._ods_notification_button_link_ih07p_1470{
  padding:.125rem
}

._ods_notification_button_link_ih07p_1470:focus{
  outline-style:solid;
  outline-width:2px
}

._ods_notification_button_link_ih07p_1470._ods_link_primary_button_ih07p_629:focus{
  outline-color:rgb(99, 102, 241);
  outline-color:rgb(var(--color-primary-500))
}

._ods_notification_button_link_ih07p_1470._ods_link_secondary_button_ih07p_650{
  --tw-text-opacity: 1;
  color:rgba(55, 65, 81,var(--tw-text-opacity));
  color:rgba(var(--color-dark-700),var(--tw-text-opacity))
}

._ods_notification_button_link_ih07p_1470._ods_link_secondary_button_ih07p_650:hover{
  --tw-text-opacity: 1;
  color:rgba(107, 114, 128,var(--tw-text-opacity));
  color:rgba(var(--color-dark-500),var(--tw-text-opacity))
}

._ods_notification_button_link_ih07p_1470._ods_link_secondary_button_ih07p_650:focus{
  outline-color:rgb(99, 102, 241);
  outline-color:rgb(var(--color-primary-500))
}

._ods_notification_button_rounded_full_ih07p_1479{
  border-radius:.5rem
}

._ods_notification_button_rounded_full_ih07p_1479._ods_button_primary_ih07p_456,._ods_notification_button_rounded_full_ih07p_1479._ods_button_white_ih07p_480{
  padding:.5rem .75rem
}

._ods_notification_button_rounded_leading_ih07p_1485{
  border-top-left-radius:.5rem;
  border-bottom-left-radius:.5rem;
  padding:1rem
}

._ods_notification_button_rounded_trailing_ih07p_1488{
  border-top-right-radius:.5rem;
  border-bottom-right-radius:.5rem;
  padding:1rem
}

._ods_notification_button_rounded_trailing_top_ih07p_1491,._ods_notification_button_rounded_trailing_bottom_ih07p_1491{
  padding:.75rem 1rem
}

._ods_notification_button_rounded_trailing_top_ih07p_1491{
  border-top-right-radius:.5rem
}

._ods_notification_button_rounded_trailing_bottom_ih07p_1491{
  border-bottom-right-radius:.5rem
}

._ods_notification_button_full_width_ih07p_1500{
  width:100%;
  justify-content:center
}

._ods_heading_ih07p_1504{
  --tw-text-opacity: 1;
  color:rgba(17, 24, 39,var(--tw-text-opacity));
  color:rgba(var(--color-dark-900),var(--tw-text-opacity))
}

._ods_heading_h1_ih07p_1507{
  font-size:3rem;
  line-height:1
}

._ods_heading_h2_ih07p_1510{
  font-size:2.25rem;
  line-height:2.5rem
}

._ods_heading_h3_ih07p_1513{
  font-size:1.875rem;
  line-height:2.25rem
}

._ods_heading_h4_ih07p_1516{
  font-size:1.5rem;
  line-height:2rem
}

._ods_heading_h5_ih07p_1519{
  font-size:1.25rem;
  line-height:1.75rem
}

._ods_heading_h6_ih07p_1522{
  font-size:1.125rem;
  line-height:1.75rem
}

._ods_heading_display1_ih07p_1525{
  font-size:8rem;
  line-height:1
}

._ods_heading_display2_ih07p_1528{
  font-size:6rem;
  line-height:1
}

._ods_heading_display3_ih07p_1531{
  font-size:4.5rem;
  line-height:1
}

._ods_heading_display4_ih07p_1534{
  font-size:3.75rem;
  line-height:1
}

._ods_paragraph_ih07p_1538{
  --tw-text-opacity: 1;
  color:rgba(17, 24, 39,var(--tw-text-opacity));
  color:rgba(var(--color-dark-900),var(--tw-text-opacity))
}

._ods_paragraph_xs_ih07p_1541{
  font-size:.75rem;
  line-height:1rem
}

._ods_paragraph_sm_ih07p_1544{
  font-size:.875rem;
  line-height:1.25rem
}

._ods_paragraph_base_ih07p_1547{
  font-size:1rem;
  line-height:1.5rem
}

._ods_paragraph_lg_ih07p_1550{
  font-size:1.125rem;
  line-height:1.75rem
}

._ods_paragraph_dark_ih07p_1553{
  --tw-text-opacity: 1;
  color:rgba(17, 24, 39,var(--tw-text-opacity));
  color:rgba(var(--color-dark-900),var(--tw-text-opacity))
}

._ods_paragraph_primary_ih07p_1556{
  --tw-text-opacity: 1;
  color:rgba(79, 70, 229,var(--tw-text-opacity));
  color:rgba(var(--color-primary-600),var(--tw-text-opacity))
}

._ods_paragraph_danger_ih07p_1559{
  --tw-text-opacity: 1;
  color:rgba(220, 38, 38,var(--tw-text-opacity));
  color:rgba(var(--color-danger-600),var(--tw-text-opacity))
}

._ods_alert_ih07p_81{
  border-radius:.375rem;
  padding:1rem
}

._ods_alert_accent_ih07p_1566{
  border-radius:0;
  border-left-width:4px
}

._ods_alert_wrapper_ih07p_1569{
  display:flex
}

._ods_alert_wrapper_icon_ih07p_1572{
  flex-shrink:0
}

._ods_alert_wrapper_content_ih07p_1575{
  margin-left:.75rem
}

._ods_alert_wrapper_content_trailingLink_ih07p_1578{
  flex:1 1
}

@media (min-width: 768px){
  ._ods_alert_wrapper_content_trailingLink_ih07p_1578{
    display:flex;
    justify-content:space-between
  }
}

._ods_alert_wrapper_dismiss_ih07p_1581{
  margin-left:auto;
  padding-left:.75rem
}

._ods_alert_wrapper_dismiss_ih07p_1581 div:first-child{
  margin:-.375rem
}

._ods_alert_wrapper_ih07p_1569 svg{
  height:1.25rem;
  width:1.25rem
}

._ods_alert_success_ih07p_1590{
  --tw-border-opacity: 1;
  border-color:rgba(52, 211, 153,var(--tw-border-opacity));
  border-color:rgba(var(--color-success-400),var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color:rgba(236, 253, 245,var(--tw-bg-opacity));
  background-color:rgba(var(--color-success-50),var(--tw-bg-opacity))
}

._ods_alert_success_ih07p_1590 button{
  --tw-bg-opacity: 1;
  background-color:rgba(236, 253, 245,var(--tw-bg-opacity));
  background-color:rgba(var(--color-success-50),var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:rgba(6, 95, 70,var(--tw-text-opacity));
  color:rgba(var(--color-success-800),var(--tw-text-opacity))
}

._ods_alert_success_ih07p_1590 button:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(209, 250, 229,var(--tw-bg-opacity));
  background-color:rgba(var(--color-success-100),var(--tw-bg-opacity))
}

._ods_alert_success_ih07p_1590 button:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(var(--color-success-600), var(--tw-ring-opacity));
  --tw-ring-offset-color: rgb(var(--color-success-50))
}

._ods_alert_success_ih07p_1590 a,._ods_alert_success_ih07p_1590 li{
  --tw-text-opacity: 1;
  color:rgba(4, 120, 87,var(--tw-text-opacity));
  color:rgba(var(--color-success-700),var(--tw-text-opacity))
}

._ods_alert_success_ih07p_1590 a:hover{
  --tw-text-opacity: 1;
  color:rgba(5, 150, 105,var(--tw-text-opacity));
  color:rgba(var(--color-success-600),var(--tw-text-opacity))
}

._ods_alert_success_ih07p_1590 ._ods_alert_wrapper_icon_ih07p_1572{
  --tw-text-opacity: 1;
  color:rgba(52, 211, 153,var(--tw-text-opacity));
  color:rgba(var(--color-success-400),var(--tw-text-opacity))
}

._ods_alert_success_ih07p_1590 ._ods_alert_dismiss_icon_ih07p_1606{
  --tw-text-opacity: 1;
  color:rgba(5, 150, 105,var(--tw-text-opacity));
  color:rgba(var(--color-success-600),var(--tw-text-opacity))
}

._ods_alert_success_ih07p_1590 p,._ods_alert_success_ih07p_1590 ._ods_alert_heading_ih07p_105{
  --tw-text-opacity: 1;
  color:rgba(6, 95, 70,var(--tw-text-opacity));
  color:rgba(var(--color-success-800),var(--tw-text-opacity))
}

._ods_alert_warning_ih07p_1613{
  --tw-border-opacity: 1;
  border-color:rgba(251, 191, 36,var(--tw-border-opacity));
  border-color:rgba(var(--color-warning-400),var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color:rgba(255, 251, 235,var(--tw-bg-opacity));
  background-color:rgba(var(--color-warning-50),var(--tw-bg-opacity))
}

._ods_alert_warning_ih07p_1613 button{
  --tw-bg-opacity: 1;
  background-color:rgba(255, 251, 235,var(--tw-bg-opacity));
  background-color:rgba(var(--color-warning-50),var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:rgba(146, 64, 14,var(--tw-text-opacity));
  color:rgba(var(--color-warning-800),var(--tw-text-opacity))
}

._ods_alert_warning_ih07p_1613 button:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(254, 243, 199,var(--tw-bg-opacity));
  background-color:rgba(var(--color-warning-100),var(--tw-bg-opacity))
}

._ods_alert_warning_ih07p_1613 button:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(var(--color-warning-600), var(--tw-ring-opacity));
  --tw-ring-offset-color: rgb(var(--color-warning-50))
}

._ods_alert_warning_ih07p_1613 a,._ods_alert_warning_ih07p_1613 p,._ods_alert_warning_ih07p_1613 li{
  --tw-text-opacity: 1;
  color:rgba(180, 83, 9,var(--tw-text-opacity));
  color:rgba(var(--color-warning-700),var(--tw-text-opacity))
}

._ods_alert_warning_ih07p_1613 a:hover{
  --tw-text-opacity: 1;
  color:rgba(217, 119, 6,var(--tw-text-opacity));
  color:rgba(var(--color-warning-600),var(--tw-text-opacity))
}

._ods_alert_warning_ih07p_1613 ._ods_alert_wrapper_icon_ih07p_1572{
  --tw-text-opacity: 1;
  color:rgba(251, 191, 36,var(--tw-text-opacity));
  color:rgba(var(--color-warning-400),var(--tw-text-opacity))
}

._ods_alert_warning_ih07p_1613 ._ods_alert_dismiss_icon_ih07p_1606{
  --tw-text-opacity: 1;
  color:rgba(217, 119, 6,var(--tw-text-opacity));
  color:rgba(var(--color-warning-600),var(--tw-text-opacity))
}

._ods_alert_warning_ih07p_1613 ._ods_alert_heading_ih07p_105{
  --tw-text-opacity: 1;
  color:rgba(146, 64, 14,var(--tw-text-opacity));
  color:rgba(var(--color-warning-800),var(--tw-text-opacity))
}

._ods_alert_info_ih07p_1636{
  --tw-border-opacity: 1;
  border-color:rgba(96, 165, 250,var(--tw-border-opacity));
  border-color:rgba(var(--color-info-400),var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color:rgba(239, 246, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-info-50),var(--tw-bg-opacity))
}

._ods_alert_info_ih07p_1636 button{
  --tw-bg-opacity: 1;
  background-color:rgba(239, 246, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-info-50),var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:rgba(30, 64, 175,var(--tw-text-opacity));
  color:rgba(var(--color-info-800),var(--tw-text-opacity))
}

._ods_alert_info_ih07p_1636 button:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(219, 234, 254,var(--tw-bg-opacity));
  background-color:rgba(var(--color-info-100),var(--tw-bg-opacity))
}

._ods_alert_info_ih07p_1636 button:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(var(--color-info-600), var(--tw-ring-opacity));
  --tw-ring-offset-color: rgb(var(--color-info-50))
}

._ods_alert_info_ih07p_1636 a,._ods_alert_info_ih07p_1636 p,._ods_alert_info_ih07p_1636 li{
  --tw-text-opacity: 1;
  color:rgba(29, 78, 216,var(--tw-text-opacity));
  color:rgba(var(--color-info-700),var(--tw-text-opacity))
}

._ods_alert_info_ih07p_1636 a:hover{
  --tw-text-opacity: 1;
  color:rgba(37, 99, 235,var(--tw-text-opacity));
  color:rgba(var(--color-info-600),var(--tw-text-opacity))
}

._ods_alert_info_ih07p_1636 ._ods_alert_wrapper_icon_ih07p_1572{
  --tw-text-opacity: 1;
  color:rgba(96, 165, 250,var(--tw-text-opacity));
  color:rgba(var(--color-info-400),var(--tw-text-opacity))
}

._ods_alert_info_ih07p_1636 ._ods_alert_dismiss_icon_ih07p_1606{
  --tw-text-opacity: 1;
  color:rgba(37, 99, 235,var(--tw-text-opacity));
  color:rgba(var(--color-info-600),var(--tw-text-opacity))
}

._ods_alert_info_ih07p_1636 ._ods_alert_heading_ih07p_105{
  --tw-text-opacity: 1;
  color:rgba(30, 64, 175,var(--tw-text-opacity));
  color:rgba(var(--color-info-800),var(--tw-text-opacity))
}

._ods_alert_danger_ih07p_1659{
  --tw-border-opacity: 1;
  border-color:rgba(248, 113, 113,var(--tw-border-opacity));
  border-color:rgba(var(--color-danger-400),var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color:rgba(254, 242, 242,var(--tw-bg-opacity));
  background-color:rgba(var(--color-danger-50),var(--tw-bg-opacity))
}

._ods_alert_danger_ih07p_1659 button{
  --tw-bg-opacity: 1;
  background-color:rgba(254, 242, 242,var(--tw-bg-opacity));
  background-color:rgba(var(--color-danger-50),var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:rgba(153, 27, 27,var(--tw-text-opacity));
  color:rgba(var(--color-danger-800),var(--tw-text-opacity))
}

._ods_alert_danger_ih07p_1659 button:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(254, 226, 226,var(--tw-bg-opacity));
  background-color:rgba(var(--color-danger-100),var(--tw-bg-opacity))
}

._ods_alert_danger_ih07p_1659 button:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(var(--color-danger-600), var(--tw-ring-opacity));
  --tw-ring-offset-color: rgb(var(--color-danger-50))
}

._ods_alert_danger_ih07p_1659 a,._ods_alert_danger_ih07p_1659 p,._ods_alert_danger_ih07p_1659 li{
  --tw-text-opacity: 1;
  color:rgba(185, 28, 28,var(--tw-text-opacity));
  color:rgba(var(--color-danger-700),var(--tw-text-opacity))
}

._ods_alert_danger_ih07p_1659 a:hover{
  --tw-text-opacity: 1;
  color:rgba(220, 38, 38,var(--tw-text-opacity));
  color:rgba(var(--color-danger-600),var(--tw-text-opacity))
}

._ods_alert_danger_ih07p_1659 ._ods_alert_wrapper_icon_ih07p_1572{
  --tw-text-opacity: 1;
  color:rgba(248, 113, 113,var(--tw-text-opacity));
  color:rgba(var(--color-danger-400),var(--tw-text-opacity))
}

._ods_alert_danger_ih07p_1659 ._ods_alert_dismiss_icon_ih07p_1606{
  --tw-text-opacity: 1;
  color:rgba(220, 38, 38,var(--tw-text-opacity));
  color:rgba(var(--color-danger-600),var(--tw-text-opacity))
}

._ods_alert_danger_ih07p_1659 ._ods_alert_heading_ih07p_105{
  --tw-text-opacity: 1;
  color:rgba(153, 27, 27,var(--tw-text-opacity));
  color:rgba(var(--color-danger-800),var(--tw-text-opacity))
}

._ods_alert_transition_enter_ih07p_1682{
  transition-property:opacity;
  transition-timing-function:cubic-bezier(.4,0,.2,1);
  transition-duration:75ms
}

._ods_alert_transition_enterFrom_ih07p_1685,._ods_alert_transition_leaveTo_ih07p_1685{
  opacity:0
}

._ods_alert_transition_enterTo_ih07p_1688,._ods_alert_transition_leaveFrom_ih07p_1688{
  opacity:1
}

._ods_alert_transition_leave_ih07p_1685{
  transition-property:opacity;
  transition-timing-function:cubic-bezier(.4,0,.2,1);
  transition-duration:.15s
}

._ods_avatar_group_ih07p_1695{
  display:flex
}

._ods_avatar_group_ih07p_1695>:not([hidden])~:not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right:calc(-.25rem * var(--tw-space-x-reverse));
  margin-left:calc(-.25rem * calc(1 - var(--tw-space-x-reverse)))
}

._ods_avatar_group_ih07p_1695{
  overflow:hidden
}

._ods_avatar_group_bottom_ih07p_1698{
  position:relative;
  z-index:0
}

._ods_avatar_group_ih07p_1695>img{
  display:inline-block;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),0 0 #0000;
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(var(--color-white), var(--tw-ring-opacity))
}

._ods_avatar_group_initial_ih07p_1704{
  display:inline-flex;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),0 0 #0000;
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(var(--color-white), var(--tw-ring-opacity))
}

._ods_avatar_group_xs_ih07p_1707>img{
  height:1.5rem;
  width:1.5rem
}

._ods_avatar_group_sm_ih07p_1710>img{
  height:2rem;
  width:2rem
}

._ods_avatar_group_base_ih07p_1713>img{
  height:2.5rem;
  width:2.5rem
}

._ods_avatar_group_lg_ih07p_1716>img{
  height:3rem;
  width:3rem
}

._ods_avatar_group_xl_ih07p_1719>img{
  height:3.5rem;
  width:3.5rem
}

._ods_avatar_group_xxl_ih07p_1722>img{
  height:4rem;
  width:4rem
}

._ods_breadcrumb_ih07p_493{
  display:flex;
  height:2.75rem
}

._ods_breadcrumb_flex_ih07p_1729{
  display:flex
}

._ods_breadcrumb_flex_center_ih07p_1732{
  display:flex;
  align-items:center
}

._ods_breadcrumb_list_ih07p_1735{
  display:flex
}

._ods_breadcrumb_list_ih07p_1735>:not([hidden])~:not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right:calc(1rem * var(--tw-space-x-reverse));
  margin-left:calc(1rem * calc(1 - var(--tw-space-x-reverse)))
}

._ods_breadcrumb_link_ih07p_1738{
  --tw-text-opacity: 1;
  color:rgba(156, 163, 175,var(--tw-text-opacity));
  color:rgba(var(--color-light-400),var(--tw-text-opacity))
}

._ods_breadcrumb_link_ih07p_1738:hover{
  --tw-text-opacity: 1;
  color:rgba(107, 114, 128,var(--tw-text-opacity));
  color:rgba(var(--color-dark-500),var(--tw-text-opacity))
}

._ods_breadcrumb_contained_ih07p_1741{
  display:flex;
  align-items:center
}

._ods_breadcrumb_contained_ih07p_1741>:not([hidden])~:not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right:calc(1rem * var(--tw-space-x-reverse));
  margin-left:calc(1rem * calc(1 - var(--tw-space-x-reverse)))
}

._ods_breadcrumb_contained_ih07p_1741{
  border-radius:.375rem;
  --tw-bg-opacity: 1;
  background-color:rgba(255, 255, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-white),var(--tw-bg-opacity));
  padding-left:1.5rem;
  padding-right:1.5rem;
  --tw-shadow: 0px 1px 3px rgba(0, 0, 0, .1), 0px 1px 2px rgba(0, 0, 0, .06);
  --tw-shadow-colored: 0px 1px 3px var(--tw-shadow-color), 0px 1px 2px var(--tw-shadow-color);
  box-shadow:0 0 #0000,0 0 #0000,var(--tw-shadow);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow)
}

._ods_breadcrumb_simple_ih07p_1744{
  display:flex;
  align-items:center
}

._ods_breadcrumb_simple_ih07p_1744>:not([hidden])~:not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right:calc(1rem * var(--tw-space-x-reverse));
  margin-left:calc(1rem * calc(1 - var(--tw-space-x-reverse)))
}

._ods_breadcrumb_simple_ih07p_1744{
  --tw-bg-opacity: 1;
  background-color:rgba(255, 255, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-white),var(--tw-bg-opacity));
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow:0 0 #0000,0 0 #0000,var(--tw-shadow);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow)
}

._ods_breadcrumb_full_width_ih07p_1747{
  display:flex;
  width:100%;
  max-width:1280px
}

._ods_breadcrumb_full_width_ih07p_1747>:not([hidden])~:not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right:calc(1rem * var(--tw-space-x-reverse));
  margin-left:calc(1rem * calc(1 - var(--tw-space-x-reverse)))
}

._ods_breadcrumb_full_width_ih07p_1747{
  --tw-bg-opacity: 1;
  background-color:rgba(255, 255, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-white),var(--tw-bg-opacity));
  padding-left:1rem;
  padding-right:1rem;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow:0 0 #0000,0 0 #0000,var(--tw-shadow);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow)
}

@media (min-width: 640px){
  ._ods_breadcrumb_full_width_ih07p_1747{
    padding-left:1.5rem;
    padding-right:1.5rem
  }
}

@media (min-width: 1024px){
  ._ods_breadcrumb_full_width_ih07p_1747{
    padding-left:2rem;
    padding-right:2rem
  }
}

._ods_divider_ih07p_1751{
  position:relative
}

._ods_divider_ih07p_1751>div button{
  padding-top:.5rem;
  padding-bottom:.5rem
}

._ods_divider_wrapper_ih07p_1757{
  position:absolute;
  inset:0;
  display:flex;
  align-items:center
}

._ods_divider_border_ih07p_1760{
  width:100%;
  border-top-width:1px;
  --tw-border-opacity: 1;
  border-color:rgba(209, 213, 219,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-300),var(--tw-border-opacity))
}

._ods_divider_content_ih07p_1763{
  position:relative;
  display:flex;
  align-items:center
}

._ods_divider_content_ih07p_1763 span{
  --tw-bg-opacity: 1;
  background-color:rgba(255, 255, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-white),var(--tw-bg-opacity))
}

._ods_divider_content_ih07p_1763 button{
  border-radius:9999px;
  --tw-bg-opacity: 1;
  background-color:rgba(255, 255, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-white),var(--tw-bg-opacity));
  padding-top:.375rem;
  padding-bottom:.375rem;
  font-size:.875rem;
  line-height:1.25rem
}

._ods_divider_content_ih07p_1763 button:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(249, 250, 251,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-50),var(--tw-bg-opacity))
}

._ods_divider_content_ih07p_1763 button:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(var(--color-primary-500), var(--tw-ring-opacity))
}

._ods_divider_content_ih07p_1763 button span{
  background-color:transparent;
  padding-left:0;
  padding-right:0
}

._ods_divider_content_ih07p_1763 button svg{
  background-color:transparent
}

._ods_divider_content_left_ih07p_1778{
  justify-content:flex-start
}

._ods_divider_content_left_ih07p_1778 span{
  padding-right:.5rem
}

._ods_divider_content_center_ih07p_1784{
  justify-content:center
}

._ods_divider_content_center_ih07p_1784 span{
  padding-left:.5rem;
  padding-right:.5rem
}

._ods_divider_content_right_ih07p_1790{
  justify-content:flex-end
}

._ods_divider_content_right_ih07p_1790 span{
  padding-left:.5rem
}

._ods_divider_content_space_between_ih07p_1796{
  justify-content:space-between
}

._ods_button_group_ih07p_1800{
  position:relative;
  z-index:0;
  display:inline-flex;
  border-radius:.375rem;
  border-width:1px;
  --tw-border-opacity: 1;
  border-color:rgba(209, 213, 219,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-300),var(--tw-border-opacity));
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / .05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow:0 0 #0000,0 0 #0000,var(--tw-shadow);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow)
}

._ods_button_group_ih07p_1800 a{
  font-weight:400
}

._ods_button_group_ih07p_1800 a:hover{
  -webkit-text-decoration-line:none;
  text-decoration-line:none
}

._ods_button_group_ih07p_1800 input{
  border-radius:.25rem;
  --tw-border-opacity: 1;
  border-color:rgba(209, 213, 219,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-300),var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color:rgba(79, 70, 229,var(--tw-text-opacity));
  color:rgba(var(--color-primary-600),var(--tw-text-opacity))
}

._ods_button_group_ih07p_1800 input:hover{
  --tw-ring-color: transparent
}

._ods_button_group_ih07p_1800 input:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(var(--color-primary-500), var(--tw-ring-opacity));
  --tw-ring-offset-width: 2px
}

._ods_button_group_ih07p_1800 select{
  position:relative;
  margin-top:0;
  border-top-left-radius:0;
  border-bottom-left-radius:0;
  border-style:none;
  padding:.5rem 2.25rem .5rem .75rem
}

._ods_button_group_ih07p_1800 select:focus{
  z-index:10;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),0 0 #0000;
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(var(--color-primary-500), var(--tw-ring-opacity));
  --tw-ring-offset-width: 2px
}

._ods_button_group_ih07p_1800 span{
  margin:0
}

._ods_button_group_children_ih07p_1815>button{
  position:relative;
  border-radius:0;
  border-top-width:0px;
  border-bottom-width:0px;
  border-right-width:1px;
  border-left-width:0px
}

._ods_button_group_children_ih07p_1815>button:focus{
  z-index:10;
  border-color:transparent;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),0 0 #0000;
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),var(--tw-shadow, 0 0 #0000);
  --tw-ring-offset-width: 0px
}

._ods_button_group_children_ih07p_1815>button:first-child{
  position:relative;
  border-top-left-radius:.375rem;
  border-bottom-left-radius:.375rem;
  border-top-right-radius:0;
  border-bottom-right-radius:0;
  border-right-width:1px;
  border-left-width:0px;
  --tw-border-opacity: 1;
  border-color:rgba(209, 213, 219,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-300),var(--tw-border-opacity))
}

._ods_button_group_children_ih07p_1815>button:first-child:focus{
  border-color:transparent
}

._ods_button_group_children_ih07p_1815>button:last-child{
  position:relative;
  border-top-right-radius:.375rem;
  border-bottom-right-radius:.375rem;
  border-top-left-radius:0;
  border-bottom-left-radius:0;
  border-style:none;
  --tw-border-opacity: 1;
  border-color:rgba(209, 213, 219,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-300),var(--tw-border-opacity))
}

._ods_button_group_children_ih07p_1815>button:last-child:focus{
  border-color:transparent
}

._ods_button_group_children_ih07p_1815>div button{
  border-top-left-radius:0;
  border-bottom-left-radius:0;
  border-style:none;
  padding:.5rem
}

._ods_button_group_children_ih07p_1815>div button:focus{
  z-index:10;
  --tw-border-opacity: 1;
  border-color:rgba(99, 102, 241,var(--tw-border-opacity));
  border-color:rgba(var(--color-primary-500),var(--tw-border-opacity));
  outline:2px solid transparent;
  outline-offset:2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),0 0 #0000;
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(var(--color-primary-500), var(--tw-ring-opacity));
  --tw-ring-offset-width: 0px
}

._ods_button_group_children_ih07p_1815>span{
  border-top-right-radius:0;
  border-bottom-right-radius:0;
  border-top-width:0px;
  border-bottom-width:0px;
  border-left-width:0px;
  border-right-width:1px;
  padding:.5rem
}

._ods_button_group_children_ih07p_1815>span:hover{
  background-color:transparent
}

._ods_dropdown_group_ih07p_1831{
  border-bottom-width:1px;
  --tw-border-opacity: 1;
  border-color:rgba(243, 244, 246,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-100),var(--tw-border-opacity));
  padding-top:.25rem;
  padding-bottom:.25rem
}

._ods_dropdown_menu_ih07p_1835{
  position:absolute;
  margin-top:.5rem;
  width:14rem;
  border-radius:.375rem;
  --tw-bg-opacity: 1;
  background-color:rgba(255, 255, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-white),var(--tw-bg-opacity));
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / .1), 0 4px 6px -4px rgb(0 0 0 / .1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow:0 0 #0000,0 0 #0000,var(--tw-shadow);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow);
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),0 0 #0000;
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),var(--tw-shadow, 0 0 #0000);
  --tw-ring-color: rgba(var(--color-black), var(--tw-ring-opacity));
  --tw-ring-opacity: .05
}

._ods_dropdown_menu_ih07p_1835:focus{
  outline:2px solid transparent;
  outline-offset:2px
}

._ods_dropdown_menu_leading_ih07p_1838{
  right:0;
  -webkit-transform-origin:top right;
  transform-origin:top right
}

._ods_dropdown_menu_trailing_ih07p_1841{
  left:0;
  -webkit-transform-origin:top left;
  transform-origin:top left
}

._ods_file_upload_ih07p_1845{
  position:relative;
  max-width:32rem
}

._ods_file_upload_label_ih07p_1848{
  position:relative;
  display:flex;
  height:8rem;
  width:100%;
  cursor:pointer;
  align-items:center;
  justify-content:center;
  border-radius:.375rem;
  border-width:2px;
  border-style:dashed;
  --tw-border-opacity: 1;
  border-color:rgba(209, 213, 219,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-300),var(--tw-border-opacity));
  padding-bottom:.75rem;
  padding-top:1.25rem;
  transition-property:all;
  transition-timing-function:cubic-bezier(.4,0,.2,1);
  transition-duration:.15s
}

._ods_file_upload_label_ih07p_1848:hover{
  --tw-border-opacity: 1;
  border-color:rgba(99, 102, 241,var(--tw-border-opacity));
  border-color:rgba(var(--color-primary-500),var(--tw-border-opacity))
}

@media (min-width: 640px){
  ._ods_file_upload_label_ih07p_1848{
    height:10rem;
    padding-top:1.5rem;
    padding-bottom:1.5rem
  }
}

._ods_file_upload_wrapper_ih07p_1851>:not([hidden])~:not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top:calc(.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom:calc(.25rem * var(--tw-space-y-reverse))
}

._ods_file_upload_wrapper_ih07p_1851{
  text-align:center
}

._ods_file_upload_close_ih07p_1854{
  position:absolute;
  right:.25rem;
  top:.25rem;
  padding:.5rem;
  --tw-text-opacity: 1;
  color:rgba(209, 213, 219,var(--tw-text-opacity));
  color:rgba(var(--color-light-300),var(--tw-text-opacity));
  transition-property:color,background-color,border-color,fill,stroke,-webkit-text-decoration-color;
  transition-property:color,background-color,border-color,text-decoration-color,fill,stroke;
  transition-property:color,background-color,border-color,text-decoration-color,fill,stroke,-webkit-text-decoration-color;
  transition-timing-function:cubic-bezier(.4,0,.2,1);
  transition-duration:.15s
}

._ods_file_upload_close_ih07p_1854:hover{
  --tw-text-opacity: 1;
  color:rgba(99, 102, 241,var(--tw-text-opacity));
  color:rgba(var(--color-primary-500),var(--tw-text-opacity))
}

._ods_file_upload_close_ih07p_1854>svg{
  height:3rem;
  width:3rem
}

._ods_file_upload_image_ih07p_1860{
  height:100%;
  object-fit:contain
}

._ods_file_upload_input_wrapper_ih07p_1863{
  position:relative;
  border-radius:.375rem;
  background-color:transparent;
  font-weight:500
}

._ods_file_upload_input_wrapper_ih07p_1863>input{
  position:absolute;
  width:1px;
  height:1px;
  padding:0;
  margin:-1px;
  overflow:hidden;
  clip:rect(0,0,0,0);
  white-space:nowrap;
  border-width:0
}

._ods_file_upload_input_wrapper_label_ih07p_1869{
  margin-top:.5rem;
  font-size:.875rem;
  line-height:1.25rem;
  --tw-text-opacity: 1;
  color:rgba(107, 114, 128,var(--tw-text-opacity));
  color:rgba(var(--color-dark-500),var(--tw-text-opacity))
}

._ods_file_upload_input_wrapper_helptext_ih07p_1872{
  margin-top:.25rem;
  font-size:.75rem;
  line-height:1rem;
  --tw-text-opacity: 1;
  color:rgba(107, 114, 128,var(--tw-text-opacity));
  color:rgba(var(--color-dark-500),var(--tw-text-opacity))
}

._ods_file_upload_error_ih07p_1875{
  --tw-border-opacity: 1;
  border-color:rgba(239, 68, 68,var(--tw-border-opacity));
  border-color:rgba(var(--color-danger-500),var(--tw-border-opacity))
}

._ods_file_upload_error_message_ih07p_1878{
  margin-top:.5rem;
  margin-bottom:.5rem;
  --tw-text-opacity: 1;
  color:rgba(239, 68, 68,var(--tw-text-opacity));
  color:rgba(var(--color-danger-500),var(--tw-text-opacity))
}

._ods_file_upload_disabled_ih07p_1881{
  cursor:not-allowed;
  opacity:.75
}

._ods_file_upload_disabled_ih07p_1881:hover{
  --tw-border-opacity: 1;
  border-color:rgba(165, 180, 252,var(--tw-border-opacity));
  border-color:rgba(var(--color-primary-300),var(--tw-border-opacity));
  opacity:.5
}

._ods_empty_state_ih07p_1885 a:hover{
  -webkit-text-decoration-line:none;
  text-decoration-line:none
}

._ods_empty_state_ih07p_1885 a:focus{
  outline:2px solid transparent;
  outline-offset:2px
}

._ods_empty_state_ih07p_1885 span{
  margin-right:.125rem
}

._ods_empty_state_ih07p_1885>svg{
  margin-left:auto;
  margin-right:auto
}

._ods_empty_state_border_ih07p_1894{
  position:relative;
  display:block;
  width:100%;
  border-radius:.375rem;
  border-width:2px;
  border-style:dashed;
  padding:3rem
}

._ods_empty_state_border_ih07p_1894:hover{
  --tw-border-opacity: 1;
  border-color:rgba(156, 163, 175,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-400),var(--tw-border-opacity))
}

._ods_empty_state_border_ih07p_1894:focus{
  outline:2px solid transparent;
  outline-offset:2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),0 0 #0000;
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(var(--color-primary-500), var(--tw-ring-opacity));
  --tw-ring-offset-width: 2px
}

._ods_empty_state_with_icon_ih07p_1897{
  text-align:center
}

._ods_empty_state_without_icon_ih07p_1900{
  text-align:left
}

._ods_empty_state_title_ih07p_1903{
  margin-top:.5rem;
  font-size:.875rem;
  line-height:1.25rem;
  font-weight:500;
  --tw-text-opacity: 1;
  color:rgba(17, 24, 39,var(--tw-text-opacity));
  color:rgba(var(--color-dark-900),var(--tw-text-opacity))
}

._ods_empty_state_help_text_ih07p_1906{
  margin-top:.25rem;
  font-size:.875rem;
  line-height:1.25rem;
  --tw-text-opacity: 1;
  color:rgba(107, 114, 128,var(--tw-text-opacity));
  color:rgba(var(--color-dark-500),var(--tw-text-opacity))
}

._ods_feed_ih07p_1910{
  list-style-type:none
}

._ods_feed_content_wrapper_ih07p_1913{
  position:relative;
  display:flex
}

._ods_feed_content_wrapper_ih07p_1913>:not([hidden])~:not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right:calc(.75rem * var(--tw-space-x-reverse));
  margin-left:calc(.75rem * calc(1 - var(--tw-space-x-reverse)))
}

._ods_feed_content_wrapper_ih07p_1913 ._ods_avatar_ih07p_112,._ods_feed_content_wrapper_ih07p_1913 ._ods_avatar_wrapper_ih07p_115{
  align-self:flex-start
}

._ods_feed_ih07p_1910 ._ods_feed_content_icon_wrapper_ih07p_1920 span{
  display:flex;
  height:2rem;
  width:2rem;
  align-items:center;
  justify-content:center;
  border-radius:9999px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),0 0 #0000;
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(var(--color-white), var(--tw-ring-opacity))
}

._ods_feed_ih07p_1910 ._ods_feed_content_icon_wrapper_ih07p_1920 span svg{
  height:1.25rem;
  width:1.25rem
}

._ods_feed_body_inline_ih07p_1926{
  min-width:0px;
  flex:1 1;
  padding-top:.375rem
}

._ods_feed_body_inline_ih07p_1926 ._ods_feed_body_wrapper_ih07p_1929{
  display:flex;
  align-items:center;
  justify-content:space-between
}

._ods_feed_body_inline_ih07p_1926 ._ods_feed_body_wrapper_ih07p_1929>:not([hidden])~:not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right:calc(1rem * var(--tw-space-x-reverse));
  margin-left:calc(1rem * calc(1 - var(--tw-space-x-reverse)))
}

._ods_feed_body_inline_ih07p_1926 ._ods_feed_body_wrapper_ih07p_1929 ._ods_feed_helptext_ih07p_1932:last-child{
  white-space:nowrap
}

._ods_feed_body_inline_ih07p_1926 ._ods_feed_body_wrapper_ih07p_1929+p{
  margin-top:.25rem
}

._ods_feed_body_block_ih07p_1938{
  flex:1 1
}

._ods_feed_body_block_ih07p_1938 ._ods_feed_body_wrapper_ih07p_1929{
  display:flex;
  flex-direction:column;
  align-items:flex-start;
  justify-content:space-between
}

._ods_feed_body_block_ih07p_1938 ._ods_feed_body_wrapper_ih07p_1929+p{
  margin-top:.25rem
}

._ods_feed_title_ih07p_1947{
  font-size:.875rem;
  line-height:1.25rem;
  font-weight:500
}

._ods_feed_helptext_ih07p_1932{
  --tw-text-opacity: 1;
  color:rgba(107, 114, 128,var(--tw-text-opacity));
  color:rgba(var(--color-dark-500),var(--tw-text-opacity))
}

._ods_feed_title_ih07p_1947 a,._ods_feed_helptext_ih07p_1932 a{
  --tw-text-opacity: 1;
  color:rgba(17, 24, 39,var(--tw-text-opacity));
  color:rgba(var(--color-dark-900),var(--tw-text-opacity))
}

._ods_feed_title_ih07p_1947 a:focus,._ods_feed_helptext_ih07p_1932 a:focus{
  outline:2px solid transparent;
  outline-offset:2px
}

._ods_form_actions_ih07p_1957{
  padding-top:1.25rem
}

._ods_form_actions_wrapper_ih07p_1960{
  display:flex;
  justify-content:flex-end
}

._ods_form_body_ih07p_1964{
  margin-top:1.5rem
}

._ods_form_body_ih07p_1964>:not([hidden])~:not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top:calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom:calc(1.5rem * var(--tw-space-y-reverse))
}

@media (min-width: 640px){
  ._ods_form_body_ih07p_1964{
    margin-top:1.25rem
  }

  ._ods_form_body_ih07p_1964>:not([hidden])~:not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top:calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(1.25rem * var(--tw-space-y-reverse))
  }

  ._ods_form_body_divided_ih07p_1967>div{
    border-top-width:1px;
    --tw-border-opacity: 1;
    border-color:rgba(229, 231, 235,var(--tw-border-opacity));
    border-color:rgba(var(--color-light-200),var(--tw-border-opacity));
    padding-top:1.25rem
  }
}

@media (min-width: 768px){
  ._ods_form_section_seperated_ih07p_1971{
    display:grid;
    grid-template-columns:repeat(3,minmax(0,1fr));
    grid-gap:1.5rem;
    gap:1.5rem
  }

  ._ods_form_section_seperated_ih07p_1971>div:first-child{
    grid-column:span 1 / span 1
  }
}

._ods_form_section_seperated_ih07p_1971>div:last-child{
  margin-top:1.25rem
}

@media (min-width: 768px){
  ._ods_form_section_seperated_ih07p_1971>div:last-child{
    grid-column:span 2 / span 2;
    margin-top:0
  }
}

._ods_form_section_panel_ih07p_1980{
  --tw-bg-opacity: 1;
  background-color:rgba(255, 255, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-white),var(--tw-bg-opacity));
  padding:1.25rem 1rem;
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / .1), 0 1px 2px -1px rgb(0 0 0 / .1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow:0 0 #0000,0 0 #0000,var(--tw-shadow);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow)
}

@media (min-width: 640px){
  ._ods_form_section_panel_ih07p_1980{
    border-radius:.5rem;
    padding:1.5rem
  }
}

._ods_form_wrapper_ih07p_1984{
  --tw-bg-opacity: 1;
  background-color:rgba(255, 255, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-white),var(--tw-bg-opacity));
  padding-top:.125rem;
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / .1), 0 1px 2px -1px rgb(0 0 0 / .1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow:0 0 #0000,0 0 #0000,var(--tw-shadow);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow)
}

@media (min-width: 640px){
  ._ods_form_wrapper_ih07p_1984{
    border-radius:.5rem
  }
}

._ods_form_wrapper_ih07p_1984 ._ods_form_header_ih07p_1987{
  padding-left:1.5rem;
  padding-right:1.5rem;
  padding-top:1.5rem
}

._ods_form_wrapper_ih07p_1984 ._ods_form_body_ih07p_1964>:not([hidden])~:not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top:calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom:calc(1.5rem * var(--tw-space-y-reverse))
}

._ods_form_wrapper_ih07p_1984 ._ods_form_body_ih07p_1964{
  padding-left:1.5rem;
  padding-right:1.5rem;
  padding-bottom:1.5rem
}

._ods_form_wrapper_ih07p_1984 ._ods_form_actions_ih07p_1957{
  border-bottom-right-radius:.5rem;
  border-bottom-left-radius:.5rem;
  --tw-bg-opacity: 1;
  background-color:rgba(249, 250, 251,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-50),var(--tw-bg-opacity));
  padding:.75rem 1rem;
  text-align:right
}

@media (min-width: 640px){
  ._ods_form_wrapper_ih07p_1984 ._ods_form_actions_ih07p_1957{
    padding-left:1.5rem;
    padding-right:1.5rem
  }
}

._ods_form_header_ih07p_1987{
  display:block
}

._ods_input_field_wrapper_ih07p_2001{
  width:100%
}

._ods_input_field_inset_container_ih07p_2004{
  border-radius:.375rem;
  border-width:1px;
  --tw-border-opacity: 1;
  border-color:rgba(209, 213, 219,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-300),var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color:rgba(255, 255, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-white),var(--tw-bg-opacity));
  padding:.5rem;
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / .05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow:0 0 #0000,0 0 #0000,var(--tw-shadow);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow)
}

._ods_input_field_inset_container_ih07p_2004:focus-within{
  z-index:10;
  --tw-border-opacity: 1;
  border-color:rgba(99, 102, 241,var(--tw-border-opacity));
  border-color:rgba(var(--color-primary-500),var(--tw-border-opacity));
  --tw-shadow: 0px 0px 0px 1px rgb(var(--color-primary-500));
  --tw-shadow-colored: 0px 0px 0px 1px var(--tw-shadow-color);
  box-shadow:0 0 #0000,0 0 #0000,var(--tw-shadow);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow)
}

._ods_input_field_inset_container_ih07p_2004:hover{
  --tw-border-opacity: 1;
  border-color:rgba(156, 163, 175,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-400),var(--tw-border-opacity))
}

._ods_input_field_inset_container_success_ih07p_2007{
  --tw-border-opacity: 1;
  border-color:rgba(110, 231, 183,var(--tw-border-opacity));
  border-color:rgba(var(--color-success-300),var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color:rgba(6, 78, 59,var(--tw-text-opacity));
  color:rgba(var(--color-success-900),var(--tw-text-opacity))
}

._ods_input_field_inset_container_success_ih07p_2007:focus-within{
  --tw-border-opacity: 1;
  border-color:rgba(16, 185, 129,var(--tw-border-opacity));
  border-color:rgba(var(--color-success-500),var(--tw-border-opacity));
  --tw-shadow: 0px 0px 0px 1px rgb(var(--color-success-500));
  --tw-shadow-colored: 0px 0px 0px 1px var(--tw-shadow-color);
  box-shadow:0 0 #0000,0 0 #0000,var(--tw-shadow);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow)
}

._ods_input_field_inset_container_success_ih07p_2007:hover{
  --tw-border-opacity: 1;
  border-color:rgba(52, 211, 153,var(--tw-border-opacity));
  border-color:rgba(var(--color-success-400),var(--tw-border-opacity))
}

._ods_input_field_inset_container_error_ih07p_2010{
  --tw-border-opacity: 1;
  border-color:rgba(252, 165, 165,var(--tw-border-opacity));
  border-color:rgba(var(--color-danger-300),var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color:rgba(127, 29, 29,var(--tw-text-opacity));
  color:rgba(var(--color-danger-900),var(--tw-text-opacity))
}

._ods_input_field_inset_container_error_ih07p_2010:focus-within{
  --tw-border-opacity: 1;
  border-color:rgba(239, 68, 68,var(--tw-border-opacity));
  border-color:rgba(var(--color-danger-500),var(--tw-border-opacity));
  --tw-shadow: 0px 0px 0px 1px rgb(var(--color-danger-500));
  --tw-shadow-colored: 0px 0px 0px 1px var(--tw-shadow-color);
  box-shadow:0 0 #0000,0 0 #0000,var(--tw-shadow);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow)
}

._ods_input_field_inset_container_error_ih07p_2010:hover{
  --tw-border-opacity: 1;
  border-color:rgba(248, 113, 113,var(--tw-border-opacity));
  border-color:rgba(var(--color-danger-400),var(--tw-border-opacity))
}

._ods_input_field_overlapping_ih07p_2013{
  position:relative
}

._ods_input_field_label_container_ih07p_2016{
  display:flex;
  justify-content:space-between
}

._ods_input_field_label_overlapping_ih07p_2019{
  position:absolute;
  top:-.75rem;
  margin-top:-.125rem;
  display:inline-block;
  --tw-bg-opacity: 1;
  background-color:rgba(255, 255, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-white),var(--tw-bg-opacity));
  padding-left:.25rem;
  padding-right:.25rem
}

._ods_input_field_label_pill_ih07p_2022{
  margin-left:1px;
  padding-left:1rem
}

._ods_input_field_text_ih07p_2025{
  margin-top:.25rem
}

._ods_input_field_helptext_ih07p_2028{
  margin-top:.5rem
}

._ods_pagination_ih07p_1009{
  display:flex;
  align-items:center;
  justify-content:space-between
}

._ods_pagination_simple_ih07p_2035{
  border-top-width:1px;
  --tw-border-opacity: 1;
  border-color:rgba(229, 231, 235,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-200),var(--tw-border-opacity));
  padding:.75rem 1rem
}

._ods_pagination_simple_mobile_ih07p_2038{
  display:none
}

@media (min-width: 640px){
  ._ods_pagination_simple_mobile_ih07p_2038{
    display:block
  }
}

._ods_pagination_simple_wrapper_ih07p_2041{
  display:flex;
  flex:1 1;
  justify-content:space-between
}

@media (min-width: 640px){
  ._ods_pagination_simple_wrapper_ih07p_2041{
    justify-content:flex-end
  }
}

._ods_pagination_button_ih07p_2044{
  border-top-width:1px;
  --tw-border-opacity: 1;
  border-color:rgba(229, 231, 235,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-200),var(--tw-border-opacity));
  padding:.75rem 1rem
}

._ods_pagination_button_mobile_ih07p_2047{
  display:flex;
  flex:1 1;
  justify-content:space-between
}

@media (min-width: 640px){
  ._ods_pagination_button_mobile_ih07p_2047{
    display:none
  }
}

._ods_pagination_button_wrapper_ih07p_2050{
  display:none
}

@media (min-width: 640px){
  ._ods_pagination_button_wrapper_ih07p_2050{
    display:flex;
    flex:1 1;
    align-items:center;
    justify-content:space-between
  }
}

._ods_pagination_button_range_ih07p_2053{
  position:relative;
  z-index:0;
  display:inline-flex
}

._ods_pagination_button_range_ih07p_2053>:not([hidden])~:not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right:calc(-1px * var(--tw-space-x-reverse));
  margin-left:calc(-1px * calc(1 - var(--tw-space-x-reverse)))
}

._ods_pagination_button_range_ih07p_2053{
  border-radius:.375rem;
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / .05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow:0 0 #0000,0 0 #0000,var(--tw-shadow);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow)
}

@media (min-width: 768px){
  ._ods_pagination_button_next_step_ih07p_2056{
    margin-left:.75rem
  }
}

._ods_pagination_link_ih07p_2059{
  border-top-width:1px;
  --tw-border-opacity: 1;
  border-color:rgba(229, 231, 235,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-200),var(--tw-border-opacity));
  padding-left:1rem;
  padding-right:1rem
}

@media (min-width: 640px){
  ._ods_pagination_link_ih07p_2059{
    padding-left:0;
    padding-right:0
  }
}

._ods_pagination_link_step_prev_ih07p_2062{
  display:flex;
  flex:1 1
}

._ods_pagination_link_step_next_ih07p_2065{
  display:flex;
  flex:1 1;
  justify-content:flex-end
}

._ods_pagination_link_wrapper_ih07p_2068{
  display:none
}

@media (min-width: 768px){
  ._ods_pagination_link_wrapper_ih07p_2068{
    margin-top:-1px;
    display:flex
  }
}

._ods_select_wrapper_ih07p_2072{
  z-index:30;
  width:100%
}

._ods_select_inline_ih07p_2075{
  z-index:30;
  display:inline-block
}

._ods_select_inline_ih07p_2075>div ul{
  right:0;
  width:-webkit-max-content;
  width:max-content
}

._ods_select_native_ih07p_2081{
  margin-top:.25rem;
  display:block;
  width:100%;
  -webkit-appearance:none;
  appearance:none;
  border-radius:.375rem;
  border-width:1px;
  --tw-border-opacity: 1;
  border-color:rgba(209, 213, 219,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-300),var(--tw-border-opacity));
  background-image:url(/static/media/Dropdown.d3b856ca.svg);
  background-size:.6rem .6rem;
  background-position:right .9rem center;
  background-repeat:no-repeat;
  padding:.5rem .5rem .5rem .75rem;
  font-size:1rem;
  line-height:1.5rem;
  outline:2px solid transparent;
  outline-offset:2px
}

._ods_select_native_ih07p_2081:hover{
  --tw-border-opacity: 1;
  border-color:rgba(156, 163, 175,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-400),var(--tw-border-opacity))
}

._ods_select_native_ih07p_2081:focus{
  --tw-border-opacity: 1;
  border-color:rgba(99, 102, 241,var(--tw-border-opacity));
  border-color:rgba(var(--color-primary-500),var(--tw-border-opacity));
  --tw-shadow: 0px 0px 0px 1px rgb(var(--color-primary-500));
  --tw-shadow-colored: 0px 0px 0px 1px var(--tw-shadow-color);
  box-shadow:0 0 #0000,0 0 #0000,var(--tw-shadow);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow)
}

@media (min-width: 640px){
  ._ods_select_native_ih07p_2081{
    font-size:.875rem;
    line-height:1.25rem
  }
}

._ods_select_container_ih07p_2085{
  position:relative;
  margin-top:.25rem;
  width:100%
}

._ods_select_disabled_ih07p_2088{
  opacity:.5
}

._ods_select_search_ih07p_2092{
  position:relative;
  margin-top:-.25rem;
  margin-bottom:.5rem;
  cursor:default;
  -webkit-user-select:none;
  -ms-user-select:none;
  user-select:none;
  border-bottom-width:1px;
  --tw-border-opacity: 1;
  border-color:rgba(229, 231, 235,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-200),var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color:rgba(249, 250, 251,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-50),var(--tw-bg-opacity));
  padding:.5rem
}

._ods_notification_body_wrapper_with_avatar_ih07p_2096{
  width:100%;
  flex:1 1
}

._ods_notification_body_wrapper_ih07p_2096 ._ods_notification_body_ih07p_2096{
  display:flex;
  align-items:flex-start
}

._ods_notification_body_wrapper_ih07p_2096 ._ods_notification_body_icon_wrapper_ih07p_2102{
  flex-shrink:0
}

._ods_notification_body_wrapper_ih07p_2096 ._ods_notification_body_icon_wrapper_ih07p_2102 svg{
  height:1.5rem;
  width:1.5rem
}

._ods_notification_body_wrapper_ih07p_2096 ._ods_notification_body_avatar_wrapper_ih07p_2108{
  flex-shrink:0;
  padding-top:.125rem
}

._ods_notification_body_wrapper_ih07p_2096 ._ods_notification_body_content_ih07p_2111{
  width:100%
}

._ods_notification_body_wrapper_ih07p_2096 ._ods_notification_body_content_inline_ih07p_2114{
  display:flex;
  flex:1 1;
  justify-content:space-between
}

._ods_notification_body_wrapper_ih07p_2096 ._ods_notification_body_content_inline_ih07p_2114 ._ods_notification_body_title_ih07p_2117{
  width:0px;
  flex:1 1
}

._ods_notification_body_wrapper_ih07p_2096 ._ods_notification_body_content_inline_ih07p_2114 button{
  margin-left:.75rem
}

._ods_notification_body_wrapper_ih07p_2096 ._ods_notification_body_content_block_ih07p_2123{
  margin-left:.75rem;
  flex:1 1;
  padding-top:.125rem
}

._ods_notification_body_wrapper_ih07p_2096 ._ods_notification_body_content_block_ih07p_2123._ods_notification_body_content_block_avatar_ih07p_2126{
  padding-top:0
}

._ods_notification_body_wrapper_ih07p_2096 ._ods_notification_body_content_ih07p_2111 ._ods_notification_body_title_ih07p_2117{
  font-size:.875rem;
  line-height:1.25rem;
  font-weight:500;
  --tw-text-opacity: 1;
  color:rgba(17, 24, 39,var(--tw-text-opacity));
  color:rgba(var(--color-dark-900),var(--tw-text-opacity))
}

._ods_notification_body_wrapper_ih07p_2096 ._ods_notification_body_content_ih07p_2111 ._ods_notification_body_title_ih07p_2117+p{
  margin-top:.25rem;
  font-size:.875rem;
  line-height:1.25rem;
  --tw-text-opacity: 1;
  color:rgba(107, 114, 128,var(--tw-text-opacity));
  color:rgba(var(--color-dark-500),var(--tw-text-opacity))
}

._ods_notification_body_wrapper_ih07p_2096 ._ods_notification_body_dismiss_ih07p_2135{
  margin-left:1rem;
  display:flex;
  flex-shrink:0
}

._ods_notification_body_wrapper_ih07p_2096 ._ods_notification_body_dismiss_ih07p_2135 button svg{
  height:1.25rem;
  width:1.25rem;
  --tw-text-opacity: 1;
  color:rgba(156, 163, 175,var(--tw-text-opacity));
  color:rgba(var(--color-light-400),var(--tw-text-opacity))
}

._ods_notification_body_wrapper_ih07p_2096 ._ods_notification_body_dismiss_ih07p_2135 button svg:hover{
  --tw-text-opacity: 1;
  color:rgba(107, 114, 128,var(--tw-text-opacity));
  color:rgba(var(--color-dark-500),var(--tw-text-opacity))
}

._ods_page_heading_ih07p_1249{
  padding-top:0;
  padding-bottom:0
}

@media (min-width: 640px){
  ._ods_page_heading_ih07p_1249{
    padding-top:2rem;
    padding-bottom:2rem
  }
}

@media (min-width: 768px){
  ._ods_page_heading_ih07p_1249{
    display:flex;
    justify-content:space-between
  }

  ._ods_page_heading_start_ih07p_2145{
    align-items:flex-start
  }

  ._ods_page_heading_center_ih07p_2148{
    align-items:center
  }

  ._ods_page_heading_end_ih07p_2151{
    align-items:flex-end
  }
}

._ods_page_heading_ih07p_1249 nav{
  margin-left:-.5rem;
  height:auto
}

@media (min-width: 640px){
  ._ods_page_heading_ih07p_1249 nav{
    margin-left:-1rem
  }
}

._ods_page_heading_light_ih07p_2157{
  --tw-bg-opacity: 1;
  background-color:rgba(255, 255, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-white),var(--tw-bg-opacity))
}

._ods_page_heading_transparent_ih07p_2160{
  background-color:transparent
}

._ods_page_heading_dark_ih07p_2163{
  --tw-bg-opacity: 1;
  background-color:rgba(31, 41, 55,var(--tw-bg-opacity));
  background-color:rgba(var(--color-dark-800),var(--tw-bg-opacity))
}

._ods_page_heading_dark_ih07p_2163 a{
  --tw-text-opacity: 1;
  color:rgba(156, 163, 175,var(--tw-text-opacity));
  color:rgba(var(--color-light-400),var(--tw-text-opacity))
}

._ods_page_heading_dark_ih07p_2163 a:hover{
  --tw-text-opacity: 1;
  color:rgba(229, 231, 235,var(--tw-text-opacity));
  color:rgba(var(--color-light-200),var(--tw-text-opacity))
}

._ods_page_heading_dark_ih07p_2163 h1{
  --tw-text-opacity: 1;
  color:rgba(255, 255, 255,var(--tw-text-opacity));
  color:rgba(var(--color-white),var(--tw-text-opacity))
}

._ods_page_heading_dark_ih07p_2163 svg{
  --tw-text-opacity: 1;
  color:rgba(107, 114, 128,var(--tw-text-opacity));
  color:rgba(var(--color-dark-500),var(--tw-text-opacity))
}

._ods_page_heading_dark_ih07p_2163 button:focus{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),0 0 #0000;
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),var(--tw-shadow, 0 0 #0000);
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: rgb(var(--color-dark-800))
}

._ods_page_heading_dark_ih07p_2163 button svg{
  --tw-text-opacity: 1;
  color:rgba(209, 213, 219,var(--tw-text-opacity));
  color:rgba(var(--color-light-300),var(--tw-text-opacity))
}

._ods_page_heading_dark_ih07p_2163 ol{
  --tw-bg-opacity: 1;
  background-color:rgba(31, 41, 55,var(--tw-bg-opacity));
  background-color:rgba(var(--color-dark-800),var(--tw-bg-opacity))
}

._ods_page_heading_dark_ih07p_2163 ol li a{
  --tw-text-opacity: 1;
  color:rgba(156, 163, 175,var(--tw-text-opacity));
  color:rgba(var(--color-light-400),var(--tw-text-opacity))
}

._ods_stacked_list_ih07p_1257>:not([hidden])~:not([hidden]){
  --tw-divide-y-reverse: 0;
  border-top-width:calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width:calc(1px * var(--tw-divide-y-reverse));
  --tw-divide-opacity: 1;
  border-color:rgba(229, 231, 235,var(--tw-divide-opacity));
  border-color:rgba(var(--color-light-200),var(--tw-divide-opacity))
}

._ods_stacked_list_wrapper_ih07p_2191{
  --tw-bg-opacity: 1;
  background-color:rgba(255, 255, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-white),var(--tw-bg-opacity))
}

@media (min-width: 640px){
  ._ods_stacked_list_wrapper_ih07p_2191{
    border-radius:.375rem
  }
}

._ods_stacked_list_wrapper_flex_ih07p_2194{
  display:flex
}

._ods_stacked_list_wrapper_justified_ih07p_2197{
  justify-content:space-between
}

._ods_radio_group_ih07p_2201{
  display:flex;
  flex-direction:column
}

._ods_radio_group_divided_ih07p_2204>:not([hidden])~:not([hidden]){
  --tw-divide-y-reverse: 0;
  border-top-width:calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width:calc(1px * var(--tw-divide-y-reverse));
  --tw-divide-opacity: 1;
  border-color:rgba(229, 231, 235,var(--tw-divide-opacity));
  border-color:rgba(var(--color-light-200),var(--tw-divide-opacity))
}

._ods_radio_group_divided_ih07p_2204>div{
  padding-top:1rem;
  padding-bottom:1rem
}

._ods_radio_group_border_ih07p_2210>:not([hidden])~:not([hidden]){
  --tw-divide-y-reverse: 0;
  border-top-width:calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width:calc(1px * var(--tw-divide-y-reverse));
  --tw-divide-opacity: 1;
  border-color:rgba(229, 231, 235,var(--tw-divide-opacity));
  border-color:rgba(var(--color-light-200),var(--tw-divide-opacity))
}

._ods_radio_group_border_ih07p_2210{
  border-top-width:1px;
  border-bottom-width:1px;
  --tw-border-opacity: 1;
  border-color:rgba(229, 231, 235,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-200),var(--tw-border-opacity))
}

._ods_radio_group_border_ih07p_2210>div{
  padding-top:1rem;
  padding-bottom:1rem
}

._ods_radio_group_header_title_ih07p_2216{
  font-size:1.125rem;
  font-weight:500;
  line-height:1.5rem;
  --tw-text-opacity: 1;
  color:rgba(17, 24, 39,var(--tw-text-opacity));
  color:rgba(var(--color-dark-900),var(--tw-text-opacity))
}

._ods_radio_group_header_help_text_ih07p_2219{
  margin-top:.25rem;
  font-size:.875rem;
  line-height:1.25rem;
  --tw-text-opacity: 1;
  color:rgba(107, 114, 128,var(--tw-text-opacity));
  color:rgba(var(--color-dark-500),var(--tw-text-opacity))
}

._ods_radio_group_header_inline_ih07p_2222{
  display:flex;
  align-items:center;
  justify-content:space-between
}

._ods_radio_group_card_ih07p_2225{
  position:relative;
  display:flex;
  cursor:pointer;
  border-radius:.5rem;
  border-width:1px;
  --tw-bg-opacity: 1;
  background-color:rgba(255, 255, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-white),var(--tw-bg-opacity));
  padding:1rem;
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / .05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow:0 0 #0000,0 0 #0000,var(--tw-shadow);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow)
}

._ods_radio_group_card_ih07p_2225:focus{
  outline:2px solid transparent;
  outline-offset:2px
}

._ods_radio_group_card_stacked_ih07p_2228{
  position:relative;
  z-index:10;
  margin-right:0;
  display:block;
  width:100%;
  cursor:pointer;
  border-width:1px;
  --tw-bg-opacity: 1;
  background-color:rgba(255, 255, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-white),var(--tw-bg-opacity));
  padding:1rem 1.5rem;
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / .05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow:0 0 #0000,0 0 #0000,var(--tw-shadow);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow)
}

._ods_radio_group_card_stacked_ih07p_2228:focus{
  outline:2px solid transparent;
  outline-offset:2px
}

@media (min-width: 640px){
  ._ods_radio_group_card_stacked_ih07p_2228{
    display:flex;
    justify-content:space-between
  }
}

._ods_radio_group_card_table_ih07p_2231{
  position:relative;
  display:flex;
  width:100%;
  cursor:pointer;
  flex-direction:column;
  border-width:1px;
  padding:1rem
}

._ods_radio_group_card_table_ih07p_2231:focus{
  outline:2px solid transparent;
  outline-offset:2px
}

@media (min-width: 768px){
  ._ods_radio_group_card_table_ih07p_2231{
    display:grid;
    grid-template-columns:repeat(3,minmax(0,1fr));
    padding-left:1rem;
    padding-right:1.5rem
  }
}

._ods_radio_group_card_table_checked_ih07p_2234{
  z-index:10;
  --tw-border-opacity: 1;
  border-color:rgba(199, 210, 254,var(--tw-border-opacity));
  border-color:rgba(var(--color-primary-200),var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color:rgba(238, 242, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-50),var(--tw-bg-opacity))
}

._ods_radio_group_card_table_checked_ih07p_2234 p{
  --tw-text-opacity: 1;
  color:rgba(67, 56, 202,var(--tw-text-opacity));
  color:rgba(var(--color-primary-700),var(--tw-text-opacity))
}

._ods_radio_group_card_table_checked_ih07p_2234 input{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),0 0 #0000;
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(var(--color-primary-500), var(--tw-ring-opacity));
  --tw-ring-offset-width: 2px
}

._ods_radio_group_card_table_checked_ih07p_2234>div :last-child{
  --tw-text-opacity: 1;
  color:rgba(67, 56, 202,var(--tw-text-opacity));
  color:rgba(var(--color-primary-700),var(--tw-text-opacity))
}

._ods_radio_group_card_table_unchecked_ih07p_2246{
  --tw-border-opacity: 1;
  border-color:rgba(229, 231, 235,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-200),var(--tw-border-opacity))
}

._ods_radio_group_card_table_unchecked_ih07p_2246 label{
  --tw-text-opacity: 1;
  color:rgba(17, 24, 39,var(--tw-text-opacity));
  color:rgba(var(--color-dark-900),var(--tw-text-opacity))
}

._ods_radio_group_card_table_unchecked_ih07p_2246>div:last-child{
  --tw-text-opacity: 1;
  color:rgba(107, 114, 128,var(--tw-text-opacity));
  color:rgba(var(--color-dark-500),var(--tw-text-opacity))
}

._ods_radio_group_card_icon_ih07p_2255{
  height:1.25rem;
  width:1.25rem;
  --tw-text-opacity: 1;
  color:rgba(79, 70, 229,var(--tw-text-opacity));
  color:rgba(var(--color-primary-600),var(--tw-text-opacity))
}

._ods_radio_group_card_icon_unchecked_ih07p_2258{
  visibility:hidden
}

._ods_radio_group_card_wrapper_default_ih07p_2261{
  display:flex;
  flex:1 1
}

._ods_radio_group_card_wrapper_stacked_ih07p_2264{
  display:flex;
  width:100%;
  align-items:center;
  justify-content:center
}

._ods_radio_group_card_children_wrapper_default_ih07p_2267{
  display:flex;
  flex-direction:column;
  text-align:left
}

._ods_radio_group_card_children_wrapper_stacked_ih07p_2270{
  width:100%;
  text-align:left;
  font-size:.875rem;
  line-height:1.25rem
}

@media (min-width: 640px){
  ._ods_radio_group_card_children_wrapper_stacked_ih07p_2270{
    display:flex;
    justify-content:space-between
  }
}

._ods_radio_group_card_checked_ih07p_2273{
  border-color:transparent
}

._ods_radio_group_card_unchecked_ih07p_2276{
  --tw-border-opacity: 1;
  border-color:rgba(209, 213, 219,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-300),var(--tw-border-opacity))
}

._ods_radio_group_card_active_ih07p_2279{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),0 0 #0000;
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(var(--color-primary-500), var(--tw-ring-opacity))
}

._ods_radio_group_card_absolute_ih07p_2282{
  pointer-events:none;
  position:absolute;
  inset:-1px
}

._ods_radio_group_card_absolute_rounded_ih07p_2285{
  border-radius:.5rem
}

._ods_radio_group_card_absolute_active_ih07p_2288{
  border-width:1px
}

._ods_radio_group_card_absolute_inactive_ih07p_2291{
  border-width:2px
}

._ods_radio_group_card_absolute_checked_ih07p_2294{
  --tw-border-opacity: 1;
  border-color:rgba(99, 102, 241,var(--tw-border-opacity));
  border-color:rgba(var(--color-primary-500),var(--tw-border-opacity))
}

._ods_radio_group_card_absolute_unchecked_ih07p_2297{
  border-color:transparent
}

._ods_radio_group_card_small_ih07p_2300{
  display:flex;
  align-items:center;
  justify-content:center;
  border-radius:.375rem;
  border-width:1px;
  padding:.75rem;
  font-size:.875rem;
  line-height:1.25rem;
  font-weight:500;
  text-transform:uppercase
}

@media (min-width: 640px){
  ._ods_radio_group_card_small_ih07p_2300{
    flex:1 1
  }
}

._ods_radio_group_card_small_active_ih07p_2303{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),0 0 #0000;
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(var(--color-primary-500), var(--tw-ring-opacity));
  --tw-ring-offset-width: 2px
}

._ods_radio_group_card_small_checked_ih07p_2306{
  border-color:transparent;
  --tw-bg-opacity: 1;
  background-color:rgba(79, 70, 229,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-600),var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:rgba(255, 255, 255,var(--tw-text-opacity));
  color:rgba(var(--color-white),var(--tw-text-opacity))
}

._ods_radio_group_card_small_checked_ih07p_2306:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(67, 56, 202,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-700),var(--tw-bg-opacity))
}

._ods_radio_group_card_small_unchecked_ih07p_2309{
  --tw-border-opacity: 1;
  border-color:rgba(229, 231, 235,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-200),var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color:rgba(255, 255, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-white),var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:rgba(17, 24, 39,var(--tw-text-opacity));
  color:rgba(var(--color-dark-900),var(--tw-text-opacity))
}

._ods_radio_group_card_small_unchecked_ih07p_2309:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(249, 250, 251,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-50),var(--tw-bg-opacity))
}

._ods_radio_group_card_small_enabled_ih07p_2312{
  cursor:pointer
}

._ods_radio_group_card_small_enabled_ih07p_2312:focus{
  outline:2px solid transparent;
  outline-offset:2px
}

._ods_radio_group_card_small_disabled_ih07p_2315{
  cursor:not-allowed;
  opacity:.25
}

._ods_radio_group_body_ih07p_2318{
  margin-top:1rem
}

._ods_radio_group_body_ih07p_2318>:not([hidden])~:not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top:calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom:calc(1rem * var(--tw-space-y-reverse))
}

._ods_radio_group_body_no_space_ih07p_2321>:not([hidden])~:not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top:calc(0px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom:calc(0px * var(--tw-space-y-reverse))
}

._ods_radio_group_body_table_wrapper_ih07p_2324{
  position:relative
}

._ods_radio_group_body_table_wrapper_ih07p_2324>:not([hidden])~:not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top:calc(-1px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom:calc(-1px * var(--tw-space-y-reverse))
}

._ods_radio_group_body_table_wrapper_ih07p_2324{
  --tw-bg-opacity: 1;
  background-color:rgba(255, 255, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-white),var(--tw-bg-opacity))
}

._ods_radio_group_body_grid_small_ih07p_2327{
  display:grid;
  grid-template-columns:repeat(3,minmax(0,1fr));
  grid-gap:.75rem;
  gap:.75rem
}

@media (min-width: 640px){
  ._ods_radio_group_body_grid_small_ih07p_2327{
    grid-template-columns:repeat(6,minmax(0,1fr))
  }
}

._ods_radio_group_body_grid_ih07p_2327{
  margin-top:1rem;
  display:grid;
  grid-template-columns:repeat(1,minmax(0,1fr));
  grid-row-gap:1.5rem;
  row-gap:1.5rem
}

@media (min-width: 640px){
  ._ods_radio_group_body_grid_ih07p_2327{
    grid-template-columns:repeat(3,minmax(0,1fr));
    -moz-column-gap:1rem;
    grid-column-gap:1rem;
    -webkit-column-gap:1rem;
    column-gap:1rem
  }
}

._ods_radio_group_body_rounded_cards_ih07p_2333>div>button:first-child{
  border-top-left-radius:.375rem;
  border-top-right-radius:.375rem;
  border-bottom-right-radius:0;
  border-bottom-left-radius:0
}

._ods_radio_group_body_rounded_cards_ih07p_2333>div>button:not(:first-child,:last-child){
  border-radius:0
}

._ods_radio_group_body_rounded_cards_ih07p_2333>div>button:last-child{
  border-bottom-right-radius:.375rem;
  border-bottom-left-radius:.375rem;
  border-top-left-radius:0;
  border-top-right-radius:0
}

._ods_radio_group_body_table_rounded_ih07p_2342>div>button:first-child>div:last-child{
  border-top-left-radius:.375rem;
  border-top-right-radius:.375rem;
  border-bottom-right-radius:0;
  border-bottom-left-radius:0
}

._ods_radio_group_body_table_rounded_ih07p_2342>div>button:last-child>div:last-child{
  border-top-left-radius:0;
  border-top-right-radius:0;
  border-bottom-right-radius:.375rem;
  border-bottom-left-radius:.375rem
}

._ods_radio_group_body_inline_ih07p_2348>:not([hidden])~:not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right:calc(0px * var(--tw-space-x-reverse));
  margin-left:calc(0px * calc(1 - var(--tw-space-x-reverse)));
  --tw-space-y-reverse: 0;
  margin-top:calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom:calc(1rem * var(--tw-space-y-reverse))
}

@media (min-width: 640px){
  ._ods_radio_group_body_inline_ih07p_2348{
    display:flex;
    align-items:center
  }

  ._ods_radio_group_body_inline_ih07p_2348>:not([hidden])~:not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right:calc(2.5rem * var(--tw-space-x-reverse));
    margin-left:calc(2.5rem * calc(1 - var(--tw-space-x-reverse)));
    --tw-space-y-reverse: 0;
    margin-top:calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(0px * var(--tw-space-y-reverse))
  }
}

._ods_radio_group_body_position_leading_ih07p_2351>div{
  align-items:flex-start
}

._ods_radio_group_body_position_center_ih07p_2354>div{
  align-items:center
}

._ods_radio_group_body_position_trailing_ih07p_2357>div{
  justify-content:space-between
}

@media (min-width: 640px){
  ._ods_tab_mobile_ih07p_2361{
    display:none
  }
}

._ods_tab_select_item_default_ih07p_2364{
  --tw-text-opacity: 1;
  color:rgba(55, 65, 81,var(--tw-text-opacity));
  color:rgba(var(--color-dark-700),var(--tw-text-opacity))
}

._ods_table_header_ih07p_2368{
  border-bottom-width:1px;
  --tw-border-opacity: 1;
  border-color:rgba(229, 231, 235,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-200),var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color:rgba(249, 250, 251,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-50),var(--tw-bg-opacity))
}

._ods_table_header_ih07p_2368 th:last-child{
  position:relative;
  padding:.75rem 1.5rem
}

._ods_table_body_ih07p_2375>:not([hidden])~:not([hidden]){
  --tw-divide-y-reverse: 0;
  border-top-width:calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width:calc(1px * var(--tw-divide-y-reverse));
  --tw-divide-opacity: 1;
  border-color:rgba(229, 231, 235,var(--tw-divide-opacity));
  border-color:rgba(var(--color-light-200),var(--tw-divide-opacity))
}

._ods_table_body_ih07p_2375{
  --tw-bg-opacity: 1;
  background-color:rgba(255, 255, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-white),var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:rgba(17, 24, 39,var(--tw-text-opacity));
  color:rgba(var(--color-dark-900),var(--tw-text-opacity))
}

._ods_slide_over_body_ih07p_2379{
  position:relative;
  margin-top:1.5rem;
  flex:1 1;
  overflow-y:auto;
  padding-left:1rem;
  padding-right:1rem
}

@media (min-width: 640px){
  ._ods_slide_over_body_ih07p_2379{
    padding-left:1.5rem;
    padding-right:1.5rem
  }
}

._ods_slide_over_body_wrapper_ih07p_2382{
  position:absolute;
  inset:0;
  padding-left:1rem;
  padding-right:1rem;
  padding-bottom:1.5rem
}

@media (min-width: 640px){
  ._ods_slide_over_body_wrapper_ih07p_2382{
    padding-left:1.5rem;
    padding-right:1.5rem
  }
}

._ods_slide_over_body_wrapper_divided_ih07p_2385>:not([hidden])~:not([hidden]){
  --tw-divide-y-reverse: 0;
  border-top-width:calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width:calc(1px * var(--tw-divide-y-reverse));
  --tw-divide-opacity: 1;
  border-color:rgb(229 231 235 / var(--tw-divide-opacity))
}

._ods_slide_over_header_ih07p_2389{
  padding-left:1rem;
  padding-right:1rem;
  padding-top:1.5rem
}

@media (min-width: 640px){
  ._ods_slide_over_header_ih07p_2389{
    padding-left:1.5rem;
    padding-right:1.5rem
  }
}

._ods_slide_over_header_branded_ih07p_2392{
  --tw-bg-opacity: 1;
  background-color:rgba(67, 56, 202,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-700),var(--tw-bg-opacity));
  padding:1.5rem 1rem
}

@media (min-width: 640px){
  ._ods_slide_over_header_branded_ih07p_2392{
    padding-left:1.5rem;
    padding-right:1.5rem
  }
}

._ods_slide_over_header_branded_ih07p_2392 h2{
  --tw-text-opacity: 1;
  color:rgba(255, 255, 255,var(--tw-text-opacity));
  color:rgba(var(--color-white),var(--tw-text-opacity))
}

._ods_slide_over_header_branded_secondary_ih07p_2398{
  --tw-bg-opacity: 1;
  background-color:rgba(249, 250, 251,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-50),var(--tw-bg-opacity))
}

._ods_slide_over_header_branded_wrapper_ih07p_2401{
  display:flex;
  height:100%;
  flex-direction:column
}

._ods_slide_over_header_title_ih07p_2404{
  font-size:1.125rem;
  line-height:1.75rem;
  font-weight:500;
  --tw-text-opacity: 1;
  color:rgba(17, 24, 39,var(--tw-text-opacity));
  color:rgba(var(--color-dark-900),var(--tw-text-opacity))
}

._ods_slide_over_header_title_branded_ih07p_2407{
  --tw-text-opacity: 1;
  color:rgba(255, 255, 255,var(--tw-text-opacity));
  color:rgba(var(--color-white),var(--tw-text-opacity))
}

._ods_slide_over_header_help_text_ih07p_2410{
  font-size:.875rem;
  line-height:1.25rem;
  --tw-text-opacity: 1;
  color:rgba(165, 180, 252,var(--tw-text-opacity));
  color:rgba(var(--color-primary-300),var(--tw-text-opacity))
}

._ods_slide_over_header_button_inside_ih07p_2413{
  border-radius:.375rem;
  border-style:none;
  --tw-bg-opacity: 1;
  background-color:rgba(255, 255, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-white),var(--tw-bg-opacity));
  padding:0;
  --tw-text-opacity: 1;
  color:rgba(156, 163, 175,var(--tw-text-opacity));
  color:rgba(var(--color-light-400),var(--tw-text-opacity))
}

._ods_slide_over_header_button_inside_ih07p_2413:hover{
  --tw-text-opacity: 1;
  color:rgba(107, 114, 128,var(--tw-text-opacity));
  color:rgba(var(--color-dark-500),var(--tw-text-opacity))
}

._ods_slide_over_header_button_inside_ih07p_2413:focus{
  outline:2px solid transparent;
  outline-offset:2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),0 0 #0000;
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(var(--color-primary-500), var(--tw-ring-opacity));
  --tw-ring-offset-width: 2px
}

._ods_slide_over_header_button_inside_branded_ih07p_2416{
  border-radius:.375rem;
  border-style:none;
  --tw-bg-opacity: 1;
  background-color:rgba(67, 56, 202,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-700),var(--tw-bg-opacity));
  padding:0;
  --tw-text-opacity: 1;
  color:rgba(199, 210, 254,var(--tw-text-opacity));
  color:rgba(var(--color-primary-200),var(--tw-text-opacity))
}

._ods_slide_over_header_button_inside_branded_ih07p_2416:hover{
  --tw-text-opacity: 1;
  color:rgba(255, 255, 255,var(--tw-text-opacity));
  color:rgba(var(--color-white),var(--tw-text-opacity))
}

._ods_slide_over_header_button_inside_branded_ih07p_2416:focus{
  outline:2px solid transparent;
  outline-offset:2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),0 0 #0000;
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(var(--color-white), var(--tw-ring-opacity));
  --tw-ring-offset-width: 0px
}

._ods_slide_over_header_button_outside_ih07p_2419{
  border-radius:.375rem;
  --tw-text-opacity: 1;
  color:rgba(209, 213, 219,var(--tw-text-opacity));
  color:rgba(var(--color-light-300),var(--tw-text-opacity))
}

._ods_slide_over_header_button_outside_ih07p_2419:hover{
  --tw-text-opacity: 1;
  color:rgba(255, 255, 255,var(--tw-text-opacity));
  color:rgba(var(--color-white),var(--tw-text-opacity))
}

._ods_slide_over_header_button_outside_ih07p_2419:focus{
  outline:2px solid transparent;
  outline-offset:2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),0 0 #0000;
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(var(--color-white), var(--tw-ring-opacity))
}

._ods_slide_over_header_button_icon_ih07p_2422{
  height:1.5rem;
  width:1.5rem
}

._ods_slide_over_header_button_wrapper_inside_ih07p_2425{
  margin-left:.75rem;
  display:flex;
  height:1.75rem;
  align-items:center
}

._ods_slide_over_header_button_wrapper_outside_ih07p_2428{
  position:absolute;
  top:0;
  left:0;
  margin-left:-2rem;
  display:flex;
  padding-top:1rem;
  padding-right:.5rem
}

@media (min-width: 640px){
  ._ods_slide_over_header_button_wrapper_outside_ih07p_2428{
    margin-left:-2.5rem;
    padding-right:1rem
  }
}

._ods_slide_over_header_wrapper_ih07p_2431{
  display:flex;
  align-items:flex-start;
  justify-content:space-between
}

._ods_step_item_bullet_ih07p_2435._ods_step_item_complete_ih07p_2435{
  display:block;
  height:.625rem;
  width:.625rem;
  border-radius:9999px;
  --tw-bg-opacity: 1;
  background-color:rgba(79, 70, 229,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-600),var(--tw-bg-opacity))
}

._ods_step_item_bullet_ih07p_2435._ods_step_item_complete_ih07p_2435:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(49, 46, 129,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-900),var(--tw-bg-opacity))
}

._ods_step_item_bullet_ih07p_2435._ods_step_item_current_ih07p_2438{
  position:relative;
  display:flex;
  align-items:center;
  justify-content:center
}

._ods_step_item_bullet_ih07p_2435._ods_step_item_upcoming_ih07p_2441{
  display:block;
  height:.625rem;
  width:.625rem;
  border-radius:9999px;
  --tw-bg-opacity: 1;
  background-color:rgba(229, 231, 235,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-200),var(--tw-bg-opacity))
}

._ods_step_item_bullet_ih07p_2435._ods_step_item_upcoming_ih07p_2441:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(156, 163, 175,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-400),var(--tw-bg-opacity))
}

._ods_step_item_bulletWithText_ih07p_2445._ods_step_item_current_ih07p_2438{
  display:flex;
  align-items:flex-start
}

._ods_step_item_bulletWithText_ih07p_2445 ._ods_step_item_name_ih07p_2448{
  margin-left:.75rem;
  font-size:.875rem;
  line-height:1.25rem;
  font-weight:500
}

._ods_step_item_bulletWithText_ih07p_2445 ._ods_step_item_name_ih07p_2448._ods_step_item_complete_ih07p_2435{
  --tw-text-opacity: 1;
  color:rgba(107, 114, 128,var(--tw-text-opacity));
  color:rgba(var(--color-dark-500),var(--tw-text-opacity))
}

._ods_step_item_bulletWithText_ih07p_2445 ._ods_step_item_name_ih07p_2448._ods_step_item_current_ih07p_2438{
  --tw-text-opacity: 1;
  color:rgba(79, 70, 229,var(--tw-text-opacity));
  color:rgba(var(--color-primary-600),var(--tw-text-opacity))
}

._ods_step_item_bulletWithText_ih07p_2445 ._ods_step_item_name_ih07p_2448._ods_step_item_upcoming_ih07p_2441{
  --tw-text-opacity: 1;
  color:rgba(107, 114, 128,var(--tw-text-opacity));
  color:rgba(var(--color-dark-500),var(--tw-text-opacity))
}

._ods_step_item_bulletWithText_ih07p_2445:hover ._ods_step_item_bullet_wrapper_ih07p_2460 svg{
  --tw-text-opacity: 1;
  color:rgba(55, 48, 163,var(--tw-text-opacity));
  color:rgba(var(--color-primary-800),var(--tw-text-opacity))
}

._ods_step_item_bulletWithText_ih07p_2445:hover ._ods_step_item_bullet_wrapper_ih07p_2460 div{
  --tw-bg-opacity: 1;
  background-color:rgba(156, 163, 175,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-400),var(--tw-bg-opacity))
}

._ods_step_item_bulletWithText_ih07p_2445:hover ._ods_step_item_name_ih07p_2448._ods_step_item_complete_ih07p_2435,._ods_step_item_bulletWithText_ih07p_2445:hover ._ods_step_item_name_ih07p_2448._ods_step_item_upcoming_ih07p_2441{
  --tw-text-opacity: 1;
  color:rgba(17, 24, 39,var(--tw-text-opacity));
  color:rgba(var(--color-dark-900),var(--tw-text-opacity))
}

._ods_step_item_bullet_ih07p_2435,._ods_step_item_bulletWithText_ih07p_2445{
  outline:2px solid transparent;
  outline-offset:2px
}

._ods_step_item_bullet_ih07p_2435 ._ods_step_item_bullet_wrapper_ih07p_2460,._ods_step_item_bulletWithText_ih07p_2445 ._ods_step_item_bullet_wrapper_ih07p_2460{
  display:flex;
  align-items:flex-start
}

._ods_step_item_circle_ih07p_2479{
  position:relative;
  display:flex;
  height:2rem;
  width:2rem;
  align-items:center;
  justify-content:center;
  border-radius:9999px;
  outline:2px solid transparent;
  outline-offset:2px
}

._ods_step_item_circle_ih07p_2479._ods_step_item_complete_ih07p_2435{
  --tw-bg-opacity: 1;
  background-color:rgba(79, 70, 229,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-600),var(--tw-bg-opacity))
}

._ods_step_item_circle_ih07p_2479._ods_step_item_complete_ih07p_2435:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(49, 46, 129,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-900),var(--tw-bg-opacity))
}

._ods_step_item_circle_ih07p_2479._ods_step_item_current_ih07p_2438{
  border-width:2px;
  --tw-border-opacity: 1;
  border-color:rgba(79, 70, 229,var(--tw-border-opacity));
  border-color:rgba(var(--color-primary-600),var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color:rgba(255, 255, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-white),var(--tw-bg-opacity))
}

._ods_step_item_circle_ih07p_2479._ods_step_item_current_ih07p_2438 ._ods_stepCircle_circle_ih07p_1368{
  --tw-bg-opacity: 1;
  background-color:rgba(79, 70, 229,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-600),var(--tw-bg-opacity))
}

._ods_step_item_circle_ih07p_2479._ods_step_item_upcoming_ih07p_2441{
  border-width:2px;
  --tw-border-opacity: 1;
  border-color:rgba(209, 213, 219,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-300),var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color:rgba(255, 255, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-white),var(--tw-bg-opacity))
}

._ods_step_item_circle_ih07p_2479._ods_step_item_upcoming_ih07p_2441:hover{
  --tw-border-opacity: 1;
  border-color:rgba(156, 163, 175,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-400),var(--tw-border-opacity))
}

._ods_step_item_circle_ih07p_2479._ods_step_item_upcoming_ih07p_2441 ._ods_stepCircle_circle_ih07p_1368{
  background-color:transparent
}

._ods_step_item_circle_ih07p_2479._ods_step_item_upcoming_ih07p_2441 ._ods_stepCircle_circle_ih07p_1368:hover,._ods_step_item_circle_ih07p_2479._ods_step_item_upcoming_ih07p_2441:hover ._ods_stepCircle_circle_ih07p_1368{
  --tw-bg-opacity: 1;
  background-color:rgba(209, 213, 219,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-300),var(--tw-bg-opacity))
}

._ods_step_item_circle_withText_ih07p_2501{
  position:relative;
  display:flex;
  align-items:flex-start;
  outline:2px solid transparent;
  outline-offset:2px
}

._ods_step_item_circle_withText_content_ih07p_2504{
  margin-left:1rem;
  display:flex;
  min-width:0px;
  flex-direction:column
}

._ods_step_item_circle_withText_content_ih07p_2504 ._ods_step_item_name_ih07p_2448{
  font-size:.75rem;
  line-height:1rem;
  font-weight:600;
  text-transform:uppercase;
  letter-spacing:.025em
}

._ods_step_item_circle_withText_content_ih07p_2504 ._ods_step_item_description_ih07p_2510{
  font-size:.875rem;
  line-height:1.25rem;
  --tw-text-opacity: 1;
  color:rgba(107, 114, 128,var(--tw-text-opacity));
  color:rgba(var(--color-dark-500),var(--tw-text-opacity))
}

._ods_step_item_circle_withText_ih07p_2501._ods_step_item_complete_ih07p_2435 ._ods_stepCircle_circle_innerWrapper_ih07p_1374{
  --tw-bg-opacity: 1;
  background-color:rgba(79, 70, 229,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-600),var(--tw-bg-opacity))
}

._group_ih07p_1:hover ._ods_step_item_circle_withText_ih07p_2501._ods_step_item_complete_ih07p_2435 ._ods_stepCircle_circle_innerWrapper_ih07p_1374{
  --tw-bg-opacity: 1;
  background-color:rgba(55, 48, 163,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-800),var(--tw-bg-opacity))
}

._ods_step_item_circle_withText_ih07p_2501._ods_step_item_current_ih07p_2438 ._ods_stepCircle_circle_innerWrapper_ih07p_1374{
  border-width:2px;
  --tw-border-opacity: 1;
  border-color:rgba(79, 70, 229,var(--tw-border-opacity));
  border-color:rgba(var(--color-primary-600),var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color:rgba(255, 255, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-white),var(--tw-bg-opacity))
}

._ods_step_item_circle_withText_ih07p_2501._ods_step_item_current_ih07p_2438 ._ods_stepCircle_circle_ih07p_1368{
  --tw-bg-opacity: 1;
  background-color:rgba(79, 70, 229,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-600),var(--tw-bg-opacity))
}

._ods_step_item_circle_withText_ih07p_2501._ods_step_item_current_ih07p_2438 ._ods_step_item_name_ih07p_2448{
  --tw-text-opacity: 1;
  color:rgba(79, 70, 229,var(--tw-text-opacity));
  color:rgba(var(--color-primary-600),var(--tw-text-opacity))
}

._ods_step_item_circle_withText_ih07p_2501._ods_step_item_upcoming_ih07p_2441 ._ods_stepCircle_circle_innerWrapper_ih07p_1374{
  border-radius:9999px;
  border-width:2px;
  --tw-border-opacity: 1;
  border-color:rgba(209, 213, 219,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-300),var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color:rgba(255, 255, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-white),var(--tw-bg-opacity))
}

._group_ih07p_1:hover ._ods_step_item_circle_withText_ih07p_2501._ods_step_item_upcoming_ih07p_2441 ._ods_stepCircle_circle_innerWrapper_ih07p_1374{
  --tw-border-opacity: 1;
  border-color:rgba(156, 163, 175,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-400),var(--tw-border-opacity))
}

._ods_step_item_circle_withText_ih07p_2501._ods_step_item_upcoming_ih07p_2441 ._ods_stepCircle_circle_ih07p_1368{
  background-color:transparent
}

._ods_step_item_circle_withText_ih07p_2501._ods_step_item_upcoming_ih07p_2441 ._ods_step_item_name_ih07p_2448{
  --tw-text-opacity: 1;
  color:rgba(107, 114, 128,var(--tw-text-opacity));
  color:rgba(var(--color-dark-500),var(--tw-text-opacity))
}

._ods_step_item_circle_withText_ih07p_2501:hover._ods_step_item_complete_ih07p_2435 ._ods_stepCircle_circle_innerWrapper_ih07p_1374{
  --tw-bg-opacity: 1;
  background-color:rgba(55, 48, 163,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-800),var(--tw-bg-opacity))
}

._ods_step_item_circle_withText_ih07p_2501:hover._ods_step_item_upcoming_ih07p_2441 ._ods_stepCircle_circle_innerWrapper_ih07p_1374{
  --tw-border-opacity: 1;
  border-color:rgba(156, 163, 175,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-400),var(--tw-border-opacity))
}

._ods_step_item_circle_withText_ih07p_2501:hover._ods_step_item_upcoming_ih07p_2441 ._ods_stepCircle_circle_ih07p_1368{
  --tw-bg-opacity: 1;
  background-color:rgba(209, 213, 219,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-300),var(--tw-bg-opacity))
}

._ods_step_item_circleLine_ih07p_2544{
  position:absolute;
  inset:0;
  display:flex;
  align-items:center
}

._ods_step_item_circleLine_ih07p_2544>div{
  height:.125rem;
  width:100%
}

._ods_step_item_circleLine_ih07p_2544>._ods_step_item_complete_ih07p_2435{
  --tw-bg-opacity: 1;
  background-color:rgba(79, 70, 229,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-600),var(--tw-bg-opacity))
}

._ods_step_item_circleLine_ih07p_2544>._ods_step_item_current_ih07p_2438,._ods_step_item_circleLine_ih07p_2544>._ods_step_item_upcoming_ih07p_2441{
  --tw-bg-opacity: 1;
  background-color:rgba(229, 231, 235,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-200),var(--tw-bg-opacity))
}

._ods_step_item_circleLine_vertical_ih07p_2557{
  position:absolute;
  top:1rem;
  left:1rem;
  margin-left:-1px;
  margin-top:.125rem;
  height:100%;
  width:.125rem
}

._ods_step_item_circleLine_vertical_ih07p_2557._ods_step_item_complete_ih07p_2435{
  --tw-bg-opacity: 1;
  background-color:rgba(79, 70, 229,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-600),var(--tw-bg-opacity))
}

._ods_step_item_circleLine_vertical_ih07p_2557._ods_step_item_current_ih07p_2438,._ods_step_item_circleLine_vertical_ih07p_2557._ods_step_item_upcoming_ih07p_2441{
  --tw-bg-opacity: 1;
  background-color:rgba(209, 213, 219,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-300),var(--tw-bg-opacity))
}

._ods_step_item_panel_ih07p_2567{
  outline:2px solid transparent;
  outline-offset:2px
}

._ods_step_item_panel_border_ih07p_2570{
  position:absolute;
  top:0;
  left:0;
  height:100%;
  width:.25rem;
  background-color:transparent
}

@media (min-width: 1024px){
  ._ods_step_item_panel_border_ih07p_2570{
    bottom:0;
    top:auto;
    height:.25rem;
    width:100%
  }
}

._ods_step_item_panel_border_wrapper_ih07p_2573{
  display:flex;
  align-items:center;
  font-size:.875rem;
  line-height:1.25rem;
  font-weight:500
}

._ods_step_item_panel_border_wrapper_ih07p_2573 ._ods_step_item_name_ih07p_2448{
  text-transform:uppercase
}

@media (min-width: 1024px){
  ._ods_step_item_panel_border_wrapper_ih07p_2573:not(:first-child){
    padding-left:2.25rem
  }
}

._ods_step_item_panel_ih07p_2567 ._ods_step_item_name_ih07p_2448{
  margin-left:1rem;
  font-size:.875rem;
  line-height:1.25rem;
  font-weight:500
}

._ods_step_item_panel_ih07p_2567 ._ods_step_item_description_ih07p_2510{
  font-size:.875rem;
  line-height:1.25rem;
  font-weight:500;
  --tw-text-opacity: 1;
  color:rgba(107, 114, 128,var(--tw-text-opacity));
  color:rgba(var(--color-dark-500),var(--tw-text-opacity))
}

._ods_step_item_panel_contentWrapper_ih07p_2588{
  display:flex;
  align-items:center;
  padding:1rem 1.5rem;
  font-size:.875rem;
  line-height:1.25rem;
  font-weight:500
}

._ods_step_item_panel_contentWrapper_content_ih07p_2591{
  margin-top:.125rem;
  margin-left:1rem;
  display:flex;
  min-width:0px;
  flex-direction:column
}

._ods_step_item_panel_contentWrapper_content_ih07p_2591 ._ods_step_item_name_ih07p_2448{
  margin-left:0
}

._ods_step_item_panel_ih07p_2567._ods_step_item_complete_ih07p_2435{
  display:flex;
  width:100%;
  align-items:center
}

._ods_step_item_panel_ih07p_2567._ods_step_item_complete_ih07p_2435 ._ods_step_item_panel_border_ih07p_2570{
  background-color:transparent
}

._ods_step_item_panel_ih07p_2567._ods_step_item_complete_ih07p_2435 ._ods_stepPanel_outlinedCircle_ih07p_1382{
  --tw-bg-opacity: 1;
  background-color:rgba(79, 70, 229,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-600),var(--tw-bg-opacity))
}

._ods_step_item_panel_ih07p_2567._ods_step_item_complete_ih07p_2435 ._ods_step_item_name_ih07p_2448{
  --tw-text-opacity: 1;
  color:rgba(17, 24, 39,var(--tw-text-opacity));
  color:rgba(var(--color-dark-900),var(--tw-text-opacity))
}

._ods_step_item_panel_ih07p_2567._ods_step_item_current_ih07p_2438{
  display:flex;
  align-items:center;
  padding:1rem 1.5rem;
  font-size:.875rem;
  line-height:1.25rem;
  font-weight:500
}

._ods_step_item_panel_ih07p_2567._ods_step_item_current_ih07p_2438 ._ods_step_item_panel_border_ih07p_2570{
  --tw-bg-opacity: 1;
  background-color:rgba(79, 70, 229,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-600),var(--tw-bg-opacity))
}

._ods_step_item_panel_ih07p_2567._ods_step_item_current_ih07p_2438 ._ods_stepPanel_outlinedCircle_ih07p_1382{
  border-width:2px;
  --tw-border-opacity: 1;
  border-color:rgba(79, 70, 229,var(--tw-border-opacity));
  border-color:rgba(var(--color-primary-600),var(--tw-border-opacity))
}

._ods_step_item_panel_ih07p_2567._ods_step_item_current_ih07p_2438 ._ods_step_item_id_ih07p_2618,._ods_step_item_panel_ih07p_2567._ods_step_item_current_ih07p_2438 ._ods_step_item_name_ih07p_2448{
  --tw-text-opacity: 1;
  color:rgba(79, 70, 229,var(--tw-text-opacity));
  color:rgba(var(--color-primary-600),var(--tw-text-opacity))
}

._ods_step_item_panel_ih07p_2567._ods_step_item_upcoming_ih07p_2441{
  display:flex;
  align-items:center
}

._ods_step_item_panel_ih07p_2567._ods_step_item_upcoming_ih07p_2441 ._ods_step_item_panel_border_ih07p_2570{
  background-color:transparent
}

._ods_step_item_panel_ih07p_2567._ods_step_item_upcoming_ih07p_2441 ._ods_stepPanel_outlinedCircle_ih07p_1382{
  border-width:2px;
  --tw-border-opacity: 1;
  border-color:rgba(209, 213, 219,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-300),var(--tw-border-opacity))
}

._ods_step_item_panel_ih07p_2567._ods_step_item_upcoming_ih07p_2441 ._ods_step_item_id_ih07p_2618,._ods_step_item_panel_ih07p_2567._ods_step_item_upcoming_ih07p_2441 ._ods_step_item_name_ih07p_2448{
  --tw-text-opacity: 1;
  color:rgba(107, 114, 128,var(--tw-text-opacity));
  color:rgba(var(--color-dark-500),var(--tw-text-opacity))
}

._ods_step_item_panel_ih07p_2567:hover._ods_step_item_complete_ih07p_2435 ._ods_step_item_panel_border_ih07p_2570{
  --tw-bg-opacity: 1;
  background-color:rgba(229, 231, 235,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-200),var(--tw-bg-opacity))
}

._ods_step_item_panel_ih07p_2567:hover._ods_step_item_complete_ih07p_2435 ._ods_stepPanel_outlinedCircle_ih07p_1382{
  --tw-bg-opacity: 1;
  background-color:rgba(55, 48, 163,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-800),var(--tw-bg-opacity))
}

._ods_step_item_panel_ih07p_2567:hover._ods_step_item_upcoming_ih07p_2441 ._ods_step_item_panel_border_ih07p_2570{
  --tw-bg-opacity: 1;
  background-color:rgba(229, 231, 235,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-200),var(--tw-bg-opacity))
}

._ods_step_item_panel_ih07p_2567:hover._ods_step_item_upcoming_ih07p_2441 ._ods_stepPanel_outlinedCircle_ih07p_1382{
  --tw-border-opacity: 1;
  border-color:rgba(156, 163, 175,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-400),var(--tw-border-opacity))
}

._ods_step_item_panel_ih07p_2567:hover._ods_step_item_upcoming_ih07p_2441 ._ods_step_item_id_ih07p_2618,._ods_step_item_panel_ih07p_2567:hover._ods_step_item_upcoming_ih07p_2441 ._ods_step_item_name_ih07p_2448{
  --tw-text-opacity: 1;
  color:rgba(17, 24, 39,var(--tw-text-opacity));
  color:rgba(var(--color-dark-900),var(--tw-text-opacity))
}

._ods_step_item_simple_ih07p_2652{
  display:flex;
  flex-direction:column;
  align-items:flex-start;
  border-left-width:4px;
  --tw-border-opacity: 1;
  border-color:rgba(79, 70, 229,var(--tw-border-opacity));
  border-color:rgba(var(--color-primary-600),var(--tw-border-opacity));
  padding-top:.5rem;
  padding-bottom:.5rem;
  padding-left:1rem;
  outline:2px solid transparent;
  outline-offset:2px
}

@media (min-width: 768px){
  ._ods_step_item_simple_ih07p_2652{
    border-left-width:0px;
    border-top-width:4px;
    padding-left:0;
    padding-top:1rem;
    padding-bottom:0
  }
}

._ods_step_item_simple_ih07p_2652._ods_step_item_complete_ih07p_2435:hover{
  --tw-border-opacity: 1;
  border-color:rgba(55, 48, 163,var(--tw-border-opacity));
  border-color:rgba(var(--color-primary-800),var(--tw-border-opacity))
}

._ods_step_item_simple_ih07p_2652._ods_step_item_complete_ih07p_2435:hover ._ods_step_item_id_ih07p_2618{
  --tw-text-opacity: 1;
  color:rgba(55, 48, 163,var(--tw-text-opacity));
  color:rgba(var(--color-primary-800),var(--tw-text-opacity))
}

._ods_step_item_simple_ih07p_2652._ods_step_item_upcoming_ih07p_2441{
  --tw-border-opacity: 1;
  border-color:rgba(229, 231, 235,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-200),var(--tw-border-opacity))
}

._ods_step_item_simple_ih07p_2652._ods_step_item_upcoming_ih07p_2441:hover{
  --tw-border-opacity: 1;
  border-color:rgba(209, 213, 219,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-300),var(--tw-border-opacity))
}

._ods_step_item_simple_ih07p_2652._ods_step_item_upcoming_ih07p_2441 ._ods_step_item_id_ih07p_2618{
  --tw-text-opacity: 1;
  color:rgba(107, 114, 128,var(--tw-text-opacity));
  color:rgba(var(--color-dark-500),var(--tw-text-opacity))
}

._ods_step_item_simple_ih07p_2652._ods_step_item_upcoming_ih07p_2441:hover ._ods_step_item_id_ih07p_2618{
  --tw-text-opacity: 1;
  color:rgba(55, 65, 81,var(--tw-text-opacity));
  color:rgba(var(--color-dark-700),var(--tw-text-opacity))
}

._ods_step_item_simple_ih07p_2652 ._ods_step_item_id_ih07p_2618{
  font-size:.75rem;
  line-height:1rem;
  font-weight:600;
  text-transform:uppercase;
  letter-spacing:.025em;
  --tw-text-opacity: 1;
  color:rgba(79, 70, 229,var(--tw-text-opacity));
  color:rgba(var(--color-primary-600),var(--tw-text-opacity))
}

._ods_step_item_simple_ih07p_2652 ._ods_step_item_name_ih07p_2448{
  font-size:.875rem;
  line-height:1.25rem;
  font-weight:500
}

._ods_step_helper_wrapper_ih07p_2677{
  display:flex;
  align-items:center;
  justify-content:center
}

._ods_step_item_ih07p_2435{
  list-style-type:none
}

._ods_step_item_non_clickable_ih07p_2684{
  pointer-events:none;
  cursor:default
}

._ods_step_item_ih07p_2435 ._ods_step_circle_circleLine_ih07p_2687{
  position:absolute;
  inset:0;
  display:flex;
  align-items:center
}

._ods_step_item_ih07p_2435 ._ods_step_circle_circleLine_ih07p_2687>div{
  height:.125rem;
  width:100%
}

._ods_step_item_ih07p_2435 ._ods_step_circle_circleLine_ih07p_2687>div._ods_step_item_complete_ih07p_2435{
  --tw-bg-opacity: 1;
  background-color:rgba(79, 70, 229,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-600),var(--tw-bg-opacity))
}

._ods_step_item_ih07p_2435 ._ods_step_circle_circleLine_ih07p_2687>div._ods_step_item_current_ih07p_2438,._ods_step_item_ih07p_2435 ._ods_step_circle_circleLine_ih07p_2687>div._ods_step_item_upcoming_ih07p_2441{
  --tw-bg-opacity: 1;
  background-color:rgba(209, 213, 219,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-300),var(--tw-bg-opacity))
}

._ods_step_item_ih07p_2435:not(:last-child)>._ods_step_circle_circleLine_line_ih07p_2699{
  position:absolute;
  top:1rem;
  left:1rem;
  margin-left:-1px;
  margin-top:.125rem;
  height:100%;
  width:.125rem
}

._ods_step_item_ih07p_2435:not(:last-child)>._ods_step_circle_circleLine_line_ih07p_2699._ods_step_item_complete_ih07p_2435{
  --tw-bg-opacity: 1;
  background-color:rgba(79, 70, 229,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-600),var(--tw-bg-opacity))
}

._ods_step_item_ih07p_2435:not(:last-child)>._ods_step_circle_circleLine_line_ih07p_2699._ods_step_item_current_ih07p_2438,._ods_step_item_ih07p_2435:not(:last-child)>._ods_step_circle_circleLine_line_ih07p_2699._ods_step_item_upcoming_ih07p_2441{
  --tw-bg-opacity: 1;
  background-color:rgba(229, 231, 235,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-200),var(--tw-bg-opacity))
}

._ods_stat_ih07p_1317 ._ods_stat_icon_wrapper_ih07p_2709{
  margin-right:1rem;
  border-radius:.375rem;
  --tw-bg-opacity: 1;
  background-color:rgba(99, 102, 241,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-500),var(--tw-bg-opacity));
  padding:.75rem
}

._ods_stat_ih07p_1317 ._ods_stat_icon_wrapper_ih07p_2709 svg{
  height:1.5rem;
  width:1.5rem;
  --tw-text-opacity: 1;
  color:rgba(255, 255, 255,var(--tw-text-opacity));
  color:rgba(var(--color-white),var(--tw-text-opacity))
}

@media (min-width: 640px){
  ._ods_tab_mobile_ih07p_2361{
    display:none
  }
}

._ods_tab_select_item_default_ih07p_2364{
  --tw-text-opacity: 1;
  color:rgba(55, 65, 81,var(--tw-text-opacity));
  color:rgba(var(--color-dark-700),var(--tw-text-opacity))
}

._ods_table_header_ih07p_2368{
  border-bottom-width:1px;
  --tw-border-opacity: 1;
  border-color:rgba(229, 231, 235,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-200),var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color:rgba(249, 250, 251,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-50),var(--tw-bg-opacity))
}

._ods_table_header_ih07p_2368 th:last-child{
  position:relative;
  padding:.75rem 1.5rem
}

._ods_table_footer_ih07p_2730{
  border-top-width:1px;
  --tw-border-opacity: 1;
  border-color:rgba(229, 231, 235,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-200),var(--tw-border-opacity))
}

._ods_table_body_ih07p_2375>:not([hidden])~:not([hidden]){
  --tw-divide-y-reverse: 0;
  border-top-width:calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width:calc(1px * var(--tw-divide-y-reverse));
  --tw-divide-opacity: 1;
  border-color:rgba(229, 231, 235,var(--tw-divide-opacity));
  border-color:rgba(var(--color-light-200),var(--tw-divide-opacity))
}

._ods_table_body_ih07p_2375{
  --tw-bg-opacity: 1;
  background-color:rgba(255, 255, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-white),var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:rgba(17, 24, 39,var(--tw-text-opacity));
  color:rgba(var(--color-dark-900),var(--tw-text-opacity))
}

._ods_textarea_ih07p_2738{
  display:block;
  width:100%;
  border-radius:.375rem;
  border-width:1px;
  --tw-border-opacity: 1;
  border-color:rgba(209, 213, 219,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-300),var(--tw-border-opacity));
  padding:.5rem .75rem;
  font-size:1rem;
  font-weight:400;
  line-height:1.5rem;
  --tw-text-opacity: 1;
  color:rgba(17, 24, 39,var(--tw-text-opacity));
  color:rgba(var(--color-dark-900),var(--tw-text-opacity));
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / .05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow:0 0 #0000,0 0 #0000,var(--tw-shadow);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow)
}

._ods_textarea_ih07p_2738:hover{
  --tw-border-opacity: 1;
  border-color:rgba(156, 163, 175,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-400),var(--tw-border-opacity))
}

._ods_textarea_ih07p_2738:focus{
  --tw-border-opacity: 1;
  border-color:rgba(99, 102, 241,var(--tw-border-opacity));
  border-color:rgba(var(--color-primary-500),var(--tw-border-opacity))
}

@media (min-width: 640px){
  ._ods_textarea_ih07p_2738{
    font-size:.875rem;
    line-height:1.25rem
  }
}

._ods_textarea_disabled_ih07p_2741{
  opacity:.5
}

._ods_textarea_label_ih07p_2744{
  font-size:.875rem;
  font-weight:500;
  line-height:1.25rem;
  --tw-text-opacity: 1;
  color:rgba(55, 65, 81,var(--tw-text-opacity));
  color:rgba(var(--color-dark-700),var(--tw-text-opacity))
}

._ods_textarea_error_ih07p_2747{
  --tw-border-opacity: 1;
  border-color:rgba(252, 165, 165,var(--tw-border-opacity));
  border-color:rgba(var(--color-danger-300),var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color:rgba(127, 29, 29,var(--tw-text-opacity));
  color:rgba(var(--color-danger-900),var(--tw-text-opacity))
}

._ods_textarea_error_ih07p_2747:hover{
  --tw-border-opacity: 1;
  border-color:rgba(248, 113, 113,var(--tw-border-opacity));
  border-color:rgba(var(--color-danger-400),var(--tw-border-opacity))
}

._ods_textarea_error_ih07p_2747:focus{
  --tw-border-opacity: 1;
  border-color:rgba(239, 68, 68,var(--tw-border-opacity));
  border-color:rgba(var(--color-danger-500),var(--tw-border-opacity));
  outline-color:rgb(239, 68, 68);
  outline-color:rgb(var(--color-danger-500))
}

._ods_textarea_helptext_ih07p_2750{
  margin-top:.5rem;
  margin-bottom:.5rem;
  font-size:.875rem;
  font-weight:400;
  line-height:1.25rem;
  --tw-text-opacity: 1;
  color:rgba(107, 114, 128,var(--tw-text-opacity));
  color:rgba(var(--color-dark-500),var(--tw-text-opacity))
}

._ods_textarea_borderless_ih07p_2753{
  border-width:1px;
  border-color:transparent;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow:0 0 #0000,0 0 #0000,var(--tw-shadow);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow);
  transition-property:all;
  transition-timing-function:cubic-bezier(.4,0,.2,1);
  transition-duration:.3s
}

._ods_textarea_borderless_ih07p_2753:hover{
  --tw-border-opacity: 1;
  border-color:rgba(156, 163, 175,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-400),var(--tw-border-opacity))
}

._ods_textarea_borderless_ih07p_2753:focus{
  --tw-border-opacity: 1;
  border-color:rgba(156, 163, 175,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-400),var(--tw-border-opacity))
}

@media (min-width: 640px){
  ._ods_grid_ih07p_2757{
    display:grid
  }
}

._ods_grid_mobile_ih07p_2760{
  display:grid
}

@media (min-width: 640px){
  ._ods_grid_1_col_ih07p_2763{
    grid-template-columns:repeat(1,minmax(0,1fr))
  }
}

@media (min-width: 768px){
  ._ods_grid_1_md_ih07p_2766{
    grid-template-columns:repeat(1,minmax(0,1fr))
  }
}

@media (min-width: 1024px){
  ._ods_grid_1_lg_ih07p_2769{
    grid-template-columns:repeat(1,minmax(0,1fr))
  }
}

._ods_grid_1_mobile_ih07p_2772{
  grid-template-columns:repeat(1,minmax(0,1fr))
}

._ods_grid_1_gap_ih07p_2775{
  grid-gap:.25rem;
  gap:.25rem
}

@media (min-width: 640px){
  ._ods_grid_1_span_ih07p_2778{
    grid-column:span 1 / span 1
  }

  ._ods_grid_2_col_ih07p_2781{
    grid-template-columns:repeat(2,minmax(0,1fr))
  }
}

@media (min-width: 768px){
  ._ods_grid_2_md_ih07p_2784{
    grid-template-columns:repeat(2,minmax(0,1fr))
  }
}

@media (min-width: 1024px){
  ._ods_grid_2_lg_ih07p_2787{
    grid-template-columns:repeat(2,minmax(0,1fr))
  }
}

._ods_grid_2_mobile_ih07p_2790{
  grid-template-columns:repeat(2,minmax(0,1fr))
}

._ods_grid_2_gap_ih07p_2793{
  grid-gap:.5rem;
  gap:.5rem
}

@media (min-width: 640px){
  ._ods_grid_2_span_ih07p_2796{
    grid-column:span 2 / span 2
  }

  ._ods_grid_3_col_ih07p_2799{
    grid-template-columns:repeat(3,minmax(0,1fr))
  }
}

@media (min-width: 768px){
  ._ods_grid_3_md_ih07p_2802{
    grid-template-columns:repeat(3,minmax(0,1fr))
  }
}

@media (min-width: 1024px){
  ._ods_grid_3_lg_ih07p_2805{
    grid-template-columns:repeat(3,minmax(0,1fr))
  }
}

._ods_grid_3_mobile_ih07p_2808{
  grid-template-columns:repeat(3,minmax(0,1fr))
}

._ods_grid_3_gap_ih07p_2811{
  grid-gap:.75rem;
  gap:.75rem
}

@media (min-width: 640px){
  ._ods_grid_3_span_ih07p_2814{
    grid-column:span 3 / span 3
  }

  ._ods_grid_4_col_ih07p_2817{
    grid-template-columns:repeat(4,minmax(0,1fr))
  }
}

@media (min-width: 768px){
  ._ods_grid_4_md_ih07p_2820{
    grid-template-columns:repeat(4,minmax(0,1fr))
  }
}

@media (min-width: 1024px){
  ._ods_grid_4_lg_ih07p_2823{
    grid-template-columns:repeat(4,minmax(0,1fr))
  }
}

._ods_grid_4_mobile_ih07p_2826{
  grid-template-columns:repeat(4,minmax(0,1fr))
}

._ods_grid_4_gap_ih07p_2829{
  grid-gap:1rem;
  gap:1rem
}

@media (min-width: 640px){
  ._ods_grid_4_span_ih07p_2832{
    grid-column:span 4 / span 4
  }

  ._ods_grid_5_col_ih07p_2835{
    grid-template-columns:repeat(5,minmax(0,1fr))
  }
}

@media (min-width: 768px){
  ._ods_grid_5_md_ih07p_2838{
    grid-template-columns:repeat(5,minmax(0,1fr))
  }
}

@media (min-width: 1024px){
  ._ods_grid_5_lg_ih07p_2841{
    grid-template-columns:repeat(5,minmax(0,1fr))
  }
}

._ods_grid_5_mobile_ih07p_2844{
  grid-template-columns:repeat(5,minmax(0,1fr))
}

._ods_grid_5_gap_ih07p_2847{
  grid-gap:1.25rem;
  gap:1.25rem
}

@media (min-width: 640px){
  ._ods_grid_5_span_ih07p_2850{
    grid-column:span 5 / span 5
  }

  ._ods_grid_6_col_ih07p_2853{
    grid-template-columns:repeat(6,minmax(0,1fr))
  }
}

@media (min-width: 768px){
  ._ods_grid_6_md_ih07p_2856{
    grid-template-columns:repeat(6,minmax(0,1fr))
  }
}

@media (min-width: 1024px){
  ._ods_grid_6_lg_ih07p_2859{
    grid-template-columns:repeat(6,minmax(0,1fr))
  }
}

._ods_grid_6_mobile_ih07p_2862{
  grid-template-columns:repeat(6,minmax(0,1fr))
}

._ods_grid_6_gap_ih07p_2865{
  grid-gap:1.5rem;
  gap:1.5rem
}

@media (min-width: 640px){
  ._ods_grid_6_span_ih07p_2868{
    grid-column:span 6 / span 6
  }
}

._ods_grid_px_ih07p_2871{
  grid-gap:1px;
  gap:1px
}

._ods_grid_shared_borders_ih07p_2874 ._ods_grid_item_ih07p_2874{
  margin:-.125rem
}

@media (min-width: 640px){
  ._ods_grid_shared_borders_ih07p_2874 ._ods_grid_item_ih07p_2874{
    margin:-1px
  }
}

._ods_grid_shared_borders_mobile_ih07p_2877 ._ods_grid_item_ih07p_2874{
  margin:-1px
}

@media (min-width: 640px){
  ._ods_modal_body_leading_ih07p_2881{
    display:flex;
    align-items:flex-start
  }
}

._ods_modal_body_leading_ih07p_2881 ._ods_modal_body_iconWrapper_ih07p_2884{
  flex-shrink:0
}

@media (min-width: 640px){
  ._ods_modal_body_leading_ih07p_2881 ._ods_modal_body_iconWrapper_ih07p_2884{
    margin-left:0;
    margin-right:0;
    height:2.5rem;
    width:2.5rem
  }

  ._ods_modal_body_leading_ih07p_2881 ._ods_modal_body_contentWrapper_ih07p_2887{
    margin-top:0;
    margin-left:1rem;
    text-align:left
  }
}

._ods_modal_body_iconWrapper_ih07p_2884{
  margin-left:auto;
  margin-right:auto;
  display:flex;
  height:3rem;
  width:3rem;
  align-items:center;
  justify-content:center;
  border-radius:9999px
}

._ods_modal_body_iconWrapper_success_ih07p_2893{
  --tw-bg-opacity: 1;
  background-color:rgba(209, 250, 229,var(--tw-bg-opacity));
  background-color:rgba(var(--color-success-100),var(--tw-bg-opacity))
}

._ods_modal_body_iconWrapper_success_ih07p_2893 svg{
  --tw-text-opacity: 1;
  color:rgba(5, 150, 105,var(--tw-text-opacity));
  color:rgba(var(--color-success-600),var(--tw-text-opacity))
}

._ods_modal_body_iconWrapper_danger_ih07p_2899{
  --tw-bg-opacity: 1;
  background-color:rgba(254, 226, 226,var(--tw-bg-opacity));
  background-color:rgba(var(--color-danger-100),var(--tw-bg-opacity))
}

._ods_modal_body_iconWrapper_danger_ih07p_2899 svg{
  --tw-text-opacity: 1;
  color:rgba(220, 38, 38,var(--tw-text-opacity));
  color:rgba(var(--color-danger-600),var(--tw-text-opacity))
}

._ods_modal_body_iconWrapper_info_ih07p_2905{
  --tw-bg-opacity: 1;
  background-color:rgba(219, 234, 254,var(--tw-bg-opacity));
  background-color:rgba(var(--color-info-100),var(--tw-bg-opacity))
}

._ods_modal_body_iconWrapper_info_ih07p_2905 svg{
  --tw-text-opacity: 1;
  color:rgba(37, 99, 235,var(--tw-text-opacity));
  color:rgba(var(--color-info-600),var(--tw-text-opacity))
}

._ods_modal_body_iconWrapper_ih07p_2884 svg{
  height:1.5rem;
  width:1.5rem
}

._ods_modal_body_contentWrapper_ih07p_2887{
  margin-top:.75rem;
  text-align:center
}

@media (min-width: 640px){
  ._ods_modal_body_contentWrapper_ih07p_2887{
    margin-top:1.25rem
  }
}

._ods_modal_body_contentWrapper_content_ih07p_2917{
  margin-top:.5rem
}

._ods_modal_body_contentWrapper_content_ih07p_2917 p{
  font-size:.875rem;
  line-height:1.25rem;
  --tw-text-opacity: 1;
  color:rgba(107, 114, 128,var(--tw-text-opacity));
  color:rgba(var(--color-dark-500),var(--tw-text-opacity))
}

._ods_navbar_content_ih07p_2924{
  display:none
}

._ods_navbar_content_ih07p_2924>:not([hidden])~:not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right:calc(1rem * var(--tw-space-x-reverse));
  margin-left:calc(1rem * calc(1 - var(--tw-space-x-reverse)))
}

@media (min-width: 640px){
  ._ods_navbar_content_ih07p_2924{
    display:flex
  }
}

._ods_navbar_content_icon_ih07p_2927{
  display:block;
  height:1.5rem;
  width:1.5rem
}

@media (min-width: 640px){
  ._ods_navbar_content_mobile_ih07p_2930{
    display:none
  }
}

._ods_navbar_content_default_ih07p_2933{
  display:flex
}

._ods_navbar_content_disclosure_button_ih07p_2936{
  display:inline-flex;
  justify-content:center;
  border-radius:.375rem;
  padding:.5rem
}

._ods_navbar_actions_ih07p_2940{
  align-items:center;
  justify-content:flex-end
}

._ods_navbar_actions_flex_ih07p_2943{
  display:flex
}

._ods_navbar_actions_ih07p_2940 button{
  margin-right:.75rem;
  border-style:none;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow:0 0 #0000,0 0 #0000,var(--tw-shadow);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow)
}

._ods_navbar_actions_ih07p_2940 button:focus{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),0 0 #0000;
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),var(--tw-shadow, 0 0 #0000)
}

._ods_navbar_logo_ih07p_2950{
  flex-shrink:0
}

._ods_navbar_logo_group_ih07p_2953{
  display:flex;
  padding-right:.75rem
}

._ods_navbar_logo_ih07p_2950>img{
  height:2rem;
  width:100%
}

._ods_navbar_logo_leading_ih07p_2959{
  justify-content:flex-start
}

._ods_navbar_logo_trailing_ih07p_2962{
  justify-content:flex-end
}

._ods_navbar_logo_center_ih07p_2965{
  position:absolute;
  left:50%;
  margin-left:-50%;
  width:100%
}

@media (min-width: 640px){
  ._ods_navbar_logo_center_ih07p_2965{
    position:static;
    margin-left:0;
    display:flex;
    width:auto;
    padding-right:.75rem
  }
}

._ods_section_heading_actions_ih07p_2969{
  margin-top:.75rem;
  display:flex;
  flex-shrink:0
}

@media (min-width: 640px){
  ._ods_section_heading_actions_ih07p_2969{
    margin-top:0;
    margin-left:1rem
  }
}

._ods_section_heading_actions_ih07p_2969>*:not(:first-child){
  margin-left:.75rem
}

._ods_section_heading_actions_ih07p_2969 svg{
  height:1.25rem;
  width:1.25rem
}

._ods_section_heading_actions_space_between_ih07p_2978{
  justify-content:space-between
}

._ods_section_heading_content_ih07p_2982{
  display:block
}

._ods_section_heading_content_title_ih07p_2985{
  font-weight:500;
  --tw-text-opacity: 1;
  color:rgba(17, 24, 39,var(--tw-text-opacity));
  color:rgba(var(--color-dark-900),var(--tw-text-opacity))
}

._ods_section_heading_content_title_heading_ih07p_2988{
  font-size:1.125rem;
  line-height:1.5rem
}

._ods_section_heading_content_title_small_ih07p_2991{
  font-size:.875rem;
  line-height:1.25rem
}

._ods_section_heading_content_flex_ih07p_2994{
  display:flex;
  align-items:center
}

._ods_section_heading_content_helptext_ih07p_2997{
  margin-top:.5rem;
  max-width:56rem;
  font-size:.875rem;
  line-height:1.25rem;
  --tw-text-opacity: 1;
  color:rgba(107, 114, 128,var(--tw-text-opacity));
  color:rgba(var(--color-dark-500),var(--tw-text-opacity))
}

._ods_section_heading_content_inline_ih07p_3000{
  margin-left:-.5rem;
  margin-top:-.5rem;
  display:flex;
  flex-wrap:wrap;
  align-items:baseline
}

._ods_section_heading_content_inline_ih07p_3000>p{
  margin-left:.5rem;
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap
}

._ods_section_heading_content_inline_ih07p_3000>h3{
  margin-left:.5rem
}

._ods_section_heading_content_tabs_ih07p_3009{
  display:block
}

._ods_action_panel_actions_ih07p_3013{
  margin-top:1.25rem;
  display:flex;
  flex-shrink:0;
  align-items:center
}

._ods_action_panel_actions_ih07p_3013>*:not(:first-child){
  margin-left:.75rem
}

._ods_action_panel_actions_link_ih07p_3019{
  margin-top:.75rem
}

._ods_action_panel_actions_mobile_block_ih07p_3022{
  margin-top:1.25rem
}

@media (min-width: 640px){
  ._ods_action_panel_actions_mobile_block_ih07p_3022{
    display:flex;
    align-items:center
  }
}

._ods_action_panel_actions_mobile_block_ih07p_3022>*{
  width:100%
}

@media (min-width: 640px){
  ._ods_action_panel_actions_mobile_block_ih07p_3022>*{
    width:auto
  }
}

._ods_action_panel_actions_mobile_block_ih07p_3022>*:not(:first-child){
  margin-top:.75rem
}

@media (min-width: 640px){
  ._ods_action_panel_actions_mobile_block_ih07p_3022>*:not(:first-child){
    margin-top:0;
    margin-left:.75rem
  }
}

._ods_action_panel_actions_ih07p_3013 svg{
  height:1.25rem;
  width:1.25rem
}

._ods_action_panel_content_ih07p_3035{
  display:block;
  max-width:36rem
}

@media (min-width: 640px){
  ._ods_action_panel_content_flex_ih07p_3038{
    display:flex
  }
}

._ods_sidebar_header_ih07p_3042{
  margin-bottom:1.25rem;
  display:flex;
  flex-shrink:0;
  align-items:center;
  padding-left:1rem;
  padding-right:1rem
}

._ods_sidebar_body_ih07p_3046>:not([hidden])~:not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top:calc(.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom:calc(.25rem * var(--tw-space-y-reverse))
}

._ods_sidebar_body_ih07p_3046{
  padding-left:.5rem;
  padding-right:.5rem
}

._ods_sidebar_body_flex_ih07p_3049{
  flex:1 1
}

._ods_sidebar_footer_ih07p_3053{
  margin-bottom:-1rem;
  display:flex;
  flex-shrink:0;
  border-top-width:1px;
  padding:1rem
}

._ods_sidebar_footer_link_ih07p_3056{
  display:flex;
  width:100%;
  flex-shrink:0;
  cursor:pointer;
  align-items:center
}

._ods_sidebar_disclosure_ih07p_3060>:not([hidden])~:not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top:calc(.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom:calc(.25rem * var(--tw-space-y-reverse))
}

._ods_sidebar_disclosure_padding_xl_ih07p_3063>div:last-child>a{
  padding-left:3.5rem
}

._ods_sidebar_disclosure_padding_lg_ih07p_3066>div:last-child>a{
  padding-left:3rem
}

._ods_sidebar_disclosure_padding_base_ih07p_3069>div:last-child>a{
  padding-left:2.5rem
}

._ods_sidebar_disclosure_content_ih07p_3072{
  display:flex;
  align-items:center
}

._ods_sidebar_disclosure_content_ih07p_3072 svg{
  height:1.5rem;
  width:1.5rem
}

._ods_sidebar_disclosure_button_ih07p_3078{
  display:flex;
  align-items:center;
  border-radius:.375rem;
  padding-top:.5rem;
  padding-bottom:.5rem;
  padding-right:.5rem;
  text-align:left;
  font-size:.875rem;
  line-height:1.25rem;
  font-weight:500
}

._ods_sidebar_disclosure_button_active_ih07p_3081{
  --tw-bg-opacity: 1;
  background-color:rgba(243, 244, 246,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-100),var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:rgba(17, 24, 39,var(--tw-text-opacity));
  color:rgba(var(--color-dark-900),var(--tw-text-opacity))
}

._ods_sidebar_disclosure_button_default_ih07p_3084{
  --tw-bg-opacity: 1;
  background-color:rgba(255, 255, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-white),var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:rgba(75, 85, 99,var(--tw-text-opacity));
  color:rgba(var(--color-dark-600),var(--tw-text-opacity))
}

._ods_sidebar_disclosure_button_default_ih07p_3084:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(249, 250, 251,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-50),var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:rgba(17, 24, 39,var(--tw-text-opacity));
  color:rgba(var(--color-dark-900),var(--tw-text-opacity))
}

._ods_sidebar_disclosure_button_wrapper_ih07p_3087{
  width:100%;
  border-radius:.375rem
}

._ods_sidebar_disclosure_button_wrapper_ih07p_3087:focus{
  outline:2px solid transparent;
  outline-offset:2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),0 0 #0000;
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(var(--color-primary-500), var(--tw-ring-opacity))
}

._ods_sidebar_disclosure_button_icon_ih07p_3090{
  margin-left:0;
  margin-right:0;
  flex-shrink:0;
  -webkit-transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transition-property:color,background-color,border-color,fill,stroke,-webkit-text-decoration-color;
  transition-property:color,background-color,border-color,text-decoration-color,fill,stroke;
  transition-property:color,background-color,border-color,text-decoration-color,fill,stroke,-webkit-text-decoration-color;
  transition-duration:.15s;
  transition-timing-function:cubic-bezier(.4,0,.2,1)
}

._group_ih07p_1:hover ._ods_sidebar_disclosure_button_icon_ih07p_3090{
  --tw-text-opacity: 1;
  color:rgba(156, 163, 175,var(--tw-text-opacity));
  color:rgba(var(--color-light-400),var(--tw-text-opacity))
}

._ods_sidebar_disclosure_button_icon_open_ih07p_3093{
  --tw-rotate: 90deg;
  -webkit-transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-text-opacity: 1;
  color:rgba(156, 163, 175,var(--tw-text-opacity));
  color:rgba(var(--color-light-400),var(--tw-text-opacity))
}

._ods_sidebar_disclosure_button_icon_default_ih07p_3096{
  --tw-text-opacity: 1;
  color:rgba(209, 213, 219,var(--tw-text-opacity));
  color:rgba(var(--color-light-300),var(--tw-text-opacity))
}

._ods_sidebar_disclosure_button_icon_left_ih07p_3099{
  margin-right:.5rem
}

._ods_sidebar_disclosure_button_icon_right_ih07p_3102{
  margin-left:auto
}

._ods_sidebar_disclosure_button_icon_right_content_ih07p_3105{
  padding-left:.75rem
}

._ods_input_group_ih07p_3109{
  margin-top:.25rem
}

._ods_input_group_ih07p_3109>:not([hidden])~:not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top:calc(-1px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom:calc(-1px * var(--tw-space-y-reverse))
}

._ods_input_group_ih07p_3109{
  border-radius:.375rem
}

._ods_input_group_shadow_ih07p_3112{
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / .05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow:0 0 #0000,0 0 #0000,var(--tw-shadow);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow)
}

._ods_input_group_label_ih07p_3115{
  display:block;
  font-size:.875rem;
  line-height:1.25rem;
  font-weight:500;
  --tw-text-opacity: 1;
  color:rgba(55, 65, 81,var(--tw-text-opacity));
  color:rgba(var(--color-dark-700),var(--tw-text-opacity))
}

._ods_input_group_padded_ih07p_3118>div>div{
  padding:.5rem
}

._ods_input_group_padded_ih07p_3118>div ._ods_input_field_inset_container_ih07p_2004{
  margin:.5rem
}

._ods_input_group_shared_borders_ih07p_3124>div button,._ods_input_group_shared_borders_ih07p_3124>div select,._ods_input_group_shared_borders_ih07p_3124>div input,._ods_input_group_shared_borders_ih07p_3124>div ._ods_input_field_inset_container_ih07p_2004{
  border-radius:0
}

._ods_input_group_shared_borders_ih07p_3124>div button:hover,._ods_input_group_shared_borders_ih07p_3124>div select:hover,._ods_input_group_shared_borders_ih07p_3124>div input:hover,._ods_input_group_shared_borders_ih07p_3124>div ._ods_input_field_inset_container_ih07p_2004:hover{
  --tw-border-opacity: 1;
  border-color:rgba(209, 213, 219,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-300),var(--tw-border-opacity))
}

._ods_input_group_shared_borders_ih07p_3124>div button:focus,._ods_input_group_shared_borders_ih07p_3124>div select:focus,._ods_input_group_shared_borders_ih07p_3124>div input:focus,._ods_input_group_shared_borders_ih07p_3124>div ._ods_input_field_inset_container_ih07p_2004:focus{
  --tw-border-opacity: 1;
  border-color:rgba(79, 70, 229,var(--tw-border-opacity));
  border-color:rgba(var(--color-primary-600),var(--tw-border-opacity))
}

._ods_input_group_shared_borders_ih07p_3124>div:first-child>div{
  border-top-left-radius:.375rem;
  border-top-right-radius:.375rem
}

._ods_input_group_shared_borders_ih07p_3124>div:first-child>div button,._ods_input_group_shared_borders_ih07p_3124>div:first-child>div select,._ods_input_group_shared_borders_ih07p_3124>div:first-child>div input{
  border-top-left-radius:.375rem;
  border-top-right-radius:.375rem
}

._ods_input_group_shared_borders_ih07p_3124>div:first-child>div:not(:first-child):not(:last-child){
  border-radius:0
}

._ods_input_group_shared_borders_ih07p_3124>div:first-child>div:not(:first-child):not(:last-child) button,._ods_input_group_shared_borders_ih07p_3124>div:first-child>div:not(:first-child):not(:last-child) select,._ods_input_group_shared_borders_ih07p_3124>div:first-child>div:not(:first-child):not(:last-child) input{
  border-radius:0
}

._ods_input_group_shared_borders_ih07p_3124>div:first-child>div:first-child:not(:only-child){
  border-top-right-radius:0
}

._ods_input_group_shared_borders_ih07p_3124>div:first-child>div:first-child:not(:only-child) button,._ods_input_group_shared_borders_ih07p_3124>div:first-child>div:first-child:not(:only-child) select,._ods_input_group_shared_borders_ih07p_3124>div:first-child>div:first-child:not(:only-child) input{
  border-top-right-radius:0
}

._ods_input_group_shared_borders_ih07p_3124>div:first-child>div:last-child:not(:only-child){
  border-top-right-radius:0
}

._ods_input_group_shared_borders_ih07p_3124>div:first-child>div:last-child:not(:only-child) button,._ods_input_group_shared_borders_ih07p_3124>div:first-child>div:last-child:not(:only-child) select,._ods_input_group_shared_borders_ih07p_3124>div:first-child>div:last-child:not(:only-child) input{
  border-top-left-radius:0
}

._ods_input_group_shared_borders_ih07p_3124>div:last-child>div{
  border-bottom-right-radius:.375rem;
  border-bottom-left-radius:.375rem
}

._ods_input_group_shared_borders_ih07p_3124>div:last-child>div button,._ods_input_group_shared_borders_ih07p_3124>div:last-child>div select,._ods_input_group_shared_borders_ih07p_3124>div:last-child>div input{
  border-bottom-right-radius:.375rem;
  border-bottom-left-radius:.375rem
}

._ods_input_group_shared_borders_ih07p_3124>div:last-child>div:not(:first-child):not(:last-child){
  border-radius:0
}

._ods_input_group_shared_borders_ih07p_3124>div:last-child>div:not(:first-child):not(:last-child) select,._ods_input_group_shared_borders_ih07p_3124>div:last-child>div:not(:first-child):not(:last-child) input{
  border-radius:0
}

._ods_input_group_shared_borders_ih07p_3124>div:last-child>div:first-child:not(:only-child){
  border-bottom-right-radius:0
}

._ods_input_group_shared_borders_ih07p_3124>div:last-child>div:first-child:not(:only-child) button,._ods_input_group_shared_borders_ih07p_3124>div:last-child>div:first-child:not(:only-child) select,._ods_input_group_shared_borders_ih07p_3124>div:last-child>div:first-child:not(:only-child) input{
  border-bottom-right-radius:0
}

._ods_input_group_shared_borders_ih07p_3124>div:last-child>div:last-child:not(:only-child){
  border-bottom-left-radius:0
}

._ods_input_group_shared_borders_ih07p_3124>div:last-child>div:last-child:not(:only-child) button,._ods_input_group_shared_borders_ih07p_3124>div:last-child>div:last-child:not(:only-child) select,._ods_input_group_shared_borders_ih07p_3124>div:last-child>div:last-child:not(:only-child) input{
  border-bottom-left-radius:0
}

._ods_input_group_row_ih07p_3193{
  display:flex;
  align-items:stretch
}

._ods_input_group_row_ih07p_3193>:not([hidden])~:not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right:calc(-1px * var(--tw-space-x-reverse));
  margin-left:calc(-1px * calc(1 - var(--tw-space-x-reverse)))
}

._react-datepicker-popper_ih07p_3197{
  z-index:40;
  margin-top:.5rem;
  border-radius:.25rem;
  border-width:1px;
  --tw-border-opacity: 1;
  border-color:rgba(209, 213, 219,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-300),var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color:rgba(255, 255, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-white),var(--tw-bg-opacity));
  padding:.5rem .75rem;
  font-size:.875rem;
  line-height:1.25rem
}

._react-datepicker-popper_ih07p_3197 ._react-datepicker_ih07p_3197{
  display:flex
}

._react-datepicker-popper_ih07p_3197 ._react-datepicker__week_ih07p_3203{
  display:flex;
  justify-content:space-around
}

._react-datepicker-popper_ih07p_3197 ._react-datepicker__day-names_ih07p_3206{
  display:flex;
  justify-content:space-around;
  text-align:center;
  font-size:.75rem;
  line-height:1rem;
  font-weight:500;
  --tw-text-opacity: 1;
  color:rgba(156, 163, 175,var(--tw-text-opacity));
  color:rgba(var(--color-light-400),var(--tw-text-opacity))
}

._react-datepicker-popper_ih07p_3197 ._react-datepicker__day-name_ih07p_3206{
  display:flex;
  height:2rem;
  width:2rem;
  align-items:center;
  justify-content:center;
  border-radius:9999px;
  padding-top:.25rem;
  padding-bottom:.25rem
}

._react-datepicker-popper_ih07p_3197 ._react-datepicker__day_ih07p_3206{
  margin-left:.125rem;
  margin-right:.125rem;
  margin-bottom:.25rem;
  display:flex;
  height:2rem;
  width:2rem;
  align-items:center;
  justify-content:center;
  border-radius:.25rem;
  padding-top:.25rem;
  padding-bottom:.25rem;
  line-height:2;
  --tw-text-opacity: 1;
  color:rgba(55, 65, 81,var(--tw-text-opacity));
  color:rgba(var(--color-dark-700),var(--tw-text-opacity));
  transition-property:color,background-color,border-color,fill,stroke,opacity,box-shadow,transform,filter,-webkit-text-decoration-color,-webkit-backdrop-filter;
  transition-property:color,background-color,border-color,fill,stroke,opacity,box-shadow,-webkit-text-decoration-color,-webkit-transform,-webkit-filter,-webkit-backdrop-filter;
  transition-property:color,background-color,border-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter;
  transition-property:color,background-color,border-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter,-webkit-text-decoration-color,-webkit-transform,-webkit-filter,-webkit-backdrop-filter;
  transition-property:color,background-color,border-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter,-webkit-text-decoration-color,-webkit-backdrop-filter;
  transition-timing-function:cubic-bezier(.4,0,.2,1);
  transition-duration:.15s
}

._react-datepicker-popper_ih07p_3197 ._react-datepicker__day_ih07p_3206:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(199, 210, 254,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-200),var(--tw-bg-opacity))
}

._react-datepicker-popper_ih07p_3197 ._react-datepicker__day--disabled_ih07p_3215{
  cursor:not-allowed;
  opacity:.4
}

._react-datepicker-popper_ih07p_3197 ._react-datepicker__day--disabled_ih07p_3215:hover{
  background-color:transparent
}

._react-datepicker-popper_ih07p_3197 ._react-datepicker__day--outside-month_ih07p_3218{
  --tw-text-opacity: 1;
  color:rgba(156, 163, 175,var(--tw-text-opacity));
  color:rgba(var(--color-light-400),var(--tw-text-opacity))
}

._react-datepicker-popper_ih07p_3197 ._react-datepicker__day--in-range_ih07p_3221,._react-datepicker-popper_ih07p_3197 ._react-datepicker__day--range-end_ih07p_3222,._react-datepicker-popper_ih07p_3197 ._react-datepicker__day--selected_ih07p_3223{
  --tw-bg-opacity: 1;
  background-color:rgba(99, 102, 241,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-500),var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:rgba(255, 255, 255,var(--tw-text-opacity));
  color:rgba(var(--color-white),var(--tw-text-opacity))
}

._react-datepicker-popper_ih07p_3197 ._react-datepicker__day--in-range_ih07p_3221:hover,._react-datepicker-popper_ih07p_3197 ._react-datepicker__day--range-end_ih07p_3222:hover,._react-datepicker-popper_ih07p_3197 ._react-datepicker__day--selected_ih07p_3223:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(199, 210, 254,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-200),var(--tw-bg-opacity))
}

._react-datepicker-popper_ih07p_3197 ._react-datepicker__day--in-selecting-range_ih07p_3226{
  --tw-bg-opacity: 1;
  background-color:rgba(199, 210, 254,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-200),var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:rgba(255, 255, 255,var(--tw-text-opacity));
  color:rgba(var(--color-white),var(--tw-text-opacity))
}

._react-datepicker-popper_ih07p_3197 ._react-datepicker__day--in-selecting-range_ih07p_3226:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(99, 102, 241,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-500),var(--tw-bg-opacity))
}

._ods_date_picker_header_wrapper_ih07p_3230{
  display:flex;
  align-items:center;
  justify-content:space-between;
  padding:.5rem
}

._ods_date_picker_header_date_ih07p_3233{
  font-size:1.125rem;
  line-height:1.75rem;
  --tw-text-opacity: 1;
  color:rgba(55, 65, 81,var(--tw-text-opacity));
  color:rgba(var(--color-dark-700),var(--tw-text-opacity))
}

._ods_date_picker_header_buttons_ih07p_3236>:not([hidden])~:not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right:calc(.5rem * var(--tw-space-x-reverse));
  margin-left:calc(.5rem * calc(1 - var(--tw-space-x-reverse)))
}

._ods_date_picker_header_button_ih07p_3236{
  display:inline-flex;
  border-radius:.25rem;
  border-width:1px;
  --tw-border-opacity: 1;
  border-color:rgba(209, 213, 219,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-300),var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color:rgba(255, 255, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-white),var(--tw-bg-opacity));
  padding:.25rem;
  font-size:.875rem;
  line-height:1.25rem;
  font-weight:500;
  --tw-text-opacity: 1;
  color:rgba(55, 65, 81,var(--tw-text-opacity));
  color:rgba(var(--color-dark-700),var(--tw-text-opacity));
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / .05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow:0 0 #0000,0 0 #0000,var(--tw-shadow);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow)
}

._ods_date_picker_header_button_ih07p_3236:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(249, 250, 251,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-50),var(--tw-bg-opacity))
}

._ods_date_picker_header_button_ih07p_3236:focus{
  outline:2px solid transparent;
  outline-offset:2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),0 0 #0000;
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(var(--color-primary-500), var(--tw-ring-opacity));
  --tw-ring-offset-width: 0px
}

._ods_date_picker_header_button_disabled_ih07p_3242{
  cursor:not-allowed;
  opacity:.5
}

._ods_date_picker_header_icon_ih07p_3245{
  height:1.25rem;
  width:1.25rem;
  --tw-text-opacity: 1;
  color:rgba(75, 85, 99,var(--tw-text-opacity));
  color:rgba(var(--color-dark-600),var(--tw-text-opacity))
}

._ods_progress_bar_ih07p_3249{
  overflow:hidden;
  border-radius:9999px;
  --tw-bg-opacity: 1;
  background-color:rgba(229, 231, 235,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-200),var(--tw-bg-opacity))
}

._ods_progress_bar_title_ih07p_3252{
  font-size:.875rem;
  line-height:1.25rem;
  font-weight:500;
  --tw-text-opacity: 1;
  color:rgba(17, 24, 39,var(--tw-text-opacity));
  color:rgba(var(--color-dark-900),var(--tw-text-opacity))
}

._ods_progress_bar_wrapper_ih07p_3255{
  margin-top:1.5rem
}

._ods_progress_bar_completed_ih07p_3258{
  height:.5rem;
  width:.75rem;
  border-radius:9999px;
  --tw-bg-opacity: 1;
  background-color:rgba(79, 70, 229,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-600),var(--tw-bg-opacity))
}

._ods_progress_bar_children_wrapper_ih07p_3261{
  margin-top:.75rem;
  display:none;
  grid-template-columns:repeat(4,minmax(0,1fr));
  font-size:.875rem;
  line-height:1.25rem;
  font-weight:500;
  --tw-text-opacity: 1;
  color:rgba(75, 85, 99,var(--tw-text-opacity));
  color:rgba(var(--color-dark-600),var(--tw-text-opacity))
}

@media (min-width: 640px){
  ._ods_progress_bar_children_wrapper_ih07p_3261{
    display:grid
  }
}

._ods_progress_bar_item_leading_ih07p_3264{
  text-align:left
}

._ods_progress_bar_item_center_ih07p_3267{
  text-align:center
}

._ods_progress_bar_item_trailing_ih07p_3270{
  text-align:right
}

._ods_progress_bar_item_primary_ih07p_3273{
  --tw-text-opacity: 1;
  color:rgba(79, 70, 229,var(--tw-text-opacity));
  color:rgba(var(--color-primary-600),var(--tw-text-opacity))
}

._ods_file_upload_preview_list_ih07p_3277{
  margin-top:1rem;
  padding:.5rem
}

@media (min-width: 768px){
  ._ods_file_upload_preview_list_ih07p_3277{
    margin-top:2rem
  }
}

._ods_file_upload_preview_list_heading_ih07p_3280{
  margin-bottom:.5rem;
  font-size:1rem;
  font-weight:500;
  line-height:1.5rem;
  --tw-text-opacity: 1;
  color:rgb(17 24 39 / var(--tw-text-opacity))
}

._ods_file_upload_preview_list_row_ih07p_3283{
  display:flex;
  width:100%;
  flex-direction:column;
  padding-top:.75rem;
  padding-bottom:.75rem;
  padding-left:0;
  padding-right:0
}

@media (min-width: 640px){
  ._ods_file_upload_preview_list_row_ih07p_3283{
    display:grid
  }
}

._ods_file_upload_preview_list_row_child_ih07p_3286{
  padding-left:0;
  padding-right:0
}

._ods_file_upload_preview_list_row_info_ih07p_3289{
  justify-content:flex-start
}

._ods_file_upload_preview_list_row_info_ih07p_3289 p{
  padding-left:.5rem
}

._ods_file_upload_preview_list_row_action_ih07p_3295{
  display:flex;
  justify-content:flex-end
}

._ods_file_upload_preview_list_last_ih07p_3298{
  border-bottom-width:1px;
  --tw-border-opacity: 1;
  border-color:rgba(229, 231, 235,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-200),var(--tw-border-opacity))
}

._ods_file_upload_preview_list_icon_ih07p_3301{
  height:2.5rem;
  width:2.5rem
}

._ods_file_upload_preview_list_error_ih07p_3304{
  margin-left:.5rem;
  height:1.25rem;
  width:1.25rem;
  --tw-text-opacity: 1;
  color:rgba(239, 68, 68,var(--tw-text-opacity));
  color:rgba(var(--color-danger-500),var(--tw-text-opacity))
}

._ods_file_upload_preview_list_p_ih07p_3307{
  font-size:.875rem;
  font-weight:500;
  line-height:1.25rem
}

@media (min-width: 640px){
  ._ods_description_list_body_ih07p_3311>:not([hidden])~:not([hidden]){
    --tw-divide-y-reverse: 0;
    border-top-width:calc(1px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width:calc(1px * var(--tw-divide-y-reverse));
    --tw-divide-opacity: 1;
    border-color:rgba(229, 231, 235,var(--tw-divide-opacity));
    border-color:rgba(var(--color-light-200),var(--tw-divide-opacity))
  }
}

._ods_description_list_group_ih07p_3315{
  padding:1rem
}

@media (min-width: 640px){
  ._ods_description_list_group_ih07p_3315{
    grid-gap:1rem;
    gap:1rem;
    padding:1.25rem 1.5rem
  }
}

._ods_description_list_group_ih07p_3315 a{
  font-weight:500;
  --tw-text-opacity: 1;
  color:rgba(79, 70, 229,var(--tw-text-opacity));
  color:rgba(var(--color-primary-600),var(--tw-text-opacity))
}

._ods_description_list_group_ih07p_3315 a:hover{
  --tw-text-opacity: 1;
  color:rgba(99, 102, 241,var(--tw-text-opacity));
  color:rgba(var(--color-primary-500),var(--tw-text-opacity));
  -webkit-text-decoration-line:none;
  text-decoration-line:none
}

._ods_description_list_group_ih07p_3315 span{
  margin-left:.5rem;
  width:0px;
  flex:1 1;
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
  --tw-text-opacity: 1;
  color:rgba(17, 24, 39,var(--tw-text-opacity));
  color:rgba(var(--color-dark-900),var(--tw-text-opacity))
}

._ods_description_list_group_ih07p_3315 svg{
  height:1.25rem;
  width:1.25rem;
  flex-shrink:0;
  --tw-text-opacity: 1;
  color:rgba(156, 163, 175,var(--tw-text-opacity));
  color:rgba(var(--color-light-400),var(--tw-text-opacity))
}

._ods_description_list_group_first_child_ih07p_3327 div:first-child{
  font-size:.875rem;
  line-height:1.25rem;
  font-weight:500;
  --tw-text-opacity: 1;
  color:rgba(107, 114, 128,var(--tw-text-opacity));
  color:rgba(var(--color-dark-500),var(--tw-text-opacity))
}

._ods_description_list_group_last_child_ih07p_3330 div:last-child{
  margin-top:.25rem;
  font-size:.875rem;
  line-height:1.25rem;
  --tw-text-opacity: 1;
  color:rgba(17, 24, 39,var(--tw-text-opacity));
  color:rgba(var(--color-dark-900),var(--tw-text-opacity))
}

@media (min-width: 640px){
  ._ods_description_list_group_last_child_ih07p_3330 div:last-child{
    margin-top:0
  }
}

._ods_dropdown_ih07p_515{
  position:relative;
  text-align:left
}

._ods_feeds_simple_ih07p_3338 ._ods_feed_wrapper_ih07p_3338,._ods_feeds_multiple_ih07p_3338 ._ods_feed_wrapper_ih07p_3338{
  position:relative;
  padding-bottom:2rem
}

@media (min-width: 768px){
  ._ods_feeds_simple_ih07p_3338 ._ods_feed_ih07p_1910:last-child ._ods_feed_wrapper_ih07p_3338,._ods_feeds_multiple_ih07p_3338 ._ods_feed_ih07p_1910:last-child ._ods_feed_wrapper_ih07p_3338{
    padding-bottom:0
  }
}

._ods_feeds_simple_ih07p_3338 ._ods_feed_content_wrapper_ih07p_1913>:not([hidden])~:not([hidden]),._ods_feeds_multiple_ih07p_3338 ._ods_feed_content_wrapper_ih07p_1913>:not([hidden])~:not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right:calc(.75rem * var(--tw-space-x-reverse));
  margin-left:calc(.75rem * calc(1 - var(--tw-space-x-reverse)))
}

._ods_feeds_simple_ih07p_3338 ._ods_feed_ih07p_1910:not(:last-child) ._ods_feed_line_ih07p_3347,._ods_feeds_multiple_ih07p_3338 ._ods_feed_ih07p_1910:not(:last-child) ._ods_feed_line_ih07p_3347{
  position:absolute;
  top:1rem;
  left:1rem;
  margin-left:-1px;
  height:100%;
  width:.125rem;
  --tw-bg-opacity: 1;
  background-color:rgba(229, 231, 235,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-200),var(--tw-bg-opacity))
}

._ods_feeds_stacked_ih07p_3350>:not([hidden])~:not([hidden]){
  --tw-divide-y-reverse: 0;
  border-top-width:calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width:calc(1px * var(--tw-divide-y-reverse));
  --tw-divide-opacity: 1;
  border-color:rgba(229, 231, 235,var(--tw-divide-opacity));
  border-color:rgba(var(--color-light-200),var(--tw-divide-opacity))
}

._ods_feeds_stacked_ih07p_3350 ._ods_feed_ih07p_1910{
  padding-top:1rem;
  padding-bottom:1rem
}

._ods_feeds_stacked_ih07p_3350 ._ods_feed_content_wrapper_ih07p_1913{
  margin-left:0
}

._ods_feeds_stacked_ih07p_3350 ._ods_feed_ih07p_1910 ._ods_feed_body_inline_ih07p_1926{
  padding-top:0
}

._ods_feeds_stacked_ih07p_3350 ._ods_feed_wrapper_ih07p_3338 ._ods_feed_line_ih07p_3347{
  display:none
}

._ods_feeds_multiple_ih07p_3338 ._ods_feed_ih07p_1910:not(:last-child) ._ods_feed_line_ih07p_3347{
  top:1.25rem;
  left:1.25rem
}

._ods_feeds_multiple_ih07p_3338 ._ods_feed_ih07p_1910 ._ods_feed_content_icon_wrapper_ih07p_1920{
  position:relative;
  padding-left:.25rem;
  padding-right:.25rem
}

._ods_feeds_multiple_ih07p_3338 ._ods_feed_ih07p_1910 ._ods_avatar_wrapper_ih07p_115{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),0 0 #0000;
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(var(--color-white), var(--tw-ring-opacity))
}

._ods_feeds_multiple_ih07p_3338 ._ods_feed_ih07p_1910 ._ods_feed_body_inline_ih07p_1926 ._ods_feed_helptext_ih07p_1932:last-child,._ods_feeds_multiple_ih07p_3338 ._ods_feed_ih07p_1910 ._ods_feed_body_block_ih07p_1938 ._ods_feed_helptext_ih07p_1932:last-child{
  white-space:normal
}

._ods_table_ih07p_782{
  min-width:100%
}

._ods_table_striped_ih07p_3382 tr:nth-child(even){
  --tw-bg-opacity: 1;
  background-color:rgba(249, 250, 251,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-50),var(--tw-bg-opacity))
}

._ods_table_wrapper_ih07p_3385{
  display:flex;
  flex-direction:column
}

._ods_table_wrapper_align_ih07p_3388{
  display:inline-block;
  min-width:100%;
  padding-top:.5rem;
  padding-bottom:.5rem;
  vertical-align:middle
}

@media (min-width: 640px){
  ._ods_table_wrapper_align_ih07p_3388{
    padding-left:1.5rem;
    padding-right:1.5rem
  }
}

@media (min-width: 1024px){
  ._ods_table_wrapper_align_ih07p_3388{
    padding-left:2rem;
    padding-right:2rem
  }
}

._ods_table_wrapper_overflow_ih07p_3391{
  margin-top:-.5rem;
  margin-bottom:-.5rem;
  overflow:visible
}

@media (min-width: 640px){
  ._ods_table_wrapper_overflow_ih07p_3391{
    margin-left:-1.5rem;
    margin-right:-1.5rem
  }
}

@media (min-width: 1024px){
  ._ods_table_wrapper_overflow_ih07p_3391{
    margin-left:-2rem;
    margin-right:-2rem
  }
}

._ods_table_wrapper_overflow_hidden_ih07p_3394{
  overflow:visible;
  border-bottom-width:1px;
  --tw-border-opacity: 1;
  border-color:rgba(229, 231, 235,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-200),var(--tw-border-opacity));
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / .1), 0 1px 2px -1px rgb(0 0 0 / .1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow:0 0 #0000,0 0 #0000,var(--tw-shadow);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow)
}

@media (min-width: 640px){
  ._ods_table_wrapper_overflow_hidden_ih07p_3394{
    border-radius:.5rem
  }
}

._ods_table_right_alignment_ih07p_3397 ._ods_table_header_ih07p_2368 th:last-child{
  text-align:right
}

._ods_table_right_alignment_ih07p_3397 ._ods_table_header_ih07p_2368 th:last-child>*{
  justify-content:flex-end
}

._ods_table_right_alignment_ih07p_3397 ._ods_table_body_ih07p_2375 td:last-child{
  text-align:right
}

._ods_table_center_alignment_ih07p_3406 ._ods_table_header_ih07p_2368 th:not(:last-child):not(:first-child){
  text-align:center
}

._ods_table_center_alignment_ih07p_3406 ._ods_table_header_ih07p_2368 th:not(:last-child):not(:first-child)>*{
  justify-content:center
}

._ods_table_center_alignment_ih07p_3406 ._ods_table_body_ih07p_2375 td:not(:last-child):not(:first-child){
  text-align:center
}

._ods_form_ih07p_1957>:not([hidden])~:not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top:calc(2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom:calc(2rem * var(--tw-space-y-reverse))
}

._ods_form_divided_ih07p_3419>:not([hidden])~:not([hidden]){
  --tw-divide-y-reverse: 0;
  border-top-width:calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width:calc(1px * var(--tw-divide-y-reverse));
  --tw-divide-opacity: 1;
  border-color:rgba(229, 231, 235,var(--tw-divide-opacity));
  border-color:rgba(var(--color-light-200),var(--tw-divide-opacity))
}

._ods_form_children_ih07p_3422>:not([hidden])~:not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top:calc(2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom:calc(2rem * var(--tw-space-y-reverse))
}

@media (min-width: 640px){
  ._ods_form_children_ih07p_3422>:not([hidden])~:not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top:calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(1.25rem * var(--tw-space-y-reverse))
  }
}

._ods_form_children_divided_ih07p_3425>:not([hidden])~:not([hidden]){
  --tw-divide-y-reverse: 0;
  border-top-width:calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width:calc(1px * var(--tw-divide-y-reverse));
  --tw-divide-opacity: 1;
  border-color:rgba(229, 231, 235,var(--tw-divide-opacity));
  border-color:rgba(var(--color-light-200),var(--tw-divide-opacity))
}

._ods_slide_over_ih07p_2379{
  position:fixed;
  top:0;
  bottom:0;
  right:0;
  display:flex;
  max-width:100%;
  padding-left:2.5rem
}

._ods_slide_over_button_outside_ih07p_3432{
  padding-left:0
}

._ods_slide_over_container_narrow_ih07p_3435{
  width:100vw;
  max-width:28rem
}

._ods_slide_over_container_wide_ih07p_3438{
  width:100vw;
  max-width:42rem
}

._ods_slide_over_wrapper_ih07p_3441{
  display:flex;
  height:100%;
  flex-direction:column;
  overflow-y:scroll;
  --tw-bg-opacity: 1;
  background-color:rgba(255, 255, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-white),var(--tw-bg-opacity));
  --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / .1), 0 8px 10px -6px rgb(0 0 0 / .1);
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow:0 0 #0000,0 0 #0000,var(--tw-shadow);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow)
}

._ods_slide_over_wrapper_footer_ih07p_3444{
  border-top-width:1px;
  --tw-border-opacity: 1;
  border-color:rgba(229, 231, 235,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-200),var(--tw-border-opacity))
}

._ods_slide_over_dialog_ih07p_3447{
  position:fixed;
  inset:0;
  overflow:hidden
}

._ods_slide_over_dialog_overlay_ih07p_3450{
  position:absolute;
  inset:0
}

._ods_slide_over_dialog_overlay_background_ih07p_3453{
  background-color:rgba(107, 114, 128,var(--tw-bg-opacity));
  background-color:rgba(var(--color-dark-500),var(--tw-bg-opacity));
  --tw-bg-opacity: .75;
  transition-property:opacity;
  transition-timing-function:cubic-bezier(.4,0,.2,1);
  transition-duration:.15s
}

._ods_slide_over_dialog_overlay_background_enter_ih07p_3456{
  transition-duration:.5s;
  transition-timing-function:cubic-bezier(.4,0,.2,1)
}

._ods_slide_over_dialog_overlay_background_enterFrom_ih07p_3459{
  opacity:0
}

._ods_slide_over_dialog_overlay_background_enterTo_ih07p_3462{
  opacity:1
}

._ods_slide_over_dialog_overlay_background_leave_ih07p_3465{
  transition-duration:.5s;
  transition-timing-function:cubic-bezier(.4,0,.2,1)
}

._ods_slide_over_dialog_overlay_background_leaveFrom_ih07p_3468{
  opacity:1
}

._ods_slide_over_dialog_overlay_background_leaveTo_ih07p_3471{
  opacity:0
}

._ods_slide_over_dialog_children_wrapper_ih07p_3474{
  position:absolute;
  inset:0;
  overflow:hidden
}

._ods_slide_over_actions_ih07p_3477{
  position:-webkit-sticky;
  position:sticky;
  display:flex;
  flex-shrink:0;
  justify-content:flex-end;
  padding:1rem
}

._ods_slide_over_actions_ih07p_3477>:last-child{
  margin-left:1rem
}

._ods_slide_over_transition_child_enter_ih07p_3483{
  -webkit-transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transition-property:color,background-color,border-color,fill,stroke,opacity,box-shadow,transform,filter,-webkit-text-decoration-color,-webkit-backdrop-filter;
  transition-property:color,background-color,border-color,fill,stroke,opacity,box-shadow,-webkit-text-decoration-color,-webkit-transform,-webkit-filter,-webkit-backdrop-filter;
  transition-property:color,background-color,border-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter;
  transition-property:color,background-color,border-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter,-webkit-text-decoration-color,-webkit-transform,-webkit-filter,-webkit-backdrop-filter;
  transition-property:color,background-color,border-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter,-webkit-text-decoration-color,-webkit-backdrop-filter;
  transition-duration:.5s;
  transition-timing-function:cubic-bezier(.4,0,.2,1)
}

@media (min-width: 640px){
  ._ods_slide_over_transition_child_enter_ih07p_3483{
    transition-duration:.7s
  }
}

._ods_slide_over_transition_child_enterFrom_ih07p_3486{
  --tw-translate-x: 100%;
  -webkit-transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

._ods_slide_over_transition_child_enterTo_ih07p_3489{
  --tw-translate-x: 0px;
  -webkit-transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

._ods_slide_over_transition_child_leave_ih07p_3492{
  -webkit-transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transition-property:color,background-color,border-color,fill,stroke,opacity,box-shadow,transform,filter,-webkit-text-decoration-color,-webkit-backdrop-filter;
  transition-property:color,background-color,border-color,fill,stroke,opacity,box-shadow,-webkit-text-decoration-color,-webkit-transform,-webkit-filter,-webkit-backdrop-filter;
  transition-property:color,background-color,border-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter;
  transition-property:color,background-color,border-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter,-webkit-text-decoration-color,-webkit-transform,-webkit-filter,-webkit-backdrop-filter;
  transition-property:color,background-color,border-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter,-webkit-text-decoration-color,-webkit-backdrop-filter;
  transition-duration:.5s;
  transition-timing-function:cubic-bezier(.4,0,.2,1)
}

@media (min-width: 640px){
  ._ods_slide_over_transition_child_leave_ih07p_3492{
    transition-duration:.7s
  }
}

._ods_slide_over_transition_child_leaveFrom_ih07p_3495{
  --tw-translate-x: 0px;
  -webkit-transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

._ods_slide_over_transition_child_leaveTo_ih07p_3498{
  --tw-translate-x: 100%;
  -webkit-transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

._ods_stats_ih07p_3502{
  margin-top:1.25rem
}

._ods_stats_simple_ih07p_3505 ._ods_stat_ih07p_1317,._ods_stats_brand_ih07p_3505 ._ods_stat_ih07p_1317{
  overflow:hidden;
  border-radius:.5rem;
  --tw-bg-opacity: 1;
  background-color:rgba(255, 255, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-white),var(--tw-bg-opacity));
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / .1), 0 1px 2px -1px rgb(0 0 0 / .1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow:0 0 #0000,0 0 #0000,var(--tw-shadow);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow)
}

._ods_stats_ih07p_3502 ._ods_stat_ih07p_1317{
  position:relative;
  display:flex;
  align-items:center;
  padding-top:1.25rem;
  padding-bottom:1.25rem
}

._ods_stats_ih07p_3502 ._ods_stat_title_ih07p_3511{
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
  font-size:.875rem;
  line-height:1.25rem;
  font-weight:500;
  --tw-text-opacity: 1;
  color:rgba(107, 114, 128,var(--tw-text-opacity));
  color:rgba(var(--color-dark-500),var(--tw-text-opacity))
}

._ods_stats_simple_ih07p_3505 ._ods_stat_ih07p_1317{
  padding-left:1rem;
  padding-right:1rem
}

@media (min-width: 640px){
  ._ods_stats_simple_ih07p_3505 ._ods_stat_ih07p_1317{
    padding:1.5rem
  }
}

._ods_stats_simple_ih07p_3505 ._ods_stat_body_ih07p_1317{
  margin-top:.25rem
}

@media (min-width: 640px){
  ._ods_stats_brand_ih07p_3505{
    grid-template-columns:repeat(2,minmax(0,1fr))
  }
}

@media (min-width: 1024px){
  ._ods_stats_brand_ih07p_3505{
    grid-template-columns:repeat(3,minmax(0,1fr))
  }
}

._ods_stats_brand_ih07p_3505 ._ods_stat_ih07p_1317{
  padding-left:1rem;
  padding-right:1rem;
  padding-top:1.25rem
}

@media (min-width: 640px){
  ._ods_stats_brand_ih07p_3505 ._ods_stat_ih07p_1317{
    padding-left:1.5rem;
    padding-right:1.5rem;
    padding-top:1.5rem
  }
}

._ods_stats_shared_borders_ih07p_3526>:not([hidden])~:not([hidden]){
  --tw-divide-y-reverse: 0;
  border-top-width:calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width:calc(1px * var(--tw-divide-y-reverse));
  --tw-divide-opacity: 1;
  border-color:rgba(229, 231, 235,var(--tw-divide-opacity));
  border-color:rgba(var(--color-light-200),var(--tw-divide-opacity))
}

._ods_stats_shared_borders_ih07p_3526{
  overflow:hidden;
  border-radius:.5rem;
  --tw-bg-opacity: 1;
  background-color:rgba(255, 255, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-white),var(--tw-bg-opacity));
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / .1), 0 1px 2px -1px rgb(0 0 0 / .1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow:0 0 #0000,0 0 #0000,var(--tw-shadow);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow)
}

@media (min-width: 768px){
  ._ods_stats_shared_borders_ih07p_3526{
    grid-template-columns:repeat(3,minmax(0,1fr))
  }

  ._ods_stats_shared_borders_ih07p_3526>:not([hidden])~:not([hidden]){
    --tw-divide-y-reverse: 0;
    border-top-width:calc(0px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width:calc(0px * var(--tw-divide-y-reverse));
    --tw-divide-x-reverse: 0;
    border-right-width:calc(1px * var(--tw-divide-x-reverse));
    border-left-width:calc(1px * calc(1 - var(--tw-divide-x-reverse)))
  }
}

._ods_stats_shared_borders_ih07p_3526 ._ods_stat_ih07p_1317{
  padding-left:1rem;
  padding-right:1rem
}

@media (min-width: 640px){
  ._ods_stats_shared_borders_ih07p_3526 ._ods_stat_ih07p_1317{
    padding:1.5rem
  }
}

._ods_stats_shared_borders_ih07p_3526 ._ods_stat_ih07p_1317 div:last-child{
  flex:1 1
}

._ods_stats_shared_borders_ih07p_3526 ._ods_stat_body_ih07p_1317{
  margin-top:.25rem;
  display:block;
  justify-content:space-between
}

@media (min-width: 1024px){
  ._ods_stats_shared_borders_ih07p_3526 ._ods_stat_body_ih07p_1317{
    display:flex
  }
}

._ods_stats_footer_ih07p_3538 ._ods_stat_ih07p_1317{
  padding-bottom:5rem
}

._ods_step_simple_ih07p_3542 ol>:not([hidden])~:not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top:calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom:calc(1rem * var(--tw-space-y-reverse))
}

@media (min-width: 768px){
  ._ods_step_simple_ih07p_3542 ol{
    display:flex
  }

  ._ods_step_simple_ih07p_3542 ol>:not([hidden])~:not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top:calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(0px * var(--tw-space-y-reverse));
    --tw-space-x-reverse: 0;
    margin-right:calc(2rem * var(--tw-space-x-reverse));
    margin-left:calc(2rem * calc(1 - var(--tw-space-x-reverse)))
  }

  ._ods_step_simple_ih07p_3542 ol li{
    flex:1 1
  }
}

._ods_step_panel_ih07p_3548._ods_step_panel_withBorder_ih07p_3548{
  margin-left:auto;
  margin-right:auto;
  max-width:80rem;
  padding-left:1rem;
  padding-right:1rem
}

@media (min-width: 640px){
  ._ods_step_panel_ih07p_3548._ods_step_panel_withBorder_ih07p_3548{
    padding-left:1.5rem;
    padding-right:1.5rem
  }
}

@media (min-width: 1024px){
  ._ods_step_panel_ih07p_3548._ods_step_panel_withBorder_ih07p_3548{
    padding-left:2rem;
    padding-right:2rem
  }
}

._ods_step_panel_ih07p_3548 ol._ods_step_panel_borderless_ih07p_3551>:not([hidden])~:not([hidden]){
  --tw-divide-y-reverse: 0;
  border-top-width:calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width:calc(1px * var(--tw-divide-y-reverse));
  --tw-divide-opacity: 1;
  border-color:rgba(209, 213, 219,var(--tw-divide-opacity));
  border-color:rgba(var(--color-light-300),var(--tw-divide-opacity))
}

._ods_step_panel_ih07p_3548 ol._ods_step_panel_borderless_ih07p_3551{
  border-radius:.375rem;
  border-width:1px;
  --tw-border-opacity: 1;
  border-color:rgba(209, 213, 219,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-300),var(--tw-border-opacity))
}

@media (min-width: 768px){
  ._ods_step_panel_ih07p_3548 ol._ods_step_panel_borderless_ih07p_3551{
    display:flex
  }

  ._ods_step_panel_ih07p_3548 ol._ods_step_panel_borderless_ih07p_3551>:not([hidden])~:not([hidden]){
    --tw-divide-y-reverse: 0;
    border-top-width:calc(0px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width:calc(0px * var(--tw-divide-y-reverse))
  }
}

._ods_step_panel_ih07p_3548 ol._ods_step_panel_borderless_ih07p_3551 li{
  position:relative
}

@media (min-width: 768px){
  ._ods_step_panel_ih07p_3548 ol._ods_step_panel_borderless_ih07p_3551 li{
    display:flex;
    flex:1 1
  }
}

._ods_step_panel_ih07p_3548 ol._ods_step_panel_borderless_ih07p_3551 li ._ods_step_panel_arrowSeperator_ih07p_3557{
  position:absolute;
  top:0;
  right:0;
  display:none;
  height:100%;
  width:1.25rem
}

@media (min-width: 768px){
  ._ods_step_panel_ih07p_3548 ol._ods_step_panel_borderless_ih07p_3551 li ._ods_step_panel_arrowSeperator_ih07p_3557{
    display:block
  }
}

._ods_step_panel_ih07p_3548 ol._ods_step_panel_borderless_ih07p_3551 li ._ods_step_panel_arrowSeperator_ih07p_3557 svg{
  height:100%;
  width:100%;
  --tw-text-opacity: 1;
  color:rgba(209, 213, 219,var(--tw-text-opacity));
  color:rgba(var(--color-light-300),var(--tw-text-opacity))
}

._ods_step_panel_ih07p_3548 ol._ods_step_panel_borderless_ih07p_3551 li:last-child ._ods_step_panel_arrowSeperator_ih07p_3557{
  display:none
}

._ods_step_panel_ih07p_3548 ol._ods_step_panel_border_ih07p_3551{
  overflow:hidden;
  border-radius:.375rem
}

@media (min-width: 1024px){
  ._ods_step_panel_ih07p_3548 ol._ods_step_panel_border_ih07p_3551{
    display:flex;
    border-radius:0;
    border-left-width:1px;
    border-right-width:1px;
    --tw-border-opacity: 1;
    border-color:rgba(229, 231, 235,var(--tw-border-opacity));
    border-color:rgba(var(--color-light-200),var(--tw-border-opacity))
  }
}

._ods_step_panel_ih07p_3548 ol._ods_step_panel_border_ih07p_3551 li{
  position:relative;
  overflow:hidden
}

@media (min-width: 1024px){
  ._ods_step_panel_ih07p_3548 ol._ods_step_panel_border_ih07p_3551 li{
    flex:1 1
  }
}

._ods_step_panel_ih07p_3548 ol._ods_step_panel_border_ih07p_3551 li ._ods_step_panel_arrowSeperator_ih07p_3557{
  position:absolute;
  inset:0;
  display:none;
  width:.75rem
}

@media (min-width: 1024px){
  ._ods_step_panel_ih07p_3548 ol._ods_step_panel_border_ih07p_3551 li ._ods_step_panel_arrowSeperator_ih07p_3557{
    display:block
  }
}

._ods_step_panel_ih07p_3548 ol._ods_step_panel_border_ih07p_3551 li ._ods_step_panel_arrowSeperator_ih07p_3557 svg{
  height:100%;
  width:100%;
  --tw-text-opacity: 1;
  color:rgba(209, 213, 219,var(--tw-text-opacity));
  color:rgba(var(--color-light-300),var(--tw-text-opacity))
}

._ods_step_panel_ih07p_3548 ol._ods_step_panel_border_ih07p_3551 li ._ods_step_panel_wrapper_ih07p_3578{
  overflow:hidden;
  border-width:1px;
  --tw-border-opacity: 1;
  border-color:rgba(229, 231, 235,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-200),var(--tw-border-opacity))
}

@media (min-width: 1024px){
  ._ods_step_panel_ih07p_3548 ol._ods_step_panel_border_ih07p_3551 li ._ods_step_panel_wrapper_ih07p_3578{
    border-width:0px
  }
}

._ods_step_panel_ih07p_3548 ol._ods_step_panel_border_ih07p_3551 li:first-child ._ods_step_panel_wrapper_ih07p_3578{
  border-top-left-radius:.375rem;
  border-top-right-radius:.375rem;
  border-bottom-width:0px
}

._ods_step_panel_ih07p_3548 ol._ods_step_panel_border_ih07p_3551 li:first-child ._ods_step_panel_arrowSeperator_ih07p_3557{
  display:none
}

._ods_step_panel_ih07p_3548 ol._ods_step_panel_border_ih07p_3551 li:last-child ._ods_step_panel_wrapper_ih07p_3578{
  border-bottom-right-radius:.375rem;
  border-bottom-left-radius:.375rem;
  border-top-width:0px
}

._ods_step_bullet_ih07p_3590{
  display:flex;
  justify-content:center
}

._ods_step_bullet_ih07p_3590._ods_step_bullet_horizontal_ih07p_3593{
  align-items:center
}

._ods_step_bullet_ih07p_3590 ol._ods_step_bullet_vertical_ih07p_3596>:not([hidden])~:not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top:calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom:calc(1.5rem * var(--tw-space-y-reverse))
}

._ods_step_bullet_ih07p_3590 ol._ods_step_bullet_horizontal_ih07p_3593{
  margin-left:2rem;
  display:flex;
  align-items:center
}

._ods_step_bullet_ih07p_3590 ol._ods_step_bullet_horizontal_ih07p_3593>:not([hidden])~:not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right:calc(1.25rem * var(--tw-space-x-reverse));
  margin-left:calc(1.25rem * calc(1 - var(--tw-space-x-reverse)))
}

._ods_step_circle_ih07p_2687 ol._ods_step_circle_vertical_ih07p_3602{
  overflow:hidden
}

._ods_step_circle_ih07p_2687 ol._ods_step_circle_vertical_ih07p_3602 li:not(:last-child){
  padding-bottom:2.5rem
}

._ods_step_circle_ih07p_2687 ol._ods_step_circle_horizontal_ih07p_3608{
  display:flex;
  align-items:center
}

._ods_step_circle_ih07p_2687 ol._ods_step_circle_horizontal_ih07p_3608 li:not(:last-child){
  padding-right:2rem
}

@media (min-width: 640px){
  ._ods_step_circle_ih07p_2687 ol._ods_step_circle_horizontal_ih07p_3608 li:not(:last-child){
    padding-right:5rem
  }
}

._ods_step_circle_ih07p_2687 ol li{
  position:relative
}

@media (min-width: 1024px){
  ._ods_step_wrapper_panel_border_ih07p_3618{
    border-top-width:1px;
    border-bottom-width:1px;
    --tw-border-opacity: 1;
    border-color:rgba(229, 231, 235,var(--tw-border-opacity));
    border-color:rgba(var(--color-light-200),var(--tw-border-opacity))
  }
}

._ods_step_wrapper_bullet_vertical_ih07p_3622{
  padding:3rem 1rem
}

@media (min-width: 640px){
  ._ods_step_wrapper_bullet_vertical_ih07p_3622{
    padding-left:1.5rem;
    padding-right:1.5rem
  }
}

@media (min-width: 1024px){
  ._ods_step_wrapper_bullet_vertical_ih07p_3622{
    padding-left:2rem;
    padding-right:2rem
  }
}

._ods_modal_ih07p_1409{
  position:fixed;
  inset:0;
  z-index:10;
  overflow-y:auto
}

._ods_modal_contentWrapper_ih07p_3629{
  display:flex;
  min-height:100vh;
  align-items:flex-end;
  justify-content:center;
  padding:1rem 1rem 5rem;
  text-align:center
}

@media (min-width: 640px){
  ._ods_modal_contentWrapper_ih07p_3629{
    display:block;
    padding:0
  }
}

._ods_modal_overlay_ih07p_3632{
  position:fixed;
  inset:0;
  background-color:rgba(107, 114, 128,var(--tw-bg-opacity));
  background-color:rgba(var(--color-dark-500),var(--tw-bg-opacity));
  --tw-bg-opacity: .75;
  transition-property:opacity;
  transition-timing-function:cubic-bezier(.4,0,.2,1);
  transition-duration:.15s
}

._ods_modal_centerItems_ih07p_3635{
  display:none
}

@media (min-width: 640px){
  ._ods_modal_centerItems_ih07p_3635{
    display:inline-block;
    height:100vh;
    vertical-align:middle
  }
}

._ods_modal_normal_ih07p_3638,._ods_modal_wide_ih07p_3638,._ods_modal_narrow_ih07p_3638{
  display:inline-block;
  -webkit-transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  overflow:hidden;
  border-radius:.5rem;
  --tw-bg-opacity: 1;
  background-color:rgba(255, 255, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-white),var(--tw-bg-opacity));
  text-align:left;
  vertical-align:bottom;
  --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / .1), 0 8px 10px -6px rgb(0 0 0 / .1);
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow:0 0 #0000,0 0 #0000,var(--tw-shadow);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow);
  transition-property:all;
  transition-timing-function:cubic-bezier(.4,0,.2,1);
  transition-duration:.15s
}

@media (min-width: 640px){
  ._ods_modal_normal_ih07p_3638,._ods_modal_wide_ih07p_3638,._ods_modal_narrow_ih07p_3638{
    margin-top:2rem;
    margin-bottom:2rem;
    vertical-align:middle
  }
}

._ods_modal_normal_ih07p_3638,._ods_modal_wide_ih07p_3638{
  padding:1.25rem 1rem 1rem
}

@media (min-width: 640px){
  ._ods_modal_normal_ih07p_3638,._ods_modal_wide_ih07p_3638{
    width:100%;
    padding:1.5rem
  }

  ._ods_modal_normal_ih07p_3638{
    max-width:24rem
  }

  ._ods_modal_narrow_ih07p_3638{
    width:100%;
    max-width:32rem
  }

  ._ods_modal_wide_ih07p_3638{
    max-width:32rem
  }
}

._ods_modal_dismiss_ih07p_3653{
  position:absolute;
  top:0;
  right:0;
  display:none;
  padding-top:1rem;
  padding-right:1rem
}

@media (min-width: 640px){
  ._ods_modal_dismiss_ih07p_3653{
    display:block
  }
}

._ods_modal_dismiss_ih07p_3653 button{
  border-style:none;
  --tw-bg-opacity: 1;
  background-color:rgba(255, 255, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-white),var(--tw-bg-opacity));
  padding:0;
  --tw-text-opacity: 1;
  color:rgba(156, 163, 175,var(--tw-text-opacity));
  color:rgba(var(--color-light-400),var(--tw-text-opacity));
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow:0 0 #0000,0 0 #0000,var(--tw-shadow);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow)
}

._ods_modal_dismiss_ih07p_3653 button:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(255, 255, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-white),var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:rgba(107, 114, 128,var(--tw-text-opacity));
  color:rgba(var(--color-dark-500),var(--tw-text-opacity))
}

._ods_modal_dismiss_ih07p_3653 button svg{
  height:1.5rem;
  width:1.5rem
}

._ods_modal_gray_footer_ih07p_3662 ._ods_modal_body_ih07p_2881{
  padding:1.25rem 1rem 1rem
}

@media (min-width: 640px){
  ._ods_modal_gray_footer_ih07p_3662 ._ods_modal_body_ih07p_2881{
    padding:1.5rem 1.5rem 1rem
  }
}

._ods_modal_gray_footer_ih07p_3662 ._ods_modal_action_ih07p_1409{
  --tw-bg-opacity: 1;
  background-color:rgba(249, 250, 251,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-50),var(--tw-bg-opacity));
  padding:.75rem 1rem
}

@media (min-width: 640px){
  ._ods_modal_gray_footer_ih07p_3662 ._ods_modal_action_ih07p_1409{
    display:flex;
    flex-direction:row-reverse;
    padding-left:1.5rem;
    padding-right:1.5rem
  }
}

._ods_modal_gray_footer_ih07p_3662 ._ods_modal_action_ih07p_1409._ods_modal_action_leading_ih07p_1415{
  margin-left:0
}

@media (min-width: 640px){
  ._ods_modal_gray_footer_ih07p_3662 ._ods_modal_action_ih07p_1409._ods_modal_action_leading_ih07p_1415{
    display:flex;
    flex-direction:row;
    padding-left:5rem
  }
}

._ods_modal_transition_enter_ih07p_3671{
  transition-duration:.3s;
  transition-timing-function:cubic-bezier(0,0,.2,1)
}

._ods_modal_transition_enterFrom_ih07p_3674,._ods_modal_transition_leaveTo_ih07p_3674{
  opacity:0
}

._ods_modal_transition_enterFrom_body_ih07p_3677,._ods_modal_transition_leaveTo_body_ih07p_3677{
  --tw-translate-y: 1rem;
  -webkit-transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

@media (min-width: 640px){
  ._ods_modal_transition_enterFrom_body_ih07p_3677,._ods_modal_transition_leaveTo_body_ih07p_3677{
    --tw-translate-y: 0px;
    --tw-scale-x: .95;
    --tw-scale-y: .95;
    -webkit-transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
  }
}

._ods_modal_transition_enterTo_ih07p_3680,._ods_modal_transition_leaveFrom_ih07p_3680{
  opacity:1
}

._ods_modal_transition_enterTo_body_ih07p_3683,._ods_modal_transition_leaveFrom_body_ih07p_3683{
  --tw-translate-y: 0px;
  -webkit-transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

@media (min-width: 640px){
  ._ods_modal_transition_enterTo_body_ih07p_3683,._ods_modal_transition_leaveFrom_body_ih07p_3683{
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    -webkit-transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
  }
}

._ods_modal_transition_leave_ih07p_3674{
  transition-duration:.2s;
  transition-timing-function:cubic-bezier(.4,0,1,1)
}

._ods_navbar_wrapper_ih07p_3690{
  height:4rem;
  grid-template-columns:-webkit-max-content auto -webkit-max-content;
  grid-template-columns:max-content auto max-content;
  align-items:center
}

._ods_navbar_z_index_ih07p_3693{
  z-index:50
}

._ods_navbar_primary_ih07p_3696{
  --tw-bg-opacity: 1;
  background-color:rgba(79, 70, 229,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-600),var(--tw-bg-opacity))
}

._ods_navbar_primary_ih07p_3696 svg{
  --tw-text-opacity: 1;
  color:rgba(255, 255, 255,var(--tw-text-opacity));
  color:rgba(var(--color-white),var(--tw-text-opacity))
}

._ods_navbar_primary_ih07p_3696 svg:hover{
  --tw-text-opacity: 1;
  color:rgba(229, 231, 235,var(--tw-text-opacity));
  color:rgba(var(--color-light-200),var(--tw-text-opacity))
}

._ods_navbar_primary_ih07p_3696>div ._ods_navbar_link_default_ih07p_3702{
  --tw-text-opacity: 1;
  color:rgba(255, 255, 255,var(--tw-text-opacity));
  color:rgba(var(--color-white),var(--tw-text-opacity))
}

._ods_navbar_primary_ih07p_3696>div ._ods_navbar_link_default_ih07p_3702:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(99, 102, 241,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-500),var(--tw-bg-opacity))
}

._ods_navbar_primary_ih07p_3696>div ._ods_navbar_link_active_ih07p_3705{
  --tw-bg-opacity: 1;
  background-color:rgba(67, 56, 202,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-700),var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:rgba(255, 255, 255,var(--tw-text-opacity));
  color:rgba(var(--color-white),var(--tw-text-opacity))
}

._ods_navbar_primary_ih07p_3696>div ._ods_navbar_content_disclosure_button_ih07p_2936{
  --tw-text-opacity: 1;
  color:rgba(255, 255, 255,var(--tw-text-opacity));
  color:rgba(var(--color-white),var(--tw-text-opacity))
}

._ods_navbar_primary_ih07p_3696>div ._ods_navbar_content_disclosure_button_ih07p_2936:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(67, 56, 202,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-700),var(--tw-bg-opacity))
}

._ods_navbar_primary_ih07p_3696>div ._ods_navbar_content_disclosure_button_ih07p_2936:focus{
  outline:2px solid transparent;
  outline-offset:2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),0 0 #0000;
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),var(--tw-shadow, 0 0 #0000);
  --tw-ring-inset: inset;
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(var(--color-white), var(--tw-ring-opacity))
}

._ods_navbar_white_ih07p_3711{
  --tw-bg-opacity: 1;
  background-color:rgba(255, 255, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-white),var(--tw-bg-opacity))
}

._ods_navbar_white_ih07p_3711 ._ods_dropdown_trigger_avatar_ih07p_536:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(var(--color-primary-500), var(--tw-ring-opacity));
  --tw-ring-offset-color: rgb(var(--color-white))
}

._ods_navbar_white_ih07p_3711 svg{
  --tw-text-opacity: 1;
  color:rgba(156, 163, 175,var(--tw-text-opacity));
  color:rgba(var(--color-light-400),var(--tw-text-opacity))
}

._ods_navbar_white_ih07p_3711 svg:hover{
  --tw-text-opacity: 1;
  color:rgba(107, 114, 128,var(--tw-text-opacity));
  color:rgba(var(--color-dark-500),var(--tw-text-opacity))
}

._ods_navbar_white_ih07p_3711 button{
  --tw-ring-offset-color: rgb(var(--color-primary-500))
}

._ods_navbar_white_ih07p_3711>div ._ods_navbar_link_default_ih07p_3702{
  --tw-text-opacity: 1;
  color:rgba(75, 85, 99,var(--tw-text-opacity));
  color:rgba(var(--color-dark-600),var(--tw-text-opacity))
}

._ods_navbar_white_ih07p_3711>div ._ods_navbar_link_default_ih07p_3702:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(249, 250, 251,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-50),var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:rgba(17, 24, 39,var(--tw-text-opacity));
  color:rgba(var(--color-dark-900),var(--tw-text-opacity))
}

._ods_navbar_white_ih07p_3711>div ._ods_navbar_link_active_ih07p_3705{
  --tw-bg-opacity: 1;
  background-color:rgba(243, 244, 246,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-100),var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:rgba(17, 24, 39,var(--tw-text-opacity));
  color:rgba(var(--color-dark-900),var(--tw-text-opacity))
}

._ods_navbar_white_ih07p_3711>div ._ods_navbar_content_disclosure_button_ih07p_2936{
  --tw-text-opacity: 1;
  color:rgba(156, 163, 175,var(--tw-text-opacity));
  color:rgba(var(--color-light-400),var(--tw-text-opacity))
}

._ods_navbar_white_ih07p_3711>div ._ods_navbar_content_disclosure_button_ih07p_2936:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(249, 250, 251,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-50),var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:rgba(75, 85, 99,var(--tw-text-opacity));
  color:rgba(var(--color-dark-600),var(--tw-text-opacity))
}

._ods_navbar_white_ih07p_3711>div ._ods_navbar_content_disclosure_button_ih07p_2936:focus{
  outline:2px solid transparent;
  outline-offset:2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),0 0 #0000;
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),var(--tw-shadow, 0 0 #0000);
  --tw-ring-inset: inset;
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(var(--color-dark-500), var(--tw-ring-opacity))
}

._ods_navbar_dark_ih07p_3732{
  --tw-bg-opacity: 1;
  background-color:rgba(31, 41, 55,var(--tw-bg-opacity));
  background-color:rgba(var(--color-dark-800),var(--tw-bg-opacity))
}

._ods_navbar_dark_ih07p_3732 svg{
  --tw-text-opacity: 1;
  color:rgba(156, 163, 175,var(--tw-text-opacity));
  color:rgba(var(--color-light-400),var(--tw-text-opacity))
}

._ods_navbar_dark_ih07p_3732 svg:hover{
  --tw-text-opacity: 1;
  color:rgba(255, 255, 255,var(--tw-text-opacity));
  color:rgba(var(--color-white),var(--tw-text-opacity))
}

._ods_navbar_dark_ih07p_3732>div ._ods_navbar_link_default_ih07p_3702{
  --tw-text-opacity: 1;
  color:rgba(209, 213, 219,var(--tw-text-opacity));
  color:rgba(var(--color-light-300),var(--tw-text-opacity))
}

._ods_navbar_dark_ih07p_3732>div ._ods_navbar_link_default_ih07p_3702:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(55, 65, 81,var(--tw-bg-opacity));
  background-color:rgba(var(--color-dark-700),var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:rgba(255, 255, 255,var(--tw-text-opacity));
  color:rgba(var(--color-white),var(--tw-text-opacity))
}

._ods_navbar_dark_ih07p_3732>div ._ods_navbar_link_active_ih07p_3705{
  --tw-bg-opacity: 1;
  background-color:rgba(17, 24, 39,var(--tw-bg-opacity));
  background-color:rgba(var(--color-dark-900),var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:rgba(255, 255, 255,var(--tw-text-opacity));
  color:rgba(var(--color-white),var(--tw-text-opacity))
}

._ods_navbar_dark_ih07p_3732>div ._ods_navbar_content_disclosure_button_ih07p_2936{
  --tw-text-opacity: 1;
  color:rgba(156, 163, 175,var(--tw-text-opacity));
  color:rgba(var(--color-light-400),var(--tw-text-opacity))
}

._ods_navbar_dark_ih07p_3732>div ._ods_navbar_content_disclosure_button_ih07p_2936:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(55, 65, 81,var(--tw-bg-opacity));
  background-color:rgba(var(--color-dark-700),var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:rgba(255, 255, 255,var(--tw-text-opacity));
  color:rgba(var(--color-white),var(--tw-text-opacity))
}

._ods_navbar_dark_ih07p_3732>div ._ods_navbar_content_disclosure_button_ih07p_2936:focus{
  outline:2px solid transparent;
  outline-offset:2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),0 0 #0000;
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),var(--tw-shadow, 0 0 #0000);
  --tw-ring-inset: inset;
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(var(--color-white), var(--tw-ring-opacity))
}

._ods_navbar_leading_ih07p_3747>div:nth-child(1){
  order:2
}

@media (min-width: 640px){
  ._ods_navbar_leading_ih07p_3747>div:nth-child(1){
    order:1
  }
}

._ods_navbar_leading_ih07p_3747>div:nth-child(2){
  z-index:20;
  order:1
}

@media (min-width: 640px){
  ._ods_navbar_leading_ih07p_3747>div:nth-child(2){
    order:2
  }
}

._ods_navbar_leading_ih07p_3747>div:nth-child(3){
  z-index:10;
  order:3
}

._ods_navbar_trailing_ih07p_3756>div:nth-child(1){
  position:static;
  left:0;
  order:1;
  margin-left:0;
  display:flex;
  justify-content:center
}

._ods_navbar_trailing_ih07p_3756>div:nth-child(2){
  z-index:20;
  order:3;
  justify-content:flex-end;
  padding-left:.75rem
}

@media (min-width: 640px){
  ._ods_navbar_trailing_ih07p_3756>div:nth-child(2){
    order:2;
    justify-content:flex-start;
    padding-left:0
  }
}

._ods_navbar_trailing_ih07p_3756>div:nth-child(3){
  z-index:10;
  order:2
}

@media (min-width: 640px){
  ._ods_navbar_trailing_ih07p_3756>div:nth-child(3){
    order:3
  }

  ._ods_navbar_disclosure_ih07p_3765{
    display:none
  }
}

._ods_navbar_disclosure_panel_ih07p_3768>:not([hidden])~:not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top:calc(.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom:calc(.25rem * var(--tw-space-y-reverse))
}

._ods_navbar_disclosure_panel_ih07p_3768{
  padding:.5rem .5rem .75rem
}

._ods_navbar_disclosure_children_ih07p_3771>a{
  width:100%
}

._ods_navbar_group_hidden_ih07p_3774{
  display:none
}

@media (min-width: 640px){
  ._ods_navbar_group_hidden_ih07p_3774{
    display:flex
  }
}

._ods_notifications_ih07p_3778{
  pointer-events:none;
  position:fixed;
  inset:0;
  display:flex;
  flex-direction:column;
  align-items:flex-end;
  grid-gap:1.5rem;
  gap:1.5rem;
  padding:1.5rem 1rem
}

@media (min-width: 640px){
  ._ods_notifications_ih07p_3778{
    align-items:flex-start;
    padding:1.5rem
  }
}

._ods_notification_ih07p_1445{
  display:flex;
  width:100%;
  flex-direction:column;
  align-items:center
}

._ods_notification_ih07p_1445>:not([hidden])~:not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top:calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom:calc(1rem * var(--tw-space-y-reverse))
}

@media (min-width: 640px){
  ._ods_notification_ih07p_1445{
    align-items:flex-end
  }
}

._ods_notification_ih07p_1445>div{
  pointer-events:auto;
  width:100%;
  border-radius:.5rem;
  --tw-bg-opacity: 1;
  background-color:rgba(255, 255, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-white),var(--tw-bg-opacity));
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / .1), 0 4px 6px -4px rgb(0 0 0 / .1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow:0 0 #0000,0 0 #0000,var(--tw-shadow);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow);
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),0 0 #0000;
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),var(--tw-shadow, 0 0 #0000);
  --tw-ring-color: rgba(var(--color-black), var(--tw-ring-opacity));
  --tw-ring-opacity: .05
}

._ods_notification_ih07p_1445>div._ods_notification_body_sm_ih07p_3788{
  max-width:24rem
}

._ods_notification_ih07p_1445>div._ods_notification_body_md_ih07p_3791{
  max-width:28rem
}

._ods_notification_ih07p_1445>div._ods_notification_stretch_ih07p_3794{
  display:flex
}

._ods_notification_ih07p_1445>div._ods_notification_divided_ih07p_3797{
  display:flex
}

._ods_notification_ih07p_1445>div._ods_notification_divided_ih07p_3797>:not([hidden])~:not([hidden]){
  --tw-divide-x-reverse: 0;
  border-right-width:calc(1px * var(--tw-divide-x-reverse));
  border-left-width:calc(1px * calc(1 - var(--tw-divide-x-reverse)));
  --tw-divide-opacity: 1;
  border-color:rgba(229, 231, 235,var(--tw-divide-opacity));
  border-color:rgba(var(--color-light-200),var(--tw-divide-opacity))
}

._ods_notification_ih07p_1445>div._ods_notification_divided_ih07p_3797 ._ods_notification_body_wrapper_ih07p_2096{
  display:flex;
  width:0px;
  flex:1 1;
  align-items:center
}

._ods_notification_ih07p_1445>div ._ods_notification_body_wrapper_ih07p_2096{
  padding:1rem
}

._ods_notification_transition_enter_ih07p_3806{
  -webkit-transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transition-property:color,background-color,border-color,fill,stroke,opacity,box-shadow,transform,filter,-webkit-text-decoration-color,-webkit-backdrop-filter;
  transition-property:color,background-color,border-color,fill,stroke,opacity,box-shadow,-webkit-text-decoration-color,-webkit-transform,-webkit-filter,-webkit-backdrop-filter;
  transition-property:color,background-color,border-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter;
  transition-property:color,background-color,border-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter,-webkit-text-decoration-color,-webkit-transform,-webkit-filter,-webkit-backdrop-filter;
  transition-property:color,background-color,border-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter,-webkit-text-decoration-color,-webkit-backdrop-filter;
  transition-timing-function:cubic-bezier(.4,0,.2,1);
  transition-duration:.3s;
  transition-timing-function:cubic-bezier(0,0,.2,1)
}

._ods_notification_transition_enter_from_ih07p_3809{
  --tw-translate-y: .5rem;
  -webkit-transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  opacity:0
}

@media (min-width: 640px){
  ._ods_notification_transition_enter_from_ih07p_3809{
    --tw-translate-y: 0px;
    --tw-translate-x: .5rem;
    -webkit-transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
  }
}

._ods_notification_transition_enter_to_ih07p_3812{
  --tw-translate-y: 0px;
  -webkit-transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  opacity:1
}

@media (min-width: 640px){
  ._ods_notification_transition_enter_to_ih07p_3812{
    --tw-translate-x: 0px;
    -webkit-transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
  }
}

._ods_notification_transition_leave_ih07p_3815{
  transition-property:color,background-color,border-color,fill,stroke,opacity,box-shadow,transform,filter,-webkit-text-decoration-color,-webkit-backdrop-filter;
  transition-property:color,background-color,border-color,fill,stroke,opacity,box-shadow,-webkit-text-decoration-color,-webkit-transform,-webkit-filter,-webkit-backdrop-filter;
  transition-property:color,background-color,border-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter;
  transition-property:color,background-color,border-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter,-webkit-text-decoration-color,-webkit-transform,-webkit-filter,-webkit-backdrop-filter;
  transition-property:color,background-color,border-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter,-webkit-text-decoration-color,-webkit-backdrop-filter;
  transition-timing-function:cubic-bezier(.4,0,.2,1);
  transition-duration:.1s;
  transition-timing-function:cubic-bezier(.4,0,1,1)
}

._ods_notification_transition_leave_from_ih07p_3818{
  opacity:1
}

._ods_notification_transition_leave_to_ih07p_3821{
  opacity:0
}

._ods_vertical_navigation_ih07p_3825>:not([hidden])~:not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top:calc(.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom:calc(.25rem * var(--tw-space-y-reverse))
}

._ods_vertical_navigation_gray_ih07p_3828 ._ods_vertical_navigation_children_active_ih07p_3828{
  --tw-bg-opacity: 1;
  background-color:rgba(229, 231, 235,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-200),var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:rgba(17, 24, 39,var(--tw-text-opacity));
  color:rgba(var(--color-dark-900),var(--tw-text-opacity))
}

._ods_vertical_navigation_group_ih07p_3831{
  margin-top:.25rem
}

._ods_vertical_navigation_group_ih07p_3831>:not([hidden])~:not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top:calc(.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom:calc(.25rem * var(--tw-space-y-reverse))
}

._ods_vertical_navigation_secondary_ih07p_3834{
  margin-top:2rem
}

._ods_vertical_navigation_title_ih07p_3837{
  padding-left:.75rem;
  padding-right:.75rem;
  font-size:.75rem;
  line-height:1rem;
  font-weight:600;
  text-transform:uppercase;
  letter-spacing:.05em;
  --tw-text-opacity: 1;
  color:rgba(107, 114, 128,var(--tw-text-opacity));
  color:rgba(var(--color-dark-500),var(--tw-text-opacity))
}

._ods_vertical_navigation_children_ih07p_3828{
  display:flex;
  cursor:pointer;
  align-items:center
}

._ods_vertical_navigation_children_active_ih07p_3828{
  --tw-bg-opacity: 1;
  background-color:rgba(243, 244, 246,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-100),var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:rgba(17, 24, 39,var(--tw-text-opacity));
  color:rgba(var(--color-dark-900),var(--tw-text-opacity))
}

._ods_vertical_navigation_children_active_ih07p_3828 svg{
  height:1.5rem;
  width:1.5rem
}

._ods_vertical_navigation_children_default_ih07p_3849{
  --tw-text-opacity: 1;
  color:rgba(75, 85, 99,var(--tw-text-opacity));
  color:rgba(var(--color-dark-600),var(--tw-text-opacity))
}

._ods_vertical_navigation_children_default_ih07p_3849:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(249, 250, 251,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-50),var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:rgba(17, 24, 39,var(--tw-text-opacity));
  color:rgba(var(--color-dark-900),var(--tw-text-opacity))
}

._ods_vertical_navigation_children_default_ih07p_3849 svg{
  height:1.5rem;
  width:1.5rem
}

._ods_banner_floating_ih07p_3856{
  padding-top:.5rem;
  padding-bottom:.5rem
}

@media (min-width: 640px){
  ._ods_banner_floating_ih07p_3856{
    padding-top:1.25rem;
    padding-bottom:1.25rem
  }
}

._ods_banner_full_width_ih07p_3859{
  --tw-bg-opacity: 1;
  background-color:rgba(79, 70, 229,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-600),var(--tw-bg-opacity))
}

._ods_banner_bottom_fixed_ih07p_3862{
  position:fixed;
  left:0;
  right:0;
  bottom:0
}

._ods_banner_container_ih07p_3865{
  padding:.5rem
}

._ods_banner_container_full_width_ih07p_3868{
  padding:.75rem
}

._ods_banner_container_centered_ih07p_3871{
  padding:.25rem .75rem
}

._ods_banner_full_wrapper_ih07p_3874{
  border-radius:.5rem;
  --tw-bg-opacity: 1;
  background-color:rgba(79, 70, 229,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-600),var(--tw-bg-opacity));
  padding:.5rem;
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / .1), 0 4px 6px -4px rgb(0 0 0 / .1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow:0 0 #0000,0 0 #0000,var(--tw-shadow);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow)
}

@media (min-width: 640px){
  ._ods_banner_full_wrapper_ih07p_3874{
    padding:.75rem
  }
}

._ods_banner_content_ih07p_3877{
  display:flex;
  width:0px;
  flex:1 1;
  align-items:center
}

._ods_banner_content_wrapper_ih07p_3880{
  display:flex;
  flex-wrap:wrap;
  align-items:center;
  justify-content:space-between
}

._ods_banner_content_centered_ih07p_3883 ._ods_banner_content_ih07p_3877{
  padding-right:4rem
}

@media (min-width: 640px){
  ._ods_banner_content_centered_ih07p_3883 ._ods_banner_content_ih07p_3877{
    padding-left:4rem;
    padding-right:4rem;
    text-align:center
  }
}

._ods_banner_content_centered_base_ih07p_3886{
  position:relative
}

._ods_banner_content_centered_body_ih07p_3889{
  display:flex;
  flex-grow:1;
  align-items:center;
  justify-content:center
}

._ods_banner_content_centered_body_ih07p_3889>div:first-child{
  margin-right:.5rem;
  width:auto;
  flex-grow:0
}

._ods_banner_content_icon_ih07p_3895{
  display:flex;
  border-radius:.5rem;
  --tw-bg-opacity: 1;
  background-color:rgba(55, 48, 163,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-800),var(--tw-bg-opacity));
  padding:.5rem
}

._ods_banner_content_icon_ih07p_3895 svg{
  height:1.5rem;
  width:1.5rem;
  --tw-text-opacity: 1;
  color:rgba(255, 255, 255,var(--tw-text-opacity));
  color:rgba(var(--color-white),var(--tw-text-opacity))
}

._ods_banner_content_children_ih07p_3901{
  margin-left:.75rem;
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
  font-weight:500;
  --tw-text-opacity: 1;
  color:rgba(255, 255, 255,var(--tw-text-opacity));
  color:rgba(var(--color-white),var(--tw-text-opacity))
}

._ods_banner_actions_ih07p_3904{
  order:3;
  margin-top:.5rem;
  width:100%;
  flex-shrink:0
}

@media (min-width: 640px){
  ._ods_banner_actions_ih07p_3904{
    order:2;
    margin-top:0;
    width:auto
  }
}

._ods_banner_actions_ih07p_3904>button{
  width:100%;
  border-style:none
}

._ods_banner_actions_ih07p_3904>button:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(238, 242, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-50),var(--tw-bg-opacity))
}

._ods_banner_actions_ih07p_3904>button:focus{
  outline-width:0px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),0 0 #0000;
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),var(--tw-shadow, 0 0 #0000);
  --tw-ring-offset-width: 0px
}

._ods_banner_actions_ih07p_3904>a{
  font-weight:700
}

._ods_banner_actions_close_button_ih07p_3913{
  display:flex;
  border-style:none;
  padding:.5rem
}

._ods_banner_actions_close_button_ih07p_3913:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(99, 102, 241,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-500),var(--tw-bg-opacity))
}

._ods_banner_actions_close_button_ih07p_3913:focus{
  outline-width:0px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),0 0 #0000;
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),var(--tw-shadow, 0 0 #0000);
  --tw-ring-offset-width: 0px
}

._ods_banner_actions_close_icon_ih07p_3916{
  order:2;
  flex-shrink:0
}

@media (min-width: 640px){
  ._ods_banner_actions_close_icon_ih07p_3916{
    order:3;
    margin-left:.5rem
  }
}

._ods_banner_actions_close_icon_svg_ih07p_3919{
  height:1.5rem;
  width:1.5rem;
  --tw-text-opacity: 1;
  color:rgba(255, 255, 255,var(--tw-text-opacity));
  color:rgba(var(--color-white),var(--tw-text-opacity))
}

@media (min-width: 640px){
  ._ods_section_heading_ih07p_2969{
    display:flex;
    align-items:center;
    justify-content:space-between
  }
}

._ods_section_heading_divded_ih07p_3926{
  border-bottom-width:1px;
  --tw-border-opacity: 1;
  border-color:rgba(229, 231, 235,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-200),var(--tw-border-opacity));
  padding-bottom:1.25rem
}

@media (min-width: 768px){
  ._ods_section_heading_with_tabs_ih07p_3929{
    position:relative
  }

  ._ods_section_heading_with_tabs_ih07p_3929 ._ods_section_heading_content_ih07p_2982{
    margin-bottom:0
  }
}

._ods_section_heading_with_tabs_ih07p_3929 ._ods_section_heading_actions_ih07p_2969{
  margin-left:0
}

@media (min-width: 640px){
  ._ods_section_heading_with_tabs_ih07p_3929 ._ods_section_heading_actions_ih07p_2969{
    margin-top:.75rem
  }
}

@media (min-width: 768px){
  ._ods_section_heading_with_tabs_ih07p_3929 ._ods_section_heading_actions_ih07p_2969{
    position:absolute;
    bottom:55%;
    right:0;
    margin-top:0;
    margin-bottom:0
  }
}

._ods_section_heading_with_tabs_ih07p_3929 ._ods_section_heading_content_tabs_ih07p_3009{
  margin-top:.75rem
}

@media (min-width: 640px){
  ._ods_section_heading_with_tabs_ih07p_3929 ._ods_section_heading_content_tabs_ih07p_3009{
    margin-top:0
  }
}

._ods_section_heading_inline_tabs_ih07p_3941{
  display:flex;
  flex-direction:column
}

@media (min-width: 768px){
  ._ods_section_heading_inline_tabs_ih07p_3941{
    position:relative;
    flex-direction:row;
    align-items:flex-end
  }
}

._ods_section_heading_inline_tabs_ih07p_3941 ._ods_section_heading_content_ih07p_2982{
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap
}

@media (min-width: 768px){
  ._ods_section_heading_inline_tabs_ih07p_3941 ._ods_section_heading_content_ih07p_2982{
    border-bottom-width:1px;
    --tw-border-opacity: 1;
    border-color:rgba(229, 231, 235,var(--tw-border-opacity));
    border-color:rgba(var(--color-light-200),var(--tw-border-opacity));
    padding-right:2.5rem;
    padding-bottom:1rem
  }
}

._ods_section_heading_inline_tabs_ih07p_3941 ._ods_section_heading_actions_ih07p_2969{
  margin-left:0
}

@media (min-width: 640px){
  ._ods_section_heading_inline_tabs_ih07p_3941 ._ods_section_heading_actions_ih07p_2969{
    margin-top:.75rem
  }
}

@media (min-width: 768px){
  ._ods_section_heading_inline_tabs_ih07p_3941 ._ods_section_heading_actions_ih07p_2969{
    order:3;
    margin-top:0;
    margin-bottom:0;
    border-bottom-width:1px;
    --tw-border-opacity: 1;
    border-color:rgba(229, 231, 235,var(--tw-border-opacity));
    border-color:rgba(var(--color-light-200),var(--tw-border-opacity));
    padding-bottom:1rem
  }

  ._ods_section_heading_inline_tabs_ih07p_3941 ._ods_tab_panels_ih07p_774{
    position:absolute;
    left:0
  }
}

._ods_section_heading_inline_tabs_ih07p_3941 ._ods_section_heading_content_tabs_ih07p_3009{
  order:3;
  margin-top:.75rem;
  flex-grow:1
}

@media (min-width: 640px){
  ._ods_section_heading_inline_tabs_ih07p_3941 ._ods_section_heading_content_tabs_ih07p_3009{
    margin-top:0
  }
}

@media (min-width: 768px){
  ._ods_section_heading_inline_tabs_ih07p_3941 ._ods_section_heading_content_tabs_ih07p_3009{
    order:2
  }
}

._ods_card_heading_flex_mobile_ih07p_3957{
  display:flex;
  align-items:center;
  justify-content:space-between
}

._ods_card_heading_helptext_ih07p_3960{
  margin-top:.25rem;
  max-width:56rem;
  font-size:.875rem;
  line-height:1.25rem;
  --tw-text-opacity: 1;
  color:rgba(107, 114, 128,var(--tw-text-opacity));
  color:rgba(var(--color-dark-500),var(--tw-text-opacity))
}

@media (min-width: 640px){
  ._ods_action_panel_ih07p_3013{
    border-radius:.5rem
  }
}

._ods_action_panel_white_ih07p_3967{
  --tw-bg-opacity: 1;
  background-color:rgba(255, 255, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-white),var(--tw-bg-opacity));
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / .1), 0 1px 2px -1px rgb(0 0 0 / .1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow:0 0 #0000,0 0 #0000,var(--tw-shadow);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow)
}

._ods_action_panel_gray_ih07p_3970{
  --tw-bg-opacity: 1;
  background-color:rgba(249, 250, 251,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-50),var(--tw-bg-opacity))
}

._ods_action_panel_wrapper_ih07p_3973{
  padding:1.25rem 1rem
}

@media (min-width: 640px){
  ._ods_action_panel_wrapper_ih07p_3973{
    padding:1.5rem
  }
}

._ods_action_panel_narrow_ih07p_3976{
  padding:1.25rem 1rem
}

@media (min-width: 640px){
  ._ods_action_panel_flex_ih07p_3979{
    display:flex;
    justify-content:space-between
  }

  ._ods_action_panel_flex_ih07p_3979 ._ods_action_panel_actions_ih07p_3013{
    margin-top:0
  }

  ._ods_action_panel_flex_top_ih07p_3985{
    align-items:flex-start
  }

  ._ods_action_panel_flex_bottom_ih07p_3988{
    align-items:flex-end
  }

  ._ods_action_panel_flex_center_ih07p_3991{
    align-items:center
  }
}

._ods_sidebar_ih07p_3042{
  display:flex;
  min-height:0px;
  flex:1 1;
  flex-direction:column
}

._ods_sidebar_wrapper_ih07p_3998{
  display:flex;
  flex:1 1;
  flex-direction:column;
  overflow-y:auto;
  padding-top:1.25rem;
  padding-bottom:1rem
}

._ods_sidebar_ih07p_3042 ._ods_vertical_navigation_children_ih07p_3828{
  display:flex;
  align-items:center
}

._ods_sidebar_ih07p_3042 ._ods_vertical_navigation_secondary_ih07p_3834{
  flex:1 1
}

._ods_sidebar_primary_ih07p_4007{
  --tw-bg-opacity: 1;
  background-color:rgba(67, 56, 202,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-700),var(--tw-bg-opacity))
}

._ods_sidebar_primary_ih07p_4007>div ._ods_sidebar_footer_ih07p_3053{
  --tw-border-opacity: 1;
  border-color:rgba(55, 48, 163,var(--tw-border-opacity));
  border-color:rgba(var(--color-primary-800),var(--tw-border-opacity));
  font-weight:500;
  --tw-text-opacity: 1;
  color:rgba(255, 255, 255,var(--tw-text-opacity));
  color:rgba(var(--color-white),var(--tw-text-opacity))
}

._ods_sidebar_primary_ih07p_4007>div ._ods_vertical_navigation_title_ih07p_3837{
  --tw-text-opacity: 1;
  color:rgba(255, 255, 255,var(--tw-text-opacity));
  color:rgba(var(--color-white),var(--tw-text-opacity))
}

._ods_sidebar_primary_ih07p_4007>div ._ods_vertical_navigation_children_active_ih07p_3828{
  --tw-bg-opacity: 1;
  background-color:rgba(55, 48, 163,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-800),var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:rgba(255, 255, 255,var(--tw-text-opacity));
  color:rgba(var(--color-white),var(--tw-text-opacity))
}

._ods_sidebar_primary_ih07p_4007>div ._ods_vertical_navigation_children_default_ih07p_3849{
  --tw-text-opacity: 1;
  color:rgba(224, 231, 255,var(--tw-text-opacity));
  color:rgba(var(--color-primary-100),var(--tw-text-opacity))
}

._ods_sidebar_primary_ih07p_4007>div ._ods_vertical_navigation_children_default_ih07p_3849:hover{
  background-color:rgba(79, 70, 229,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-600),var(--tw-bg-opacity));
  --tw-bg-opacity: .75
}

._ods_sidebar_primary_ih07p_4007>div ._ods_sidebar_disclosure_button_wrapper_ih07p_3087:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(var(--color-primary-300), var(--tw-ring-opacity))
}

._ods_sidebar_primary_ih07p_4007>div ._ods_sidebar_disclosure_button_active_ih07p_3081{
  --tw-bg-opacity: 1;
  background-color:rgba(55, 48, 163,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-800),var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:rgba(255, 255, 255,var(--tw-text-opacity));
  color:rgba(var(--color-white),var(--tw-text-opacity))
}

._ods_sidebar_primary_ih07p_4007>div ._ods_sidebar_disclosure_button_default_ih07p_3084{
  --tw-bg-opacity: 1;
  background-color:rgba(67, 56, 202,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-700),var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:rgba(224, 231, 255,var(--tw-text-opacity));
  color:rgba(var(--color-primary-100),var(--tw-text-opacity))
}

._ods_sidebar_primary_ih07p_4007>div ._ods_sidebar_disclosure_button_default_ih07p_3084:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(79, 70, 229,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-600),var(--tw-bg-opacity))
}

._ods_sidebar_primary_ih07p_4007>div ._ods_sidebar_disclosure_button_icon_default_ih07p_3096{
  --tw-text-opacity: 1;
  color:rgba(224, 231, 255,var(--tw-text-opacity));
  color:rgba(var(--color-primary-100),var(--tw-text-opacity))
}

._ods_sidebar_primary_ih07p_4007>div ._ods_sidebar_disclosure_button_icon_open_ih07p_3093{
  --tw-text-opacity: 1;
  color:rgba(255, 255, 255,var(--tw-text-opacity));
  color:rgba(var(--color-white),var(--tw-text-opacity))
}

._ods_sidebar_dark_ih07p_4037{
  --tw-bg-opacity: 1;
  background-color:rgba(31, 41, 55,var(--tw-bg-opacity));
  background-color:rgba(var(--color-dark-800),var(--tw-bg-opacity))
}

._ods_sidebar_dark_ih07p_4037>div ._ods_sidebar_footer_ih07p_3053{
  --tw-border-opacity: 1;
  border-color:rgba(31, 41, 55,var(--tw-border-opacity));
  border-color:rgba(var(--color-dark-800),var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color:rgb(55 65 81 / var(--tw-bg-opacity));
  font-weight:500;
  --tw-text-opacity: 1;
  color:rgba(255, 255, 255,var(--tw-text-opacity));
  color:rgba(var(--color-white),var(--tw-text-opacity))
}

._ods_sidebar_dark_ih07p_4037>div ._ods_vertical_navigation_title_ih07p_3837{
  --tw-text-opacity: 1;
  color:rgba(255, 255, 255,var(--tw-text-opacity));
  color:rgba(var(--color-white),var(--tw-text-opacity))
}

._ods_sidebar_dark_ih07p_4037>div ._ods_vertical_navigation_children_active_ih07p_3828{
  --tw-bg-opacity: 1;
  background-color:rgba(17, 24, 39,var(--tw-bg-opacity));
  background-color:rgba(var(--color-dark-900),var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:rgba(255, 255, 255,var(--tw-text-opacity));
  color:rgba(var(--color-white),var(--tw-text-opacity))
}

._ods_sidebar_dark_ih07p_4037>div ._ods_vertical_navigation_children_default_ih07p_3849{
  --tw-text-opacity: 1;
  color:rgba(209, 213, 219,var(--tw-text-opacity));
  color:rgba(var(--color-light-300),var(--tw-text-opacity))
}

._ods_sidebar_dark_ih07p_4037>div ._ods_vertical_navigation_children_default_ih07p_3849:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(55, 65, 81,var(--tw-bg-opacity));
  background-color:rgba(var(--color-dark-700),var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:rgba(255, 255, 255,var(--tw-text-opacity));
  color:rgba(var(--color-white),var(--tw-text-opacity))
}

._ods_sidebar_dark_ih07p_4037>div ._ods_sidebar_disclosure_button_wrapper_ih07p_3087:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(var(--color-light-300), var(--tw-ring-opacity))
}

._ods_sidebar_dark_ih07p_4037>div ._ods_sidebar_disclosure_button_active_ih07p_3081{
  --tw-bg-opacity: 1;
  background-color:rgba(17, 24, 39,var(--tw-bg-opacity));
  background-color:rgba(var(--color-dark-900),var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:rgba(255, 255, 255,var(--tw-text-opacity));
  color:rgba(var(--color-white),var(--tw-text-opacity))
}

._ods_sidebar_dark_ih07p_4037>div ._ods_sidebar_disclosure_button_default_ih07p_3084{
  --tw-bg-opacity: 1;
  background-color:rgba(31, 41, 55,var(--tw-bg-opacity));
  background-color:rgba(var(--color-dark-800),var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:rgba(209, 213, 219,var(--tw-text-opacity));
  color:rgba(var(--color-light-300),var(--tw-text-opacity))
}

._ods_sidebar_dark_ih07p_4037>div ._ods_sidebar_disclosure_button_default_ih07p_3084:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(55, 65, 81,var(--tw-bg-opacity));
  background-color:rgba(var(--color-dark-700),var(--tw-bg-opacity))
}

._ods_sidebar_dark_ih07p_4037>div ._ods_sidebar_disclosure_button_icon_default_ih07p_3096{
  --tw-text-opacity: 1;
  color:rgba(243, 244, 246,var(--tw-text-opacity));
  color:rgba(var(--color-light-100),var(--tw-text-opacity))
}

._ods_sidebar_dark_ih07p_4037>div ._ods_sidebar_disclosure_button_icon_open_ih07p_3093{
  --tw-text-opacity: 1;
  color:rgba(255, 255, 255,var(--tw-text-opacity));
  color:rgba(var(--color-white),var(--tw-text-opacity))
}

._ods_sidebar_white_ih07p_4067{
  --tw-bg-opacity: 1;
  background-color:rgba(255, 255, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-white),var(--tw-bg-opacity))
}

._ods_sidebar_white_ih07p_4067>div ._ods_sidebar_footer_ih07p_3053{
  --tw-border-opacity: 1;
  border-color:rgba(229, 231, 235,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-200),var(--tw-border-opacity));
  font-weight:500
}

._ods_sidebar_white_ih07p_4067>div ._ods_vertical_navigation_children_active_ih07p_3828{
  --tw-bg-opacity: 1;
  background-color:rgba(243, 244, 246,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-100),var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:rgba(17, 24, 39,var(--tw-text-opacity));
  color:rgba(var(--color-dark-900),var(--tw-text-opacity))
}

._ods_sidebar_white_ih07p_4067>div ._ods_vertical_navigation_children_default_ih07p_3849{
  --tw-text-opacity: 1;
  color:rgba(75, 85, 99,var(--tw-text-opacity));
  color:rgba(var(--color-dark-600),var(--tw-text-opacity))
}

._ods_sidebar_white_ih07p_4067>div ._ods_vertical_navigation_children_default_ih07p_3849:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(249, 250, 251,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-50),var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:rgba(17, 24, 39,var(--tw-text-opacity));
  color:rgba(var(--color-dark-900),var(--tw-text-opacity))
}

._ods_data_grid_selectable_ih07p_4080{
  cursor:pointer
}

._ods_data_grid_selectable_item_ih07p_4083{
  --tw-bg-opacity: 1;
  background-color:rgba(238, 242, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-50),var(--tw-bg-opacity));
  transition-property:all;
  transition-timing-function:cubic-bezier(.4,0,.2,1);
  transition-duration:.1s
}

._ods_data_grid_sort_wrapper_ih07p_4086{
  display:flex;
  align-items:center
}

._ods_data_grid_search_ih07p_4089{
  padding-left:.5rem
}

._ods_data_grid_search_ih07p_4089 button:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(249, 250, 251,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-50),var(--tw-bg-opacity))
}

._ods_data_grid_search_ih07p_4089 button:focus{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),0 0 #0000;
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),var(--tw-shadow, 0 0 #0000);
  --tw-ring-offset-width: 0px
}

._ods_data_grid_search_icon_light_ih07p_4095{
  --tw-text-opacity: 1;
  color:rgba(156, 163, 175,var(--tw-text-opacity));
  color:rgba(var(--color-light-400),var(--tw-text-opacity))
}

._ods_data_grid_search_icon_primary_ih07p_4098{
  --tw-text-opacity: 1;
  color:rgba(99, 102, 241,var(--tw-text-opacity));
  color:rgba(var(--color-primary-500),var(--tw-text-opacity))
}

._ods_data_grid_search_trigger_ih07p_4101 ._ods_icon_base_ih07p_543{
  height:auto;
  width:auto
}

._ods_data_grid_pagination_ih07p_4104{
  padding:0;
  text-transform:none
}

._ods_data_grid_pagination_mobile_ih07p_4107{
  display:flex;
  flex:1 1;
  justify-content:space-between
}

@media (min-width: 640px){
  ._ods_data_grid_pagination_mobile_ih07p_4107{
    display:none
  }
}

._ods_data_grid_pagination_message_ih07p_4110{
  display:none
}

@media (min-width: 640px){
  ._ods_data_grid_pagination_message_ih07p_4110{
    display:flex;
    flex:1 1;
    align-items:center;
    justify-content:space-between
  }
}

._ods_data_grid_pagination_wrapper_ih07p_4113{
  position:relative;
  z-index:0;
  display:inline-flex
}

._ods_data_grid_pagination_wrapper_ih07p_4113>:not([hidden])~:not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right:calc(-1px * var(--tw-space-x-reverse));
  margin-left:calc(-1px * calc(1 - var(--tw-space-x-reverse)))
}

._ods_data_grid_pagination_wrapper_ih07p_4113{
  border-radius:.375rem;
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / .05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow:0 0 #0000,0 0 #0000,var(--tw-shadow);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow)
}

@media (min-width: 768px){
  ._ods_data_grid_pagination_next_ih07p_4116{
    margin-left:.75rem
  }
}

._ods_data_grid_relative_ih07p_4119>th{
  position:relative
}

._ods_popover_ih07p_4123{
  position:relative;
  width:100%
}

._ods_popover_transition_enter_ih07p_4126{
  transition-property:color,background-color,border-color,fill,stroke,opacity,box-shadow,transform,filter,-webkit-text-decoration-color,-webkit-backdrop-filter;
  transition-property:color,background-color,border-color,fill,stroke,opacity,box-shadow,-webkit-text-decoration-color,-webkit-transform,-webkit-filter,-webkit-backdrop-filter;
  transition-property:color,background-color,border-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter;
  transition-property:color,background-color,border-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter,-webkit-text-decoration-color,-webkit-transform,-webkit-filter,-webkit-backdrop-filter;
  transition-property:color,background-color,border-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter,-webkit-text-decoration-color,-webkit-backdrop-filter;
  transition-timing-function:cubic-bezier(.4,0,.2,1);
  transition-duration:.2s;
  transition-timing-function:cubic-bezier(0,0,.2,1)
}

._ods_popover_transition_enter_from_ih07p_4129{
  --tw-translate-y: .25rem;
  -webkit-transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  opacity:0
}

._ods_popover_transition_enter_to_ih07p_4132{
  --tw-translate-y: 0px;
  -webkit-transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  opacity:1
}

._ods_popover_transition_leave_ih07p_4135{
  transition-property:color,background-color,border-color,fill,stroke,opacity,box-shadow,transform,filter,-webkit-text-decoration-color,-webkit-backdrop-filter;
  transition-property:color,background-color,border-color,fill,stroke,opacity,box-shadow,-webkit-text-decoration-color,-webkit-transform,-webkit-filter,-webkit-backdrop-filter;
  transition-property:color,background-color,border-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter;
  transition-property:color,background-color,border-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter,-webkit-text-decoration-color,-webkit-transform,-webkit-filter,-webkit-backdrop-filter;
  transition-property:color,background-color,border-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter,-webkit-text-decoration-color,-webkit-backdrop-filter;
  transition-timing-function:cubic-bezier(.4,0,.2,1);
  transition-duration:.15s;
  transition-timing-function:cubic-bezier(.4,0,1,1)
}

._ods_popover_transition_leave_from_ih07p_4138{
  --tw-translate-y: 0px;
  -webkit-transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  opacity:1
}

._ods_popover_transition_leave_to_ih07p_4141{
  --tw-translate-y: .25rem;
  -webkit-transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  opacity:0
}

._ods_popover_panel_ih07p_4144{
  position:absolute;
  z-index:50;
  margin-top:.375rem;
  width:100%
}

._ods_popover_trigger_ih07p_4147{
  display:flex;
  align-items:center
}

._ods_popover_trigger_ih07p_4147:focus{
  outline-width:0px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),0 0 #0000;
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),var(--tw-shadow, 0 0 #0000)
}

@media (min-width: 1024px){
  ._ods_calendar_ih07p_4151{
    display:flex;
    height:100%;
    flex:1 1 auto;
    flex-direction:column
  }
}

._ods_calendar_wrapper_ih07p_4154:focus{
  outline:2px solid transparent;
  outline-offset:2px
}

@media (min-width: 1024px){
  ._ods_calendar_wrapper_ih07p_4154{
    display:flex;
    height:100%;
    flex-direction:column
  }
}

._ods_calendar_wrapper_ih07p_4154>div:focus{
  border-width:0px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),0 0 #0000;
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),var(--tw-shadow, 0 0 #0000)
}

@media (min-width: 1024px){
  ._ods_calendar_wrapper_ih07p_4154>div{
    display:flex;
    height:100%;
    flex-direction:column
  }
}

._ods_calendar_icon_ih07p_4160{
  height:1.25rem;
  width:1.25rem
}

._ods_calendar_header_wrapper_ih07p_4163 ._react-datepicker__day-names_ih07p_3206{
  display:grid;
  grid-template-columns:repeat(7,minmax(0,1fr));
  grid-gap:1px;
  gap:1px;
  border-width:1px;
  --tw-border-opacity: 1;
  border-color:rgba(229, 231, 235,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-200),var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color:rgba(229, 231, 235,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-200),var(--tw-bg-opacity));
  text-align:center;
  font-size:.75rem;
  font-weight:600;
  line-height:1.5rem;
  --tw-text-opacity: 1;
  color:rgba(55, 65, 81,var(--tw-text-opacity));
  color:rgba(var(--color-dark-700),var(--tw-text-opacity))
}

@media (min-width: 1024px){
  ._ods_calendar_header_wrapper_ih07p_4163 ._react-datepicker__day-names_ih07p_3206{
    flex:none
  }
}

._ods_calendar_day_ih07p_4166{
  display:flex;
  height:100%;
  align-items:center;
  justify-content:center;
  padding:.5rem .75rem
}

._ods_calendar_day_ih07p_4166:focus{
  outline:2px solid transparent;
  outline-offset:2px
}

@media (min-width: 1024px){
  ._ods_calendar_day_ih07p_4166{
    display:block
  }
}

._ods_calendar_day_disabled_ih07p_4169>div{
  cursor:default
}

._ods_calendar_day_disabled_ih07p_4169>div>div>div:last-child>div:last-child{
  pointer-events:none
}

._ods_calendar_ih07p_4151 ._react-datepicker__header_ih07p_4175>div:last-child{
  display:none
}

._ods_calendar_ih07p_4151 ._react-datepicker__day-name_ih07p_3206{
  --tw-bg-opacity: 1;
  background-color:rgba(255, 255, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-white),var(--tw-bg-opacity));
  padding-top:.5rem;
  padding-bottom:.5rem
}

@media (min-width: 1024px){
  ._ods_calendar_ih07p_4151 ._react-datepicker__month-container_ih07p_4181{
    display:flex;
    height:100%;
    flex-direction:column
  }
}

._ods_calendar_ih07p_4151 ._react-datepicker__month_ih07p_4181{
  border-width:0px;
  --tw-bg-opacity: 1;
  background-color:rgba(229, 231, 235,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-200),var(--tw-bg-opacity));
  font-size:.75rem;
  line-height:1.5rem;
  --tw-text-opacity: 1;
  color:rgba(55, 65, 81,var(--tw-text-opacity));
  color:rgba(var(--color-dark-700),var(--tw-text-opacity))
}

@media (min-width: 1024px){
  ._ods_calendar_ih07p_4151 ._react-datepicker__month_ih07p_4181{
    display:flex;
    height:100%;
    flex-direction:column
  }
}

._ods_calendar_ih07p_4151 ._react-datepicker__week_ih07p_3203{
  display:flex;
  border-left-width:1px;
  border-bottom-width:1px;
  --tw-border-opacity: 1;
  border-color:rgba(229, 231, 235,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-200),var(--tw-border-opacity))
}

@media (min-width: 1024px){
  ._ods_calendar_ih07p_4151 ._react-datepicker__week_ih07p_3203{
    height:100%
  }
}

._ods_calendar_ih07p_4151 ._react-datepicker__day_ih07p_3206{
  position:relative;
  flex-basis:100%;
  border-right-width:1px;
  --tw-border-opacity: 1;
  border-color:rgba(229, 231, 235,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-200),var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color:rgba(255, 255, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-white),var(--tw-bg-opacity))
}

._ods_calendar_ih07p_4151 ._react-datepicker__day_ih07p_3206:focus{
  outline:2px solid transparent;
  outline-offset:2px
}

._ods_calendar_ih07p_4151 ._react-datepicker__day--disabled_ih07p_3215{
  cursor:not-allowed;
  opacity:.4
}

._ods_calendar_ih07p_4151 ._react-datepicker__day--disabled_ih07p_3215:hover{
  background-color:transparent
}

._ods_calendar_ih07p_4151 ._react-datepicker__day--outside-month_ih07p_3218{
  --tw-bg-opacity: 1;
  background-color:rgba(249, 250, 251,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-50),var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:rgba(156, 163, 175,var(--tw-text-opacity));
  color:rgba(var(--color-light-400),var(--tw-text-opacity))
}

._ods_calendar_ih07p_4151 ._react-datepicker__day--today_ih07p_4199 span{
  display:flex;
  height:1.25rem;
  width:1.25rem;
  align-items:center;
  justify-content:center;
  border-radius:9999px;
  --tw-bg-opacity: 1;
  background-color:rgba(79, 70, 229,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-600),var(--tw-bg-opacity));
  font-weight:600;
  --tw-text-opacity: 1;
  color:rgba(255, 255, 255,var(--tw-text-opacity));
  color:rgba(var(--color-white),var(--tw-text-opacity))
}

@media (min-width: 1024px){
  ._ods_calendar_ih07p_4151 ._react-datepicker__day--today_ih07p_4199 span{
    height:1.5rem;
    width:1.5rem
  }
}

._ods_calendar_ih07p_4151 ._react-datepicker__day--selected_ih07p_3223{
  --tw-bg-opacity: 1;
  background-color:rgba(199, 210, 254,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-200),var(--tw-bg-opacity))
}

._ods_calendar_ih07p_4151 ._react-datepicker__day--selected_ih07p_3223:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(224, 231, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-100),var(--tw-bg-opacity))
}

._ods_calendar_header_ih07p_4163{
  position:relative;
  z-index:20;
  display:flex;
  align-items:center;
  justify-content:space-between;
  border-left-width:1px;
  border-right-width:1px;
  border-top-width:1px;
  --tw-border-opacity: 1;
  border-color:rgba(229, 231, 235,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-200),var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color:rgba(249, 250, 251,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-50),var(--tw-bg-opacity));
  padding:1rem 1.5rem
}

@media (min-width: 1024px){
  ._ods_calendar_header_ih07p_4163{
    flex:none
  }
}

._ods_calendar_header_title_ih07p_4208{
  font-size:1.125rem;
  line-height:1.75rem;
  font-weight:600;
  --tw-text-opacity: 1;
  color:rgba(17, 24, 39,var(--tw-text-opacity));
  color:rgba(var(--color-dark-900),var(--tw-text-opacity))
}

._ods_calendar_header_actions_ih07p_4211{
  display:flex;
  align-items:center
}

._ods_calendar_header_actions_button_icon_ih07p_4214{
  display:flex;
  align-items:center;
  justify-content:center;
  border-width:1px;
  --tw-border-opacity: 1;
  border-color:rgba(229, 231, 235,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-200),var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color:rgba(255, 255, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-white),var(--tw-bg-opacity));
  padding:.5rem 1rem .5rem .75rem;
  --tw-text-opacity: 1;
  color:rgba(156, 163, 175,var(--tw-text-opacity));
  color:rgba(var(--color-light-400),var(--tw-text-opacity))
}

._ods_calendar_header_actions_button_icon_ih07p_4214:hover{
  --tw-text-opacity: 1;
  color:rgba(107, 114, 128,var(--tw-text-opacity));
  color:rgba(var(--color-dark-500),var(--tw-text-opacity))
}

._ods_calendar_header_actions_button_icon_ih07p_4214:focus{
  position:relative
}

@media (min-width: 768px){
  ._ods_calendar_header_actions_button_icon_ih07p_4214{
    width:2.25rem;
    padding-left:.5rem;
    padding-right:.5rem
  }

  ._ods_calendar_header_actions_button_icon_ih07p_4214:hover{
    --tw-bg-opacity: 1;
    background-color:rgba(249, 250, 251,var(--tw-bg-opacity));
    background-color:rgba(var(--color-light-50),var(--tw-bg-opacity))
  }
}

._ods_calendar_header_actions_button_icon_leading_ih07p_4217{
  border-top-left-radius:.375rem;
  border-bottom-left-radius:.375rem;
  border-right-width:0px
}

._ods_calendar_header_actions_button_icon_trailing_ih07p_4220{
  border-top-right-radius:.375rem;
  border-bottom-right-radius:.375rem;
  border-left-width:0px
}

._ods_calendar_header_actions_button_text_ih07p_4223{
  display:none;
  border-top-width:1px;
  border-bottom-width:1px;
  --tw-border-opacity: 1;
  border-color:rgba(229, 231, 235,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-200),var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color:rgba(255, 255, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-white),var(--tw-bg-opacity));
  padding-left:.875rem;
  padding-right:.875rem;
  font-size:.875rem;
  line-height:1.25rem;
  font-weight:500;
  --tw-text-opacity: 1;
  color:rgba(55, 65, 81,var(--tw-text-opacity));
  color:rgba(var(--color-dark-700),var(--tw-text-opacity))
}

._ods_calendar_header_actions_button_text_ih07p_4223:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(249, 250, 251,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-50),var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:rgba(17, 24, 39,var(--tw-text-opacity));
  color:rgba(var(--color-dark-900),var(--tw-text-opacity))
}

._ods_calendar_header_actions_button_text_ih07p_4223:focus{
  position:relative
}

@media (min-width: 768px){
  ._ods_calendar_header_actions_button_text_ih07p_4223{
    display:block
  }
}

._ods_calendar_header_months_ih07p_4226{
  display:flex;
  align-items:center;
  border-radius:.375rem;
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / .05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow:0 0 #0000,0 0 #0000,var(--tw-shadow);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow)
}

@media (min-width: 768px){
  ._ods_calendar_header_months_ih07p_4226{
    align-items:stretch
  }
}

._danger_range_ih07p_4230 ._react-datepicker__day--in-selecting-range_ih07p_3226,._danger_range_ih07p_4230 ._react-datepicker__day--in-selecting-range_ih07p_3226:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(254, 226, 226,var(--tw-bg-opacity));
  background-color:rgba(var(--color-danger-100),var(--tw-bg-opacity))
}

._danger_range_ih07p_4230 ._react-datepicker__day--in-selecting-range_ih07p_3226>div{
  --tw-bg-opacity: 1;
  background-color:rgba(254, 226, 226,var(--tw-bg-opacity));
  background-color:rgba(var(--color-danger-100),var(--tw-bg-opacity))
}

._danger_range_ih07p_4230 ._react-datepicker__day--in-selecting-range_ih07p_3226>div:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(254, 226, 226,var(--tw-bg-opacity));
  background-color:rgba(var(--color-danger-100),var(--tw-bg-opacity))
}

._gray_range_ih07p_4237 ._react-datepicker__day--in-selecting-range_ih07p_3226,._gray_range_ih07p_4237 ._react-datepicker__day--in-selecting-range_ih07p_3226:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(249, 250, 251,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-50),var(--tw-bg-opacity))
}

._gray_range_ih07p_4237 ._react-datepicker__day--in-selecting-range_ih07p_3226>div{
  --tw-bg-opacity: 1;
  background-color:rgba(249, 250, 251,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-50),var(--tw-bg-opacity))
}

._gray_range_ih07p_4237 ._react-datepicker__day--in-selecting-range_ih07p_3226>div:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(249, 250, 251,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-50),var(--tw-bg-opacity))
}

._primary_range_ih07p_4244 ._react-datepicker__day--in-selecting-range_ih07p_3226,._primary_range_ih07p_4244 ._react-datepicker__day--in-selecting-range_ih07p_3226:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(224, 231, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-100),var(--tw-bg-opacity))
}

._primary_range_ih07p_4244 ._react-datepicker__day--in-selecting-range_ih07p_3226>div{
  --tw-bg-opacity: 1;
  background-color:rgba(224, 231, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-100),var(--tw-bg-opacity))
}

._primary_range_ih07p_4244 ._react-datepicker__day--in-selecting-range_ih07p_3226>div:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(224, 231, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-100),var(--tw-bg-opacity))
}

._ods_container_constrained_ih07p_4251{
  margin-left:auto;
  margin-right:auto;
  max-width:80rem;
  padding-left:1rem;
  padding-right:1rem
}

@media (min-width: 640px){
  ._ods_container_constrained_ih07p_4251{
    padding-left:1.5rem;
    padding-right:1.5rem
  }
}

@media (min-width: 1024px){
  ._ods_container_constrained_ih07p_4251{
    padding-left:2rem;
    padding-right:2rem
  }
}

._ods_container_constrained_w_full_mobile_ih07p_4254{
  margin-left:auto;
  margin-right:auto;
  max-width:80rem
}

@media (min-width: 640px){
  ._ods_container_constrained_w_full_mobile_ih07p_4254{
    padding-left:1.5rem;
    padding-right:1.5rem
  }
}

@media (min-width: 1024px){
  ._ods_container_constrained_w_full_mobile_ih07p_4254{
    padding-left:2rem;
    padding-right:2rem
  }
}

._ods_container_constrained_to_breakpoint_ih07p_4257{
  width:100%
}

@media (min-width: 640px){
  ._ods_container_constrained_to_breakpoint_ih07p_4257{
    max-width:640px
  }
}

@media (min-width: 768px){
  ._ods_container_constrained_to_breakpoint_ih07p_4257{
    max-width:768px
  }
}

@media (min-width: 1024px){
  ._ods_container_constrained_to_breakpoint_ih07p_4257{
    max-width:1024px
  }
}

@media (min-width: 1280px){
  ._ods_container_constrained_to_breakpoint_ih07p_4257{
    max-width:1280px
  }
}

@media (min-width: 1536px){
  ._ods_container_constrained_to_breakpoint_ih07p_4257{
    max-width:1536px
  }
}

._ods_container_constrained_to_breakpoint_ih07p_4257{
  margin-left:auto;
  margin-right:auto;
  padding-left:1rem;
  padding-right:1rem
}

@media (min-width: 640px){
  ._ods_container_constrained_to_breakpoint_ih07p_4257{
    padding-left:1.5rem;
    padding-right:1.5rem
  }
}

@media (min-width: 1024px){
  ._ods_container_constrained_to_breakpoint_ih07p_4257{
    padding-left:2rem;
    padding-right:2rem
  }
}

._ods_container_constrained_to_breakpoint_w_full_mobile_ih07p_4260{
  width:100%
}

@media (min-width: 640px){
  ._ods_container_constrained_to_breakpoint_w_full_mobile_ih07p_4260{
    max-width:640px
  }
}

@media (min-width: 768px){
  ._ods_container_constrained_to_breakpoint_w_full_mobile_ih07p_4260{
    max-width:768px
  }
}

@media (min-width: 1024px){
  ._ods_container_constrained_to_breakpoint_w_full_mobile_ih07p_4260{
    max-width:1024px
  }
}

@media (min-width: 1280px){
  ._ods_container_constrained_to_breakpoint_w_full_mobile_ih07p_4260{
    max-width:1280px
  }
}

@media (min-width: 1536px){
  ._ods_container_constrained_to_breakpoint_w_full_mobile_ih07p_4260{
    max-width:1536px
  }
}

._ods_container_constrained_to_breakpoint_w_full_mobile_ih07p_4260{
  margin-left:auto;
  margin-right:auto
}

@media (min-width: 640px){
  ._ods_container_constrained_to_breakpoint_w_full_mobile_ih07p_4260{
    padding-left:1.5rem;
    padding-right:1.5rem
  }
}

@media (min-width: 1024px){
  ._ods_container_constrained_to_breakpoint_w_full_mobile_ih07p_4260{
    padding-left:2rem;
    padding-right:2rem
  }
}

._ods_container_constrained_narrow_ih07p_4263{
  margin-left:auto;
  margin-right:auto;
  max-width:80rem;
  padding-left:1rem;
  padding-right:1rem
}

@media (min-width: 640px){
  ._ods_container_constrained_narrow_ih07p_4263{
    padding-left:1.5rem;
    padding-right:1.5rem
  }
}

@media (min-width: 1024px){
  ._ods_container_constrained_narrow_ih07p_4263{
    padding-left:2rem;
    padding-right:2rem
  }
}

._ods_container_constrained_narrow_content_ih07p_4266{
  margin-left:auto;
  margin-right:auto;
  max-width:48rem
}

._ods_list_container_divided_ih07p_4270>:not([hidden])~:not([hidden]){
  --tw-divide-y-reverse: 0;
  border-top-width:calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width:calc(1px * var(--tw-divide-y-reverse));
  --tw-divide-opacity: 1;
  border-color:rgba(229, 231, 235,var(--tw-divide-opacity));
  border-color:rgba(var(--color-light-200),var(--tw-divide-opacity))
}

._ods_list_container_divided_ih07p_4270>li{
  padding-top:1rem;
  padding-bottom:1rem
}

._ods_list_container_divided_w_full_mobile_ih07p_4276>:not([hidden])~:not([hidden]){
  --tw-divide-y-reverse: 0;
  border-top-width:calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width:calc(1px * var(--tw-divide-y-reverse));
  --tw-divide-opacity: 1;
  border-color:rgba(229, 231, 235,var(--tw-divide-opacity));
  border-color:rgba(var(--color-light-200),var(--tw-divide-opacity))
}

._ods_list_container_divided_w_full_mobile_ih07p_4276>li{
  padding:1rem
}

@media (min-width: 640px){
  ._ods_list_container_divided_w_full_mobile_ih07p_4276>li{
    padding-left:0;
    padding-right:0
  }
}

._ods_list_container_card_divided_ih07p_4282>:not([hidden])~:not([hidden]){
  --tw-divide-y-reverse: 0;
  border-top-width:calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width:calc(1px * var(--tw-divide-y-reverse));
  --tw-divide-opacity: 1;
  border-color:rgba(229, 231, 235,var(--tw-divide-opacity));
  border-color:rgba(var(--color-light-200),var(--tw-divide-opacity))
}

._ods_list_container_card_divided_ih07p_4282>li{
  padding:1rem 1.5rem
}

._ods_list_container_card_divided_w_full_mobile_ih07p_4288>:not([hidden])~:not([hidden]){
  --tw-divide-y-reverse: 0;
  border-top-width:calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width:calc(1px * var(--tw-divide-y-reverse));
  --tw-divide-opacity: 1;
  border-color:rgba(229, 231, 235,var(--tw-divide-opacity));
  border-color:rgba(var(--color-light-200),var(--tw-divide-opacity))
}

._ods_list_container_card_divided_w_full_mobile_ih07p_4288>li{
  padding:1rem
}

@media (min-width: 640px){
  ._ods_list_container_card_divided_w_full_mobile_ih07p_4288>li{
    padding-left:1.5rem;
    padding-right:1.5rem
  }
}

._ods_list_container_card_seperated_ih07p_4294>:not([hidden])~:not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top:calc(.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom:calc(.75rem * var(--tw-space-y-reverse))
}

._ods_list_container_card_seperated_ih07p_4294>li{
  overflow:hidden;
  border-radius:.375rem;
  --tw-bg-opacity: 1;
  background-color:rgba(255, 255, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-white),var(--tw-bg-opacity));
  padding:1rem 1.5rem;
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / .1), 0 1px 2px -1px rgb(0 0 0 / .1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow:0 0 #0000,0 0 #0000,var(--tw-shadow);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow)
}

._ods_list_container_card_seperated_w_full_mobile_ih07p_4300>:not([hidden])~:not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top:calc(.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom:calc(.75rem * var(--tw-space-y-reverse))
}

._ods_list_container_card_seperated_w_full_mobile_ih07p_4300>li{
  overflow:hidden;
  --tw-bg-opacity: 1;
  background-color:rgba(255, 255, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-white),var(--tw-bg-opacity));
  padding:1rem;
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / .1), 0 1px 2px -1px rgb(0 0 0 / .1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow:0 0 #0000,0 0 #0000,var(--tw-shadow);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow)
}

@media (min-width: 640px){
  ._ods_list_container_card_seperated_w_full_mobile_ih07p_4300>li{
    border-radius:.375rem;
    padding-left:1.5rem;
    padding-right:1.5rem
  }
}

._ods_list_container_card_flat_ih07p_4306>:not([hidden])~:not([hidden]){
  --tw-divide-y-reverse: 0;
  border-top-width:calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width:calc(1px * var(--tw-divide-y-reverse));
  --tw-divide-opacity: 1;
  border-color:rgba(209, 213, 219,var(--tw-divide-opacity));
  border-color:rgba(var(--color-light-300),var(--tw-divide-opacity))
}

._ods_list_container_card_flat_ih07p_4306>li{
  padding:1rem 1.5rem
}

._ods_list_container_wrapper_card_divided_ih07p_4312{
  overflow:hidden;
  border-radius:.375rem;
  --tw-bg-opacity: 1;
  background-color:rgba(255, 255, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-white),var(--tw-bg-opacity));
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / .1), 0 1px 2px -1px rgb(0 0 0 / .1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow:0 0 #0000,0 0 #0000,var(--tw-shadow);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow)
}

._ods_list_container_wrapper_card_flat_ih07p_4315{
  overflow:hidden;
  border-radius:.375rem;
  border-width:1px;
  --tw-border-opacity: 1;
  border-color:rgba(209, 213, 219,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-300),var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color:rgba(255, 255, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-white),var(--tw-bg-opacity))
}

._ods_panel_ih07p_1394{
  overflow:hidden;
  border-radius:.5rem;
  --tw-bg-opacity: 1;
  background-color:rgba(255, 255, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-white),var(--tw-bg-opacity));
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / .1), 0 1px 2px -1px rgb(0 0 0 / .1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow:0 0 #0000,0 0 #0000,var(--tw-shadow);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow)
}

._ods_panel_edge_ih07p_4322{
  border-radius:0
}

@media (min-width: 640px){
  ._ods_panel_edge_ih07p_4322{
    border-radius:.5rem
  }
}

._ods_sidebar_layout_ih07p_4326 main{
  flex:1 1
}

._ods_sidebar_layout_ih07p_4326 ._ods_sidebar_layout_header_ih07p_4329{
  display:flex;
  flex:1 1;
  justify-content:space-between;
  padding-left:1rem;
  padding-right:1rem
}

._ods_sidebar_layout_ih07p_4326 ._ods_sidebar_layout_body_ih07p_4332{
  margin-left:auto;
  margin-right:auto;
  max-width:80rem;
  padding:1.5rem 1rem
}

@media (min-width: 640px){
  ._ods_sidebar_layout_ih07p_4326 ._ods_sidebar_layout_body_ih07p_4332{
    padding-left:1.5rem;
    padding-right:1.5rem
  }
}

@media (min-width: 768px){
  ._ods_sidebar_layout_ih07p_4326 ._ods_sidebar_layout_body_ih07p_4332{
    padding-left:2rem;
    padding-right:2rem
  }
}

._ods_sidebar_layout_constrained_ih07p_4336{
  margin-left:auto;
  margin-right:auto;
  display:flex;
  max-width:56rem;
  flex-direction:column
}

@media (min-width: 768px){
  ._ods_sidebar_layout_constrained_ih07p_4336{
    padding-left:2rem;
    padding-right:2rem
  }
}

@media (min-width: 1280px){
  ._ods_sidebar_layout_constrained_ih07p_4336{
    padding-left:0;
    padding-right:0
  }
}

._ods_sidebar_layout_constrained_ih07p_4336 main{
  flex:1 1
}

._ods_sidebar_layout_constrained_ih07p_4336 ._ods_sidebar_layout_header_ih07p_4329{
  display:flex;
  flex:1 1;
  justify-content:space-between;
  padding-left:1rem;
  padding-right:1rem
}

@media (min-width: 768px){
  ._ods_sidebar_layout_constrained_ih07p_4336 ._ods_sidebar_layout_header_ih07p_4329{
    padding-left:0;
    padding-right:0
  }
}

._ods_sidebar_layout_constrained_ih07p_4336 ._ods_sidebar_layout_body_ih07p_4332{
  padding-top:1.5rem;
  padding-bottom:1.5rem
}

.react-datepicker-popper{
  z-index:40;
  margin-top:.5rem;
  border-radius:.25rem;
  border-width:1px;
  --tw-border-opacity: 1;
  border-color:rgba(209, 213, 219,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-300),var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color:rgba(255, 255, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-white),var(--tw-bg-opacity));
  padding:.5rem .75rem;
  font-size:.875rem;
  line-height:1.25rem
}

.react-datepicker-popper .react-datepicker{
  display:flex
}

.react-datepicker-popper .react-datepicker__week{
  display:flex;
  justify-content:space-around
}

.react-datepicker-popper .react-datepicker__day-names{
  display:flex;
  justify-content:space-around;
  text-align:center;
  font-size:.75rem;
  line-height:1rem;
  font-weight:500;
  --tw-text-opacity: 1;
  color:rgba(156, 163, 175,var(--tw-text-opacity));
  color:rgba(var(--color-light-400),var(--tw-text-opacity))
}

.react-datepicker-popper .react-datepicker__day-name{
  display:flex;
  height:2rem;
  width:2rem;
  align-items:center;
  justify-content:center;
  border-radius:9999px;
  padding-top:.25rem;
  padding-bottom:.25rem
}

.react-datepicker-popper .react-datepicker__day{
  margin-left:.125rem;
  margin-right:.125rem;
  margin-bottom:.25rem;
  display:flex;
  height:2rem;
  width:2rem;
  align-items:center;
  justify-content:center;
  border-radius:.25rem;
  padding-top:.25rem;
  padding-bottom:.25rem;
  line-height:2;
  --tw-text-opacity: 1;
  color:rgba(55, 65, 81,var(--tw-text-opacity));
  color:rgba(var(--color-dark-700),var(--tw-text-opacity));
  transition-property:color,background-color,border-color,fill,stroke,opacity,box-shadow,transform,filter,-webkit-text-decoration-color,-webkit-backdrop-filter;
  transition-property:color,background-color,border-color,fill,stroke,opacity,box-shadow,-webkit-text-decoration-color,-webkit-transform,-webkit-filter,-webkit-backdrop-filter;
  transition-property:color,background-color,border-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter;
  transition-property:color,background-color,border-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter,-webkit-text-decoration-color,-webkit-transform,-webkit-filter,-webkit-backdrop-filter;
  transition-property:color,background-color,border-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter,-webkit-text-decoration-color,-webkit-backdrop-filter;
  transition-timing-function:cubic-bezier(.4,0,.2,1);
  transition-duration:.15s
}

.react-datepicker-popper .react-datepicker__day:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(199, 210, 254,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-200),var(--tw-bg-opacity))
}

.react-datepicker-popper .react-datepicker__day--disabled{
  cursor:not-allowed;
  opacity:.4
}

.react-datepicker-popper .react-datepicker__day--disabled:hover{
  background-color:transparent
}

.react-datepicker-popper .react-datepicker__day--outside-month{
  --tw-text-opacity: 1;
  color:rgba(156, 163, 175,var(--tw-text-opacity));
  color:rgba(var(--color-light-400),var(--tw-text-opacity))
}

.react-datepicker-popper .react-datepicker__day--in-range,.react-datepicker-popper .react-datepicker__day--range-end,.react-datepicker-popper .react-datepicker__day--selected{
  --tw-bg-opacity: 1;
  background-color:rgba(99, 102, 241,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-500),var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:rgba(255, 255, 255,var(--tw-text-opacity));
  color:rgba(var(--color-white),var(--tw-text-opacity))
}

.react-datepicker-popper .react-datepicker__day--in-range:hover,.react-datepicker-popper .react-datepicker__day--range-end:hover,.react-datepicker-popper .react-datepicker__day--selected:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(199, 210, 254,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-200),var(--tw-bg-opacity))
}

.react-datepicker-popper .react-datepicker__day--in-selecting-range{
  --tw-bg-opacity: 1;
  background-color:rgba(199, 210, 254,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-200),var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:rgba(255, 255, 255,var(--tw-text-opacity));
  color:rgba(var(--color-white),var(--tw-text-opacity))
}

.react-datepicker-popper .react-datepicker__day--in-selecting-range:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(99, 102, 241,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-500),var(--tw-bg-opacity))
}

.ods_date_picker_header_wrapper{
  display:flex;
  align-items:center;
  justify-content:space-between;
  padding:.5rem
}

.ods_date_picker_header_date{
  font-size:1.125rem;
  line-height:1.75rem;
  --tw-text-opacity: 1;
  color:rgba(55, 65, 81,var(--tw-text-opacity));
  color:rgba(var(--color-dark-700),var(--tw-text-opacity))
}

.ods_date_picker_header_buttons>:not([hidden])~:not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right:calc(.5rem * var(--tw-space-x-reverse));
  margin-left:calc(.5rem * calc(1 - var(--tw-space-x-reverse)))
}

.ods_date_picker_header_button{
  display:inline-flex;
  border-radius:.25rem;
  border-width:1px;
  --tw-border-opacity: 1;
  border-color:rgba(209, 213, 219,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-300),var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color:rgba(255, 255, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-white),var(--tw-bg-opacity));
  padding:.25rem;
  font-size:.875rem;
  line-height:1.25rem;
  font-weight:500;
  --tw-text-opacity: 1;
  color:rgba(55, 65, 81,var(--tw-text-opacity));
  color:rgba(var(--color-dark-700),var(--tw-text-opacity));
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / .05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow:0 0 #0000,0 0 #0000,var(--tw-shadow);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow)
}

.ods_date_picker_header_button:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(249, 250, 251,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-50),var(--tw-bg-opacity))
}

.ods_date_picker_header_button:focus{
  outline:2px solid transparent;
  outline-offset:2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),0 0 #0000;
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(var(--color-primary-500), var(--tw-ring-opacity));
  --tw-ring-offset-width: 0px
}

.ods_date_picker_header_button_disabled{
  cursor:not-allowed;
  opacity:.5
}

.ods_date_picker_header_icon{
  height:1.25rem;
  width:1.25rem;
  --tw-text-opacity: 1;
  color:rgba(75, 85, 99,var(--tw-text-opacity));
  color:rgba(var(--color-dark-600),var(--tw-text-opacity))
}

.container{
  width:100%
}

@media (min-width: 640px){
  .container{
    max-width:640px
  }
}

@media (min-width: 768px){
  .container{
    max-width:768px
  }
}

@media (min-width: 1024px){
  .container{
    max-width:1024px
  }
}

@media (min-width: 1280px){
  .container{
    max-width:1280px
  }
}

@media (min-width: 1536px){
  .container{
    max-width:1536px
  }
}

.sr-only{
  position:absolute;
  width:1px;
  height:1px;
  padding:0;
  margin:-1px;
  overflow:hidden;
  clip:rect(0,0,0,0);
  white-space:nowrap;
  border-width:0
}

.pointer-events-none{
  pointer-events:none
}

.absolute{
  position:absolute
}

.relative{
  position:relative
}

.sticky{
  position:-webkit-sticky;
  position:sticky
}

.inset-0{
  inset:0
}

.inset-y-0{
  top:0;
  bottom:0
}

.right-0{
  right:0
}

.top-0{
  top:0
}

.left-0{
  left:0
}

.z-10{
  z-index:10
}

.order-2{
  order:2
}

.col-span-2{
  grid-column:span 2 / span 2
}

.-m-2{
  margin:-.5rem
}

.m-2{
  margin:.5rem
}

.mx-auto{
  margin-left:auto;
  margin-right:auto
}

.my-4{
  margin-top:1rem;
  margin-bottom:1rem
}

.mx-8{
  margin-left:2rem;
  margin-right:2rem
}

.mx-1{
  margin-left:.25rem;
  margin-right:.25rem
}

.mt-6{
  margin-top:1.5rem
}

.mt-2{
  margin-top:.5rem
}

.ml-1{
  margin-left:.25rem
}

.mt-8{
  margin-top:2rem
}

.ml-2{
  margin-left:.5rem
}

.-ml-2{
  margin-left:-.5rem
}

.mt-1{
  margin-top:.25rem
}

.ml-3{
  margin-left:.75rem
}

.mt-3{
  margin-top:.75rem
}

.mt-10{
  margin-top:2.5rem
}

.mt-4{
  margin-top:1rem
}

.ml-4{
  margin-left:1rem
}

.mb-2\.5{
  margin-bottom:.625rem
}

.mb-2{
  margin-bottom:.5rem
}

.mr-1\.5{
  margin-right:.375rem
}

.mr-1{
  margin-right:.25rem
}

.mr-5{
  margin-right:1.25rem
}

.mt-1\.5{
  margin-top:.375rem
}

.-mt-20{
  margin-top:-5rem
}

.-mt-2{
  margin-top:-.5rem
}

.ml-5{
  margin-left:1.25rem
}

.ml-0{
  margin-left:0
}

.mb-4{
  margin-bottom:1rem
}

.mb-1\.5{
  margin-bottom:.375rem
}

.mb-1{
  margin-bottom:.25rem
}

.ml-6{
  margin-left:1.5rem
}

.mb-px{
  margin-bottom:1px
}

.mr-3{
  margin-right:.75rem
}

.ml-7{
  margin-left:1.75rem
}

.mt-2\.5{
  margin-top:.625rem
}

.mb-5{
  margin-bottom:1.25rem
}

.mt-0{
  margin-top:0
}

.mb-8{
  margin-bottom:2rem
}

.ml-0\.5{
  margin-left:.125rem
}

.ml-auto{
  margin-left:auto
}

.mt-px{
  margin-top:1px
}

.block{
  display:block
}

.inline-block{
  display:inline-block
}

.inline{
  display:inline
}

.\!inline{
  display:inline!important
}

.flex{
  display:flex
}

.inline-flex{
  display:inline-flex
}

.table{
  display:table
}

.\!table{
  display:table!important
}

.grid{
  display:grid
}

.contents{
  display:contents
}

.hidden{
  display:none
}

.h-12{
  height:3rem
}

.h-5{
  height:1.25rem
}

.h-full{
  height:100%
}

.h-16{
  height:4rem
}

.h-6{
  height:1.5rem
}

.h-10{
  height:2.5rem
}

.h-32{
  height:8rem
}

.h-24{
  height:6rem
}

.h-screen{
  height:100vh
}

.h-20{
  height:5rem
}

.h-2{
  height:.5rem
}

.h-8{
  height:2rem
}

.h-48{
  height:12rem
}

.h-64{
  height:16rem
}

.h-96{
  height:24rem
}

.min-h-\[640px\]{
  min-height:640px
}

.w-auto{
  width:auto
}

.w-5{
  width:1.25rem
}

.w-full{
  width:100%
}

.w-0{
  width:0px
}

.w-16{
  width:4rem
}

.w-6{
  width:1.5rem
}

.w-10{
  width:2.5rem
}

.w-24{
  width:6rem
}

.w-20{
  width:5rem
}

.w-2{
  width:.5rem
}

.w-12{
  width:3rem
}

.w-32{
  width:8rem
}

.w-64{
  width:16rem
}

.min-w-0{
  min-width:0px
}

.max-w-sm{
  max-width:24rem
}

.max-w-3xl{
  max-width:48rem
}

.max-w-2xl{
  max-width:42rem
}

.max-w-lg{
  max-width:32rem
}

.max-w-xs{
  max-width:20rem
}

.flex-1{
  flex:1 1
}

.flex-shrink-0{
  flex-shrink:0
}

.flex-grow{
  flex-grow:1
}

.rotate-\[-120deg\]{
  --tw-rotate: -120deg;
  -webkit-transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.rotate-0{
  --tw-rotate: 0deg;
  -webkit-transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.scale-50{
  --tw-scale-x: .5;
  --tw-scale-y: .5;
  -webkit-transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.scale-100{
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  -webkit-transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.scale-95{
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  -webkit-transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.transform{
  -webkit-transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.cursor-pointer{
  cursor:pointer
}

.select-all{
  -webkit-user-select:all;
  -ms-user-select:all;
  user-select:all
}

.list-disc{
  list-style-type:disc
}

.grid-cols-1{
  grid-template-columns:repeat(1,minmax(0,1fr))
}

.grid-cols-3{
  grid-template-columns:repeat(3,minmax(0,1fr))
}

.flex-row{
  flex-direction:row
}

.flex-col{
  flex-direction:column
}

.flex-col-reverse{
  flex-direction:column-reverse
}

.flex-wrap{
  flex-wrap:wrap
}

.items-start{
  align-items:flex-start
}

.items-end{
  align-items:flex-end
}

.items-center{
  align-items:center
}

.items-stretch{
  align-items:stretch
}

.justify-start{
  justify-content:flex-start
}

.justify-end{
  justify-content:flex-end
}

.justify-center{
  justify-content:center
}

.justify-between{
  justify-content:space-between
}

.gap-3{
  grid-gap:.75rem;
  gap:.75rem
}

.gap-6{
  grid-gap:1.5rem;
  gap:1.5rem
}

.gap-4{
  grid-gap:1rem;
  gap:1rem
}

.gap-x-4{
  -moz-column-gap:1rem;
  grid-column-gap:1rem;
  -webkit-column-gap:1rem;
  column-gap:1rem
}

.space-y-6>:not([hidden])~:not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top:calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom:calc(1.5rem * var(--tw-space-y-reverse))
}

.space-y-1>:not([hidden])~:not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top:calc(.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom:calc(.25rem * var(--tw-space-y-reverse))
}

.space-y-3>:not([hidden])~:not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top:calc(.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom:calc(.75rem * var(--tw-space-y-reverse))
}

.space-x-4>:not([hidden])~:not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right:calc(1rem * var(--tw-space-x-reverse));
  margin-left:calc(1rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-x-3>:not([hidden])~:not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right:calc(.75rem * var(--tw-space-x-reverse));
  margin-left:calc(.75rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-4>:not([hidden])~:not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top:calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom:calc(1rem * var(--tw-space-y-reverse))
}

.space-y-2>:not([hidden])~:not([hidden]){
  --tw-space-y-reverse: 0;
  margin-top:calc(.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom:calc(.5rem * var(--tw-space-y-reverse))
}

.-space-x-1>:not([hidden])~:not([hidden]){
  --tw-space-x-reverse: 0;
  margin-right:calc(-.25rem * var(--tw-space-x-reverse));
  margin-left:calc(-.25rem * calc(1 - var(--tw-space-x-reverse)))
}

.divide-y>:not([hidden])~:not([hidden]){
  --tw-divide-y-reverse: 0;
  border-top-width:calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width:calc(1px * var(--tw-divide-y-reverse))
}

.divide-none>:not([hidden])~:not([hidden]){
  border-style:none
}

.divide-light-200>:not([hidden])~:not([hidden]){
  --tw-divide-opacity: 1;
  border-color:rgba(229, 231, 235,var(--tw-divide-opacity));
  border-color:rgba(var(--color-light-200),var(--tw-divide-opacity))
}

.self-center{
  align-self:center
}

.overflow-hidden{
  overflow:hidden
}

.truncate{
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap
}

.whitespace-nowrap{
  white-space:nowrap
}

.rounded{
  border-radius:.25rem
}

.rounded-xl{
  border-radius:.75rem
}

.rounded-lg{
  border-radius:.5rem
}

.rounded-full{
  border-radius:9999px
}

.rounded-md{
  border-radius:.375rem
}

.rounded-none{
  border-radius:0
}

.rounded-t-md{
  border-top-left-radius:.375rem;
  border-top-right-radius:.375rem
}

.rounded-b-md{
  border-bottom-right-radius:.375rem;
  border-bottom-left-radius:.375rem
}

.border{
  border-width:1px
}

.border-2{
  border-width:2px
}

.border-4{
  border-width:4px
}

.border-y-0{
  border-top-width:0px;
  border-bottom-width:0px
}

.border-t{
  border-top-width:1px
}

.border-b{
  border-bottom-width:1px
}

.border-t-0{
  border-top-width:0px
}

.border-l{
  border-left-width:1px
}

.border-r-0{
  border-right-width:0px
}

.border-dashed{
  border-style:dashed
}

.border-none{
  border-style:none
}

.border-light-200{
  --tw-border-opacity: 1;
  border-color:rgba(229, 231, 235,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-200),var(--tw-border-opacity))
}

.border-transparent{
  border-color:transparent
}

.border-light-300{
  --tw-border-opacity: 1;
  border-color:rgba(209, 213, 219,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-300),var(--tw-border-opacity))
}

.border-white{
  --tw-border-opacity: 1;
  border-color:rgba(255, 255, 255,var(--tw-border-opacity));
  border-color:rgba(var(--color-white),var(--tw-border-opacity))
}

.border-light-100{
  --tw-border-opacity: 1;
  border-color:rgba(243, 244, 246,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-100),var(--tw-border-opacity))
}

.border-light-400{
  --tw-border-opacity: 1;
  border-color:rgba(156, 163, 175,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-400),var(--tw-border-opacity))
}

.bg-light-50{
  --tw-bg-opacity: 1;
  background-color:rgba(249, 250, 251,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-50),var(--tw-bg-opacity))
}

.bg-pink-500{
  --tw-bg-opacity: 1;
  background-color:rgb(236 72 153 / var(--tw-bg-opacity))
}

.bg-warning-500{
  --tw-bg-opacity: 1;
  background-color:rgba(245, 158, 11,var(--tw-bg-opacity));
  background-color:rgba(var(--color-warning-500),var(--tw-bg-opacity))
}

.bg-success-500{
  --tw-bg-opacity: 1;
  background-color:rgba(16, 185, 129,var(--tw-bg-opacity));
  background-color:rgba(var(--color-success-500),var(--tw-bg-opacity))
}

.bg-info-500{
  --tw-bg-opacity: 1;
  background-color:rgba(59, 130, 246,var(--tw-bg-opacity));
  background-color:rgba(var(--color-info-500),var(--tw-bg-opacity))
}

.bg-primary-500{
  --tw-bg-opacity: 1;
  background-color:rgba(99, 102, 241,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-500),var(--tw-bg-opacity))
}

.bg-purple-500{
  --tw-bg-opacity: 1;
  background-color:rgb(168 85 247 / var(--tw-bg-opacity))
}

.bg-light-100{
  --tw-bg-opacity: 1;
  background-color:rgba(243, 244, 246,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-100),var(--tw-bg-opacity))
}

.bg-primary-600{
  --tw-bg-opacity: 1;
  background-color:rgba(79, 70, 229,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-600),var(--tw-bg-opacity))
}

.bg-light-400{
  --tw-bg-opacity: 1;
  background-color:rgba(156, 163, 175,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-400),var(--tw-bg-opacity))
}

.bg-dark-600{
  --tw-bg-opacity: 1;
  background-color:rgba(75, 85, 99,var(--tw-bg-opacity));
  background-color:rgba(var(--color-dark-600),var(--tw-bg-opacity))
}

.bg-white{
  --tw-bg-opacity: 1;
  background-color:rgba(255, 255, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-white),var(--tw-bg-opacity))
}

.bg-light-200{
  --tw-bg-opacity: 1;
  background-color:rgba(229, 231, 235,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-200),var(--tw-bg-opacity))
}

.bg-success-400{
  --tw-bg-opacity: 1;
  background-color:rgba(52, 211, 153,var(--tw-bg-opacity));
  background-color:rgba(var(--color-success-400),var(--tw-bg-opacity))
}

.bg-transparent{
  background-color:transparent
}

.bg-red-800{
  --tw-bg-opacity: 1;
  background-color:rgb(153 27 27 / var(--tw-bg-opacity))
}

.bg-black{
  --tw-bg-opacity: 1;
  background-color:rgba(0, 0, 0,var(--tw-bg-opacity));
  background-color:rgba(var(--color-black),var(--tw-bg-opacity))
}

.bg-gray-50{
  --tw-bg-opacity: 1;
  background-color:rgb(249 250 251 / var(--tw-bg-opacity))
}

.bg-danger-200{
  --tw-bg-opacity: 1;
  background-color:rgba(254, 202, 202,var(--tw-bg-opacity));
  background-color:rgba(var(--color-danger-200),var(--tw-bg-opacity))
}

.bg-info-400{
  --tw-bg-opacity: 1;
  background-color:rgba(96, 165, 250,var(--tw-bg-opacity));
  background-color:rgba(var(--color-info-400),var(--tw-bg-opacity))
}

.bg-danger-600{
  --tw-bg-opacity: 1;
  background-color:rgba(220, 38, 38,var(--tw-bg-opacity));
  background-color:rgba(var(--color-danger-600),var(--tw-bg-opacity))
}

.bg-gray-100{
  --tw-bg-opacity: 1;
  background-color:rgb(243 244 246 / var(--tw-bg-opacity))
}

.bg-success-50{
  --tw-bg-opacity: 1;
  background-color:rgba(236, 253, 245,var(--tw-bg-opacity));
  background-color:rgba(var(--color-success-50),var(--tw-bg-opacity))
}

.bg-opacity-20{
  --tw-bg-opacity: .2
}

.fill-white{
  fill:rgb(255, 255, 255);
  fill:rgb(var(--color-white))
}

.object-cover{
  object-fit:cover
}

.p-0{
  padding:0
}

.p-2{
  padding:.5rem
}

.p-8{
  padding:2rem
}

.py-12{
  padding-top:3rem;
  padding-bottom:3rem
}

.px-4{
  padding-left:1rem;
  padding-right:1rem
}

.py-8{
  padding-top:2rem;
  padding-bottom:2rem
}

.px-2{
  padding-left:.5rem;
  padding-right:.5rem
}

.py-6{
  padding-top:1.5rem;
  padding-bottom:1.5rem
}

.py-4{
  padding-top:1rem;
  padding-bottom:1rem
}

.px-3{
  padding-left:.75rem;
  padding-right:.75rem
}

.py-2{
  padding-top:.5rem;
  padding-bottom:.5rem
}

.px-6{
  padding-left:1.5rem;
  padding-right:1.5rem
}

.py-5{
  padding-top:1.25rem;
  padding-bottom:1.25rem
}

.px-0{
  padding-left:0;
  padding-right:0
}

.py-0{
  padding-top:0;
  padding-bottom:0
}

.px-2\.5{
  padding-left:.625rem;
  padding-right:.625rem
}

.py-0\.5{
  padding-top:.125rem;
  padding-bottom:.125rem
}

.py-16{
  padding-top:4rem;
  padding-bottom:4rem
}

.py-3{
  padding-top:.75rem;
  padding-bottom:.75rem
}

.px-12{
  padding-left:3rem;
  padding-right:3rem
}

.py-1{
  padding-top:.25rem;
  padding-bottom:.25rem
}

.pb-2{
  padding-bottom:.5rem
}

.pl-5{
  padding-left:1.25rem
}

.pl-4{
  padding-left:1rem
}

.pb-1\.5{
  padding-bottom:.375rem
}

.pb-1{
  padding-bottom:.25rem
}

.pl-1{
  padding-left:.25rem
}

.pt-2{
  padding-top:.5rem
}

.pl-3{
  padding-left:.75rem
}

.pr-4{
  padding-right:1rem
}

.pt-8{
  padding-top:2rem
}

.pt-4{
  padding-top:1rem
}

.pl-8{
  padding-left:2rem
}

.pr-3{
  padding-right:.75rem
}

.text-left{
  text-align:left
}

.text-center{
  text-align:center
}

.text-right{
  text-align:right
}

.text-sm{
  font-size:.875rem;
  line-height:1.25rem
}

.text-lg{
  font-size:1.125rem;
  line-height:1.75rem
}

.text-xs{
  font-size:.75rem;
  line-height:1rem
}

.text-2xl{
  font-size:1.5rem;
  line-height:2rem
}

.text-xl{
  font-size:1.25rem;
  line-height:1.75rem
}

.text-3xl{
  font-size:1.875rem;
  line-height:2.25rem
}

.font-bold{
  font-weight:700
}

.font-extrabold{
  font-weight:800
}

.font-medium{
  font-weight:500
}

.font-semibold{
  font-weight:600
}

.font-light{
  font-weight:300
}

.font-normal{
  font-weight:400
}

.uppercase{
  text-transform:uppercase
}

.leading-7{
  line-height:1.75rem
}

.leading-5{
  line-height:1.25rem
}

.leading-6{
  line-height:1.5rem
}

.tracking-wide{
  letter-spacing:.025em
}

.text-dark-500{
  --tw-text-opacity: 1;
  color:rgba(107, 114, 128,var(--tw-text-opacity));
  color:rgba(var(--color-dark-500),var(--tw-text-opacity))
}

.text-primary-500{
  --tw-text-opacity: 1;
  color:rgba(99, 102, 241,var(--tw-text-opacity));
  color:rgba(var(--color-primary-500),var(--tw-text-opacity))
}

.text-dark-700{
  --tw-text-opacity: 1;
  color:rgba(55, 65, 81,var(--tw-text-opacity));
  color:rgba(var(--color-dark-700),var(--tw-text-opacity))
}

.text-light-400{
  --tw-text-opacity: 1;
  color:rgba(156, 163, 175,var(--tw-text-opacity));
  color:rgba(var(--color-light-400),var(--tw-text-opacity))
}

.text-dark-900{
  --tw-text-opacity: 1;
  color:rgba(17, 24, 39,var(--tw-text-opacity));
  color:rgba(var(--color-dark-900),var(--tw-text-opacity))
}

.text-white{
  --tw-text-opacity: 1;
  color:rgba(255, 255, 255,var(--tw-text-opacity));
  color:rgba(var(--color-white),var(--tw-text-opacity))
}

.text-dark-600{
  --tw-text-opacity: 1;
  color:rgba(75, 85, 99,var(--tw-text-opacity));
  color:rgba(var(--color-dark-600),var(--tw-text-opacity))
}

.text-light-200{
  --tw-text-opacity: 1;
  color:rgba(229, 231, 235,var(--tw-text-opacity));
  color:rgba(var(--color-light-200),var(--tw-text-opacity))
}

.text-light-300{
  --tw-text-opacity: 1;
  color:rgba(209, 213, 219,var(--tw-text-opacity));
  color:rgba(var(--color-light-300),var(--tw-text-opacity))
}

.text-primary-100{
  --tw-text-opacity: 1;
  color:rgba(224, 231, 255,var(--tw-text-opacity));
  color:rgba(var(--color-primary-100),var(--tw-text-opacity))
}

.text-success-400{
  --tw-text-opacity: 1;
  color:rgba(52, 211, 153,var(--tw-text-opacity));
  color:rgba(var(--color-success-400),var(--tw-text-opacity))
}

.text-dark-800{
  --tw-text-opacity: 1;
  color:rgba(31, 41, 55,var(--tw-text-opacity));
  color:rgba(var(--color-dark-800),var(--tw-text-opacity))
}

.text-primary-600{
  --tw-text-opacity: 1;
  color:rgba(79, 70, 229,var(--tw-text-opacity));
  color:rgba(var(--color-primary-600),var(--tw-text-opacity))
}

.text-primary-300{
  --tw-text-opacity: 1;
  color:rgba(165, 180, 252,var(--tw-text-opacity));
  color:rgba(var(--color-primary-300),var(--tw-text-opacity))
}

.text-primary-200{
  --tw-text-opacity: 1;
  color:rgba(199, 210, 254,var(--tw-text-opacity));
  color:rgba(var(--color-primary-200),var(--tw-text-opacity))
}

.text-gray-900{
  --tw-text-opacity: 1;
  color:rgb(17 24 39 / var(--tw-text-opacity))
}

.text-gray-500{
  --tw-text-opacity: 1;
  color:rgb(107 114 128 / var(--tw-text-opacity))
}

.text-success-500{
  --tw-text-opacity: 1;
  color:rgba(16, 185, 129,var(--tw-text-opacity));
  color:rgba(var(--color-success-500),var(--tw-text-opacity))
}

.text-danger-500{
  --tw-text-opacity: 1;
  color:rgba(239, 68, 68,var(--tw-text-opacity));
  color:rgba(var(--color-danger-500),var(--tw-text-opacity))
}

.text-success-800{
  --tw-text-opacity: 1;
  color:rgba(6, 95, 70,var(--tw-text-opacity));
  color:rgba(var(--color-success-800),var(--tw-text-opacity))
}

.text-success-600{
  --tw-text-opacity: 1;
  color:rgba(5, 150, 105,var(--tw-text-opacity));
  color:rgba(var(--color-success-600),var(--tw-text-opacity))
}

.text-warning-700{
  --tw-text-opacity: 1;
  color:rgba(180, 83, 9,var(--tw-text-opacity));
  color:rgba(var(--color-warning-700),var(--tw-text-opacity))
}

.text-info-700{
  --tw-text-opacity: 1;
  color:rgba(29, 78, 216,var(--tw-text-opacity));
  color:rgba(var(--color-info-700),var(--tw-text-opacity))
}

.text-warning-800{
  --tw-text-opacity: 1;
  color:rgba(146, 64, 14,var(--tw-text-opacity));
  color:rgba(var(--color-warning-800),var(--tw-text-opacity))
}

.text-info-800{
  --tw-text-opacity: 1;
  color:rgba(30, 64, 175,var(--tw-text-opacity));
  color:rgba(var(--color-info-800),var(--tw-text-opacity))
}

.text-danger-800{
  --tw-text-opacity: 1;
  color:rgba(153, 27, 27,var(--tw-text-opacity));
  color:rgba(var(--color-danger-800),var(--tw-text-opacity))
}

.underline{
  -webkit-text-decoration-line:underline;
  text-decoration-line:underline
}

.placeholder-dark-500:-ms-input-placeholder{
  --tw-placeholder-opacity: 1;
  color:rgba(107, 114, 128,var(--tw-placeholder-opacity));
  color:rgba(var(--color-dark-500),var(--tw-placeholder-opacity))
}

.placeholder-dark-500::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1;
  color:rgba(107, 114, 128,var(--tw-placeholder-opacity));
  color:rgba(var(--color-dark-500),var(--tw-placeholder-opacity))
}

.placeholder-dark-500::placeholder{
  --tw-placeholder-opacity: 1;
  color:rgba(107, 114, 128,var(--tw-placeholder-opacity));
  color:rgba(var(--color-dark-500),var(--tw-placeholder-opacity))
}

.opacity-25{
  opacity:.25
}

.opacity-75{
  opacity:.75
}

.opacity-0{
  opacity:0
}

.opacity-100{
  opacity:1
}

.opacity-70{
  opacity:.7
}

.shadow-sm{
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / .05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow:0 0 #0000,0 0 #0000,var(--tw-shadow);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow)
}

.shadow{
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / .1), 0 1px 2px -1px rgb(0 0 0 / .1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow:0 0 #0000,0 0 #0000,var(--tw-shadow);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow)
}

.shadow-lg{
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / .1), 0 4px 6px -4px rgb(0 0 0 / .1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow:0 0 #0000,0 0 #0000,var(--tw-shadow);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow)
}

.shadow-none{
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow:0 0 #0000,0 0 #0000,var(--tw-shadow);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow)
}

.filter{
  -webkit-filter:var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  filter:var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.transition{
  transition-property:color,background-color,border-color,fill,stroke,opacity,box-shadow,transform,filter,-webkit-text-decoration-color,-webkit-backdrop-filter;
  transition-property:color,background-color,border-color,fill,stroke,opacity,box-shadow,-webkit-text-decoration-color,-webkit-transform,-webkit-filter,-webkit-backdrop-filter;
  transition-property:color,background-color,border-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter;
  transition-property:color,background-color,border-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter,-webkit-text-decoration-color,-webkit-transform,-webkit-filter,-webkit-backdrop-filter;
  transition-property:color,background-color,border-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter,-webkit-text-decoration-color,-webkit-backdrop-filter;
  transition-timing-function:cubic-bezier(.4,0,.2,1);
  transition-duration:.15s
}

.duration-\[400ms\]{
  transition-duration:.4s
}

.duration-200{
  transition-duration:.2s
}

.ease-in-out{
  transition-timing-function:cubic-bezier(.4,0,.2,1)
}

.line-clamp-2{
  overflow:hidden;
  display:-webkit-box;
  -webkit-box-orient:vertical;
  -webkit-line-clamp:2
}

.focus-within\:text-dark-600:focus-within{
  --tw-text-opacity: 1;
  color:rgba(75, 85, 99,var(--tw-text-opacity));
  color:rgba(var(--color-dark-600),var(--tw-text-opacity))
}

.focus-within\:outline-none:focus-within{
  outline:2px solid transparent;
  outline-offset:2px
}

.focus-within\:ring-2:focus-within{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),0 0 #0000;
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),var(--tw-shadow, 0 0 #0000)
}

.focus-within\:ring-inset:focus-within{
  --tw-ring-inset: inset
}

.focus-within\:ring-primary-500:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(var(--color-primary-500), var(--tw-ring-opacity))
}

.focus-within\:ring-primary-600:focus-within{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(var(--color-primary-600), var(--tw-ring-opacity))
}

.hover\:scale-105:hover{
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
  -webkit-transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform:translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.hover\:cursor-pointer:hover{
  cursor:pointer
}

.hover\:rounded-t-md:hover{
  border-top-left-radius:.375rem;
  border-top-right-radius:.375rem
}

.hover\:bg-light-50:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(249, 250, 251,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-50),var(--tw-bg-opacity))
}

.hover\:bg-light-200:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(229, 231, 235,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-200),var(--tw-bg-opacity))
}

.hover\:bg-primary-700:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(67, 56, 202,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-700),var(--tw-bg-opacity))
}

.hover\:bg-dark-700:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(55, 65, 81,var(--tw-bg-opacity));
  background-color:rgba(var(--color-dark-700),var(--tw-bg-opacity))
}

.hover\:bg-danger-100:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(254, 226, 226,var(--tw-bg-opacity));
  background-color:rgba(var(--color-danger-100),var(--tw-bg-opacity))
}

.hover\:bg-danger-700:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(185, 28, 28,var(--tw-bg-opacity));
  background-color:rgba(var(--color-danger-700),var(--tw-bg-opacity))
}

.hover\:bg-success-100:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(209, 250, 229,var(--tw-bg-opacity));
  background-color:rgba(var(--color-success-100),var(--tw-bg-opacity))
}

.hover\:bg-opacity-30:hover{
  --tw-bg-opacity: .3
}

.hover\:text-primary-500:hover{
  --tw-text-opacity: 1;
  color:rgba(99, 102, 241,var(--tw-text-opacity));
  color:rgba(var(--color-primary-500),var(--tw-text-opacity))
}

.hover\:text-light-200:hover{
  --tw-text-opacity: 1;
  color:rgba(229, 231, 235,var(--tw-text-opacity));
  color:rgba(var(--color-light-200),var(--tw-text-opacity))
}

.hover\:text-dark-500:hover{
  --tw-text-opacity: 1;
  color:rgba(107, 114, 128,var(--tw-text-opacity));
  color:rgba(var(--color-dark-500),var(--tw-text-opacity))
}

.hover\:text-warning-600:hover{
  --tw-text-opacity: 1;
  color:rgba(217, 119, 6,var(--tw-text-opacity));
  color:rgba(var(--color-warning-600),var(--tw-text-opacity))
}

.hover\:text-info-600:hover{
  --tw-text-opacity: 1;
  color:rgba(37, 99, 235,var(--tw-text-opacity));
  color:rgba(var(--color-info-600),var(--tw-text-opacity))
}

.hover\:text-white:hover{
  --tw-text-opacity: 1;
  color:rgba(255, 255, 255,var(--tw-text-opacity));
  color:rgba(var(--color-white),var(--tw-text-opacity))
}

.hover\:underline:hover{
  -webkit-text-decoration-line:underline;
  text-decoration-line:underline
}

.hover\:no-underline:hover{
  -webkit-text-decoration-line:none;
  text-decoration-line:none
}

.focus\:border-transparent:focus{
  border-color:transparent
}

.focus\:placeholder-light-400:focus:-ms-input-placeholder{
  --tw-placeholder-opacity: 1;
  color:rgba(156, 163, 175,var(--tw-placeholder-opacity));
  color:rgba(var(--color-light-400),var(--tw-placeholder-opacity))
}

.focus\:placeholder-light-400:focus::-webkit-input-placeholder{
  --tw-placeholder-opacity: 1;
  color:rgba(156, 163, 175,var(--tw-placeholder-opacity));
  color:rgba(var(--color-light-400),var(--tw-placeholder-opacity))
}

.focus\:placeholder-light-400:focus::placeholder{
  --tw-placeholder-opacity: 1;
  color:rgba(156, 163, 175,var(--tw-placeholder-opacity));
  color:rgba(var(--color-light-400),var(--tw-placeholder-opacity))
}

.focus\:outline-none:focus{
  outline:2px solid transparent;
  outline-offset:2px
}

.focus\:outline:focus{
  outline-style:solid
}

.focus\:outline-2:focus{
  outline-width:2px
}

.focus\:outline-primary-500:focus{
  outline-color:rgb(99, 102, 241);
  outline-color:rgb(var(--color-primary-500))
}

.focus\:ring-2:focus{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),0 0 #0000;
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),var(--tw-shadow, 0 0 #0000)
}

.focus\:ring-0:focus{
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),0 0 #0000;
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),var(--tw-shadow, 0 0 #0000)
}

.focus\:ring-primary-500:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(var(--color-primary-500), var(--tw-ring-opacity))
}

.focus\:ring-pink-500:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(236 72 153 / var(--tw-ring-opacity))
}

.focus\:ring-danger-500:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(var(--color-danger-500), var(--tw-ring-opacity))
}

.focus\:ring-success-600:focus{
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(var(--color-success-600), var(--tw-ring-opacity))
}

.focus\:ring-offset-2:focus{
  --tw-ring-offset-width: 2px
}

.focus\:ring-offset-dark-800:focus{
  --tw-ring-offset-color: rgb(var(--color-dark-800))
}

.focus\:ring-offset-success-50:focus{
  --tw-ring-offset-color: rgb(var(--color-success-50))
}

.active\:bg-opacity-40:active{
  --tw-bg-opacity: .4
}

.group:hover .group-hover\:text-primary-400{
  --tw-text-opacity: 1;
  color:rgba(129, 140, 248,var(--tw-text-opacity));
  color:rgba(var(--color-primary-400),var(--tw-text-opacity))
}

.group:hover .group-hover\:text-dark-500{
  --tw-text-opacity: 1;
  color:rgba(107, 114, 128,var(--tw-text-opacity));
  color:rgba(var(--color-dark-500),var(--tw-text-opacity))
}

@media (min-width: 640px){
  .sm\:col-span-2{
    grid-column:span 2 / span 2
  }

  .sm\:col-start-2{
    grid-column-start:2
  }

  .sm\:mx-auto{
    margin-left:auto;
    margin-right:auto
  }

  .sm\:mx-0{
    margin-left:0;
    margin-right:0
  }

  .sm\:mx-64{
    margin-left:16rem;
    margin-right:16rem
  }

  .sm\:mx-1{
    margin-left:.25rem;
    margin-right:.25rem
  }

  .sm\:mt-0{
    margin-top:0
  }

  .sm\:ml-4{
    margin-left:1rem
  }

  .sm\:-mt-26{
    margin-top:-104px
  }

  .sm\:ml-5{
    margin-left:1.25rem
  }

  .sm\:ml-0{
    margin-left:0
  }

  .sm\:ml-6{
    margin-left:1.5rem
  }

  .sm\:mb-0{
    margin-bottom:0
  }

  .sm\:mt-px{
    margin-top:1px
  }

  .sm\:ml-3{
    margin-left:.75rem
  }

  .sm\:mr-3{
    margin-right:.75rem
  }

  .sm\:block{
    display:block
  }

  .sm\:inline{
    display:inline
  }

  .sm\:flex{
    display:flex
  }

  .sm\:grid{
    display:grid
  }

  .sm\:hidden{
    display:none
  }

  .sm\:h-32{
    height:8rem
  }

  .sm\:h-80{
    height:20rem
  }

  .sm\:h-6{
    height:1.5rem
  }

  .sm\:w-full{
    width:100%
  }

  .sm\:w-32{
    width:8rem
  }

  .sm\:min-w-0{
    min-width:0px
  }

  .sm\:max-w-md{
    max-width:28rem
  }

  .sm\:max-w-xs{
    max-width:20rem
  }

  .sm\:flex-1{
    flex:1 1
  }

  .sm\:flex-shrink-0{
    flex-shrink:0
  }

  .sm\:grid-cols-3{
    grid-template-columns:repeat(3,minmax(0,1fr))
  }

  .sm\:flex-row{
    flex-direction:row
  }

  .sm\:flex-wrap{
    flex-wrap:wrap
  }

  .sm\:items-end{
    align-items:flex-end
  }

  .sm\:items-center{
    align-items:center
  }

  .sm\:items-baseline{
    align-items:baseline
  }

  .sm\:justify-end{
    justify-content:flex-end
  }

  .sm\:space-y-0>:not([hidden])~:not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top:calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(0px * var(--tw-space-y-reverse))
  }

  .sm\:space-y-5>:not([hidden])~:not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top:calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(1.25rem * var(--tw-space-y-reverse))
  }

  .sm\:divide-y-0>:not([hidden])~:not([hidden]){
    --tw-divide-y-reverse: 0;
    border-top-width:calc(0px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width:calc(0px * var(--tw-divide-y-reverse))
  }

  .sm\:divide-x>:not([hidden])~:not([hidden]){
    --tw-divide-x-reverse: 0;
    border-right-width:calc(1px * var(--tw-divide-x-reverse));
    border-left-width:calc(1px * calc(1 - var(--tw-divide-x-reverse)))
  }

  .sm\:truncate{
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap
  }

  .sm\:px-10{
    padding-left:2.5rem;
    padding-right:2.5rem
  }

  .sm\:px-6{
    padding-left:1.5rem;
    padding-right:1.5rem
  }

  .sm\:px-48{
    padding-left:12rem;
    padding-right:12rem
  }

  .sm\:px-72{
    padding-left:18rem;
    padding-right:18rem
  }

  .sm\:px-40{
    padding-left:10rem;
    padding-right:10rem
  }

  .sm\:px-0{
    padding-left:0;
    padding-right:0
  }

  .sm\:py-5{
    padding-top:1.25rem;
    padding-bottom:1.25rem
  }

  .sm\:py-0{
    padding-top:0;
    padding-bottom:0
  }

  .sm\:px-52{
    padding-left:13rem;
    padding-right:13rem
  }

  .sm\:pt-2{
    padding-top:.5rem
  }

  .sm\:pt-5{
    padding-top:1.25rem
  }

  .sm\:pt-10{
    padding-top:2.5rem
  }

  .sm\:text-left{
    text-align:left
  }

  .sm\:text-right{
    text-align:right
  }

  .sm\:text-sm{
    font-size:.875rem;
    line-height:1.25rem
  }

  .sm\:text-3xl{
    font-size:1.875rem;
    line-height:2.25rem
  }

  .sm\:text-2xl{
    font-size:1.5rem;
    line-height:2rem
  }
}

@media (min-width: 768px){
  .md\:mt-0{
    margin-top:0
  }

  .md\:ml-6{
    margin-left:1.5rem
  }

  .md\:ml-0{
    margin-left:0
  }

  .md\:block{
    display:block
  }

  .md\:inline{
    display:inline
  }

  .md\:hidden{
    display:none
  }

  .md\:space-y-0>:not([hidden])~:not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top:calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(0px * var(--tw-space-y-reverse))
  }

  .md\:pl-0{
    padding-left:0
  }

  .md\:text-center{
    text-align:center
  }

  .md\:text-right{
    text-align:right
  }
}

@media (min-width: 1024px){
  .lg\:mt-4{
    margin-top:1rem
  }

  .lg\:mt-0{
    margin-top:0
  }

  .lg\:block{
    display:block
  }

  .lg\:flex{
    display:flex
  }

  .lg\:hidden{
    display:none
  }

  .lg\:h-0{
    height:0px
  }

  .lg\:min-h-\[768px\]{
    min-height:768px
  }

  .lg\:flex-none{
    flex:none
  }

  .lg\:items-center{
    align-items:center
  }

  .lg\:px-20{
    padding-left:5rem;
    padding-right:5rem
  }
}

@media (min-width: 1280px){
  .xl\:px-24{
    padding-left:6rem;
    padding-right:6rem
  }
}

@media (min-width: 1024px){
  .ods_calendar{
    display:flex;
    height:100%;
    flex:1 1 auto;
    flex-direction:column
  }
}

.ods_calendar_wrapper:focus{
  outline:2px solid transparent;
  outline-offset:2px
}

@media (min-width: 1024px){
  .ods_calendar_wrapper{
    display:flex;
    height:100%;
    flex-direction:column
  }
}

.ods_calendar_wrapper>div:focus{
  border-width:0px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),0 0 #0000;
  box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),var(--tw-shadow, 0 0 #0000)
}

@media (min-width: 1024px){
  .ods_calendar_wrapper>div{
    display:flex;
    height:100%;
    flex-direction:column
  }
}

.ods_calendar_icon{
  height:1.25rem;
  width:1.25rem
}

.ods_calendar_header_wrapper .react-datepicker__day-names{
  display:grid;
  grid-template-columns:repeat(7,minmax(0,1fr));
  grid-gap:1px;
  gap:1px;
  border-width:1px;
  --tw-border-opacity: 1;
  border-color:rgba(229, 231, 235,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-200),var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color:rgba(229, 231, 235,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-200),var(--tw-bg-opacity));
  text-align:center;
  font-size:.75rem;
  font-weight:600;
  line-height:1.5rem;
  --tw-text-opacity: 1;
  color:rgba(55, 65, 81,var(--tw-text-opacity));
  color:rgba(var(--color-dark-700),var(--tw-text-opacity))
}

@media (min-width: 1024px){
  .ods_calendar_header_wrapper .react-datepicker__day-names{
    flex:none
  }
}

.ods_calendar_day{
  display:flex;
  height:100%;
  align-items:center;
  justify-content:center;
  padding:.5rem .75rem
}

.ods_calendar_day:focus{
  outline:2px solid transparent;
  outline-offset:2px
}

@media (min-width: 1024px){
  .ods_calendar_day{
    display:block
  }
}

.ods_calendar_day_disabled>div{
  cursor:default
}

.ods_calendar_day_disabled>div>div>div:last-child>div:last-child{
  pointer-events:none
}

.ods_calendar .react-datepicker__header>div:last-child{
  display:none
}

.ods_calendar .react-datepicker__day-name{
  --tw-bg-opacity: 1;
  background-color:rgba(255, 255, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-white),var(--tw-bg-opacity));
  padding-top:.5rem;
  padding-bottom:.5rem
}

@media (min-width: 1024px){
  .ods_calendar .react-datepicker__month-container{
    display:flex;
    height:100%;
    flex-direction:column
  }
}

.ods_calendar .react-datepicker__month{
  border-width:0px;
  --tw-bg-opacity: 1;
  background-color:rgba(229, 231, 235,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-200),var(--tw-bg-opacity));
  font-size:.75rem;
  line-height:1.5rem;
  --tw-text-opacity: 1;
  color:rgba(55, 65, 81,var(--tw-text-opacity));
  color:rgba(var(--color-dark-700),var(--tw-text-opacity))
}

@media (min-width: 1024px){
  .ods_calendar .react-datepicker__month{
    display:flex;
    height:100%;
    flex-direction:column
  }
}

.ods_calendar .react-datepicker__week{
  display:flex;
  border-left-width:1px;
  border-bottom-width:1px;
  --tw-border-opacity: 1;
  border-color:rgba(229, 231, 235,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-200),var(--tw-border-opacity))
}

@media (min-width: 1024px){
  .ods_calendar .react-datepicker__week{
    height:100%
  }
}

.ods_calendar .react-datepicker__day{
  position:relative;
  flex-basis:100%;
  border-right-width:1px;
  --tw-border-opacity: 1;
  border-color:rgba(229, 231, 235,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-200),var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color:rgba(255, 255, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-white),var(--tw-bg-opacity))
}

.ods_calendar .react-datepicker__day:focus{
  outline:2px solid transparent;
  outline-offset:2px
}

.ods_calendar .react-datepicker__day--disabled{
  cursor:not-allowed;
  opacity:.4
}

.ods_calendar .react-datepicker__day--disabled:hover{
  background-color:transparent
}

.ods_calendar .react-datepicker__day--outside-month{
  --tw-bg-opacity: 1;
  background-color:rgba(249, 250, 251,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-50),var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:rgba(156, 163, 175,var(--tw-text-opacity));
  color:rgba(var(--color-light-400),var(--tw-text-opacity))
}

.ods_calendar .react-datepicker__day--today span{
  display:flex;
  height:1.25rem;
  width:1.25rem;
  align-items:center;
  justify-content:center;
  border-radius:9999px;
  --tw-bg-opacity: 1;
  background-color:rgba(79, 70, 229,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-600),var(--tw-bg-opacity));
  font-weight:600;
  --tw-text-opacity: 1;
  color:rgba(255, 255, 255,var(--tw-text-opacity));
  color:rgba(var(--color-white),var(--tw-text-opacity))
}

@media (min-width: 1024px){
  .ods_calendar .react-datepicker__day--today span{
    height:1.5rem;
    width:1.5rem
  }
}

.ods_calendar .react-datepicker__day--selected{
  --tw-bg-opacity: 1;
  background-color:rgba(199, 210, 254,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-200),var(--tw-bg-opacity))
}

.ods_calendar .react-datepicker__day--selected:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(224, 231, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-100),var(--tw-bg-opacity))
}

.ods_calendar_header{
  position:relative;
  z-index:20;
  display:flex;
  align-items:center;
  justify-content:space-between;
  border-left-width:1px;
  border-right-width:1px;
  border-top-width:1px;
  --tw-border-opacity: 1;
  border-color:rgba(229, 231, 235,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-200),var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color:rgba(249, 250, 251,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-50),var(--tw-bg-opacity));
  padding:1rem 1.5rem
}

@media (min-width: 1024px){
  .ods_calendar_header{
    flex:none
  }
}

.ods_calendar_header_title{
  font-size:1.125rem;
  line-height:1.75rem;
  font-weight:600;
  --tw-text-opacity: 1;
  color:rgba(17, 24, 39,var(--tw-text-opacity));
  color:rgba(var(--color-dark-900),var(--tw-text-opacity))
}

.ods_calendar_header_actions{
  display:flex;
  align-items:center
}

.ods_calendar_header_actions_button_icon{
  display:flex;
  align-items:center;
  justify-content:center;
  border-width:1px;
  --tw-border-opacity: 1;
  border-color:rgba(229, 231, 235,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-200),var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color:rgba(255, 255, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-white),var(--tw-bg-opacity));
  padding:.5rem 1rem .5rem .75rem;
  --tw-text-opacity: 1;
  color:rgba(156, 163, 175,var(--tw-text-opacity));
  color:rgba(var(--color-light-400),var(--tw-text-opacity))
}

.ods_calendar_header_actions_button_icon:hover{
  --tw-text-opacity: 1;
  color:rgba(107, 114, 128,var(--tw-text-opacity));
  color:rgba(var(--color-dark-500),var(--tw-text-opacity))
}

.ods_calendar_header_actions_button_icon:focus{
  position:relative
}

@media (min-width: 768px){
  .ods_calendar_header_actions_button_icon{
    width:2.25rem;
    padding-left:.5rem;
    padding-right:.5rem
  }

  .ods_calendar_header_actions_button_icon:hover{
    --tw-bg-opacity: 1;
    background-color:rgba(249, 250, 251,var(--tw-bg-opacity));
    background-color:rgba(var(--color-light-50),var(--tw-bg-opacity))
  }
}

.ods_calendar_header_actions_button_icon_leading{
  border-top-left-radius:.375rem;
  border-bottom-left-radius:.375rem;
  border-right-width:0px
}

.ods_calendar_header_actions_button_icon_trailing{
  border-top-right-radius:.375rem;
  border-bottom-right-radius:.375rem;
  border-left-width:0px
}

.ods_calendar_header_actions_button_text{
  display:none;
  border-top-width:1px;
  border-bottom-width:1px;
  --tw-border-opacity: 1;
  border-color:rgba(229, 231, 235,var(--tw-border-opacity));
  border-color:rgba(var(--color-light-200),var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color:rgba(255, 255, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-white),var(--tw-bg-opacity));
  padding-left:.875rem;
  padding-right:.875rem;
  font-size:.875rem;
  line-height:1.25rem;
  font-weight:500;
  --tw-text-opacity: 1;
  color:rgba(55, 65, 81,var(--tw-text-opacity));
  color:rgba(var(--color-dark-700),var(--tw-text-opacity))
}

.ods_calendar_header_actions_button_text:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(249, 250, 251,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-50),var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color:rgba(17, 24, 39,var(--tw-text-opacity));
  color:rgba(var(--color-dark-900),var(--tw-text-opacity))
}

.ods_calendar_header_actions_button_text:focus{
  position:relative
}

@media (min-width: 768px){
  .ods_calendar_header_actions_button_text{
    display:block
  }
}

.ods_calendar_header_months{
  display:flex;
  align-items:center;
  border-radius:.375rem;
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / .05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow:0 0 #0000,0 0 #0000,var(--tw-shadow);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow)
}

@media (min-width: 768px){
  .ods_calendar_header_months{
    align-items:stretch
  }
}

.danger_range .react-datepicker__day--in-selecting-range,.danger_range .react-datepicker__day--in-selecting-range:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(254, 226, 226,var(--tw-bg-opacity));
  background-color:rgba(var(--color-danger-100),var(--tw-bg-opacity))
}

.danger_range .react-datepicker__day--in-selecting-range>div{
  --tw-bg-opacity: 1;
  background-color:rgba(254, 226, 226,var(--tw-bg-opacity));
  background-color:rgba(var(--color-danger-100),var(--tw-bg-opacity))
}

.danger_range .react-datepicker__day--in-selecting-range>div:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(254, 226, 226,var(--tw-bg-opacity));
  background-color:rgba(var(--color-danger-100),var(--tw-bg-opacity))
}

.gray_range .react-datepicker__day--in-selecting-range,.gray_range .react-datepicker__day--in-selecting-range:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(249, 250, 251,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-50),var(--tw-bg-opacity))
}

.gray_range .react-datepicker__day--in-selecting-range>div{
  --tw-bg-opacity: 1;
  background-color:rgba(249, 250, 251,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-50),var(--tw-bg-opacity))
}

.gray_range .react-datepicker__day--in-selecting-range>div:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(249, 250, 251,var(--tw-bg-opacity));
  background-color:rgba(var(--color-light-50),var(--tw-bg-opacity))
}

.primary_range .react-datepicker__day--in-selecting-range,.primary_range .react-datepicker__day--in-selecting-range:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(224, 231, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-100),var(--tw-bg-opacity))
}

.primary_range .react-datepicker__day--in-selecting-range>div{
  --tw-bg-opacity: 1;
  background-color:rgba(224, 231, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-100),var(--tw-bg-opacity))
}

.primary_range .react-datepicker__day--in-selecting-range>div:hover{
  --tw-bg-opacity: 1;
  background-color:rgba(224, 231, 255,var(--tw-bg-opacity));
  background-color:rgba(var(--color-primary-100),var(--tw-bg-opacity))
}

:root{
  --rt-color-white: #fff;
  --rt-color-dark: #222;
  --rt-color-success: #8dc572;
  --rt-color-error: #be6464;
  --rt-color-warning: #f0ad4e;
  --rt-color-info: #337ab7;
  --rt-opacity: 0.9
}

.styles-module_tooltip__mnnfp{
  visibility:hidden;
  width:-webkit-max-content;
  width:max-content;
  position:absolute;
  top:0;
  left:0;
  padding:8px 16px;
  border-radius:3px;
  font-size:90%;
  pointer-events:none;
  opacity:0;
  transition:opacity 0.3s ease-out;
  will-change:opacity, visibility
}

.styles-module_fixed__7ciUi{
  position:fixed
}

.styles-module_arrow__K0L3T{
  position:absolute;
  background:inherit;
  width:8px;
  height:8px;
  -webkit-transform:rotate(45deg);
  transform:rotate(45deg)
}

.styles-module_no-arrow__KcFZN{
  display:none
}

.styles-module_clickable__Bv9o7{
  pointer-events:auto
}

.styles-module_show__2NboJ{
  visibility:visible;
  opacity:0.9;
  opacity:var(--rt-opacity)
}

.styles-module_dark__xNqje{
  background:#222;
  background:var(--rt-color-dark);
  color:#fff;
  color:var(--rt-color-white)
}

.styles-module_light__Z6W-X{
  background-color:#fff;
  background-color:var(--rt-color-white);
  color:#222;
  color:var(--rt-color-dark)
}

.styles-module_success__A2AKt{
  background-color:#8dc572;
  background-color:var(--rt-color-success);
  color:#fff;
  color:var(--rt-color-white)
}

.styles-module_warning__SCK0X{
  background-color:#f0ad4e;
  background-color:var(--rt-color-warning);
  color:#fff;
  color:var(--rt-color-white)
}

.styles-module_error__JvumD{
  background-color:#be6464;
  background-color:var(--rt-color-error);
  color:#fff;
  color:var(--rt-color-white)
}

.styles-module_info__BWdHW{
  background-color:#337ab7;
  background-color:var(--rt-color-info);
  color:#fff;
  color:var(--rt-color-white)
}

